import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import axios from "axios";
import $ from "jquery";
import { AJAROOT } from "@/common/gdata/global.js";
import queryString from "query-string";
import { checkUserLogin, MyToast, MyToastShow } from "@/jscript/common.js";

import "@/css/user.css";
import rjt from "../../../statics/rjt.png";

class Index extends Component {

  constructor(props) {
    super(props);
     this.posted=false;
    this.state = {
      //  myu: "",
      title: "",
      editid: "",
      username: "",
      lastname: "",
      telephone: "",
      email: "",
      password: "",
      remark: "",
      opened: "1",
      selectedbranch: ",",
      branchlist: [],
      postresult: "",
      routinelist: [],
      routineid: 0,
      mytoast: {
        show: false,
      },
    };
  }

 
  render() {
    const { myusername } = this.props;
    //  let menujson = Menu();
    // console.log(menujson);

    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;

    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px", width: "90%" }}
      >
        <div className="pagetitle">
          <div style={{ float: "left" }}>
            <Link to={"/user/employee/list"}>Employee List</Link>{" "}
            <img src={rjt} style={{ width: "18px", height: "18px" }} />{" "}
            {this.state.title}
          </div>
          <div
            style={{
              float: "right",
            }}
          >
            <div
              style={{}}
              onClick={() => {
                window.history.back(-1);
              }}
            >
              <span className="iconfont fanhui">&#xe625;</span>
            </div>
          </div>
        </div>
        <div className="row textright">
          <div>
            <Link to="/user/employee/list">
              <input
                type="button"
                name="button1"
                value="Employee List"
                className="add"
              ></input>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
            {/* <div className={this.state.uptype === "1" ? "row" : "nodis"}>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 ">
                姓名:
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 ">
                <input
                  value={this.state.upgzhnickname}
                  onChange={this.changeFirstName.bind(this)}
                />
              </div>
            </div> */}

            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * Account:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  readOnly={this.state.editid == "" ? false : true}
                  value={this.state.username}
                  onChange={this.changeUsername.bind(this)}
                />
              </div>
            </div>
            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * Nickname:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.lastname}
                  onChange={this.changeName.bind(this)}
                />
              </div>
            </div>
            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * Telephone:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.telephone}
                  onChange={this.changeTelephone.bind(this)}
                />
              </div>
            </div>
            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Email:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.email}
                  onChange={this.changeEmail.bind(this)}
                />
              </div>
            </div>
            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                {this.state.editid == "" && <span>*</span>} Password:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.password}
                  onChange={this.changePassword.bind(this)}
                />
              </div>
            </div>
            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Remark:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.remark}
                  onChange={this.changeRemark.bind(this)}
                />
              </div>
            </div>
            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Status:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  type="radio"
                  name="opened"
                  value="1"
                  onChange={() => this.setOpened("1")}
                  checked={this.state.opened == "1" ? true : false}
                  style={{
                    width: "15px",
                    height: "15px",
                    padding: "5px",
                  }}
                ></input>
                {" Normal"}
                <input
                  type="radio"
                  name="opened"
                  value="0"
                  onChange={() => this.setOpened("0")}
                  checked={this.state.opened == "0" ? true : false}
                  style={{
                    width: "15px",
                    height: "15px",
                    padding: "5px",
                    marginLeft: "10px",
                  }}
                ></input>
                {" Closed "}
              </div>
            </div>
            {/*    <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * Routine Time:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                {this.state.routinelist.map((item, index) => {
                  return (
                    <div key={"routine" + index}>
                      <input
                        className="checkbox"
                        type="radio"
                        value={item.username}
                        checked={
                          this.state.routineid * 1 == item.id ? true : false
                        }
                        onChange={() => this.selRoutine(item.id)}
                      />
                      {"　" + item.name}
                    </div>
                  );
                })}
              </div>
            </div> */}

            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * Branch:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                {(this.state.selectedbranch)&&this.state.branchlist.map((item, index) => {
                  return (
                    <div key={"branch" + index}>
                      <input
                        className="checkbox clear"
                        type="checkbox"
                        value={item.username}
                        checked={
                          this.state.selectedbranch.indexOf(
                            "," + item.username + ","
                          ) >= 0
                            ? true
                            : false
                        }
                        onChange={() => this.selBranch(item.username)}
                      />
                      {"　" + item.lastname}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 textcenter">
                <button className="submit" onClick={() => this.submit()}>
                  Submit
                </button>
              </div>
            </div>
            <div className="row">
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter postresult"
                style={{ width: "70%", marginTop: "10px" }}
              >
                {this.state.postresult}
              </div>
            </div>
          </div>
        </div>
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
  setOpened(value) {
    this.setState({
      opened: value,
    });
  }
  selRoutine(value) {
    this.setState({
      routineid: value,
    });
  }
  selBranch(username) {
    let selectedbranch = this.state.selectedbranch;
    if (selectedbranch.indexOf("," + username + ",") >= 0)
      selectedbranch = selectedbranch.replace("," + username + ",", ",");
    else {
      selectedbranch = selectedbranch + username + ",";
    }

    this.setState({
      selectedbranch: selectedbranch,
    });
  }
  changeUsername(e) {
    let val = e.target.value;
    this.setState({
      username: val,
    });
  }
  changeName(e) {
    let val = e.target.value;
    this.setState({
      lastname: val,
    });
  }
  changePassword(e) {
    let val = e.target.value;
    console.log(val);
    this.setState({
      password: val,
    });
  }
  changeTelephone(e) {
    let val = e.target.value;
    this.setState({
      telephone: val,
    });
  }
  changeEmail(e) {
    let val = e.target.value;
    this.setState({
      email: val,
    });
  }
  changeRemark(e) {
    let val = e.target.value;
    this.setState({
      remark: val,
    });
  }
  /* getRoutineData(myusername, ukey, id) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&onlyname=1&action=routinelist";

    axios.get(geturl).then((res) => {
      const result = res.data;

      this.setState({
        routinelist: result.list,
      });
      if (id == "" && result.list.length > 0) {
        this.setState({
          routineid: result.list[0].id,
        });
      }
      // this.setState({}); 
    });
  } */
  submit() {
  if(this.posted)return;

  
    const { myusername, ukey } = this.props;
    //console.log(this.state.pages);
    // document.getElementById("result2").style.color = "#000000";
    //
    this.setState({
      postresult: "Waiting....",
    });
    // document.getElementById("result2").innerHTML = "正在提交....";
    let dianscope = this.state.selectedbranch;
    const postdata = {
      uid: myusername,
      editid: this.state.editid,
      username: this.state.username,
      telephone: this.state.telephone,
      email: this.state.email,
      password: this.state.password,
      lastname: this.state.lastname,
      remark: this.state.remark,
      dianscope: dianscope,
      opened: this.state.opened,
      routineid: this.state.routineid,
    };

    const this2 = this;
    var error = "";

    if (this.state.username === "") error = "Please fill in account";
    else if (this.state.telephone === "") error = "Please fill in telephone";
    else if (this.state.lastname == "") error = "Please fill in nickname";
    // else if (this.state.routineid == 0) error = "Please select routine time";
    else if (this.state.password == "" && this.state.editid == "")
      error = "Please fill in password";
    else if (dianscope == ",") error = "Please select branch";
    //document.getElementById("result2").style.color = "red";
    if (error !== "") {
      this.setState({
        postresult: error,
      });
      return;
    }
     
    let posturl= AJAROOT +
          "/postaja/savexx.aspx?action=saveemployee&u=" +
          myusername +
          "&ukey=" +
          encodeURIComponent(ukey) +
          "";
      console.log(posturl);
      $.ajax({
        type: "POST",
        dataType: "json",
        url:
         posturl,
        data: JSON.stringify(postdata),
      })
        .done(function (res) {
          var r = res.r;
 this.posted=true;
          if (r === 1) {
            //  this.postresult = "登陆成功！";
   
            MyToastShow(
              this2,
              2,
              "Successfully! ",
              150,
              80,
              "success",
              "addbranch"
            );
            this2.setState({
              postresult: "Successfully! ",
            });
//window.history.back(-1);
         this2.props.history.push("/user/employee/list");
          } else {
            MyToastShow(this2, 2, res.s, 200, 120, "fail", "addemployee");
            //document.getElementById("result2").style.color = "red";
            this2.setState({
              postresult: res.s,
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    
  }

  getBranchData(myusername, ukey, editid) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=branchlist";

    axios.get(geturl).then((res) => {
      const result = res.data;
  
      let selectedbranch = ",";
      result.list.forEach((element, j) => {
        selectedbranch = selectedbranch + element.username + ",";
      });
      //  let groupright = eval(result.groupright);
      if (editid == "") {
        this.setState({
          selectedbranch: selectedbranch,
          branchlist: result.list,
        });
      } else {
        this.setState({
          //  selectedbranch: selectedbranch,
          branchlist: result.list,
        });
      }
      // this.setState({}); /**/
    });
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;
    var { id } = queryString.parse(this.props.location.search);
    if (id == undefined) id = "";
    var title = "Add Employee";
    if (id != "") {
      title = "Edit Employee";
    }
    document.title = title;
    this.setState({
      title: title,
      editid: id,
    });

    // getUserIndexData(this, myusername, ukey);
    if (id != "") this.getData(myusername, encodeURIComponent(ukey), id);
    this.getBranchData(myusername, encodeURIComponent(ukey), id);
    // this.getRoutineData(myusername, encodeURIComponent(ukey), id);
    //  this.getData(myusername, ukey);
    //  MyToastShow(this, 200, "Successfully! ", 150, 80, "success", "addgroup");
  }

  getData(myusername, ukey, id) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=employeeinfo&id=" +
      id +
      "";
          console.log(geturl);
    axios.get(geturl).then((res) => {
      const result = res.data;
    console.log(result);
      //  let groupright = eval(result.groupright);
      this.setState({
        editid: result.id,
        username: result.username,

        lastname: result.lastname,
        telephone: result.telephone,
        email: result.email,
        opened: result.opened,
        remark: result.remark,
        //  routineid: result.routineid,
        selectedbranch: result.dianscope,
      });
      // this.setState({}); /**/
    });
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  componentWillUpdate() {}
  componentDidUpdate() {}
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    lastname: state.getIn(["gdata", "lastname"]),
  };
};

export default connect(mapStateToProps, null)(Index);
