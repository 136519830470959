import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import queryString from "query-string";
import { AJAROOT, FormKey } from "@/common/gdata/global.js";
import { Link, withRouter } from "react-router-dom";
import {
  Fenye,
  MyToast,
  MyToastShow,
  TurnNull,
  getDateFromPeriodno,
} from "@/jscript/common.js";
import { Pages } from "@/pages/type/style.js";
//import $ from "jquery";

import "@/css/user.css";
import "@/css/react-datepicker.css";
//import rjt from "../../../statics/rjt.png";
import DatePicker from "react-datepicker";

const LCss = {
  agree: {
    padding: "15px",
  },
  signdiv: {
    verticalAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  signcave: {
    height: "52px",
    lineHeight: "52px",
    width: "202px",

    border: "1px solid #ccc",
    position: "relative",
    float: "left",
    textAlign: "left",
  },
  canve: {
    background: "#fff",
    position: "absolute",
    top: 0,
    left: 0,
  },
  radio: {
    width: "18px",
    height: "18px",
    marginLeft: "10px",
    marginRight: "3px",
  },
  conditioncheck: {
    display: "flex",

    alignContent: "center",
    paddingLeft: "5px",
    Height: "25px",
    lineHeight: "25px",
  },
  conditiondiv: {
    width: "100%",
    paddingLeft: "5px",
    fontWeight: "bold",
    height: "30px",
    lineHeight: "30px",
  },
  checkout: {
    width: "18px",
    height: "18px",
    marginRight: "5px",
  },
  field: {
    display: "inline-block",
    paddingLeft: "10px",
    paddingRight: "10px",

    borderBottom: "1px solid #000",
    marginRight: "18px",
    marginLeft:"5px"
    
  },
};

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      mounted: 0,
      title: "",
      list: [],
      schusername: "",

      p_page: 1,
      p_username: "",
      p_zsxm: "",
      p_fromdate: "",
      p_todate: "",
      schfromdate: "",
      fromdate: "",
      schtodate: "",
      todate: "",
      error: "",

      postresult: "",
      //  page: 1,
      pagesize: 30,
      totalpage: 0,
      page: 1,

      mytoast: {
        show: false,
      },
      //   page: 1,
    };
  }

  render() {
    const { myusername, myusertype } = this.props;

    // let checkstatus = checkUserLogin(this.props, myusername);

    // if (checkstatus != "") return checkstatus;
    // let _username = TurnNull(this.state.username);
    //let _name = TurnNull(this.props.name);
    // let _telephone = TurnNull(this.props.telephone);
    // const Now = new Date();

    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px", width: "90%" }}
      >
        <div className="pagetitle">{this.state.title}</div>

        {/*  <div className="row " style={{ height: "55px", lineHeight: "55px" }}>
          <div
            className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
            onClick={() => {
              window.history.back(-1);
            }}
          >
           
              <span className="iconfont fanhui">&#xe625;</span>
        
          </div>
          <div
            className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textcenter "
            style={{ fontSize: "19px", fontWeight: "bolder" }}
          >
            {this.state.title}
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
           
              <input
                style={{ height: "35px", lineHeight: "35px" }}
                type="button"
                value="Print"
                onClick={() =>
                  this.printPage(
                    "/user/customer/planlist?username=" +
                      this.state.username +
                      "&fromdate="+this.state.fromdate+""
                  )
                }
              ></input>
          
          </div>
        </div> */}
        <div className="row textright">
          <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10  textleft schform">
            <input
              style={{
                float: "left",
                width: "150px",
              }}
              className="schfield"
              name="schusername"
              value={this.state.schusername}
              onChange={(ref) => this.changeField(ref, "schusername")}
              placeholder="Account"
            ></input>

            <div
              style={{
                width: "130px",
                float: "left",
              }}
            >
              <DatePicker
                className="schfield schdate"
                selected={this.state.fromdate}
                onChange={(ref) => this.setFromdate(ref)}
                showYearDropdown
                dateFormat="yyyy-MM-dd"
                placeholderText="Input from date"
              />
            </div>
            <div
              style={{
                width: "22px",
                float: "left",
              }}
            >
              -
            </div>
            <div
              style={{
                width: "130px",
                float: "left",
              }}
            >
              <DatePicker
                className="schfield schdate"
                selected={this.state.todate}
                onChange={(ref) => this.setTodate(ref)}
                showYearDropdown
                dateFormat="yyyy-MM-dd"
                placeholderText="Input end date"
              />
            </div>
            <input
              onClick={() => this.search()}
              type="button"
              name="schbut"
              value=" Search "
              className="add"
            ></input>

            {this.state.list.length>0&& (
              <input
                style={{
                  marginLeft: "25px",
                  height: "30px",
                  borderRadius: "5px",
                  width: "100px",
                }}
                type="button"
                value="Print"
                onClick={() =>
                  this.printPage(
                    "/user/customer/planlist?username=" +
                      this.state.schusername +
                      "&fromdate=" +
                      this.state.schfromdate +
                       "&todate=" +
                      this.state.schtodate +
                      ""
                  )
                }
              ></input>
            )}
          </div>
        </div>
        <div style={{ padding: "15px" }}></div>
        <div className="PageNext" id="content">
          {this.state.list.map((item, index) => {
            // if (_status == "2") _status = "Commented ";
            return (
              <div
                className="row "
                key={"group" + index}
                style={{ position: "relative", paddingBottom: "15px",borderBottom:"2px solid #aaaaaa",margin:"15px" }}
              >
            
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                          Treatement Plan for:
                          <span
                            style={Object.assign({}, LCss.field, {
                              width: "150px",
                            })}
                          >
                            {item.customer}
                          </span>{" "}
                          Date:
                          <span
                            style={Object.assign({}, LCss.field, {
                              width: "150px",
                            })}
                          >
                            {getDateFromPeriodno(item.periodno)}
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
                          Limitations of activities of daily living:
                          <span
                            style={Object.assign({}, LCss.field, {
                              borderBottom: "0px",
                            })}
                          >
                            <input
                              className="printField"
                              style={{ width: "200px" }}
                              value={item.limititation}
                              onChange={(ref) =>
                                this.changeField(ref, "limititation")
                              }
                            />
                          </span>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
                          Treatment plan discussed with Client:
                          <span
                            style={Object.assign({}, LCss.field, {
                              borderBottom: "0px",
                            })}
                          >
                            <input
                              type="radio"
                              name="discussed"
                              value="Y"
                              onChange={(ref) =>
                                this.setCheck(ref, "discussed")
                              }
                              checked={item.discussed == "Y" ? true : false}
                              style={{
                                width: "18px",
                                height: "18px",
                              }}
                            ></input>
                            {"Yes "}
                            <input
                              type="radio"
                              name="discussed"
                              value="N"
                              onChange={(ref) =>
                                this.setCheck(ref, "discussed")
                              }
                              checked={item.discussed == "N" ? true : false}
                              style={{
                                width: "18px",
                                height: "18px",
                              }}
                            ></input>
                            {"No"}
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
                          Clients Goal / Treatment Goal:
                          <span
                            style={Object.assign({}, LCss.field, {
                              borderBottom: "0px",
                            })}
                          >
                            <input
                              className="printField"
                              style={{ width: "200px" }}
                              value={this.state.goal}
                              onChange={(ref) => this.changeField(ref, "goal")}
                            />
                          </span>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
                          Received informed consent for treatment:
                          <span
                            style={Object.assign({}, LCss.field, {
                              borderBottom: "0px",
                            })}
                          >
                            <input
                              type="radio"
                              name="received"
                              value="Y"
                              onChange={(ref) =>
                                this.setCheck(ref, "discussed")
                              }
                              checked={item.received == "Y" ? true : false}
                              style={{
                                width: "18px",
                                height: "18px",
                              }}
                            ></input>
                            {"Yes "}
                            <input
                              type="radio"
                              name="received"
                              value="N"
                              onChange={(ref) =>
                                this.setCheck(ref, "discussed")
                              }
                              checked={item.received == "N" ? true : false}
                              style={{
                                width: "18px",
                                height: "18px",
                              }}
                            ></input>
                            {"No"}
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                          Type / Focus of treatment:
                          <span
                            style={Object.assign({}, LCss.field, {
                              borderBottom: "0px",
                            })}
                          >
                            <input
                              className="printField"
                              style={{ width: "360px" }}
                              value={item.focus}
                              onChange={(ref) => this.changeField(ref, "focus")}
                            />
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
                          Frequency:
                          <span
                            style={Object.assign({}, LCss.field, {
                              borderBottom: "0px",
                            })}
                          >
                            <input
                              className="printField"
                              style={{ width: "200px" }}
                              value={item.frequency}
                              onChange={(ref) =>
                                this.changeField(ref, "frequency")
                              }
                            />
                          </span>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
                          Duration:
                          <span
                            style={Object.assign({}, LCss.field, {
                              borderBottom: "0px",
                            })}
                          >
                            <input
                              className="printField"
                              style={{ width: "200px" }}
                              value={item.duratiuon}
                              onChange={(ref) =>
                                this.changeField(ref, "duratiuon")
                              }
                            />
                          </span>
                        </div>
                      </div>
                      <div className="row" style={{ height: "25px" }}>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                          <span style={{ fontWeight: "bold" }}>
                            Areas to be treated:
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                          {item.areas.map((item, index) => {
                            let q = "";
                            let haveq = false;
                            if (typeof item.q != "undefined") {
                              haveq = true;
                              q = item.q;
                            }
                            let width = "160px";
                            if (haveq) width = "320px";
                            return (
                              <div
                                key={"acer" + index}
                                style={{
                                  display: "flex",
                                  width: width,
                                  float: "left",
                                  padding: "5px",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  name="ser"
                                  value="Y"
                                  checked={item.c == "Y" ? true : false}
                                   onChange={(ref) =>
                                this.changeField(ref, "")
                              }
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    marginRight: "3px",
                                  }}
                                ></input>
                                {item.name}

                                {haveq && (
                                  <div
                                    style={{
                                      paddingLeft: "5px",
                                    }}
                                  >
                                    , {q}{" "}
                                    <input
                                      className="printField"
                                      style={{ height: "25px", with: "100px" }}
                                      value={item.a}
                                      onChange={(ref) =>
                                        this.changeField(ref, "")
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
                          Accessments Performed:
                          <span
                            style={Object.assign({}, LCss.field, {
                              borderBottom: "0px",
                            })}
                          >
                            <input
                              className="printField"
                              style={{ width: "200px" }}
                              value={item.performed}
                              onChange={(ref) => this.changeField(ref, "")}
                            />
                          </span>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
                          Anticipated Progression of Responses:
                          <span
                            style={Object.assign({}, LCss.field, {
                              borderBottom: "0px",
                            })}
                          >
                            <input
                              className="printField"
                              style={{ width: "200px" }}
                              value={item.antiresponse}
                              onChange={(ref) => this.changeField(ref, "")}
                            />
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
                          Results of Assessments:
                          <span
                            style={Object.assign({}, LCss.field, {
                              borderBottom: "0px",
                            })}
                          >
                            <input
                              className="printField"
                              style={{ width: "200px" }}
                              value={item.result}
                              onChange={(ref) => this.changeField(ref, "")}
                            />
                          </span>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
                          Remedial exercises / Hydrotherapy recommended:
                          <span
                            style={Object.assign({}, LCss.field, {
                              borderBottom: "0px",
                            })}
                          >
                            <input
                              className="printField"
                              style={{ width: "200px" }}
                              value={item.recommended}
                              onChange={(ref) => this.changeField(ref, "")}
                            />
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
                          Reassessment schedule:
                          <span
                            style={Object.assign({}, LCss.field, {
                              borderBottom: "0px",
                            })}
                          >
                            <input
                              className="printField"
                              style={{ width: "200px" }}
                              value={item.schedule}
                              onChange={(ref) => this.changeField(ref, "")}
                            />
                          </span>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
                          Contraindications / Risks:
                          <span
                            style={Object.assign({}, LCss.field, {
                              borderBottom: "0px",
                            })}
                          >
                            <input
                              className="printField"
                              style={{ width: "200px" }}
                              value={item.contraindications}
                              onChange={(ref) => this.changeField(ref, "")}
                            />
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
                          Referrals:
                          <span
                            style={Object.assign({}, LCss.field, {
                              borderBottom: "0px",
                            })}
                          >
                            <input
                              className="printField"
                              style={{ width: "200px" }}
                              value={item.referral}
                              onChange={(ref) => this.changeField(ref, "")}
                            />
                          </span>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft"></div>
                      </div>
                   
                </div>
              </div>
            );
          })}
        </div>
        <div className="row">
          <Pages>
            {Fenye(
              "/user/customer/planlist?username=" +
                this.state.schusername +
                "&fromdate=" +
                this.state.schfromdate +
                "&todate=" +
                this.state.schtodate +
                "&pagesize=" +
                this.state.pagesize +
                "",
              this.state.page,
              this.state.totalpage
            )}
          </Pages>
        </div>

        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }

  printPage(url) {
 
    var orihtml = window.document.body.innerHTML; //从标记里获取需要打印的页面
    //  document.getElementById("content").style.padding = "10px";
    var printHtml = document.getElementById("content").innerHTML; //从标记里获取需要打印的页面

    window.document.body.innerHTML = printHtml; //需要打印的页面

    window.print();
    window.document.body.innerHTML = orihtml;
    window.open(url, "_self");
  }
  //changeField(e, field) {}
  // setCheck(ref, field) {}
  search() {
    const { myusername, ukey } = this.props;

    let fromdate = "";
    if (this.state.fromdate != "") {
      fromdate = this.state.fromdate;
      fromdate =
        fromdate.getFullYear() +
        "-" +
        (fromdate.getMonth() + 1) +
        "-" +
        fromdate.getDate();
    }

    let todate = "";
    if (this.state.todate != "") {
      todate = this.state.todate;
      todate =
        todate.getFullYear() +
        "-" +
        (todate.getMonth() + 1) +
        "-" +
        todate.getDate();
    }

    let schusername = this.state.schusername;
    //let order = this.state.order;
    // let page = this.state.page;

    let url =
      "/user/customer/planlist?username=" +
      schusername +
      "&fromdate=" +
      fromdate +
      "&todate=" +
      todate +
      "&pagesize=" +
      this.state.pagesize +
      "&page=1";

    this.props.history.push(url);
  }
  setFromdate(val) {
    let fromdate = "";
    let schfromdate = "";
    if (val == null) {
      schfromdate = "";
      fromdate = "";
    } else {
      let date = new Date(
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      );
      fromdate = date;
      schfromdate =
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate();
    }

    this.setState({
      fromdate: fromdate,
      schfromdate: schfromdate,
    });
  }

  setTodate(val) {
    let todate = "";
    let schtodate = "";
    if (val == null) {
      schtodate = "";
      todate = "";
    } else {
      let date = new Date(
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      );
      todate = date;
      schtodate =
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate();
    }

    this.setState({
      todate: todate,
      schtodate: schtodate,
    });
  }
setCheck(e,field){}
  changeField(e, field) {
    let val = e.target.value;

    if (field == "schusername") {
      this.setState({
        schusername: val,
      });
    } else if (field == "schzsxm") {
      this.setState({
        schzsxm: val,
      });
    } else if (field == "schtelephone") {
      this.setState({
        schtelephone: val,
      });
    } else if (field == "schservice") {
      this.setState({
        schservice: val,
      });
    }
  }
  getData(
    myusername,
    ukey,
    page,
    username,

    fromdate,
    todate
  ) {
    
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&customer=" +
      username +
      "&todate=" +
      todate +
      "&fromdate=" +
      fromdate +
      "&page=" +
      page +
      "&pagesize=" +
      this.state.pagesize +
      "&action=planlist";
    console.log(geturl);
    axios.get(geturl).then((res) => {
      const result = res.data;
      console.log(result);
      /*  if (mounted == 1) {
        this.setState({
          mounted: 1,
        });
      } */
      this.setState({
        list: result.list,
        // totalpage: result.num,
        totalpage: Math.ceil(result["num"] / this.state.pagesize),
        //     num: result["num"],
        //  page: page,
      });
      
    });
  }
  loadData(mounted) {
    const { myusername, ukey } = this.props;
    var {
      page,
      username,

      fromdate,
      todate,
    } = queryString.parse(this.props.location.search);
    if (page == null) page = 1;
    if (username == null) username = "";

    if (fromdate == null) fromdate = "";
    if (todate == null) todate = "";
    var dfromdate = "";
    if (fromdate != "") {
      dfromdate = new Date(fromdate);
    }

    var dtodate = "";
    if (todate != "") {
      dtodate = new Date(todate);
    }
   

    if (mounted==0) {
    this.setState({
       
        page: page,
        schusername: username,
        schfromdate: fromdate,
        schtodate: todate,
        fromdate: dfromdate,
        todate: dtodate,

        p_username: username,
        p_page: page,
        p_todate: todate,
        p_fromdate: fromdate,
      });
      this.getData(
        myusername,
        encodeURIComponent(ukey),
        page,
        username,

        fromdate,
        todate
      );
      
    } else {
      if (
        page !== this.state.p_page ||
        username !== this.state.p_username ||
        fromdate !== this.state.p_fromdate ||
        todate !== this.state.p_todate
      ) {
        this.getData(
          myusername,
          encodeURIComponent(ukey),
          page,
          username,

          fromdate,
          todate
        );

        this.setState({
          p_username: username,
          p_page: page,
          p_todate: todate,
          p_fromdate: fromdate,
        });
      }
    }
  }
  componentDidMount() {

      var title = "Plan List";
    document.title = title;
    this.loadData(0);

    this.setState({
     title: title,
      mounted: 1,
    }); /* */
    // this.getEmployeeData(myusername, encodeURIComponent(ukey));
    //if (IsPC()) getUserIndexData(this, myusername, ukey);
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {
    if (this.state.mounted == 1) {
      this.loadData(1);
      
    }
  }
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),

 
  };
};

export default connect(mapStateToProps, null)(withRouter(Index));
