import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
//import SignatureCanvas from "react-signature-canvas";
import $ from "jquery";
import { AJAROOT } from "@/common/gdata/global.js";
//import MySignatureCanvas from "@/jscript/myCanve.js";
import {
  checkUserLogin,
  IsPC,
  MyToast,
  //  MyToastShow,
  getDateFromPeriodno,
} from "@/jscript/common.js";
import axios from "axios";
import "@/css/user.css";
import "@/css/react-datepicker.css";
import SignatureCanvas from "react-signature-canvas";
//import DatePicker from "react-datepicker";
let emptysign =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

const LCss = {
  agree: {
    padding: "15px",
  },
  signdiv: {
    verticalAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  signcave: {
    height: "53px",
    lineHeight: "53px",
    width: "162px",

    border: "1px solid #ccc",
    position: "relative",

    textAlign: "left",
    marginLeft: "3px",
  },
  canve: {
    background: "#fff",
    position: "absolute",
    top: 0,
    left: 0,
    height: "50px",
  },
  radio: {
    width: "18px",
    height: "18px",
    marginLeft: "10px",
    marginRight: "3px",
  },
  conditioncheck: {
    display: "flex",

    alignContent: "center",
    paddingLeft: "5px",
    Height: "25px",
    lineHeight: "25px",
  },
  conditiondiv: {
    width: "100%",
    paddingLeft: "5px",
    fontWeight: "bold",
    height: "30px",
    lineHeight: "30px",
  },
  checkout: {
    width: "18px",
    height: "18px",
    marginRight: "5px",
  },
  field: {
    display: "inline-block",
    paddingLeft: "10px",
    paddingRight: "10px",
    width: "360px",
    borderBottom: "1px solid #000",
    marginRight: "18px",
    marginLeft: "5px",
  },
};
class User extends Component {
  constructor(props) {
    super(props);
    this.canvasRef1 = React.createRef();
    this.state = {
      //  myu: "",

      title: "",
      error: "",
      editid: "",
      bookid: "",
      customer: "",
      periodno: "",
      fromtime: "",
      totime: "",
      minutes: 0,
      price: 0,
      employeename: "",
      assessment: "",
      treatment: "",

      techniquesused: [],

      acerstreated: [],
      clinicalfinding: "",
      clientsreaction: "",

      recommandedselfcare: "",
      therapistsign: emptysign,
      postresult: "",
      mytoast: {
        show: false,
      },
      isprint: false,
      haveimg1: 0,
    };
  }

  render() {
    const { myusername } = this.props;
    const IsMobile = !IsPC();
    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;
    //var zsxm = this.state.zsxm;
    // if (zsxm === "") zsxm = this.state.myusername;

    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px" }}
      >
        <div className="row " style={{ height: "55px", lineHeight: "55px" }}>
          <div
            className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
            onClick={() => {
              this.back();
            }}
          >
            {!this.state.isprint && (
              <span className="iconfont fanhui">&#xe625;</span>
            )}
          </div>
          <div
            className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textcenter "
            style={{ fontSize: "19px", fontWeight: "bolder" }}
          >
            {!this.state.isprint ? this.state.title : ""}
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            {!this.state.isprint ? (
              <a
                onClick={() =>
                  this.print(
                    "/user/form/treatnote?bookid=" +
                      this.state.bookid +
                      "&print=1"
                  )
                }
              >
                {!IsMobile ? "Print" : ""}
              </a>
            ) : (
              <input
                style={{ height: "35px", lineHeight: "35px" }}
                type="button"
                value="Print"
                onClick={() =>
                  this.printPage(
                    "/user/form/treatnote?bookid=" +
                      this.state.bookid +
                      "&print=1"
                  )
                }
              ></input>
            )}
          </div>
        </div>
        <div className="PageNext" id="content">
          {this.state.isprint && (
            <div className="printtitle">{this.state.title}</div>
          )}
          <div className="row" style={{ paddingTop: "10px" }}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  Treatment notes for:
                  <span style={LCss.field}>{this.state.customer}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  Date:
                  <span
                    style={Object.assign({}, LCss.field, {
                      width: "150px",
                    })}
                  >
                    {getDateFromPeriodno(this.state.periodno)}
                  </span>
                  Time:
                  <span
                    style={Object.assign({}, LCss.field, {
                      width: "150px",
                    })}
                  >
                    {this.state.fromtime} - {this.state.totime}
                  </span>
                  Duration:
                  <span
                    style={Object.assign({}, LCss.field, {
                      width: "150px",
                    })}
                  >
                    {this.state.minutes} min
                  </span>
                  Fee $:
                  <span
                    style={Object.assign({}, LCss.field, {
                      width: "100px",
                      marginRight: "0px",
                    })}
                  >
                    {this.state.price}
                  </span>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft"
                  style={{
                    height: "55px",
                    lineHeight: "55px",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      borderBottom: "0px",
                  
                      display: IsMobile?"block":"flex",
              
                    alignContent: "center",
                    alignItems: "center",
                    }}
                  >
                    <span style={{display:"block",}}>Informed consent received:</span>
                    <span style={{display:"block",
                    
              
                    alignContent: "center",
                    alignItems: "center",}}><input
                      type="checkbox"
                      name="treatment"
                      value="Y"
                      onChange={(ref) => this.changeField2(ref, 0, "treatment")}
                      checked={this.state.treatment == "Y" ? true : false}
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "3px",
                        marginLeft: "3px",
                      }}
                    ></input>
                    {"treatment"}
                    <input
                      type="checkbox"
                      name="assessment"
                      value="Y"
                      onChange={(ref) =>
                        this.changeField2(ref, 0, "assessment")
                      }
                      checked={this.state.assessment == "Y" ? true : false}
                      style={{
                        width: "18px",
                        height: "18px",
                        marginLeft: "8px",
                        marginRight: "3px",
                      }}
                    ></input>
                    {"assessment"}</span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft"
                  style={{
                    height: "55px",
                    lineHeight: "55px",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      float: "left",
                      height: "55px",
                      lineHeight: "55px",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Therapist:
                    <span style={LCss.signcave}>
                      {this.state.haveimg1 == 0 ? (
                        <SignatureCanvas
                          penColor="black"
                          ref={this.canvasRef1} //用于调用插件的API
                          canvasProps={{
                            width: 160,
                            height: 50,
                            className: "sigCanvas",
                            style: LCss.canve,
                          }}
                        />
                      ) : (
                        <span
                          style={{
                            textAlign: "center",
                            height: "50px",
                            lineHeight: "50px",
                          }}
                        >
                          <img src={this.state.therapistsign} style={{}}></img>
                        </span>
                      )}
                    </span>
                    <button
                      style={{
                        marginLeft: "10px",
                        height: "30px",
                        lineHeight: "30px",
                      }}
                      onClick={() => this.clearCanv(1)}
                    >
                      {this.state.haveimg1 == 1 ? "Reset" : "Clear"}
                    </button>
                  </div>
                </div>
              </div>

              <div className="row" style={{ height: "25px" }}>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  <span style={{ fontWeight: "bold" }}>Techniques Used:</span>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  {this.state.techniquesused.map((item, index) => {
                    let q = "";
                    let haveq = false;
                    if (typeof item.q != "undefined") {
                      haveq = true;
                      q = item.q;
                    }
                    let width = "160px";
                    if (haveq) width = "480px";
                    return (
                      <div
                        key={"ser" + index}
                        style={{
                          display: "flex",
                          width: width,
                          float: "left",
                          padding: "5px",
                        }}
                      >
                        <input
                          type="checkbox"
                          name="ser"
                          value="Y"
                          onChange={(ref) =>
                            this.changeField2(ref, index, "techniquesused")
                          }
                          checked={item.c == "Y" ? true : false}
                          style={{
                            width: "18px",
                            height: "18px",
                            marginRight: "3px",
                          }}
                        ></input>
                        {item.name}

                        {haveq && (
                          <div
                            style={{
                              paddingLeft: "5px",
                            }}
                          >
                            {q}{" "}
                            <input
                              className="printField"
                              style={{ height: "25px", with: "100px" }}
                              value={item.a}
                              onChange={(ref) =>
                                this.changeField3(ref, index, "techniquesused")
                              }
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="row" style={{ height: "25px" }}>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  <span style={{ fontWeight: "bold" }}>Areas Treated:</span>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  {this.state.acerstreated.map((item, index) => {
                    let q = "";
                    let haveq = false;
                    if (typeof item.q != "undefined") {
                      haveq = true;
                      q = item.q;
                    }
                    let width = "160px";
                    if (haveq) width = "320px";
                    return (
                      <div
                        key={"acer" + index}
                        style={{
                          display: "flex",
                          width: width,
                          float: "left",
                          padding: "5px",
                        }}
                      >
                        <input
                          type="checkbox"
                          name="ser"
                          value="Y"
                          onChange={(ref) =>
                            this.changeField2(ref, index, "acerstreated")
                          }
                          checked={item.c == "Y" ? true : false}
                          style={{
                            width: "18px",
                            height: "18px",
                            marginRight: "3px",
                          }}
                        ></input>
                        {item.name}

                        {haveq && (
                          <div
                            style={{
                              paddingLeft: "5px",
                            }}
                          >
                            , {q}{" "}
                            <input
                              className="printField"
                              style={{ height: "25px", with: "100px" }}
                              value={item.a}
                              onChange={(ref) =>
                                this.changeField3(ref, index, "acerstreated")
                              }
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  Clinical findings:
                  <span
                    style={Object.assign({}, LCss.field, {
                      borderBottom: "0px",
                    })}
                  >
                    <input
                      className="printField"
                      style={{ width: "360px" }}
                      value={this.state.clinicalfinding}
                      onChange={(ref) =>
                        this.changeField(ref, "clinicalfinding")
                      }
                    />
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  Clients reaction / feedback:
                  <span
                    style={Object.assign({}, LCss.field, {
                      borderBottom: "0px",
                    })}
                  >
                    <input
                      className="printField"
                      style={{ width: "360px" }}
                      value={this.state.clientsreaction}
                      onChange={(ref) =>
                        this.changeField(ref, "clientsreaction")
                      }
                    />
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  Recommended Self-Care:
                  <span
                    style={Object.assign({}, LCss.field, {
                      borderBottom: "0px",
                    })}
                  >
                    <input
                      className="printField"
                      style={{ width: "360px" }}
                      value={this.state.recommandedselfcare}
                      onChange={(ref) =>
                        this.changeField(ref, "recommandedselfcare")
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ paddingTop: "25px" }}>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter">
            <button className="submit" onClick={() => this.submit()}>
              Submit
            </button>
          </div>
        </div>
        <div className="row">
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter"
            style={{ width: "70%", marginTop: "10px" }}
          >
            {this.state.postresult}
          </div>
        </div>
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
  back() {
    if (window.confirm("Do you need to save the form?")) {
      this.submit();
      window.history.back();
    } else window.history.back();
  }
  clearCanv(index) {
    if (index == 1) {
      if (this.state.haveimg1 == 0) this.canvasRef1.current.clear();
      this.setState({
        haveimg1: 0,
      });
    }
  }
  changeField2(ref, index, field) {
    let val = "";
    if (ref.target.checked) {
      val = "Y";
    } else {
      val = "N";
    }

    if (field == "techniquesused") {
      let techniquesused = this.state.techniquesused;
      techniquesused[index].c = val;

      this.setState({
        techniquesused: techniquesused,
      });
    } else if (field == "acerstreated") {
      let acerstreated = this.state.acerstreated;
      acerstreated[index].c = val;

      this.setState({
        acerstreated: acerstreated,
      });
    } else if (field == "assessment") {
      this.setState({
        assessment: val,
      });
    } else if (field == "treatment") {
      this.setState({
        treatment: val,
      });
    }
  }
  changeField3(ref, index, field) {
    let val = ref.target.value;
    if (field == "techniquesused") {
      let techniquesused = this.state.techniquesused;
      techniquesused[index].a = val;

      this.setState({
        techniquesused: techniquesused,
      });
    } else if (field == "acerstreated") {
      let acerstreated = this.state.acerstreated;
      acerstreated[index].a = val;

      this.setState({
        acerstreated: acerstreated,
      });
    }
  }
  setBirthday(val, field) {
    let _date = val;
    if (val == null) _date = "";
    if (_date != "") {
    /*   let date = new Date(
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      );

      _date = date; */
    }
    if (field == "birthday") {
      this.setState({
        birthday: _date,
      });
    } else if (field == "ec_signdate") {
      this.setState({
        ec_signdate: _date,
      });
    }
  }

  changeField(e, field) {
    let val = e.target.value;
    if (field == "clinicalfinding")
      this.setState({
        clinicalfinding: val,
      });
    else if (field == "clientsreaction") {
      this.setState({
        clientsreaction: val,
      });
    } else if (field == "recommandedselfcare")
      this.setState({
        recommandedselfcare: val,
      });
    else if (field == "ec_secondmembername")
      this.setState({
        ec_secondmembername: val,
      });
  }

  submit() {
    const { myusername, ukey } = this.props;
    // document.getElementById("result2").style.color = "#000000";

    this.setState({
      postresult: "Waiting....",
    });
    // document.getElementById("result2").innerHTML = "正在提交....";

    //var gzhnickname = this.state.upgzhnickname;
    //var comname = this.state.upcomname;
    let therapistsign = this.state.therapistsign;
    if (this.state.haveimg1 == 0) {
      therapistsign = this.canvasRef1.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      if (therapistsign == emptysign) therapistsign = "";
    }
    const postdata = {
      editid: this.state.editid,
      bookid: this.state.bookid,
      assessment: this.state.assessment,
      treatment: this.state.treatment,

      techniquesused: this.state.techniquesused,

      acerstreated: this.state.acerstreated,
      clinicalfinding: this.state.clinicalfinding,
      clientsreaction: this.state.clientsreaction,

      recommandedselfcare: this.state.recommandedselfcare,
      therapistsign: therapistsign,
    };
    console.log(postdata);
    // return;
    const this2 = this;
    var error = "";

    // else if (clientsigndate == "") error = "Please fill in signature date";

    //document.getElementById("result2").style.color = "red";
    if (error !== "") {
      this.setState({
        postresult: error,
      });
    } else {
      $.ajax({
        type: "POST",
        dataType: "json",
        url:
          AJAROOT +
          "/postaja/savexx.aspx?action=savetreatenote&u=" +
          myusername +
          "&ukey=" +
          encodeURIComponent(ukey) +
          "",
        data: JSON.stringify(postdata),
      })
        .done(function (res) {
          console.log(res);
          var r = res.r;
          var msg = res.msg;

          if (r === "1") {
            //  this.postresult = "登陆成功！";

            this2.setState({
              postresult: "Successfully",
            });
            //if (myusername == this2.state.username)
            //this2.props.history.push("/user");
          } else {
            //document.getElementById("result2").style.color = "red";
            this2.setState({
              postresult: msg,
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    }
  }

  getData(myusername, ukey, bookid) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&bookid=" +
      bookid +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&action=treatdnote";

    axios.get(geturl).then((res) => {
      const result = res.data;
      console.log(result);
      let haveimg1 = 0;
      let therapistsign = result.therapistsign;
      if (therapistsign == null) therapistsign = "";
      if (therapistsign != "") {
        haveimg1 = 1;
      }

      this.setState({
        editid: result.treatenoteid,
        customer: result.customer,

        periodno: result.periodno,
        fromtime: result.fromtime,
        totime: result.totime,
        minutes: result.minutes,
        price: result.price,
        employeename: result.employeename,
        assessment: result.assessment,
        treatment: result.treatment,

        techniquesused: result.techniquesused,

        acerstreated: result.acerstreated,
        clinicalfinding: result.clinicalfinding,
        clientsreaction: result.clientsreaction,

        recommandedselfcare: result.recommandedselfcare,
        therapistsign: therapistsign,

        haveimg1: haveimg1,
      });
    });
  }
  printPage(url) {
    var orihtml = window.document.body.innerHTML; //从标记里获取需要打印的页面
    document.getElementById("content").style.padding = "10px";
    var printHtml = document.getElementById("content").innerHTML; //从标记里获取需要打印的页面

    window.document.body.innerHTML = printHtml; //需要打印的页面

    window.print();
    window.document.body.innerHTML = orihtml;
    window.open(url, "_self");
  }
  print(url) {
    window.open(url, "_blank");
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;
    //const { t } = this.props.match.params;
    var { bookid, print } = queryString.parse(this.props.location.search);
    var title = "Treatment Note";
    document.title = title;
    if (bookid == null) bookid = "";
    if (print == null) print = "";
    if (print == "1") {
      this.setState({
        isprint: true,
      });
    }
    this.setState({
      title: title,
      bookid: bookid,
    });

    //  getUserIndexData(this, myusername, ukey);
    this.getData(myusername, ukey, bookid);
  }

  componentWillUnmount() {
    this.submit();
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {}
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
  };
};

export default connect(mapStateToProps, null)(User);
