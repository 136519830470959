import styled from "styled-components";


export const HeaderWrapper = styled.div`
  position: relative;
  height: 55px;
  float: center;
  padding: 0px auto;
  margin: 0px auto;
  border-bottom: 1px solid #f0f0f0;
  text-align: center;
  background: #337b70;
  width: 100%;
`;

export const Nav = styled.div`
  width: 50%;
  float: left;
  height: 51px;
  margin: 0 auto;
  padding-right: 20px;
  box-sizing: border-box;
`;

export const Logo = styled.div`
  display: block;
  float: left;

  height: 70px;
  width: 140px;

  padding-left: 0px;
  margin-left: -13px;

 
  background-size: contain;
  border-radius: 10px;
`;
// background: #fff url(${logoPic}) no-repeat center center;
export const NavSearch = styled.input.attrs({
  placeholder: "输入关键词",
})`
  width: 100%;
  height: 35px;
  border: 1px solid #bbb;
  outline: none;
  border-radius: 5px;
  line-height: 35px;
  margin-top: 0px;
  padding: 0 15px 0 10px;

  box-sizing: border-box;
  font-size: 14px;
  margin-left: 10px;
  &::placeholder {
    color: #999;
  }
  color: #666;
  &.focused {
    width: 100%;
  }
`;

export const SearchInfo = styled.div`
  position: absolute;
  left: 20px;
  top: 56px;
  width: 240px;
  padding: 0 20px;
  z-index: 999;
  background: #eeeeee;
  box-shadow: 0 0 8px rgb(0, 0, 0, 0.2);
`;

export const SearchInfoTitle = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
  line-height: 20px;
  font-size: 14px;
  color: #969696;
`;

export const SearchInfoSwitch = styled.span`
  float: right;
  font-size: 13px;
  cursor: pointer;
  .spin {
    display: block;
    float: left;
    font-size: 12px;
    margin-right: 2px;
    transition: all 0.2s ease-in;
    transform: rotate(0deg);
    transform-origin: center center;
  }
`;

export const SearchInfoList = styled.div`
  overflow: hidden;
`;

export const SearchInfoItem = styled.a`
  padding: 0 5px;
  line-height: 20px;
  border: 1px solid #ddd;

  font-size: 12px;
  border-radius: 3px;
  display: block;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #787878;
`;

export const Addition = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 56px;
`;

export const Button = styled.div`
  float: right;
  line-height: 30px;
  border-radius: 10px;
  margin-top: 9px;
  margin-right: 5px;
  padding: 0 5px;
  border: 1px solid #222222;
  &.reg {
    color: #222222;
  }
`;

export const SearchWrapper = styled.div`
width:300px,
  z-index: 999;
  position: relative;

  .zoom {
    position: absolute;
    right: 5px;
    bottom: 1px;
    width: 35px;
    line-height: 35px;

    text-align: center;
  }
`;

export const Nav2 = styled.div`
  width: 43%;
  float: left;
  height: 50px;
  margin: 0 auto;
  padding-right: 0px;
  box-sizing: border-box;
`;

export const Nav2Item = styled.div`
  line-height: 40px;
  padding: 0 5px 0 0;
  font-size: 15px;
  color: #222222;

  &.right {
    float: right;
    color: #222222;
  }
  &.active {
    color: #222222;
  }
  &.right .login {
    padding-right: 15px;
    color: #222222;
  }
  .tb {
    font-size: 32px;
    padding-right: 5px;
  }
  .cartnum {
    position: relative;
    left: -10px;
    top: -10px;
    font-weight: normal;
    font-size: 23px;
  }
  .user {
    position: relative;
    bottom: 2px;
    font-size: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;
