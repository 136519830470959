import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { AJAROOT } from "@/common/gdata/global.js";
import { IsPC, showSvg } from "@/jscript/common.js";
import axios from "axios";
//import puppeteer from "puppeteer";
import "@/css/home.css";
import "@/css/global.css";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      gradelist: [],


      screenWidth: 0,
    };
  }
  render() {
    const IsMobile = !IsPC();
    //JSON.parse(JSON.stringify(cpLb)).le

    const styles = {
      lbtitle: {
        fontSize: "17px",
        fontWeight: "bold",
        padding: "10px",
        color: "#fff",
      },
    };
   
    return (
      <div
        className="container wrap"
        style={{
          width: "100%",
          paddingTop: 0,
        }}
      >
        {IsMobile && <div style={{ paddingTop: "10px" }}></div>}

        <div style={{ padding: "10px", border: "0px solid red" }}>
          <div style={{ fontSize: "22px" }}>
            This is easy-to-use, faster and powerful book system. Register now
            and 30-day trial.
          </div>
          <div style={{ fontSize: "22px", marginTop: "30px" }}>
            <span
              style={{
                textAlign: "center",
                background: "#f6f6f6",
                padding: "10px",
                width: "300px",
                display: "inline-block",
              }}
            >
              About Price
            </span>
          </div>
<div style={{border:"1px solid #ddd",width:"900px",marginTop:"15px",padding:"10px"}}>

              <div className="row" style={{height:"30px"}}>
               
                  <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 bold">
                    
                   Grade
                  </div>
                   <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 bold">  
                   Price
                  </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold">                    
                   Total Books
                  </div>
                   <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold">                    
                   Total Sms
                  </div>
                   <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold">                    
                   Subdomain
                  </div>
              </div>
           
             {
          this.state.gradelist.map((item, index) => {
          

            return (
              <div className="row " style={{height:"35px"}} key={"frm" + index}>
               
                  <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3" style={{height:"35px",lineHeight:"35px"}}>
                    
                   Grade {item.gradename}
                  </div>
                  <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3" style={{height:"35px",lineHeight:"35px"}}>  
                   CAD ${item.price} {index==0?"(30-day trial)":"/1 month"}
                  </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 " style={{height:"35px",lineHeight:"35px"}}>                    
                 {item.books>=999999?"unlimited":item.books}
                  </div>
                   <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 " style={{height:"35px",lineHeight:"35px"}}>                    
                   CAD ${item.sms}
                  </div>
                   <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 " style={{height:"35px",lineHeight:"35px"}}>                    
                  {item.subdomain*1==1?showSvg("gou",25,25,"green"):showSvg("cha",25,25,"red")}
                  </div>
             
              </div>
            );
          })}
          </div>
     {/*      <div style={{ fontSize: "17px" }}>
            <div style={{ padding: "10px", marginTop: "10px" }}>
              <span className="iconfont fanhui duigou">&#xe605;</span>
              Free try for a month.
            </div>
            <div style={{ padding: "10px" }}>
              <span className="iconfont fanhui duigou">&#xe605;</span>
             60$ / month after try.
            </div>

            <div style={{ padding: "10px" }}>
              <span className="iconfont fanhui duigou">&#xe605;</span>
             For inquiries about other custom function fees, please contact customer service.

            </div>

          </div> */}

            <button className="submit" style={{width:"300px"}} onClick={()=>{this.props.history.push("/register")}}>
                  Try Now
                  </button>
        </div>
      </div>
    );
  }
  componentDidUpdate() {
  
  }
  componentDidMount() {
   
   this.getData("","");
  }
  getData(myusername, ukey) {
    const { myusertype } = this.props;
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&action=gradelist";

    axios.get(geturl).then((res) => {
      const result = res.data;

      if (result.r == 1) {
        this.setState({
          gradelist: result.gradelist,
  
        });
      }
      // this.setState({}); /**/
    });
  }
}
const mapState = (state) => ({
  myusername: state.getIn(["gdata", "username"]),
  ukey: state.getIn(["gdata", "ukey"]),

  //  dflblist: state.getIn(["home", "dflblist"]),
});

export default connect(mapState, null)(withRouter(Home));
