import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { Link } from "react-router-dom";
import $ from "jquery";
import { AJAROOT } from "@/common/gdata/global.js";
import {
  checkUserLogin,
  IsPC,
  MyToast,
  MyToastShow,
} from "@/jscript/common.js";
import axios from "axios";
import "@/css/user.css";
import "@/css/react-datepicker.css";

//import DatePicker from "react-datepicker";
class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",

      title: "",
      firstname: "",
      lastname: "",

      telephone: "",
      haveinsurance: 1,
      address: "",
      city: "",
      province: "",
      postal: "",
      birthday: "",
      email: "",
      storetax:13,
      error: "",
      contactname: "",
      contacttel: "",
      familydoc: "",
      familydoctel: "",
      familydocaddress: "",
      insur_massage: "",
      insur_acupuncture: "",
      insur_osteopath: "",
      insur_physiotherapy: "",
      insur_chiropractic: "",
      insur_naturopath: "",
      insur_company: "",
      insur_policynum: "",
      insur_memberid: "",
      seriousdata: [],
      seriousothers: "",
      haveallergy: "",
      whatallergy: "",
      ongoinghealthy: "",
      longtertreat: "",

      postresult: "",
      mytoast: {
        show: false,
      },
    };
  }
  render() {
    const { myusername, myusertype } = this.props;
    const IsMobile = !IsPC();
    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;
    //var zsxm = this.state.zsxm;
    // if (zsxm === "") zsxm = this.state.myusername;
    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px" }}
      >
        <div className="row " style={{ height: "55px", lineHeight: "55px" }}>
          <div
            className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
            onClick={() => {
              window.history.back(-1);
            }}
          >
            <span className="iconfont fanhui">&#xe625;</span>
          </div>
          <div
            className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textcenter "
            style={{ fontSize: "19px", fontWeight: "bolder" }}
          >
            {this.state.title}

            {this.state.username != this.props.myusername && (
              <Link
                style={{ fontSize: "15px", paddingLeft: "50px" }}
                to={"/userup/uppwd?username=" + this.state.username}
              >
                Edit Password
              </Link>
            )}
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>
        </div>
        <div className="row">
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft"
            style={{ fontSize: "16px" }}
          >
            Account Information
          </div>
        </div>
        <div
          className="row"
          style={{ border: "1px solid #ddd", paddingTop: "10px" }}
        >
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * {myusertype >= 2 ? "Company Name:" : "First Name"}
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.firstname}
                  onChange={(ref) => this.changeField(ref, "firstname")}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * {myusertype >= 2 ? "Store Name:" : "Last Name"}
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.lastname}
                  onChange={(ref) => this.changeField(ref, "lastname")}
                />
              </div>
            </div>
             {myusertype>=2&&<div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * Tax
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.storetax}
                  onChange={(ref) => this.changeField(ref, "storetax")}
                />%
              </div>
            </div>}

            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * Telephone:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.telephone}
                  onChange={(ref) => this.changeField(ref, "telephone")}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * Address:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  style={{ width: "360px" }}
                  value={this.state.address}
                  onChange={(ref) => this.changeField(ref, "address")}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                City:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.city}
                  onChange={(ref) => this.changeField(ref, "city")}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Province:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.province}
                  onChange={(ref) => this.changeField(ref, "province")}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Postal Code:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.postal}
                  onChange={(ref) => this.changeField(ref, "postal")}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Email:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.email}
                  onChange={(ref) => this.changeField(ref, "email")}
                />
              </div>
            </div>

            {myusertype == 0 && (
              <div className="row">
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                  Have Insurance:
                </div>
                <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <input
                    type="checkbox"
                    name="haveinsurance"
                    value="Y"
                    onChange={(ref) => this.changeField(ref, "haveinsurance")}
                    checked={this.state.haveinsurance == 1 ? true : false}
                    style={{
                      width: "18px",
                      height: "18px",
                    }}
                  ></input>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter postresult"
            style={{ width: "70%", marginTop: "5px", height: "25px" }}
          >
            {this.state.postresult}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 textcenter">
            <button className="submit" onClick={() => this.submit()}>
              Submit
            </button>
          </div>
        </div>
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
  setBirthday(val) {
    let birthday = "";
    if (val == null) birthday = "";
    else {
      let date = new Date(
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      );
      birthday = date;
    }

    this.setState({
      birthday: birthday,
    });
  }
  setSerData(index) {
    let serviousdata = this.state.seriousdata;
    let val = serviousdata[index].a;
    val = (val + 1) % 2;
    serviousdata[index].a = val;
    this.setState({
      seriousdata: serviousdata,
    });
  }

  setRadio(val, field) {
    if (val == "") val = "N";
    if (val == "N") val = "Y";
    else val = "N";

    if (field == "insur_massage")
      this.setState({
        insur_massage: val,
      });
    else if (field == "insur_acupuncture")
      this.setState({
        insur_acupuncture: val,
      });
    else if (field == "insur_acupuncture")
      this.setState({
        insur_acupuncture: val,
      });
    else if (field == "insur_osteopath")
      this.setState({
        insur_osteopath: val,
      });
    else if (field == "insur_physiotherapy")
      this.setState({
        insur_physiotherapy: val,
      });
    else if (field == "insur_chiropractic")
      this.setState({
        insur_chiropractic: val,
      });
    else if (field == "haveallergy")
      this.setState({
        haveallergy: val,
      });
  }
  changeField(e, field) {
    let val = e.target.value;
    if (field == "username")
      this.setState({
        username: val,
      });
    else if (field == "firstname")
      this.setState({
        firstname: val,
      });
    else if (field == "lastname")
      this.setState({
        lastname: val,
      });
    else if (field == "password")
      this.setState({
        password: val,
      });
    else if (field == "telephone")
      this.setState({
        telephone: val,
      });
    else if (field == "email")
      this.setState({
        email: val,
      });
    else if (field == "address") {
      this.setState({
        address: val,
      });
    } else if (field == "city")
      this.setState({
        city: val,
      });
    else if (field == "province")
      this.setState({
        province: val,
      });
    else if (field == "postal")
      this.setState({
        postal: val,
      });
else if (field == "storetax")
      this.setState({
        storetax: val,
      });
      
    else if (field == "haveinsurance") {
      val = "";
      if (e.target.checked) {
        val = 1;
      } else {
        val = 0;
      }
      this.setState({
        haveinsurance: val,
      });
    }
  }

  submit() {
    const { myusername, myusertype, ukey } = this.props;

    // document.getElementById("result2").style.color = "#000000";

    this.setState({
      postresult: "Waiting....",
    });
    // document.getElementById("result2").innerHTML = "正在提交....";
    var telephone = this.state.telephone;
    var firstname = this.state.firstname;
    var lastname = this.state.lastname;
    //var gzhnickname = this.state.upgzhnickname;
    //var comname = this.state.upcomname;
    var address = this.state.address;
    let birthday = this.state.birthday;
    if (birthday != "") {
      birthday =
        birthday.getFullYear() +
        "-" +
        (birthday.getMonth() + 1) +
        "-" +
        birthday.getDate();
    }

    const postdata = {
      username: this.state.username,
      telephone: telephone,
      firstname: firstname,
      lastname: lastname,
      address: this.state.address,
      city: this.state.city,
      province: this.state.province,
      postal: this.state.postal,
      email: this.state.email,
     storetax: this.state.storetax/100,
  
      address: address,
      birthday: birthday,
      haveinsurance: this.state.haveinsurance,
    };

    const this2 = this;
    var error = "";
    if (this.state.telephone === "") error = "Please fill in telephone";
    else if (this.state.firstname == "")
      error =
        myusertype >= 2
          ? "Please fill in company name"
          : "Please fill in first name";
    else if (this.state.lastname == "")
      error =
        myusertype >= 2
          ? "Please fill in store name"
          : "Please fill in last name";
    else if (this.state.address == "") error = "Please fill in address";

    //document.getElementById("result2").style.color = "red";
    if (error !== "") {
      this.setState({
        postresult: error,
      });
    } else {
      $.ajax({
        type: "POST",
        dataType: "json",
        url:
          AJAROOT +
          "/postaja/savexx.aspx?action=upinfo&u=" +
          myusername +
          "&ukey=" +
          encodeURIComponent(ukey) +
          "",
        data: JSON.stringify(postdata),
      })
        .done(function (res) {
         
          var r = res.r;
          var msg = res.s;

          if (r === "1") {
            //  this.postresult = "登陆成功！";
            MyToastShow(
              this2,
              2,
              "Successfully!",
              200,
              120,
              "success",
              "addemployee"
            );
            this2.setState({
              postresult: "",
            });
            //if (myusername == this2.state.username)
            //this2.props.history.push("/user");
          } else {
            //document.getElementById("result2").style.color = "red";
            MyToastShow(this2, 2, msg, 200, 120, "success", "addemployee");
            this2.setState({
              postresult: "",
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    }
  }

  getData(myusername, ukey, username) {
    if (username == "") username = myusername;
    var geturl =
      AJAROOT +
      "/getaja/user.aspx?u=" +
      myusername +
      "&username=" +
      username +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "";
    console.log(geturl);
    axios.get(geturl).then((res) => {
      const result = res.data;
      console.log(result);
      // this.setState({}); /**/

      var firstname = result.firstname;
      var lastname = result.lastname;
      let birthday = result.birthday;
      if (birthday == null) birthday = "";
      if (birthday != "") {
        birthday = new Date(birthday);
      }
      // if (zsxm === "") zsxm = username;
      this.setState({
        firstname: firstname,
        lastname: lastname,
        telephone: result.telephone,
        address: result.address,
        city: result.city,
        province: result.province,
        postal: result.postal,
        
        // birthday: birthday,
        email: result.email,
        storetax:result.storetax*100,
        haveinsurance: result.haveinsurance,
      });
    });
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;
    //const { t } = this.props.match.params;
    var { username } = queryString.parse(this.props.location.search);
    var title = "Edit Basic Info";

    if (username == null) username = "";
    if (username == "") {
      username = myusername;
    }
    this.setState({
      title: title,
      username: username,
    });

    //  getUserIndexData(this, myusername, ukey);
    this.getData(myusername, ukey, username);
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {}
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
  };
};

export default connect(mapStateToProps, null)(User);
