import React, { Component } from "react";
import { connect } from "react-redux";
import {  withRouter } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
//import queryString from "query-string";
import { AJAROOT,Kefu } from "@/common/gdata/global.js";
import cookie from "react-cookies";
import { IsPC, isSafari } from "@/jscript/common.js";
import loadinggif from "@/statics/Loading_icon.gif";
import logoPic from "@/statics/logo2.png";
import "@/css/chat.css";
import "@/css/global.css";
const maxwidth = window.innerWidth;
const leftwidth = parseInt(maxwidth * (1 / 6));
const IsMobile = !IsPC();
const LCss = {
  luser: { width: "50px", float: "left", border: "0px solid red" },
  ruser: {
    width: "25px",
    float: "right",
    textAlign: "right",
    height: "35px",
    lineHeight: "35px",
  },
  lmsg: {
    float: "left",

    maxWidth: IsMobile ? maxwidth - 100 : "550px",
    background: "#fff",
    borderRadius: "10px",
    padding: "8px",
    whiteSpace: "pre-wrap",
    border: "0px solid red",
  },
  rmsg: {
    float: "right",
    maxWidth: IsMobile ? "500px" : "550px",
    background: "#95ec69",
    borderRadius: "10px",
    padding: "8px",

    marginRight: "0px",
    whiteSpace: "pre-wrap",
  },
  h5_msgsend: {
    /* top: this.state.maxheight - 50 */
    border: "0px solid red",
    width: maxwidth - 60,
  },
  pc_msgsend: {
    position: "fixed",
    /* top: this.state.maxheight - 50 */
    width: maxwidth - leftwidth - 50,

    zIndex: "9999",
    left: 200,
    bottom: 0 /**/,
  },
};

class Chat extends Component {
  //inputRef = React.createRef();

  handleInputFocus = () => {
    //  alert(window.navigator.standalone);

    if (isSafari() && !window.navigator.standalone) {
      setTimeout(() => {
        this.setState({
          //  loading: true,
          // keyheight: 260,
        });
        //  document.body.scrollTop = document.body.scrollHeight;
        //window.scrollTo = 20000;
        // document.body.scrollTop = 20000;
        //  alert(document.body.scrollHeight);
        //  const height = window.innerHeight - document.documentElement.clientHeight;
        // let h1 = document.getElementById("messages1").style.height;
        //  document.getElementById("messages1").style.height = h1 - 500;
        // alert(document.getElementById("messages1").style.height);
        //  alert(document.getElementById("messages1").style.height);
      }, 300);
    }
    /*  document.getElementById("inputmsg").scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    }); */
    //  alert("aa");
    // input.scrollIntoView(true);
    /*   this.inputRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    }); */
  };
  constructor(props) {
    super(props);

    this.state = {
      //    pagesize: 30,
      receiver:"",
      loading: false,
      prompt: "",
      text: "",
     // topiclist: [],
      //modellist: [],
     // selectedmodel: "gpt-3.5-turbo-0301",
      //selectedmodel: "gpt-3.5-turbo",
      
      history: [
        /*  { role: "user", content: "你好" },
        { assitant: "assistant", content: "你好，有什么可以帮你的" }  */
      ],
      //  totalpage: 0,

   
      error: "",
      

      mobileshowleft: false,
      maxheight: window.innerHeight-120,
      maxwidth: window.innerWidth,
      keyheight: IsMobile && isSafari() && !window.navigator.standalone ? 0 : 0,
      showset: 0,
      lastid:0
    };
    // this.handleResize = this.handleResize.bind(this);
  }

  render() {
  const myusername=this.props.myusername;
    // console.log(GetTypeId());
    // const maxheight = window.innerHeight;
    //

    var nextpage = this.state.page * 1 + 1;

    if (nextpage > this.state.totalpage * 1) nextpage = this.state.totalpage;
    var lastpage = this.state.page * 1 - 1;
    if (lastpage < 1) lastpage = 1;
    //JSON.parse(JSON.stringify(cpLb)).le

    // const url = "/type/" + typeid + "/{page}";
    //const yestr = Fenye2(typeid, lastpage, nextpage, totalpage);
    const styles = {
      lbtitle: {
        fontSize: "16px",

        padding: "10px",
        color: "#111",
        fontWeight: "800",
      },
    };

    let leftbottomheight = 200;
    let lefttopheight = 82;
    
    return (
      <div
        className="container top"
        style={{
          marginTop: 0,
          paddingTop: 0,
          marginBottom: 0,
          paddingBottom: 0,
          backgroundColor: "#eee",
        }}
      >
        <div className="row">
          
          <div
            id="messages1"
            className=
             "col-xs-12 col-sm-12 col-md-12 col-lg-12 "
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              marginLeft: 0,
              marginRight: 0,
              backgroundColor: "#eee",
              height:this.state.maxheight - 5 - this.state.keyheight,
              position: "relative",
            }}
          >
            <div
              id="messages"
              style={{
                width: "100%",
                border: "0px solid red",
                padding: "5px",
                marginBottom: "10px",
                height:
                  this.state.maxheight - 60 - this.state.keyheight,
                /*   scrollY: "auto", */
                overflow: "auto",
                /*   display: "flex", */
                overflowY: "scroll",
                scrollBehavior: "smooth",
              }}
            >
              {this.state.history.length == 0 && (
                <div style={{ padding: "100px", fontSize: "17px" }}>
                  Input your message
                </div>
              )}
              {this.state.history.map((item, index) => {
                const role = item.role;

                return (
                  <div
                    key={"msg" + index}
                    style={{
                      width: "100%",
                      padding: "8px",
                      border: "0px solid red",
                      clear: "both",
                      minHeight: "35px",
                    }}
                  >
                    <div style={role == "user" ? LCss.ruser : LCss.luser}>
                      {role == "user" ? (
                        <span
                          style={{ verticalAlign: "center", minHeight: "35px" }}
                        >
                          Me
                        </span>
                      ) : (
                        <img
                          src={logoPic}
                          style={{
                            width: "38px",
                            height: "38px",
                            float: "center",
                          }}
                        ></img>
                      )}
                    </div>
                    <div style={role == "user" ? LCss.rmsg : LCss.lmsg}>
                      {item.content}
                      {role != "user"&&myusername==Kefu&&<span style={{cursor:"pointer"}} onClick={()=>{this.setState({
                      receiver:item.sender
                      })}}>【{item.sender}】</span>}
                    </div>
                  </div>
                );
              })}
              {(this.state.loading || this.state.error != "") && (
                <div
                  style={{
                    width: "100%",
                    padding: "8px",
                    border: "0px solid red",
                    clear: "both",
                    minHeight: "35px",
                  }}
                >
                  <div style={LCss.ruser}>
                    Me
                  </div>
                  <div style={LCss.rmsg}>
                    {this.state.error == "" ? (
                      <img
                        src={loadinggif}
                        style={{
                          width: "38px",
                          height: "38px",
                          float: "center",
                        }}
                      ></img>
                    ) : (
                      this.state.error
                    )}
                  </div>
                </div>
              )}
            </div>
            <div style={IsMobile ? LCss.h5_msgsend : LCss.pc_msgsend}>
       
          
                <div style={{ width: "100%", border: "0px solid red" }}>
                 

                  <div
                    style={{
                      height: "45px",
                      lineHeight: "45px",
                      width: "85%",
                      borderRadius: "5px",
                      float: "left",
                    }}
                  >
                  {this.state.receiver!=""&&<span style={{float:"left",paddingRight:"5px"}}>T: {this.state.receiver}</span>}
                    <input
                      id="inputmsg"
                      onFocus={this.handleInputFocus}
                      onKeyDown={(ref) => this.KeyDown(ref)}
                      //   onFocus={(ref) => this.focus(ref)}
                      onBlur={(ref) => this.blur(ref)}
                      /* value={this.state.prompt}
                      
                      onChange={(ref) => this.setField(ref, "prompt")} */
                      placeholder="Send a message"
                      ref={(input) => {
                        this.prompt = input;
                      }}
                      type="text"
                      name="prompt"
                      style={{
                        height: "45px",
                        lineHeight: "45px",
                        width: this.state.receiver==""?"100%":"90%",
                        borderRadius: "5px",
                        float: "left",
                        fontSize: "17px",
                        border: "none",
                        outline: "none",
                        border: "1px solid #aaa",
                        /*    position: "fixed",
                        bottom: 0,
                        left: 0,
                        right: 0, */
                      }}
                    ></input>
                    
                  </div>
                  <div
                    style={{
                      height: "45px",
                      lineHeight: "45px",
                      width: "15%",
                      borderRadius: "5px",
                      float: "right",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "8px",
                        height: "45px",
                        lineHeight: "45px",
                        width: "80px",
                        borderRadius: "5px",
                        float: "left",
                        border: "1px solid #07c160",
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#07c160",
                      }}
                    >
                      <input
                        onClick={() => this.Send2()}
                        type="submit"
                        name="button1"
                        value=" Send "
                        style={{
                          height: "43px",
                          lineHeight: "43px",
                          width: "80px",

                          fontSize: "18px",
                          float: "center",
                          border: "0px",
                          backgroundColor: "#07c160",
                          color: "white",
                        }}
                      ></input>
                    </div>
                    {/* <img
                        src={loadinggif}
                        style={{
                          width: "43px",
                          height: "43px",
                          float: "center",
                        }}
                      ></img> */}
                  </div>
                </div>
          
            </div>
          </div>
        </div>
      
      </div>
    );
  }
 
  
  setField(ref, field) {
    let val = ref.target.value;
    /*   if (ref.key === "Enter") {
      console.log("Enter key pressed");
      this.Send();
    } */
    if (field == "prompt") {
      this.setState({
        //  loading: true,
        prompt: val,
      });
    }
  }

  blur(event) {
    if (this.state.keyheight > 0) {
      this.setState({
        keyheight: 0,
      });
    }
    //  this.handleResize();
  }
  KeyDown(event) {
    if (event.key === "Enter") {
      this.Send2();
    }
  }
  
  
 Send2() {
    const { myusername, ukey } = this.props;
    //  console.log("aaa" + this.state.hba);

    // event.preventDefault();
    let prompt = this.prompt.value;
    //  console.log("b" + prompt);
    if (prompt == "") return;
  

    this.setState(
      {
        loading: true,
        error: "",
      },
      () => {
        this.prompt.value = "";
        var myDiv = document.getElementById("messages");
        myDiv.scrollTop = myDiv.scrollHeight;
      }
    );
    /*    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
    }; */

    //  let posturl = "https://api.openai.com/v1/chat/completions";
 

   
    var myDiv = document.getElementById("messages");
    myDiv.scrollTop = myDiv.scrollHeight;
    let data = {};

      data = {
      
   
        message: prompt,
        receiver:this.state.receiver
      };
  
console.log(data);
    let that = this;
    /*     this.setState({
      loading: true,
      error: "",
    }); */

    /*    var myDiv = document.getElementById("messages");
    myDiv.scrollTop = myDiv.scrollHeight; */
    $.ajax({
      type: "POST",
      dataType: "json",
      url:
        AJAROOT +
        "/postaja/savexx.aspx?action=sendmsg&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "",
      data: JSON.stringify(data),
    })
      .done(function (res) {
        // console.log(res);
        if (res.r == -2) {
          alert("Please login in");
           this.setState(
            {
             
              loading: false,
            });
          this.props.history.push(
            "/login?tourl=" + encodeURIComponent("/chat") + ""
          );
        } else if (res.r == 1) {
 
      //   let _message = res.message;

          //  console.log(response.data.choices);
            let assis = res.data
          // console.log(_message.content);
         
         // that.pushMyServer(prompt, _message.content, token1, token2);
          /*  if (_message.content.indexOf("\n\n") == 0) {
          _message.content = _message.content.substring(2);
        } */
          // console.log(_message.content);
let history=that.state.history;
          history.push(assis);
          // if (message.length >= 100) message.splice(0, message.length - 4);
          that.setState(
            {
              // prompt: "",
              history: history,
           lastid:res.lastid,
              loading: false,
            },
            () => {
              var myDiv = document.getElementById("messages");
              myDiv.scrollTop = myDiv.scrollHeight;
            }
          );
        } else {
          that.prompt.value = prompt;
          that.setState({
            loading: false,
            error: "Error, Please try again later",
          });
        }
      })
      .catch((error) => {
        that.prompt.value = prompt;
        that.setState({
          loading: false,
          error: "Error",
        });
        console.error(error);
      });
    // const generatedText = await generateText(prompt);

    // console.log(generatedText);
    //  setText(generatedText);
  }

  componentDidMount() {
    

    const { myusername, ukey } = this.props;
    if (myusername != "") 
    {
    this.loadHistory();
this.timer = setInterval(() => {
            this.loadHistory();
            //   console.log('把一个定时器的引用挂在this上');
          }, 5000);
    }
    else {
      this.reLogin();

      // this.props.history.push(
      //    "/login?tourl=" + encodeURIComponent("/chat") + ""
      //  );
    }

    document.title = "Chat with us";
    /*     window.addEventListener("resize", this.handleResize);
    window.addEventListener("KeyboardUp", this.handleResize);
    window.addEventListener("KeyboardDown", this.handleResize); */
  }

  reLogin() {
    const { myusername, ukey } = this.props;
    if (myusername === null) myusername = "";
    //  console.log("rememberusername1 " + cookie.load("username"));
    let rememberusername = "";
    let rememberopenid = "";
    if (myusername === "") {
   
      rememberusername = cookie.load("username");
      rememberopenid = cookie.load("openid");
      if (rememberusername == null) rememberusername = "";
      if (rememberopenid == null) rememberopenid = "";
    }

    var geturl =
      AJAROOT +
      "/getaja/index.aspx?id=1&u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&rememberusername=" +
      rememberusername +
      "&rememberopenid=" +
      rememberopenid +
      "";

    axios.get(geturl).then((res) => {
      const result = res.data;

      const lgstatus = result["lgstatus"];
      if (lgstatus === "logout") {
        //clear cookie
        const action = {
          type: "change_login_data",
          username: "",
          ukey: "",
          usertype: 0,
          usergrade: 1,
          cartnum: 0,
        };
        this.props.changeLoginData(action);
      

        //  var showmheader = true;
        // if (path == "/" || !IsMobile) showmheader = false;
        //  let pagetitle = this.props.pagetitle;

        this.props.history.push(
          "/login?tourl=" + encodeURIComponent("/chat") + ""
        );
      } else if (lgstatus === "reload") {
        const action = {
          type: "change_login_data",
          username: result["uname"],
          ukey: result["ukey"],
          usertype: result["usertype"],
          usergrade: result["usergrade"],
          cartnum: result["cartnum"],
        };

     
        this.props.changeLoginData(action);
       
    
      }
    });
  }
  loadHistory() {
  if(this.state.loading)return;
    const { myusername, ukey } = this.props;
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&action=chatlist&lastid="+this.state.lastid+"";
      console.log(geturl);
    axios.get(geturl).then((res) => {
   
      const result = res.data;
        console.log(result);
      if(result.r==1)
      {
      let history=this.state.history;

       history = history.concat(result.list);
    
      this.setState(
        {
          history:history,
          lastid:result.lastid
        },
        () => {
          var myDiv = document.getElementById("messages");
          myDiv.scrollTop = myDiv.scrollHeight;
        }
      );
      }
      // this.setState({}); /**/
    });
  }
  handleResize() {
    return;
    const currentHeight = window.innerHeight;

    if (currentHeight < this.state.maxheight) {
      // 键盘打开时调整元素位置

      const heightDifference = this.state.maxheight - currentHeight;

      document.getElementById("messages").style.bottom =
        heightDifference + "px";
    } else {
      // 键盘关闭时恢复原始位置
      document.getElementById("messages").style.bottom = "0";
    }
  }
  componentWillUnmount() {
    // window.removeEventListener("resize", this.handleResize);
    // window.removeEventListener("KeyboardUp", this.handleResize);
    // window.removeEventListener("KeyboardDown", this.handleResize);
        this.timer && clearTimeout(this.timer);
    this.setState = (state, callback) => {
      return;
    };

  }

  componentWillUpdate() {}
  componentDidUpdate() {
    // const { typeid } = this.props.match.params;
  }
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),

    //usertype: state.getIn(["header", "usertype"]),
  };
};
const mapDispatch = (dispath) => ({
  changeData(action) {
    dispath(action);
  },
  changeLoginData(action) {
    dispath(action);
  },
});
export default connect(mapStateToProps, mapDispatch)(withRouter(Chat));
