import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import $ from "jquery";
import axios from "axios";
import copy from "copy-to-clipboard";
//import { Link } from "react-router-dom";
import { AJAROOT } from "@/common/gdata/global.js";
import {
  checkUserLogin,
  IsPC,
  MyToast,
  MyToastShow,
} from "@/jscript/common.js";

import "@/css/user.css";
import rjt from "../../../statics/rjt.png";
//import { ListConsumer } from 'antd/lib/list';

const LCss = {
  button: {
    marginLeft: "15px",
    padding: "5px",
    background: "#ddd",
    borderRadius: "5px",
  },
  column: {
    border: "1px solid #bbb",
    borderRadius: "5px",
    marginTop: "15px",
    paddingBottom: "8px",
  },
};
class User extends Component {
  constructor(props) {
    super(props);
    this.fileSelectedHandler = this.fileSelectedHandler.bind(this);
    this.fileUploadHandler = this.fileUploadHandler.bind(this);
    this.state = {
      //  myu: "",
      title: "",
      error: "",
      hour1: "",
      minute1: "",
      hour2: "",
      minute2: "",
      logo: "",
      selectedFile: null,
      postresult: "",
      action: "",
      mytoast: {
        show: false,
      },
      submittitle: "Save",
      downloadurl: "",
      registerlink: "",
      booklink: "",
      mounted: 0,
      sys_mingrid_height: 30,
      confirmbyemail: 0,
      confirmbysms: 0,
      confirmbefore: 72,
      confirmmsgtemplate: "",
      fnoticebysms:0,
      fnoticebyemail:0,
      fnoticetemplate:"",
      afterfeedlink:"",
      parentusername:""
    };
  }
  render() {
    const { myusername } = this.props;
    const IsMobile = !IsPC();
    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;
    var zsxm = this.state.zsxm;
    if (zsxm === "") zsxm = this.state.myusername;
    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px", width: "90%" }}
      >
        <div className="pagetitle">
          System <img src={rjt} style={{ width: "18px", height: "18px" }} />{" "}
          {this.state.title}
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
            {this.state.action == "ini" && (
              <div
                style={{
                  border: "0px solid #bbb",
                  borderRadius: "5px",
                  height: "100px",
                  textAlign: "center",
                  padding: "25px",
                }}
              >
                <div>
                  This operation will clear and delete all data, leaving only
                  the main account. Please proceed with caution.
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter">
                  <button className="submit" onClick={() => this.submit()}>
                    {this.state.submittitle}
                  </button>
                </div>
              </div>
            )}
            {this.state.action == "backupdb" && (
              <div
                style={{
                  border: "0px solid #bbb",
                  borderRadius: "5px",
                  height: "auto",
                  textAlign: "center",
                  padding: "25px",
                  display:"flex",
                  flexDirection:"column"
                }}
              >
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter">
                  <button className="submit" onClick={() => this.submit()}>
                    {this.state.submittitle}
                  </button>
                </div>
                <div style={{ margin: "30px",height: "auto", }}>
                  {this.state.downloadurl != "" && (
                    <span>
                      <a
                        href={AJAROOT + "" + this.state.downloadurl + ""}
                        target="_blank"
                      >
                        Download
                      </a>
                    </span>
                  )}
                </div>
              </div>
            )}
            {this.state.action == "worktime" && (
              <>
                <div style={LCss.column}>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter bolder">
                      <span style={{fontWeight:"bold"}}>Worktime</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                      Earliest work on time:
                    </div>
                    <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <input
                        type="text"
                        style={{ width: "30px" }}
                        value={this.state.hour1}
                        onChange={(ref) => this.change(ref, "hour1")}
                      />
                      {" : "}
                      <input
                        type="text"
                        style={{ width: "30px" }}
                        value={this.state.minute1}
                        onChange={(ref) => this.change(ref, "minute1")}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                      Latest work off time:
                    </div>
                    <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <input
                        type="text"
                        style={{ width: "30px" }}
                        value={this.state.hour2}
                        onChange={(ref) => this.change(ref, "hour2")}
                      />
                      {" : "}
                      <input
                        type="text"
                        style={{ width: "30px" }}
                        value={this.state.minute2}
                        onChange={(ref) => this.change(ref, "minute2")}
                      />
                    </div>
                  </div>
                </div>

                <div style={LCss.column}>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter bolder">
                      <span style={{fontWeight:"bold"}}>Book Panel</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                      10 Minutes Height Of Panel:
                    </div>
                    <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <input
                        type="text"
                        style={{ width: "40px", marginRight: "5px" }}
                        value={this.state.sys_mingrid_height}
                        onChange={(ref) =>
                          this.change(ref, "sys_mingrid_height")
                        }
                      />
                      px
                    </div>
                  </div>
                </div>

                {this.state.parentusername==""&&<div style={LCss.column}>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter bolder">
                       <span style={{fontWeight:"bold"}}>Logo</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright"></div>
                    <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <div className="file-upload" style={{ float: "left" }}>
                        <label className="custom-file-upload">
                          Select Image
                        </label>
                        <input
                          id="file-upload"
                          type="file"
                          name="imgFile"
                          onChange={this.fileSelectedHandler}
                        />
                      </div>

                      {this.state.logo != "" && (
                        <div
                          className="imgcontainer"
                          style={{
                            width: "75px",
                            float: "left",
                            marginLeft: "50px",
                          }}
                        >
                          <img src={AJAROOT + this.state.logo}></img>
                        </div>
                      )}
                    </div>
                  </div>
                </div>}

                {this.state.parentusername==""&&<div style={LCss.column}>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter bolder">
                       <span style={{fontWeight:"bold"}}>Customer Book Notice && Confirm</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                      Notice by:
                    </div>
                    <div
                      className="col-xs-9 col-sm-9 col-md-9 col-lg-9"
                      style={{ display: "flex" }}
                    >
                      Email{" "}
                      <input
                        type="checkbox"
                        name="confirmbyemail"
                        value="Y"
                        onChange={(ref) =>
                          this.change(ref, "confirmbyemail")
                        }
                        checked={this.state.confirmbyemail == 1 ? true : false}
                        style={{
                          width: "18px",
                          height: "18px",
                          marginLeft: "5px",
                          marginRight: "15px",
                        }}
                      ></input>
                      Sms{" "}
                      <input
                        type="checkbox"
                        name="confirmbysms"
                        value="Y"
                        onChange={(ref) =>
                          this.change(ref, "confirmbysms")
                        }
                        checked={this.state.confirmbysms == 1 ? true : false}
                        style={{
                          width: "18px",
                          height: "18px",
                          marginLeft: "5px",
                        }}
                      ></input>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                      Notice before:
                    </div>
                    <div
                      className="col-xs-9 col-sm-9 col-md-9 col-lg-9"
                      style={{ display: "flex" }}
                    >
                      <input
                        type="text"
                        style={{ width: "40px", marginRight: "5px" }}
                        value={this.state.confirmbefore}
                        onChange={(ref) =>
                          this.change(ref, "confirmbefore")
                        }
                      /> Hours
                    </div>
                  </div>

                   <div className="row">
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                      Message Template:
                    </div>
                    <div
                      className="col-xs-9 col-sm-9 col-md-9 col-lg-9"
                      style={{ display: "flex" }}
                    >
                      <textarea
                        type="text"
                        style={{ width: "360px", height:"130px",marginRight: "5px" }}
                        value={this.state.confirmmsgtemplate}
                        onChange={(ref) =>
                          this.change(ref, "confirmmsgtemplate")
                        }
                      /> 
                    </div>
                  </div>
                </div>}

                {this.state.parentusername==""&&<div style={LCss.column}>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter bolder">
                       <span style={{fontWeight:"bold"}}>Customer Feedback</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                      Notice by:
                    </div>
                    <div
                      className="col-xs-9 col-sm-9 col-md-9 col-lg-9"
                      style={{ display: "flex" }}
                    >
                      Email{" "}
                      <input
                        type="checkbox"
                        name="fnoticebyemail"
                        value="Y"
                        onChange={(ref) =>
                          this.change(ref, "fnoticebyemail")
                        }
                        checked={this.state.fnoticebyemail == 1 ? true : false}
                        style={{
                          width: "18px",
                          height: "18px",
                          marginLeft: "5px",
                          marginRight: "15px",
                        }}
                      ></input>
                      Sms{" "}
                      <input
                        type="checkbox"
                        name="fnoticebysms"
                        value="Y"
                        onChange={(ref) =>
                          this.change(ref, "fnoticebysms")
                        }
                        checked={this.state.fnoticebysms == 1 ? true : false}
                        style={{
                          width: "18px",
                          height: "18px",
                          marginLeft: "5px",
                        }}
                      ></input>
                    </div>
                  </div>

                 

                   <div className="row">
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                      Message Template:
                    </div>
                    <div
                      className="col-xs-9 col-sm-9 col-md-9 col-lg-9"
                      style={{ display: "flex" }}
                    >
                      <textarea
                        type="text"
                        style={{ width: "360px", height:"130px",marginRight: "5px" }}
                        value={this.state.fnoticetemplate}
                        onChange={(ref) =>
                          this.change(ref, "fnoticetemplate")
                        }
                      /> 
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                      <div>After Feedback Jump Link:</div>
                      <div>(Will jump to this link after customer feedback 5 star, such as google comment link )</div>
                    </div>
                    <div
                      className="col-xs-9 col-sm-9 col-md-9 col-lg-9"
                      style={{ display: "flex" }}
                    >
                      <textarea
                        type="text"
                        style={{ width: "360px", height:"130px",marginRight: "5px" }}
                        value={this.state.afterfeedlink}
                        onChange={(ref) =>
                          this.change(ref, "afterfeedlink")
                        }
                      /> 
                    </div>
                  </div>
                </div>}
              </>
            )}

            {this.state.action == "link" && (
              <div style={{ border: "1px solid #bbb", borderRadius: "5px" }}>
                <div className="row">
                  <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                    Customer Register Link:
                  </div>
                  <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                    {this.state.registerlink}
                    <span
                      style={LCss.button}
                      onClick={() => {
                        this.CopyLink(this.state.registerlink);
                      }}
                    >
                      Copy Link
                    </span>
                    <span
                      style={LCss.button}
                      onClick={() => {
                        this.OpenLink(this.state.registerlink);
                      }}
                    >
                      Open Link
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                    Customer Book Link:
                  </div>
                  <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                    {this.state.booklink}
                    <span
                      style={LCss.button}
                      onClick={() => {
                        this.CopyLink(this.state.booklink);
                      }}
                    >
                      Copy Link
                    </span>

                    <span
                      style={LCss.button}
                      onClick={() => {
                        this.OpenLink(this.state.booklink);
                      }}
                    >
                      Open Link
                    </span>
                  </div>
                </div>
              </div>
            )}
            {this.state.action == "worktime" && (
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter">
                  <button className="submit" onClick={() => this.submit()}>
                    {this.state.submittitle}
                  </button>
                </div>
              </div>
            )}

            <div className="row">
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter"
                style={{ width: "70%", marginTop: "10px" }}
              >
                {this.state.postresult}
              </div>
            </div>
          </div>
        </div>
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }

  fileSelectedHandler(event) {
    //  console.log(event.target.files);
    let file = event.target.files[0];
    if (file) {
      this.fileUploadHandler(file);
    }
    /*  this.setState({
      selectedFile: event.target.files[0],
    }); */
  }

  fileUploadHandler(file) {
    const { myusername, ukey } = this.props;
    const formData = new FormData();
    formData.append("imgFile", file);
    let posturl =
      AJAROOT +
      "/postaja/savexx.aspx?action=upimg&subaction=logo&filetype=i&u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "";
    let that = this;
    $.ajax({
      type: "POST",
      processData: false,
      contentType: false,
      dataType: "json",
      url: posturl,

      data: formData,
    })
      .done(function (data) {
        var r = data.r;
        var msg = data.s;

        if (r === 1) {
          that.setState({
            postresult: "Successfully! ",
            logo: data.url,
          });
          MyToastShow(
            that,
            2,
            "Upload Success!",
            200,
            120,
            "success",
            "addsrvcate"
          );
        } else {
          that.setState({
            postresult: msg,
          });
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {});
  }

  CopyLink(link) {
    copy(link);
    MyToastShow(this, 2, "Copy succeeded", 200, 120, "success", "addsrvcate");
  }
  OpenLink(link) {
    window.open(link, "_blank");
  }
  change(e, field) {
    let val = e.target.value;
    if (field == "hour1") {
      this.setState({
        hour1: val,
      });
    } 
    else if(field=="afterfeedlink")
    {
     this.setState({
        afterfeedlink: val,
      });
    }
    else if (field == "hour2") {
      this.setState({
        hour2: val,
      });
    } else if (field == "minute1") {
      this.setState({
        minute1: val,
      });
    } else if (field == "minute2") {
      this.setState({
        minute2: val,
      });
    } else if (field == "sys_mingrid_height") {
      if (!isNaN(val)) {
        this.setState({
          sys_mingrid_height: val,
        });
      }
    }
     else if (field == "confirmbyemail") {
      val = "";
      if (e.target.checked) {
        val = 1;
      } else {
        val = 0;
      }
      this.setState({
        confirmbyemail: val,
      });
    } 
     else if (field == "confirmbysms") {

      val = "";
      if (e.target.checked) {
        val = 1;
      } else {
        val = 0;
      }
      this.setState({
        confirmbysms: val,
      });
    } 
    else if(field=="confirmbefore")
    {
    this.setState({
        confirmbefore: val,
      });
    }
     else if(field=="confirmmsgtemplate")
    {
    this.setState({
        confirmmsgtemplate: val,
      });
    }
   
     else if(field=="fnoticetemplate")
    {
    this.setState({
        fnoticetemplate: val,
      });
    }
    else if (field == "fnoticebysms") {
      val = "";
      if (e.target.checked) {
        val = 1;
      } else {
        val = 0;
      }
      this.setState({
        fnoticebysms: val,
      });
    } 
     else if (field == "fnoticebyemail") {

      val = "";
      if (e.target.checked) {
        val = 1;
      } else {
        val = 0;
      }
      this.setState({
        fnoticebyemail: val,
      });
    }    
  }
  submit() {
    const { myusername, ukey } = this.props;

    // document.getElementById("result2").style.color = "#000000";

    this.setState({
      postresult: "Waiting....",
    });
    // document.getElementById("result2").innerHTML = "正在提交....";
    let worktimefrom = this.state.hour1 + ":" + this.state.minute1;
    let worktimeto = this.state.hour2 + ":" + this.state.minute2;

    let postdata = {};

    let action = "";
    if (this.state.action == "worktime") {
      action = "saveworktime";
      postdata = {
        uid: myusername,
        worktimefrom: worktimefrom,
        worktimeto: worktimeto,
        sys_mingrid_height: this.state.sys_mingrid_height * 1,
        logo: this.state.logo,
        confirmbyemail:this.state.confirmbyemail* 1,
        confirmbysms:this.state.confirmbysms* 1,
        confirmbefore:this.state.confirmbefore* 1,

        confirmmsgtemplate:this.state.confirmmsgtemplate,
        fnoticebyemail:this.state.fnoticebyemail* 1,
        fnoticebysms:this.state.fnoticebysms* 1,
        fnoticetemplate:this.state.fnoticetemplate,
        afterfeedlink:this.state.afterfeedlink
      };
    }
    if (this.state.action == "backupdb") {
      action = "backupdb";
    } else if (this.state.action == "ini") {
      action = "inistoredata";
    }
    const that = this;
    var error = "";

    //document.getElementById("result2").style.color = "red";
    if (error !== "") {
      this.setState({
        postresult: error,
      });
    } else {
      $.ajax({
        type: "POST",
        dataType: "json",
        url:
          AJAROOT +
          "/postaja/savexx.aspx?action=" +
          action +
          "&u=" +
          myusername +
          "&ukey=" +
          encodeURIComponent(ukey) +
          "",
        data: JSON.stringify(postdata),
      })
        .done(function (data) {
          var r = data.r;
          var msg = data.s;

          if (r === 1) {
            //  this.postresult = "登陆成功！";
            if (that.state.action == "setup") {
            } else if (that.state.action == "backupdb") {
              that.setState({
                downloadurl: data.downloadurl,
              });
            }

            that.setState({
              postresult: "Successfully! ",
            });
            MyToastShow(that, 2, msg, 200, 120, "success", "addsrvcate");
            // this2.props.history.push("/user");
          } else {
            //document.getElementById("result2").style.color = "red";
            that.setState({
              postresult: msg,
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    }
  }
  getData(myusername, ukey) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?action=worktime&dianusername=" +
      myusername +
      "&u=" +
      myusername +
      "&ukey=" +
      ukey;

    axios.get(geturl).then((res) => {
      const result = res.data;

      let worktimefrom = result.worktimefrom.split(":");
      let worktimeto = result.worktimeto.split(":");

      let hour1 = worktimefrom[0];
      let minute1 = worktimefrom[1];
      let hour2 = worktimeto[0];
      let minute2 = worktimeto[1];
      let confirmmsgtemplate=result.confirmmsgtemplate;
      if(confirmmsgtemplate=="")
      {
       confirmmsgtemplate="Dear {customername}, \nYour book on {storename} will begin at {time}.\nClick the below link to confirm or cancel your appointment: {link} \n\nOur address is {address}, please don't forget it.";
 
      }

      let fnoticetemplate=result.fnoticetemplate;
      if(fnoticetemplate=="")
      {
       fnoticetemplate="How would you rate your experience with {company}? \nPlease leave a review: {link}";
 
      }
      this.setState({
        logo: result.logo,
        hour1: hour1,
        minute1: minute1,
        hour2: hour2,
        minute2: minute2,
        sys_mingrid_height: result.sys_mingrid_height,
        confirmbyemail: result.confirmbyemail,
        confirmbysms: result.confirmbysms,
        confirmbefore: result.confirmbefore,
        confirmmsgtemplate: confirmmsgtemplate,
fnoticebyemail: result.fnoticebyemail,
        fnoticebysms: result.fnoticebysms,
      
        fnoticetemplate: fnoticetemplate,
        afterfeedlink:result.afterfeedlink,
        parentusername:result.parentusername
        
      });
      // this.setState({}); /**/
    });
  }
  setData(action) {
    const { myusername, ukey } = this.props;
    var title = "";
    let registerlink = "";
    let booklink = "";
    var submittitle = "Save";
    if (action === "worktime") {
      title = "System Setup";
    } else if (action === "backupdb") {
      submittitle = "Backup Now";
      title = "Database Backup";
    } else if (action === "ini") {
      submittitle = "Initialize Now";
      title = "Initialize System";
    } else if (action === "link") {
      submittitle = "Signin && Book Link";
      title = "Signin && Book Link";
      let host = window.location.host;
      //   let port=window.location.port;
      // if(port=="80"||port=="443")port=port;
      // else port=":"+port;
      let protocol = window.location.protocol;
      let domain = protocol + "//" + host;
      registerlink = domain + "/register?store=" + myusername + "";
      booklink = domain + "/user/book/add?store=" + myusername + "";
    }
    this.setState({
      title: title,
      action: action,
      submittitle: submittitle,
      registerlink: registerlink,
      booklink: booklink,
      postresult: "",
    });
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;
    var { action } = queryString.parse(this.props.location.search);
    this.setData(action);

    //  getUserIndexData(this, myusername, ukey);
    this.getData(myusername, ukey);
  let confirmmsgtemplate="Dear {customername}, \nYour booked service will begin at {time}.\nClick the below link to confirm or cancel your appointment: {link} \n\n{address}\n{company}";
    this.setState({ mounted: 1,confirmmsgtemplate:confirmmsgtemplate });
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {
    if (this.state.mounted == 1) {
      const { myusername, ukey } = this.props;
      var { action } = queryString.parse(this.props.location.search);

      if (action != this.state.action) this.setData(action);
    }
    //
  }
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    dianusername: state.getIn(["gdata", "dianusername"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
  };
};

export default connect(mapStateToProps, null)(User);
