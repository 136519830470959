import { fromJS } from "immutable";
import { defaultStore } from "../../gdata/global";

let gdata = sessionStorage.getItem("gdata")
  ? JSON.parse(sessionStorage.getItem("gdata"))
  : {
      username: "",
      lastname: "",
      ukey: "",
      usergrade: 1,
      usertype: 0,
      cartnum: 0,
      parentusername: "", //据此判断是不是总店
      dianusername: "",
      pagetitle: "",
      storeinfo:defaultStore
    };


let cartnum = sessionStorage.getItem("cartnum")
  ? sessionStorage.getItem("cartnum")
  : 0;
let defaultState = fromJS({
  username: gdata["username"],
  lastname: gdata["lastname"],
     firstname: gdata["firstname"],
  ukey: gdata["ukey"],
  usergrade: gdata["usergrade"],
  usertype: gdata["usertype"],
  parentusername: gdata["parentusername"],
  dianusername: gdata["dianusername"],
  cartnum: cartnum,
  storeinfo: gdata["storeinfo"],
});



//defaultState.storeinfo=gdata["storeinfo"];
const Todo = (state = defaultState, action) => {

  switch (action.type) {
    case "change_login_data": {
      sessionStorage.setItem("gdata", JSON.stringify(action));
    //  sessionStorage.setItem("cartnum", action.cartnum);
      return state.merge({
        username: action.username,
        lastname: action.lastname,
        firstname: action.firstname,
        ukey: action.ukey,
        usergrade: action.usergrade,
        usertype: action.usertype,
        cartnum: action.cartnum,
        parentusername: action.parentusername, //据此判断是不是总店
        dianusername: action.dianusername,
        storeinfo:action.storeinfo
      });
    }

    case "change_cart_num": {
      sessionStorage.setItem("cartnum", action.cartnum);

      return state.merge({
        cartnum: action.cartnum,
      });
    }
    case "change_pagetitle": {
      sessionStorage.setItem("pagetitle", action.pagetitle);

      return state.merge({
        pagetitle: action.pagetitle,
      });
    }
    default: {
      return state;
    }
  }
};
export default Todo;
