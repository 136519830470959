import alfalogo from "@/statics/logo.png";
import publiclogo from "@/statics/plogo.png";
//window.location.protocol
/* */
const islocal=false;

export const AJAROOT =islocal?
  window.location.host.indexOf("localhost") >= 0 ||
  window.location.host.indexOf("192.168.") >= 0||

  window.location.host.indexOf("10.0.0.152") >= 0
    ? "http://localhost:8810/"
    : "https://www.alfabooking.ca/":"https://www.alfabooking.ca/";  
    

    /**/  

//export const AJAROOT = "https://www.alfabooking.ca/";
    
//export const AJAROOT = "http://88.chkjfl.com/";

export const SiteName = "SmarterBook System";
export const Kefu = "a0001"; //"a0001";
export const defaultStore = {
 logo: publiclogo,
  sitename: "SmarterBook",
  name: "SmarterBook",
  address: "41 petword scrb",
  postal: "M1S 3M6",
  tel: "(647) 518-9209",
  email: "",
}; /**/
/*
export const defaultStore = `{
  "company":"Alfa Spa & Wellness",
  "name":"Alfa Spa & Wellness",
  "address":"119 Ellesmere Rd",
  "postal":"M1R 4C3",
  "tel":"(647) 349-3303",
  "email":""
}`;  */
export const bookedWorktimeColor="#8279ce";
export const SiteUrl = "http://localhost";
export const Dollar = "CA$";
export const Color1 = "#337b70";
export const Color2 = "#ff6633";
export const rentUnit = "month";
export const checkColor = "#f0daf3";//前台确认过的紫色颜色
export const bookButtonColor = "#57c0e3";//"#6666ff";
export const littleBlueColor = "#bee8f8";
export const offTimeColor = "#eee";
export const mileFee = 0.5;
export const insuranceHl = 0.02;
export const FromZip = "M1V4T1";
export const Tax = 0.13;
export const FromHour = "9:30";
export const ToHour = "20:00";
export const PickUpDiff = 5;
export const RememberMe = 1;
export const FreeYunfeiAmount = 35;
export const DefaultYunFee = 25;
export const tihuodz = "";
export const MinDawai = 10;
export const BasicWidth=150;
export const checkoutBgcolor = "#aaaaaa";
export const greeTitleBg = "#e7e7e7";
export const fixedColor = "#ffe9bb";
export const mainGreen="#519956";
export function UserType() {
  return {
    0: "Customer",
    1: "Employee",
    2: "Admin",
    3: "Store",
  };
}

export function getDomainInfo()
{
  var domain = window.location.hostname;

// 去掉 'www.' 部分
var domainWithoutWWW = domain.replace(/^www\./, '');

  
  let info={};
  let allowReg=true;
   if(domainWithoutWWW=="alfabooking.ca")
   {
    allowReg=false;
   }
  info.allowReg=allowReg;
  return info;
}

export const MainDomain = [ "www.smarterbook.net", "889.chkjfl.com","localhost", "10.0.0.152",

];
export const domainConfig = [
  /* {
    domain: "88.chkjfl.com",
    logo: alfalogo,
    company: "Alfa Spa & Wellness",
    showtop: 0,
    storeinfo: defaultStore,
  }, */
  {
    domain: "www.smarterbook.net",
    logo: publiclogo,
    company: "SmarterBook",
    showtop: 1,
    storeinfo: defaultStore,
  },
  {
    domain: "889.chkjfl.com",
    logo: publiclogo,
    company: "SmarterBook",
    showtop: 1,
    storeinfo: defaultStore,
  },
  {
    domain: "localhost",
    logo: publiclogo,
    showtop: 1,
    company: "SmarterBook",
    storeinfo: defaultStore,
  },
  {
    domain: "10.0.0.152",
    logo: publiclogo,
    showtop: 1,
    company: "SmarterBook",
    storeinfo: defaultStore,
  },
];

export function mainNation() {
  return [
    { code: "CA", tel: "1", name: "Canada", zh_cn: "加拿大", zh_tw: "加拿大" },
    {
      code: "US",
      tel: "1",
      name: "United States of America",
      zh_cn: "美国",
      zh_tw: "美國",
    },
    {
      code: "UK",
      tel: "44",
      name: "United Kingdom",
      zh_cn: "英国",
      zh_tw: "英國",
    },
    {
      code: "AU",
      tel: "61",
      name: "Australia",
      zh_cn: "澳大利亚",
      zh_tw: "澳大利亞",
    },
    { code: "DE", tel: "49", name: "Germany", zh_cn: "德国", zh_tw: "德國" },
    { code: "FR", tel: "33", name: "France", zh_cn: "法国", zh_tw: "法國" },
    { code: "JP", tel: "81", name: "Japan", zh_cn: "日本", zh_tw: "日本" },
    {
      code: "KR",
      tel: "82",
      name: "South Korea",
      zh_cn: "韩国",
      zh_tw: "韓國",
    },
    { code: "CN", tel: "86", name: "China", zh_cn: "中国", zh_tw: "中國" },
    {
      code: "TW",
      tel: "886",
      name: "Taiwan(China)",
      zh_cn: "中国台湾",
      zh_tw: "中國台灣",
    },
    {
      code: "HK",
      tel: "852",
      name: "Hong Kong SAR",
      zh_cn: "中国香港",
      zh_tw: "中國香港",
    },
    {
      code: "MO",
      tel: "853",
      name: "Macao SAR",
      zh_cn: "中国澳门",
      zh_tw: "中國澳門",
    },
  ];
}
export function Nation() {
  return [
    {
      code: "AF",
      tel: "93",
      name: "Afghanistan",
      zh_cn: "阿富汗",
      zh_tw: "阿富汗",
    },
    {
      code: "AL",
      tel: "355",
      name: "Albania",
      zh_cn: "阿尔巴尼亚",
      zh_tw: "阿爾巴尼亞",
    },
    {
      code: "DZ",
      tel: "213",
      name: "Algeria",
      zh_cn: "阿尔及利亚",
      zh_tw: "阿爾及利亞",
    },
    {
      code: "AD",
      tel: "376",
      name: "Andorra",
      zh_cn: "安道尔共和国",
      zh_tw: "安道爾共和國",
    },
    {
      code: "AO",
      tel: "244",
      name: "Angola",
      zh_cn: "安哥拉",
      zh_tw: "安哥拉",
    },
    {
      code: "AI",
      tel: "1264",
      name: "Anguilla",
      zh_cn: "安圭拉岛",
      zh_tw: "安圭拉島",
    },
    {
      code: "AG",
      tel: "1268",
      name: "Antigua and Barbuda",
      zh_cn: "安提瓜和巴布达",
      zh_tw: "安提瓜和巴布達",
    },
    {
      code: "AR",
      tel: "54",
      name: "Argentina",
      zh_cn: "阿根廷",
      zh_tw: "阿根廷",
    },
    {
      code: "AM",
      tel: "374",
      name: "Armenia",
      zh_cn: "亚美尼亚",
      zh_tw: "亞美尼亞",
    },
    {
      code: "AU",
      tel: "61",
      name: "Australia",
      zh_cn: "澳大利亚",
      zh_tw: "澳大利亞",
    },
    {
      code: "AT",
      tel: "43",
      name: "Austria",
      zh_cn: "奥地利",
      zh_tw: "奧地利",
    },
    {
      code: "AZ",
      tel: "994",
      name: "Azerbaijan",
      zh_cn: "阿塞拜疆",
      zh_tw: "阿塞拜疆",
    },
    {
      code: "BS",
      tel: "1242",
      name: "Bahamas",
      zh_cn: "巴哈马",
      zh_tw: "巴哈馬",
    },
    { code: "BH", tel: "973", name: "Bahrain", zh_cn: "巴林", zh_tw: "巴林" },
    {
      code: "BD",
      tel: "880",
      name: "Bangladesh",
      zh_cn: "孟加拉国",
      zh_tw: "孟加拉國",
    },
    {
      code: "BB",
      tel: "1246",
      name: "Barbados",
      zh_cn: "巴巴多斯",
      zh_tw: "巴巴多斯",
    },
    {
      code: "BY",
      tel: "375",
      name: "Belarus",
      zh_cn: "白俄罗斯",
      zh_tw: "白俄羅斯",
    },
    {
      code: "BE",
      tel: "32",
      name: "Belgium",
      zh_cn: "比利时",
      zh_tw: "比利時",
    },
    {
      code: "BZ",
      tel: "501",
      name: "Belize",
      zh_cn: "伯利兹",
      zh_tw: "伯利茲",
    },
    { code: "BJ", tel: "229", name: "Benin", zh_cn: "贝宁", zh_tw: "貝寧" },
    {
      code: "BM",
      tel: "1441",
      name: "Bermuda Is.",
      zh_cn: "百慕大群岛",
      zh_tw: "百慕大群島",
    },
    { code: "BT", tel: "975", name: "Bhutan", zh_cn: "不丹", zh_tw: "不丹" },
    {
      code: "BO",
      tel: "591",
      name: "Bolivia",
      zh_cn: "玻利维亚",
      zh_tw: "玻利維亞",
    },
    {
      code: "BA",
      tel: "387",
      name: "Bosnia and Herzegovina",
      zh_cn: "波斯尼亚和黑塞哥维那",
      zh_tw: "波斯尼亞和黑塞哥維那",
    },
    {
      code: "BW",
      tel: "267",
      name: "Botswana",
      zh_cn: "博茨瓦纳",
      zh_tw: "博茨瓦納",
    },
    { code: "BR", tel: "55", name: "Brazil", zh_cn: "巴西", zh_tw: "巴西" },
    { code: "BN", tel: "673", name: "Brunei", zh_cn: "文莱", zh_tw: "文萊" },
    {
      code: "BG",
      tel: "359",
      name: "Bulgaria",
      zh_cn: "保加利亚",
      zh_tw: "保加利亞",
    },
    {
      code: "BF",
      tel: "226",
      name: "Burkina-faso",
      zh_cn: "布基纳法索",
      zh_tw: "布基納法索",
    },
    { code: "MM", tel: "95", name: "Burma", zh_cn: "缅甸", zh_tw: "緬甸" },
    {
      code: "BI",
      tel: "257",
      name: "Burundi",
      zh_cn: "布隆迪",
      zh_tw: "布隆迪",
    },
    {
      code: "KH",
      tel: "855",
      name: "Cambodia",
      zh_cn: "柬埔寨",
      zh_tw: "柬埔寨",
    },
    {
      code: "CM",
      tel: "237",
      name: "Cameroon",
      zh_cn: "喀麦隆",
      zh_tw: "喀麥隆",
    },
    { code: "CA", tel: "1", name: "Canada", zh_cn: "加拿大", zh_tw: "加拿大" },
    {
      code: "CV",
      tel: "238",
      name: "Cape Verde",
      zh_cn: "佛得角",
      zh_tw: "佛得角",
    },
    {
      code: "KY",
      tel: "1345",
      name: "Cayman Is.",
      zh_cn: "开曼群岛",
      zh_tw: "開曼群島",
    },
    {
      code: "CF",
      tel: "236",
      name: "Central African Republic",
      zh_cn: "中非共和国",
      zh_tw: "中非共和國",
    },
    { code: "TD", tel: "235", name: "Chad", zh_cn: "乍得", zh_tw: "乍得" },
    { code: "CL", tel: "56", name: "Chile", zh_cn: "智利", zh_tw: "智利" },
    { code: "CN", tel: "86", name: "China", zh_cn: "中国", zh_tw: "中國" },
    {
      code: "CO",
      tel: "57",
      name: "Colombia",
      zh_cn: "哥伦比亚",
      zh_tw: "哥倫比亞",
    },
    {
      code: "KM",
      tel: "269",
      name: "Comoros",
      zh_cn: "科摩罗",
      zh_tw: "科摩羅",
    },
    { code: "CG", tel: "242", name: "Congo", zh_cn: "刚果", zh_tw: "剛果" },
    {
      code: "CK",
      tel: "682",
      name: "Cook Is.",
      zh_cn: "库克群岛",
      zh_tw: "庫克群島",
    },
    {
      code: "CR",
      tel: "506",
      name: "Costa Rica",
      zh_cn: "哥斯达黎加",
      zh_tw: "哥斯達黎加",
    },
    {
      code: "HR",
      tel: "385",
      name: "Croatia",
      zh_cn: "克罗地亚",
      zh_tw: "克羅地亞",
    },
    { code: "CU", tel: "53", name: "Cuba", zh_cn: "古巴", zh_tw: "古巴" },
    {
      code: "CY",
      tel: "357",
      name: "Cyprus",
      zh_cn: "塞浦路斯",
      zh_tw: "塞浦路斯",
    },
    {
      code: "CZ",
      tel: "420",
      name: "Czech Republic",
      zh_cn: "捷克",
      zh_tw: "捷克",
    },
    { code: "DK", tel: "45", name: "Denmark", zh_cn: "丹麦", zh_tw: "丹麥" },
    {
      code: "DJ",
      tel: "253",
      name: "Djibouti",
      zh_cn: "吉布提",
      zh_tw: "吉布提",
    },
    {
      code: "DO",
      tel: "1890",
      name: "Dominica Rep.",
      zh_cn: "多米尼加共和国",
      zh_tw: "多米尼加共和國",
    },
    {
      code: "EC",
      tel: "593",
      name: "Ecuador",
      zh_cn: "厄瓜多尔",
      zh_tw: "厄瓜多爾",
    },
    { code: "EG", tel: "20", name: "Egypt", zh_cn: "埃及", zh_tw: "埃及" },
    {
      code: "SV",
      tel: "503",
      name: "EI Salvador",
      zh_cn: "萨尔瓦多",
      zh_tw: "薩爾瓦多",
    },
    {
      code: "EE",
      tel: "372",
      name: "Estonia",
      zh_cn: "爱沙尼亚",
      zh_tw: "愛沙尼亞",
    },
    {
      code: "ET",
      tel: "251",
      name: "Ethiopia",
      zh_cn: "埃塞俄比亚",
      zh_tw: "埃塞俄比亞",
    },
    { code: "FJ", tel: "679", name: "Fiji", zh_cn: "斐济", zh_tw: "斐濟" },
    { code: "FI", tel: "358", name: "Finland", zh_cn: "芬兰", zh_tw: "芬蘭" },
    { code: "FR", tel: "33", name: "France", zh_cn: "法国", zh_tw: "法國" },
    {
      code: "GF",
      tel: "594",
      name: "French Guiana",
      zh_cn: "法属圭亚那",
      zh_tw: "法屬圭亞那",
    },
    { code: "GA", tel: "241", name: "Gabon", zh_cn: "加蓬", zh_tw: "加蓬" },
    {
      code: "GM",
      tel: "220",
      name: "Gambia",
      zh_cn: "冈比亚",
      zh_tw: "岡比亞",
    },
    {
      code: "GE",
      tel: "995",
      name: "Georgia",
      zh_cn: "格鲁吉亚",
      zh_tw: "格魯吉亞",
    },
    { code: "DE", tel: "49", name: "Germany", zh_cn: "德国", zh_tw: "德國" },
    { code: "GH", tel: "233", name: "Ghana", zh_cn: "加纳", zh_tw: "加納" },
    {
      code: "GI",
      tel: "350",
      name: "Gibraltar",
      zh_cn: "直布罗陀",
      zh_tw: "直布羅陀",
    },
    { code: "GR", tel: "30", name: "Greece", zh_cn: "希腊", zh_tw: "希臘" },
    {
      code: "GD",
      tel: "1809",
      name: "Grenada",
      zh_cn: "格林纳达",
      zh_tw: "格林納達",
    },
    { code: "GU", tel: "1671", name: "Guam", zh_cn: "关岛", zh_tw: "關島" },
    {
      code: "GT",
      tel: "502",
      name: "Guatemala",
      zh_cn: "危地马拉",
      zh_tw: "危地馬拉",
    },
    {
      code: "GN",
      tel: "224",
      name: "Guinea",
      zh_cn: "几内亚",
      zh_tw: "幾內亞",
    },
    {
      code: "GY",
      tel: "592",
      name: "Guyana",
      zh_cn: "圭亚那",
      zh_tw: "圭亞那",
    },
    { code: "HT", tel: "509", name: "Haiti", zh_cn: "海地", zh_tw: "海地" },
    {
      code: "HN",
      tel: "504",
      name: "Honduras",
      zh_cn: "洪都拉斯",
      zh_tw: "洪都拉斯",
    },
    {
      code: "HK",
      tel: "852",
      name: "Hongkong",
      zh_cn: "中国香港",
      zh_tw: "中國香港",
    },
    {
      code: "HU",
      tel: "36",
      name: "Hungary",
      zh_cn: "匈牙利",
      zh_tw: "匈牙利",
    },
    { code: "IS", tel: "354", name: "Iceland", zh_cn: "冰岛", zh_tw: "冰島" },
    { code: "IN", tel: "91", name: "India", zh_cn: "印度", zh_tw: "印度" },
    {
      code: "ID",
      tel: "62",
      name: "Indonesia",
      zh_cn: "印度尼西亚",
      zh_tw: "印度尼西亞",
    },
    { code: "IR", tel: "98", name: "Iran", zh_cn: "伊朗", zh_tw: "伊朗" },
    { code: "IQ", tel: "964", name: "Iraq", zh_cn: "伊拉克", zh_tw: "伊拉克" },
    {
      code: "IE",
      tel: "353",
      name: "Ireland",
      zh_cn: "爱尔兰",
      zh_tw: "愛爾蘭",
    },
    {
      code: "IL",
      tel: "972",
      name: "Israel",
      zh_cn: "以色列",
      zh_tw: "以色列",
    },
    { code: "IT", tel: "39", name: "Italy", zh_cn: "意大利", zh_tw: "意大利" },
    {
      code: "JM",
      tel: "1876",
      name: "Jamaica",
      zh_cn: "牙买加",
      zh_tw: "牙買加",
    },
    { code: "JP", tel: "81", name: "Japan", zh_cn: "日本", zh_tw: "日本" },
    { code: "JO", tel: "962", name: "Jordan", zh_cn: "约旦", zh_tw: "約旦" },
    {
      code: "KZ",
      tel: "7",
      name: "Kazakstan",
      zh_cn: "哈萨克斯坦",
      zh_tw: "哈薩克斯坦",
    },
    { code: "KE", tel: "254", name: "Kenya", zh_cn: "肯尼亚", zh_tw: "肯尼亞" },
    {
      code: "KI",
      tel: "686",
      name: "Kiribati",
      zh_cn: "基里巴斯",
      zh_tw: "基里巴斯",
    },
    { code: "KR", tel: "82", name: "Korea", zh_cn: "韩国", zh_tw: "韓國" },
    {
      code: "KW",
      tel: "965",
      name: "Kuwait",
      zh_cn: "科威特",
      zh_tw: "科威特",
    },
    {
      code: "KG",
      tel: "331",
      name: "Kyrgyzstan",
      zh_cn: "吉尔吉斯坦",
      zh_tw: "吉爾吉斯坦",
    },
    { code: "LA", tel: "856", name: "Laos", zh_cn: "老挝", zh_tw: "老撾" },
    {
      code: "LV",
      tel: "371",
      name: "Latvia",
      zh_cn: "拉脱维亚",
      zh_tw: "拉脫維亞",
    },
    {
      code: "LB",
      tel: "961",
      name: "Lebanon",
      zh_cn: "黎巴嫩",
      zh_tw: "黎巴嫩",
    },
    {
      code: "LS",
      tel: "266",
      name: "Lesotho",
      zh_cn: "莱索托",
      zh_tw: "萊索托",
    },
    {
      code: "LR",
      tel: "231",
      name: "Liberia",
      zh_cn: "利比里亚",
      zh_tw: "利比里亞",
    },
    { code: "LY", tel: "218", name: "Libya", zh_cn: "利比亚", zh_tw: "利比亞" },
    {
      code: "LI",
      tel: "423",
      name: "Liechtenstein",
      zh_cn: "列支敦士登",
      zh_tw: "列支敦士登",
    },
    {
      code: "LT",
      tel: "370",
      name: "Lithuania",
      zh_cn: "立陶宛",
      zh_tw: "立陶宛",
    },
    {
      code: "LU",
      tel: "352",
      name: "Luxembourg",
      zh_cn: "卢森堡",
      zh_tw: "盧森堡",
    },
    {
      code: "MO",
      tel: "853",
      name: "Macao",
      zh_cn: "中国澳门",
      zh_tw: "中國澳門",
    },
    {
      code: "MK",
      tel: "389",
      name: "Macedonia",
      zh_cn: "马其顿",
      zh_tw: "馬其頓",
    },
    {
      code: "MG",
      tel: "261",
      name: "Madagascar",
      zh_cn: "马达加斯加",
      zh_tw: "馬達加斯加",
    },
    {
      code: "MW",
      tel: "265",
      name: "Malawi",
      zh_cn: "马拉维",
      zh_tw: "馬拉維",
    },
    {
      code: "MY",
      tel: "60",
      name: "Malaysia",
      zh_cn: "马来西亚",
      zh_tw: "馬來西亞",
    },
    {
      code: "MV",
      tel: "960",
      name: "Maldives",
      zh_cn: "马尔代夫",
      zh_tw: "馬爾代夫",
    },
    { code: "ML", tel: "223", name: "Mali", zh_cn: "马里", zh_tw: "馬里" },
    { code: "MT", tel: "356", name: "Malta", zh_cn: "马耳他", zh_tw: "馬耳他" },
    {
      code: "MH",
      tel: "692",
      name: "Marshall Islands",
      zh_cn: "马绍尔群岛",
      zh_tw: "馬紹爾群島",
    },
    {
      code: "MQ",
      tel: "596",
      name: "Martinique",
      zh_cn: "马提尼克",
      zh_tw: "馬提尼克",
    },
    {
      code: "MR",
      tel: "222",
      name: "Mauritania",
      zh_cn: "毛里塔尼亚",
      zh_tw: "毛里塔尼亞",
    },
    {
      code: "MU",
      tel: "230",
      name: "Mauritius",
      zh_cn: "毛里求斯",
      zh_tw: "毛里求斯",
    },
    {
      code: "YT",
      tel: "269",
      name: "Mayotte",
      zh_cn: "马约特",
      zh_tw: "馬約特",
    },
    { code: "MX", tel: "52", name: "Mexico", zh_cn: "墨西哥", zh_tw: "墨西哥" },
    {
      code: "FM",
      tel: "691",
      name: "Micronesia",
      zh_cn: "密克罗尼西亚",
      zh_tw: "密克羅尼西亞",
    },
    {
      code: "MD",
      tel: "373",
      name: "Moldova",
      zh_cn: "摩尔多瓦",
      zh_tw: "摩爾多瓦",
    },
    {
      code: "MC",
      tel: "377",
      name: "Monaco",
      zh_cn: "摩纳哥",
      zh_tw: "摩納哥",
    },
    { code: "MN", tel: "976", name: "Mongolia", zh_cn: "蒙古", zh_tw: "蒙古" },
    {
      code: "MS",
      tel: "1664",
      name: "Montserrat Is",
      zh_cn: "蒙特塞拉特岛",
      zh_tw: "蒙特塞拉特島",
    },
    {
      code: "MA",
      tel: "212",
      name: "Morocco",
      zh_cn: "摩洛哥",
      zh_tw: "摩洛哥",
    },
    {
      code: "MZ",
      tel: "258",
      name: "Mozambique",
      zh_cn: "莫桑比克",
      zh_tw: "莫桑比克",
    },
    {
      code: "NA",
      tel: "264",
      name: "Namibia",
      zh_cn: "纳米比亚",
      zh_tw: "納米比亞",
    },
    { code: "NR", tel: "674", name: "Nauru", zh_cn: "瑙鲁", zh_tw: "瑙魯" },
    { code: "NP", tel: "977", name: "Nepal", zh_cn: "尼泊尔", zh_tw: "尼泊爾" },
    {
      code: "AN",
      tel: "599",
      name: "Netheriands Antilles",
      zh_cn: "荷属安的列斯",
      zh_tw: "荷屬安的列斯",
    },
    {
      code: "NL",
      tel: "31",
      name: "Netherlands",
      zh_cn: "荷兰",
      zh_tw: "荷蘭",
    },
    {
      code: "NC",
      tel: "687",
      name: "New Caledonia",
      zh_cn: "新喀里多尼亚",
      zh_tw: "新喀里多尼亞",
    },
    {
      code: "NZ",
      tel: "64",
      name: "New Zealand",
      zh_cn: "新西兰",
      zh_tw: "新西蘭",
    },
    {
      code: "NI",
      tel: "505",
      name: "Nicaragua",
      zh_cn: "尼加拉瓜",
      zh_tw: "尼加拉瓜",
    },
    { code: "NE", tel: "227", name: "Niger", zh_cn: "尼日尔", zh_tw: "尼日爾" },
    {
      code: "NG",
      tel: "234",
      name: "Nigeria",
      zh_cn: "尼日利亚",
      zh_tw: "尼日利亞",
    },
    { code: "NU", tel: "683", name: "Niue", zh_cn: "纽埃", zh_tw: "紐埃" },
    {
      code: "NF",
      tel: "672",
      name: "Norfolk Island",
      zh_cn: "诺福克岛",
      zh_tw: "諾福克島",
    },
    {
      code: "KP",
      tel: "850",
      name: "North Korea",
      zh_cn: "朝鲜",
      zh_tw: "朝鮮",
    },
    { code: "NO", tel: "47", name: "Norway", zh_cn: "挪威", zh_tw: "挪威" },
    { code: "OM", tel: "968", name: "Oman", zh_cn: "阿曼", zh_tw: "阿曼" },
    {
      code: "PK",
      tel: "92",
      name: "Pakistan",
      zh_cn: "巴基斯坦",
      zh_tw: "巴基斯坦",
    },
    { code: "PW", tel: "680", name: "Palau", zh_cn: "帕劳", zh_tw: "帕勞" },
    {
      code: "PA",
      tel: "507",
      name: "Panama",
      zh_cn: "巴拿马",
      zh_tw: "巴拿馬",
    },
    {
      code: "PG",
      tel: "675",
      name: "Papua New Guinea",
      zh_cn: "巴布亚新几内亚",
      zh_tw: "巴布亞新幾內亞",
    },
    {
      code: "PY",
      tel: "595",
      name: "Paraguay",
      zh_cn: "巴拉圭",
      zh_tw: "巴拉圭",
    },
    { code: "PE", tel: "51", name: "Peru", zh_cn: "秘鲁", zh_tw: "秘魯" },
    {
      code: "PH",
      tel: "63",
      name: "Philippines",
      zh_cn: "菲律宾",
      zh_tw: "菲律賓",
    },
    { code: "PL", tel: "48", name: "Poland", zh_cn: "波兰", zh_tw: "波蘭" },
    {
      code: "PF",
      tel: "689",
      name: "French Polynesia",
      zh_cn: "法属波利尼西亚",
      zh_tw: "法屬波利尼西亞",
    },
    {
      code: "PT",
      tel: "351",
      name: "Portugal",
      zh_cn: "葡萄牙",
      zh_tw: "葡萄牙",
    },
    {
      code: "PR",
      tel: "1787",
      name: "Puerto Rico",
      zh_cn: "波多黎各",
      zh_tw: "波多黎各",
    },
    { code: "QA", tel: "974", name: "Qatar", zh_cn: "卡塔尔", zh_tw: "卡塔爾" },
    {
      code: "RE",
      tel: "262",
      name: "Reunion",
      zh_cn: "留尼旺",
      zh_tw: "留尼旺",
    },
    {
      code: "RO",
      tel: "40",
      name: "Romania",
      zh_cn: "罗马尼亚",
      zh_tw: "羅馬尼亞",
    },
    { code: "RU", tel: "7", name: "Russia", zh_cn: "俄罗斯", zh_tw: "俄羅斯" },
    {
      code: "RW",
      tel: "250",
      name: "Rwanda",
      zh_cn: "卢旺达",
      zh_tw: "盧旺達",
    },
    {
      code: "LC",
      tel: "1758",
      name: "Saint Lucia",
      zh_cn: "圣卢西亚",
      zh_tw: "聖盧西亞",
    },
    {
      code: "SM",
      tel: "378",
      name: "San Marino",
      zh_cn: "圣马力诺",
      zh_tw: "聖馬力諾",
    },
    {
      code: "ST",
      tel: "239",
      name: "Sao Tome and Principe",
      zh_cn: "圣多美和普林西比",
      zh_tw: "聖多美和普林西比",
    },
    {
      code: "SA",
      tel: "966",
      name: "Saudi Arabia",
      zh_cn: "沙特阿拉伯",
      zh_tw: "沙特阿拉伯",
    },
    {
      code: "SN",
      tel: "221",
      name: "Senegal",
      zh_cn: "塞内加尔",
      zh_tw: "塞內加爾",
    },
    {
      code: "RS",
      tel: "381",
      name: "Serbia",
      zh_cn: "塞尔维亚",
      zh_tw: "塞爾維亞",
    },
    {
      code: "SC",
      tel: "248",
      name: "Seychelles",
      zh_cn: "塞舌尔",
      zh_tw: "塞舌爾",
    },
    {
      code: "SL",
      tel: "232",
      name: "Sierra Leone",
      zh_cn: "塞拉利昂",
      zh_tw: "塞拉利昂",
    },
    {
      code: "SG",
      tel: "65",
      name: "Singapore",
      zh_cn: "新加坡",
      zh_tw: "新加坡",
    },
    {
      code: "SK",
      tel: "421",
      name: "Slovakia",
      zh_cn: "斯洛伐克",
      zh_tw: "斯洛伐克",
    },
    {
      code: "SI",
      tel: "386",
      name: "Slovenia",
      zh_cn: "斯洛文尼亚",
      zh_tw: "斯洛文尼亞",
    },
    {
      code: "SB",
      tel: "677",
      name: "Solomon Is",
      zh_cn: "所罗门群岛",
      zh_tw: "所羅門群島",
    },
    {
      code: "SO",
      tel: "252",
      name: "Somalia",
      zh_cn: "索马里",
      zh_tw: "索馬里",
    },
    {
      code: "ZA",
      tel: "27",
      name: "South Africa",
      zh_cn: "南非",
      zh_tw: "南非",
    },
    { code: "ES", tel: "34", name: "Spain", zh_cn: "西班牙", zh_tw: "西班牙" },
    {
      code: "LK",
      tel: "94",
      name: "Sri Lanka",
      zh_cn: "斯里兰卡",
      zh_tw: "斯里蘭卡",
    },
    { code: "SD", tel: "249", name: "Sudan", zh_cn: "苏丹", zh_tw: "蘇丹" },
    {
      code: "SR",
      tel: "597",
      name: "Suriname",
      zh_cn: "苏里南",
      zh_tw: "蘇里南",
    },
    {
      code: "SZ",
      tel: "268",
      name: "Swaziland",
      zh_cn: "斯威士兰",
      zh_tw: "斯威士蘭",
    },
    { code: "SE", tel: "46", name: "Sweden", zh_cn: "瑞典", zh_tw: "瑞典" },
    {
      code: "CH",
      tel: "41",
      name: "Switzerland",
      zh_cn: "瑞士",
      zh_tw: "瑞士",
    },
    { code: "SY", tel: "963", name: "Syria", zh_cn: "叙利亚", zh_tw: "敘利亞" },
    {
      code: "TW",
      tel: "886",
      name: "Taiwan",
      zh_cn: "中国台湾",
      zh_tw: "中國台灣",
    },
    {
      code: "TJ",
      tel: "992",
      name: "Tajikistan",
      zh_cn: "塔吉克斯坦",
      zh_tw: "塔吉克斯坦",
    },
    {
      code: "TZ",
      tel: "255",
      name: "Tanzania",
      zh_cn: "坦桑尼亚",
      zh_tw: "坦桑尼亞",
    },
    { code: "TH", tel: "66", name: "Thailand", zh_cn: "泰国", zh_tw: "泰國" },
    { code: "TG", tel: "228", name: "Togo", zh_cn: "多哥", zh_tw: "多哥" },
    { code: "TO", tel: "676", name: "Tonga", zh_cn: "汤加", zh_tw: "湯加" },
    {
      code: "TT",
      tel: "1809",
      name: "Trinidad and Tobago",
      zh_cn: "特立尼达和多巴哥",
      zh_tw: "特立尼達和多巴哥",
    },
    {
      code: "TN",
      tel: "216",
      name: "Tunisia",
      zh_cn: "突尼斯",
      zh_tw: "突尼斯",
    },
    { code: "TR", tel: "90", name: "Turkey", zh_cn: "土耳其", zh_tw: "土耳其" },
    {
      code: "TM",
      tel: "993",
      name: "Turkmenistan",
      zh_cn: "土库曼斯坦",
      zh_tw: "土庫曼斯坦",
    },
    {
      code: "UG",
      tel: "256",
      name: "Uganda",
      zh_cn: "乌干达",
      zh_tw: "烏干達",
    },
    {
      code: "UA",
      tel: "380",
      name: "Ukraine",
      zh_cn: "乌克兰",
      zh_tw: "烏克蘭",
    },
    {
      code: "AE",
      tel: "971",
      name: "United Arab Emirates",
      zh_cn: "阿拉伯联合酋长国",
      zh_tw: "阿拉伯聯合酋長國",
    },
    {
      code: "UK",
      tel: "44",
      name: "United Kingdom",
      zh_cn: "英国",
      zh_tw: "英國",
    },
    {
      code: "US",
      tel: "1",
      name: "United States of America",
      zh_cn: "美国",
      zh_tw: "美國",
    },
    {
      code: "UY",
      tel: "598",
      name: "Uruguay",
      zh_cn: "乌拉圭",
      zh_tw: "烏拉圭",
    },
    {
      code: "UZ",
      tel: "998",
      name: "Uzbekistan",
      zh_cn: "乌兹别克斯坦",
      zh_tw: "烏茲別克斯坦",
    },
    {
      code: "VU",
      tel: "678",
      name: "Vanuatu",
      zh_cn: "瓦努阿图",
      zh_tw: "瓦努阿圖",
    },
    {
      code: "VA",
      tel: "39",
      name: "Vatican City State",
      zh_cn: "梵蒂冈",
      zh_tw: "梵蒂岡",
    },
    {
      code: "VE",
      tel: "58",
      name: "Venezuela",
      zh_cn: "委内瑞拉",
      zh_tw: "委內瑞拉",
    },
    { code: "VN", tel: "84", name: "Vietnam", zh_cn: "越南", zh_tw: "越南" },
    { code: "YE", tel: "967", name: "Yemen", zh_cn: "也门", zh_tw: "也門" },
    {
      code: "YU",
      tel: "381",
      name: "Yugoslavia",
      zh_cn: "南斯拉夫",
      zh_tw: "南斯拉夫",
    },
    {
      code: "ZM",
      tel: "260",
      name: "Zambia",
      zh_cn: "赞比亚",
      zh_tw: "贊比亞",
    },
    { code: "ZR", tel: "243", name: "Zaire", zh_cn: "扎伊尔", zh_tw: "扎伊爾" },
    {
      code: "ZW",
      tel: "263",
      name: "Zimbabwe",
      zh_cn: "津巴布韦",
      zh_tw: "津巴布韋",
    },
  ];
}

export function GUserGrade() {
  return {
    1: "Customer",
    2: "v1",
    3: "v2",
    4: "v3",
    5: "v4",
    6: "v5",
  };
}

export const PayMethods = {
  credit: "Credit Card",
  cash: "Cash",
  gift: "Gift Card",
  interact: "Interact Card",
  prepaid: "Pre-Paid",
  eclaims: "Eclaims",
};

/* export const GlobalUserForm = {
//国家-省-行业
  CA_ON_SPA: {
    Basic: "Spa",//more infomation
    Health: "Health-History",
    Eclaim: "Eclaim",
  },
};
export const GlobalServiceForm = {
  CA_ON_SPA: {
    Acup: "Acupuncture",
    SensitiveConsent: "SensitiveConsent",
    TreatNote: "TreatNote",
    TreatPlan: "TreatPlan",
  },
}; */
export const FormKey = {
  Spa: "Acupuncture",
  Acup: "Acupuncture",
  SensitiveConsent: "SensitiveConsent",
  TreatNote: "TreatNote",
  TreatPlan: "TreatPlan",
  Health: "Health-History",
  Eclaim: "Eclaim",
};
export const Hb = {
  A: "现金",
  C: "代金券",
};
/* 
export const Menu = [
  { title: "Home", url: "/index", child: [], checked: 1 },
  {
    title: "Customer",
    checked: 1,
    child: [
      { title: "Customer List", url: "/customer/list", checked: 1 },
      { title: "Add Customer", url: "/customer/add", checked: 1 },
      { title: "Del Customer", url: "/customer/del", checked: 1 },
      { title: "Add File1", url: "/customer/att1", checked: 1 },
      { title: "Add File2", url: "/customer/att2", checked: 1 },
    ],
  },
  {
    title: "Book",
    checked: 1,
    child: [
      { title: "Book List", url: "/book/list", checked: 1 },
      { title: "Add Book", url: "/book/add", checked: 1 },
      { title: "Edit Book", url: "/book/edit", checked: 1 },
      { title: "Del Book", url: "/book/del", checked: 1 },
    ],
  },
  {
    title: "AfterSale",
    checked: 1,
    child: [
      { title: "AfterSale List", url: "/aftersale/list", checked: 1 },
      { title: "Edit Aftersale", url: "/aftersale/edit", checked: 1 },
      { title: "Del Aftersale", url: "/aftersale/del", checked: 1 },
    ],
  },
  {
    title: "Employee",
    checked: 1,
    child: [
      { title: "Search Employee", url: "/employee/list", checked: 1 },
      { title: "Add/Edit Employee", url: "/employee/add", checked: 1 },
      { title: "Del Employee", url: "/employee/del", checked: 1 },
      { title: "Employee Work Time", url: "/employee/time", checked: 1 },
    ],
  },
  {
    title: "Service",
    checked: 1,

    child: [
      { title: "Search Service", url: "/service/list", checked: 1 },
      { title: "Add/Edit Service", url: "/service/add", checked: 1 },
      { title: "Del Service", url: "/service/del", checked: 1 },
    ],
  },
  {
    title: "Service Category",
    checked: 1,

    child: [
      { title: "Service Category List", url: "/servicecate/list", checked: 1 },
      {
        title: "Add/Edit Service Category",
        url: "/servicecate/add",
        checked: 1,
      },

      {
        title: "Del Service Category",
        url: "/servicecate/del",
        checked: 1,
      },
    ],
  },
  {
    title: "Branch",
    checked: 1,
    child: [
      { title: "Branch List", url: "/branch/list", checked: 1 },
      {
        title: "Add/Edit Branch",
        url: "/branch/add",
        checked: 1,
      },

      {
        title: "Del Branch",
        url: "/branch/del",
        checked: 1,
      },
    ],
  },
  {
    title: "Manager",
    checked: 1,
    child: [
      { title: "Manager List", url: "/manager/list", checked: 1 },
      {
        title: "Add/Edit Manager",
        url: "/manager/add",
        checked: 1,
      },

      {
        title: "Del Manager",
        url: "/manager/del",
        checked: 1,
      },
    ],
  },
  {
    title: "Group",
    checked: 1,
    child: [
      { title: "Group List", url: "/group/list", checked: 1 },
      {
        title: "Add/Edit Group",
        url: "/group/add",
        checked: 1,
      },
      {
        title: "Del Group",
        url: "/group/del",
        checked: 1,
      },
    ],
  },
  {
    title: "System setup",
    checked: 1,
    child: [
      { title: "Database Backup", url: "/system/dbbackup", checked: 1 },
      { title: "Edit Info", url: "/system/upinfo", checked: 1 },
      { title: "Password Set", url: "/system/uppwd", checked: 1 },
    ],
  },
]; */
