import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { AJAROOT } from "@/common/gdata/global.js";
import { Link } from "react-router-dom";
import { checkUserLogin,  IsPC, MyToast,
  MyToastShow, } from "@/jscript/common.js";
import "@/css/user.css";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      title: "",
      list: [],

      zsxm: "",
      regtime: "",
      hba: 0,
      hbb: 0,
      hbc: 0,
      xfze: 0,
      totaljj: 0,
      error: "",

      postresult: "",
        mytoast: {
        show: false,
      },
    };
  }
  render() {
    const { myusername } = this.props;

    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;

    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px", width: "90%" }}
      >
        <div className="pagetitle">{this.state.title}</div>
        <div className="row textright">
          <div>
            <Link to="/user/srvcate/add">
              <input
                type="button"
                name="button1"
                value="New Category"
                className="add"
              ></input>
            </Link>
          </div>
        </div>
        <div style={{ padding: "15px" }}></div>

        <div className="row">
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 bold textleft">
            Category name
          </div>

          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 bold textleft">
            Branch
          </div>
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 bold textleft">
            Create time
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright"></div>
        </div>

        {this.state.list.map((item, index) => {
          return (
            <div className="row list" key={"group" + index}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 " style={{borderRadius:"3px",backgroundColor:item.bgcolor!=''?item.bgcolor:""}}>
                {item.name}
              </div>
              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 ">
                {item.dianscope}
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 ">
                {item.dt}
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
                <Link to={"/user/srvcate/add?id=" + item["id"] + ""}>
                  Update
                </Link>{" "}
                <a
                  href="#"
                  onClick={() =>
                    this.Del(index,item["id"])
                  }
                >
                  Delete
                </a>
              </div>
            </div>
          );
        })}
                     {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
  Del(index,id) {
      const { myusername, ukey } = this.props;
    if (window.confirm("Are you sure to delete it?")) {
      this.DelData(myusername, encodeURIComponent(ukey), id, index);
    } else return;
  }
  
  DelData(myusername, ukey, id,index) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=delservicecategory&id=" +
      id +
      "";

    axios.get(geturl).then((res) => {
      const result = res.data;

      if (result.r == 1) {
       let list = this.state.list;
        list.splice(index, 1);
        this.setState({
          list: list,
        });
        MyToastShow(
          this,
          1000,
          "Successfully! ",
          150,
          80,
          "success",
          "addgroup"
        );
      } else {
        MyToastShow(this, 2, result.s, 200, 120, "fail", "addbranch");
      }
      // this.setState({}); /**/
    });
  }
  getData(myusername, ukey) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=srvcatelist";

    axios.get(geturl).then((res) => {
      const result = res.data;

      this.setState({
        list: result.list,
      });
      // this.setState({}); /**/
    });
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;
    const { t } = this.props.match.params;

    var title = "Service Category List";
    document.title = title;
    this.setState({
      title: title,
    });
    //if (IsPC()) getUserIndexData(this, myusername, ukey);
    this.getData(myusername, encodeURIComponent(ukey));
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {}
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
  };
};

export default connect(mapStateToProps, null)(Index);
