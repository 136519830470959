import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
/* import SignatureCanvas from "react-signature-canvas";
import $ from "jquery";
import { AJAROOT } from "@/common/gdata/global.js"; */
//import MySignatureCanvas from "@/jscript/myCanve.js";
import { HealthForm, getHealthData,saveHealForm } from "@/jscript/health.js";
import { checkUserLogin, IsPC, MyToast } from "@/jscript/common.js";
//import axios from "axios";
import "@/css/user.css";
import "@/css/react-datepicker.css";

//import DatePicker from "react-datepicker";
let emptysign =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

const LCss = {
  agree: {
    padding: "15px",
  },
  signdiv: {
    verticalAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  signcave: {
    height: "52px",
    lineHeight: "52px",
    width: "202px",

    border: "1px solid #ccc",
    position: "relative",
    float: "left",
    textAlign: "left",
  },
  canve: {
    background: "#fff",
    position: "absolute",
    top: 0,
    left: 0,
  },
  radio: {
    width: "18px",
    height: "18px",
    marginLeft: "10px",
    marginRight: "3px",
  },
  conditioncheck: {
    display: "flex",

    alignContent: "center",
    paddingLeft: "5px",
    Height: "25px",
    lineHeight: "25px",
  },
  conditiondiv: {
    width: "100%",
    paddingLeft: "5px",
    fontWeight: "bold",
    height: "30px",
    lineHeight: "30px",
  },
  checkout: {
    width: "18px",
    height: "18px",
    marginRight: "5px",
  },
};
class User extends Component {
  constructor(props) {
    super(props);
    this.canvasRef1 = React.createRef();
    this.canvasRef2 = React.createRef();
    this.canvasRef3 = React.createRef();
    this.state = {
      //  myu: "",

      title: "",
      error: "",

      firstname: "",
      lastname: "",

      telephone: "",

      address: "",

      birthday: "",

      occupation: "",
      hasmassagebefore: "",
      haspractitionerrefer: "",
      refaddress: "",
      conditiondata: [],
      selfoverall: "",
      physicianname: "",
      physicianaddress: "",

      currentmedication: "",
      currenttreat: "",
      havetreatment: "",
      whattreatment: "",
      surgerydate: "",
      surgerynature: "",
      injurydate: "",
      injurynature: "",
      haveothercondition: "",
      whatcondition: "",
      haveequipment: "",
      whatequipment: "",
      whereequipment: "",
      massagereason: "",
      initialdate: "",
      date1: "",
      date2: "",
      date3: "",
      date3: "",
      date4: "",
      clientname: "",
      clientsign: emptysign,
      clientsigndate: "",

      haveimg1: 0,

      postresult: "",
      mytoast: {
        show: false,
      },
      isprint: false,
    };
  }

  render() {
    const { myusername } = this.props;
    const IsMobile = !IsPC();
    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;
    //var zsxm = this.state.zsxm;
    // if (zsxm === "") zsxm = this.state.myusername;

    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px" }}
      >
        <div className="row " style={{ height: "55px", lineHeight: "55px" }}>
          <div
            className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
            onClick={() => {
              this.back();
            }}
          >
            {!this.state.isprint && (
              <span className="iconfont fanhui">&#xe625;</span>
            )}
          </div>
          <div
            className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textcenter "
            style={{ fontSize: "19px", fontWeight: "bolder" }}
          >
            {!this.state.isprint ? this.state.title : ""}
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            {!this.state.isprint ? (
              <a
                onClick={() =>
                  this.print(
                    "/user/form/health?username=" +
                      this.state.username +
                      "&print=1"
                  )
                }
              >
                 {!IsMobile?"Print":""}
              </a>
            ) : (
              <input
                style={{ height: "35px", lineHeight: "35px" }}
                type="button"
                value="Print"
                onClick={() =>
                  this.printPage(
                    "/user/form/health?username=" +
                      this.state.username +
                      "&print=1"
                  )
                }
              ></input>
            )}
          </div>
        </div>
        {HealthForm(this)}
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
  back() {
    if (window.confirm("Do you need to save the form?")) {
      saveHealForm(this);
      window.history.back();
    } else window.history.back();
  }
  clearCanv(index) {
    if (index == 1) {
      if (this.state.haveimg1 == 0) this.canvasRef1.current.clear();
      this.setState({
        haveimg1: 0,
      });
    }
  }
  setBirthday(val, field) {
    let _date = val;
    if (val == null) _date = "";
    if (_date != "") {
   /*    let date = new Date(
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      );

      _date = date; */
    }
    if (field == "birthday") {
      this.setState({
        birthday: _date,
      });
    } else if (field == "clientsigndate") {
      this.setState({
        clientsigndate: _date,
      });
    } else if (field == "initialdate") {
      this.setState({
        initialdate: _date,
      });
    }
  }

  setRadio(ref, field) {
    let val = ref.target.value;
    if (field == "hasmassagebefore")
      this.setState({
        hasmassagebefore: val,
      });
    else if (field == "haspractitionerrefer")
      this.setState({
        haspractitionerrefer: val,
      });
    else if (field == "havetreatment")
      this.setState({
        havetreatment: val,
      });
    else if (field == "haveothercondition")
      this.setState({
        haveothercondition: val,
      });
    else if (field == "haveequipment")
      this.setState({
        haveequipment: val,
      });
    else if (field == "insur_chiropractic")
      this.setState({
        insur_chiropractic: val,
      });
    else if (field == "haveallergy")
      this.setState({
        haveallergy: val,
      });
  }
  changeField2(ref, index, index2) {
    let val = ref.target.value;

    let conditiondata = this.state.conditiondata;
    conditiondata[index].child[index2].a = val;

    this.setState({
      conditiondata: conditiondata,
    });
  }
  changeField3(ref, index, index2) {
    let val = ref.target.value;

    let conditiondata = this.state.conditiondata;
    conditiondata[index].child[index2].a2 = val;

    this.setState({
      conditiondata: conditiondata,
    });
  }
  setRadio2(ref, index) {
    let val = ref.target.value;

    let conditiondata = this.state.conditiondata;
    conditiondata[index].c = val;

    this.setState({
      conditiondata: conditiondata,
    });
  }

  setCheck(ref, index, index2) {
    let val = "";
    if (ref.target.checked) {
      val = "Y";
    } else {
      val = "N";
    }
    /*     if (val == "") val = "N";
    if (val == "N") val = "Y";
    else val = "N";
 */
    let conditiondata = this.state.conditiondata;
    conditiondata[index].child[index2].c = val;

    this.setState({
      conditiondata: conditiondata,
    });
  }
  changeField(e, field) {
    let val = e.target.value;
    if (field == "occupation")
      this.setState({
        occupation: val,
      });
    else if (field == "refaddress")
      this.setState({
        refaddress: val,
      });
    else if (field == "address") {
      this.setState({
        address: val,
      });
    } else if (field == "whattreatment")
      this.setState({
        whattreatment: val,
      });
    else if (field == "selfoverall")
      this.setState({
        selfoverall: val,
      });
    else if (field == "physicianname")
      this.setState({
        physicianname: val,
      });
    else if (field == "physicianaddress")
      this.setState({
        physicianaddress: val,
      });
    else if (field == "currentmedication")
      this.setState({
        currentmedication: val,
      });
    else if (field == "currenttreat")
      this.setState({
        currenttreat: val,
      });
    else if (field == "surgerydate")
      this.setState({
        surgerydate: val,
      });
    else if (field == "surgerynature")
      this.setState({
        surgerynature: val,
      });
    else if (field == "injurydate")
      this.setState({
        injurydate: val,
      });
    else if (field == "injurynature")
      this.setState({
        injurynature: val,
      });
    else if (field == "whatcondition")
      this.setState({
        whatcondition: val,
      });
    else if (field == "whatequipment")
      this.setState({
        whatequipment: val,
      });
    else if (field == "whereequipment")
      this.setState({
        whereequipment: val,
      });
    else if (field == "massagereason")
      this.setState({
        massagereason: val,
      });
    else if (field == "clientname")
      this.setState({
        clientname: val,
      });
    else if (field == "insur_policynum")
      this.setState({
        insur_policynum: val,
      });
    else if (field == "insur_memberid")
      this.setState({
        insur_memberid: val,
      });
    else if (field == "seriousothers")
      this.setState({
        seriousothers: val,
      });
    else if (field == "whatallergy")
      this.setState({
        whatallergy: val,
      });
    else if (field == "ongoinghealthy")
      this.setState({
        ongoinghealthy: val,
      });
    else if (field == "longtertreat")
      this.setState({
        longtertreat: val,
      });
    else if (field == "patientsign")
      this.setState({
        patientsign: val,
      });
    else if (field == "guardiansign")
      this.setState({
        guardiansign: val,
      });
    else if (field == "practitionersign")
      this.setState({
        practitionersign: val,
      });
  }

  print(url) {
    window.open(url, "_blank");
  }
  printPage(url) {
    var orihtml = window.document.body.innerHTML; //从标记里获取需要打印的页面
    document.getElementById("content").style.padding = "10px";
    var printHtml = document.getElementById("content").innerHTML; //从标记里获取需要打印的页面

    window.document.body.innerHTML = printHtml; //需要打印的页面

    window.print();
    window.document.body.innerHTML = orihtml;
    window.open(url, "_self");
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;
    //const { t } = this.props.match.params;
    var { username, print } = queryString.parse(this.props.location.search);
    var title = "Health History Form";
    document.title = title;
    if (username == null) username = "";
    if (username == "") {
      username = myusername;
    }
    if (print == null) print = "";
    if (print == "1") {
      this.setState({
        isprint: true,
      });
    }
    this.setState({
      title: title,
      username: username,
    });

    //  getUserIndexData(this, myusername, ukey);

    getHealthData(this, username);
  }

  componentWillUnmount() {
    saveHealForm(this);
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {}
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
  };
};

export default connect(mapStateToProps, null)(User);
