import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import axios from "axios";
import queryString from "query-string";
import $ from "jquery";
import {
  AJAROOT, bookButtonColor,
  offTimeColor,
  littleBlueColor,
  bookedWorktimeColor
} from "@/common/gdata/global.js";



import { Link } from "react-router-dom";
import {
  checkUserLogin,
  getMinutes,
  showDayHours,
  MyToastShow,
  MyToast, TimeIsBetween, equalTime, getPeriodnoFromDate
} from "@/jscript/common.js";
import "@/css/user.css";
import "@/css/react-datepicker.css";
//import "react-datepicker/dist/react-datepicker.css";

import rjt from "../../../statics/rjt.png";
import DatePicker from "react-datepicker";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      title: "",
      list: [],
      months: [],

      weeks: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      colors: [
        /*  { title: "On Work", color: "#009900" } */
        {
          title: "On Work",
          color: "#00cc66",
        },
        /*  { title: "Off Work", color: "#ccc" }, */
      ],
      username: "",
      datausername: "",
      schfromdate: "",
      schtodate: "",
      fulltime: 0,
      hours: [],
      worktimelist: [],
      error: "",
      mytoast: {
        show: false,
      },
      postresult: "",
      worktimefrom: "",
      worktimeto: "",
      selectedWeekday: [],
      selectedMonthday: [],
      submited: false,
      selectedDate:false
    };
  }
  render() {
    const { myusername } = this.props;

    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;
    // const [startDate, setStartDate] = useState(new Date());
    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px", width: "90%" }}
      >
        <div className="pagetitle">
          <div style={{ float: "left" }}>
            <Link to={"/user/employee/list"}>Employee List</Link>{" "}
            <img src={rjt} style={{ width: "18px", height: "18px" }} />{" "}
            {this.state.title}
          </div>
          <div
            style={{
              float: "right",
            }}
          >
            <div
              style={{}}
              onClick={() => {
                window.history.back(-1);
              }}
            >
              <span className="iconfont fanhui">&#xe625;</span>
            </div>
          </div>
        </div>
        <div className="block " style={{ marginBottom: "0px" }}>
          <div className="r bold" style={{ paddingLeft: "8px", fontSize: "17px" }}>
            Step 1 : Select date range
          </div>
        </div>


        <div className="row textright ">
          <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10  textleft schform">

            <div
              style={{
                width: "210px",
                float: "left",
              }}
            >
              <select
                style={{ width: "190px" }}
                name="employee"
                className="schfield"
                value={this.state.username}
                onChange={(e) => this.setUsername(e.target.value)}
              /*  onChange={(ref) => this.setUsername(ref)} */
              >
                {this.state.list.map((item, index) => {
                  //  const date2 = new Date(arr[0], arr[1], item2);

                  return (
                    <option key={"emply" + index} value={item.username}>
                      {item.firstname} {item.lastname}
                    </option>
                  );
                })}
              </select>
            </div>
            <div
              style={{
                width: "100px",
                float: "left",

              }}
            >
              <DatePicker
                selected={this.state.schfromdate}
                onChange={(ref) => this.setStartDate(ref)}
                showYearDropdown
                dateFormat="yyyy-MM-dd"
                placeholderText="Input from date"
                className="schfield"
                style={{ width: "130px" }}
              />
            </div>
            <div
              style={{
                width: "20px",
                marginLeft: "30px",
                float: "left",
                textAlign: "left",
                height: "30px",
                lineHeight: "30px"
              }}
            >
              {"-"}
            </div>

            <div style={{ width: "180px", textAlign: "left", float: "left" }}>
              <DatePicker
                selected={this.state.schtodate}
                onChange={(ref) => this.setToDate(ref)}
                showYearDropdown
                dateFormat="yyyy-MM-dd"
                placeholderText="Input end date"
                className="schfield"
                style={{ width: "130px" }}
              />
            </div>

            <input
              type="button"
              name="schbut"
              value=" Select "
              className="add"
              onClick={() => this.doSearch()}
            ></input>
          </div>
        </div>


        <div style={{ padding: "15px" }}></div>

        <div style={{ clear: "both" }}></div>
        {this.state.months.map((item, index) => {

        let periodno1 = getPeriodnoFromDate(this.state.schfromdate);
        let periodno2 = getPeriodnoFromDate(this.state.schtodate);
          let arr = item.v.split("-");

          // let yearno = arr[0] * 1;
          //  let monthno = arr[1] * 1;

          const date1 = new Date(arr[0], arr[1] - 1, 1);

          let blankdays = [];
          let _index = date1.getDay() - 1;
          if (_index == -1) _index = 6;
          for (let j = 0; j < _index; j++) {
            blankdays.push(j);
          }

          return (
            <div
              style={{
                float: "left",
                width: "300px",
                height: "310px",
                border: "1px solid #ddd",
                borderRadius: "5px",
                margin: "15px",
                padding: "10px",
              }}
              key={"group" + index}
            >
              <div
                style={{
                  textAlign: "center",
                  padding: "6px",
                  fontWeight: "bold",
                }}
              >
                {this.state.datausername + "　"} {item.v}
              </div>
              <div style={{ height: "210px", position: "relative" }}>
                {this.state.weeks.map((item2, index2) => {
                  //  const date2 = new Date(arr[0], arr[1], item2);
                  let bgcolor = "#fff";
                  if (item.weeks[index2].check == 1) bgcolor = bookButtonColor;

                  return (
                    <div
                      style={{
                        backgroundColor: bgcolor,
                        border: "1px solid #ddd",
                      }}
                      className="calendarday"
                      key={"weeks" + index2}
                    >
                      <div
                        style={{
                          textAlign: "center",
                        }}
                        onClick={() => this.selectWeek(index, index2)}
                      >
                        {item2}
                      </div>
                    </div>
                  );
                })}

                {blankdays.map((item2, index2) => {
                  //  const date2 = new Date(arr[0], arr[1], item2);

                  return (
                    <div
                      className="calendarday"
                      key={"blank" + index2}
                      style={{ border: "0px" }}
                    ></div>
                  );
                })}

                {item.days.map((item2, index2) => {
                
                  let canclick = true;
                  //  const date2 = new Date(arr[0], arr[1], item2);
                  let fulltime = -2;
                  let day = item2.v;
                  if (day <= 9) day = "0" + day;

                  var periodno = item.v.replace("-", "") + "" + day;
                  let bgcolor = "#fff";
                  let _item = null;
                  let checked = false;

                  _item = this.state.worktimelist.find(
                    (item) => item.periodno === periodno
                  );
                  if (_item != null) {
                    fulltime = _item.fulltime;
                    if (item2.hasOwnProperty('canclick') && !item2.canclick) {
                      bgcolor = bookedWorktimeColor;
                      canclick = false;
                    }
                    //  console.log(periodno,JSON.parse(_item.hours));


                    /*  _item.hours.forEach(__item => {
                       __item.min.forEach(minItem => {
                         if (minItem.hasOwnProperty('b')) {
                           hasbook=true;
                         }
                       });
                     }); */
                    //   console.log(result);

                  }
                  if (!item2.hasOwnProperty('clicked')) {
                    item2.clicked = false;
                  }

                  if (item2.check == 1) {
                    if (bgcolor !== bookedWorktimeColor) bgcolor = bookButtonColor;
                    checked = true;
                  }
                  else {
                    if (_item != null && !item2.clicked) {


                      //    bgcolor = this.state.colors[0].color;
                      if (fulltime == "-1") {
                        checked = false;
                        bgcolor = offTimeColor;
                      }
                      else {
                        bgcolor = bookButtonColor;
                        checked = true;
                      }
                    }
                  }


                  let outofselect=false;
                  if (this.state.schfromdate) {
                   
                    if (periodno < periodno1) {
                      canclick = false;
                      outofselect=true;
                      bgcolor = "#eee";
                    }
                    //return (<Fragment key={"day" + index2}></Fragment>);
                    //bgcolor="#ccc";
                  }

                  if (this.state.schtodate) {
                  
                    if (periodno > periodno2) {
                      canclick = false;
                      outofselect=true;
                      bgcolor = "#eee";
                    }
                    //return (<Fragment key={"day" + index2}></Fragment>);
                    //bgcolor="#ccc";
                  }
                  return (
                    <div key={"day" + index2}>
                      <div
                        className="calendarday"
                        style={{
                          backgroundColor: bgcolor,

                          border: "1px solid #ddd",
                        }}
                      >
                        <div
                          onClick={() => this.selectDay(index, index2, canclick, checked)}
                          onMouseOver={() =>
                            this.showDay(fulltime, _item, index, index2)
                          }
                          onMouseOut={() => this.showDayLeave(fulltime, index)}
                          onMouseLeave={() =>
                            this.showDayLeave(fulltime, index)
                          }

                          style={{
                            textAlign: "center",
                            color:outofselect?bgcolor:'#000'
                          }}
                         
                        >
                          {outofselect?"N":item2.v} 
                        </div>
                      </div>
                      <div
                        id={"day" + index + ""}
                        style={{
                          display: "none",
                          position: "absolute",
                          background: "#f6f6f6",
                          width: "300px",
                          minheight: "300px",
                          border: "1px solid #ddd",
                          top: 200,
                          left: -0,
                          zIndex: 9,
                          padding: "10px",
                        }}
                      ></div>
                    </div>
                  );
                })}
              </div>
              <div style={{ clear: "both" }}>
                <div
                  style={{
                    textAlign: "center",
                    height: "30px",
                    lineHeight: "30px",

                    verticalAlign: "middle",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    display: "flex",
                    paddingTop: "18px",
                  }}
                >
                  <input
                    type="checkbox"
                    name="selall"
                    value=" Select All "
                    checked={this.state.months[index].check == 1}
                    style={{
                      width: "30px",
                      height: "30px",
                      marginRight: "10px",
                    }}
                    onChange={() => this.selectALL(index)}
                  ></input>
                </div>
              </div>
            </div>
          );
        })}


        <div style={{ clear: "both" }}></div>


        {this.state.months.length > 0 && <div className="block " style={{ marginBottom: "0px", marginTop: "15px", borderTop: "1px solid #ccc" }}>
          <div className="r bold" style={{ paddingLeft: "8px", fontSize: "17px" }}>
            Step 2 : Select week day or month day
          </div>

          <div className="r" style={{ paddingLeft: "8px", fontSize: "17px" }}>

            {this.state.selectedWeekday.map((item, index) => {
              //  const date2 = new Date(arr[0], arr[1], item2);
              let bgcolor = "#ffffff";
              if (item.check == 1) bgcolor = bookButtonColor;
              return (
                <div className='weekday' style={{ backgroundColor: bgcolor }}
                  key={"www" + index}
                  onClick={() => this.selWeekDay(index)}>{this.state.weeks[index]}</div>

              );
            })}



          </div>
          <div className="r" style={{ paddingLeft: "38px", fontSize: "17px", textAlign: "center" }}>

            OR
          </div>
          <div className="r" style={{ paddingLeft: "8px", fontSize: "17px" }}>

            {this.state.selectedMonthday.map((item, index) => {
              //  const date2 = new Date(arr[0], arr[1], item2);
              let bgcolor = "#ffffff";
              if (item.check == 1) bgcolor = bookButtonColor;
              return (
                <div className='weekday' style={{ backgroundColor: bgcolor }}
                  key={"ddd" + index}
                  onClick={() => this.selMonthDay(index)}>{item.v}</div>

              );
            })}

          </div>
        </div>}


        {this.state.months.length > 0 && <div className="block " style={{ marginBottom: "0px", marginTop: "15px", borderTop: "1px solid #ccc" }}>
          <div className="r bold" style={{ paddingLeft: "8px", fontSize: "17px" }}>
            Step 3 : Select times
          </div>
        </div>}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            alignContent: "flex-start",
            textAlign: "left",
            borderTopColor: "#ddd",
            borderTopWidth: "1px",
            marginTop: "15px",
            flexDirection: "column"
          }}
        >

          {this.state.months.length > 0 && this.state.fulltime == 0 && this.state.hours.length > 0 && (
            <div
              style={{
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                border: "0px solid red",
              }}
            >
              {showDayHours(this, this.state.hours)}
            </div>
          )}
          <div
            style={{
              padding: "10px",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              marginLeft: "26px"
            }}
          >
            {this.state.selectedDate&&<input
              style={{
                width: "300px",

              }}
              type="button"
              name="schbut"

              value={this.state.submited ? " Submiting... " : " Save "}
              className="add"
              onClick={() => this.Submit()}
            ></input>}
          </div>
        </div>

        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );


  }
  /*   weekBlank(date1) {
    let arr = [1, 2, 3];

    arr.map((item2, index2) => {
      console.log(item2);
      //  const date2 = new Date(arr[0], arr[1], item2);

      return <div className="calendarday"></div>;
    });
  } */
  /*   getMinutes() {
    let days = [
      { v: 0, check: 0 },
      { v: 10, check: 0 },
      { v: 20, check: 0 },
      { v: 30, check: 0 },
      { v: 40, check: 0 },
      { v: 50, check: 0 },
    ];
    return days;
  } */
  /* selectALLMin(index) {
    let months = this.state.months;
    let check = (months[index].check + 1) % 2;
    months[index].check = check;
    let days = months[index].days;
this.state.months
    days.forEach((element, j) => {
      element.check = check;
    });
    //console.log(days);
    this.setState({
      months: months,
    });
  } */
  selWeekDay(index) {
    let list = this.state.selectedWeekday;
    let check = (list[index].check + 1) % 2;

    list[index].check = check;


    let months = this.state.months;
    let weekindex = index;
    weekindex++;
    if (weekindex == 7) weekindex = 0;
    months.forEach((element, j) => {

      element.days.forEach((day, j2) => {
        const date = element.v + "-" + day.v;
        const [_year, _month, _day] = date.split('-').map(Number);
        const _date = new Date(_year, _month - 1, _day)


        const dayOfWeek = _date.getDay();
        /**/
        if (dayOfWeek == weekindex) {

          //  months[j].days[j2].check=check;
          day.check = check;


          // day.check=1;
        }

      });
    });

    this.setState({

      selectedWeekday: list,
      months: months
    });

  }

  selMonthDay(index) {
    let list = this.state.selectedMonthday;
    let check = (list[index].check + 1) % 2;

    list[index].check = check;


    let months = this.state.months;
    let dayindex = index;


    months.forEach((element, j) => {

      element.days.forEach((day, j2) => {

        /**/
        if (dayindex == j2) {
          //  console.log(weekindex, "dayOfWeek", date, _date);
          //  months[j].days[j2].check=check;
          day.check = check;


          // day.check=1;
        }

      });
    });

    this.setState({

      selectedMonthday: list,
      months: months
    });

  }
  selfulltime(value) {
    this.setState({
      fulltime: value,
    });
  }
  getWeeks() {
    let w = [
      { v: 1, check: 0 },
      { v: 2, check: 0 },
      { v: 3, check: 0 },
      { v: 4, check: 0 },
      { v: 5, check: 0 },
      { v: 6, check: 0 },
      { v: 7, check: 0 },
    ];
    return w;
  }
  getDays() {
    let days = [
      { v: 1, check: 0 },
      { v: 2, check: 0 },
      { v: 3, check: 0 },
      { v: 4, check: 0 },
      { v: 5, check: 0 },
      { v: 6, check: 0 },
      { v: 7, check: 0 },
      { v: 8, check: 0 },
      { v: 9, check: 0 },
      { v: 10, check: 0 },
      { v: 11, check: 0 },
      { v: 12, check: 0 },
      { v: 13, check: 0 },
      { v: 14, check: 0 },
      { v: 15, check: 0 },
      { v: 16, check: 0 },
      { v: 17, check: 0 },
      { v: 18, check: 0 },
      { v: 19, check: 0 },
      { v: 20, check: 0 },
      { v: 21, check: 0 },
      { v: 22, check: 0 },
      { v: 23, check: 0 },
      { v: 24, check: 0 },
      { v: 25, check: 0 },
      { v: 26, check: 0 },
      { v: 27, check: 0 },
      { v: 28, check: 0 },
    ];
    return days;
  }
  Submit() {
    if (this.state.submited) return;
    this.setState({
      submited: true
    });
    let error = "";
    const { myusername, ukey } = this.props;
    let periodno = [];
   
    this.state.months.forEach((month, j) => {
      month.days.forEach((day, j) => {
        let canupdate = true;
        if (day.hasOwnProperty('canclick') && !day.canclick) {
          canupdate = false;
        }
        if (canupdate) {
          let item = { periodno: month.v + "-" + day.v, check: day.check }

          periodno.push(item);
        }
        //if (day.check == 1) periodno.push(month.v + "-" + day.v);
      });
    });

    if (periodno.length == 0) {
      error = "Please select day";
    }

    const hours = this.state.hours;
    //console.log(hours);
    /* if (error == "") {
      if (this.state.fulltime == 0) {
        let selectedmin = [];
        hours.forEach((hour, j) => {
          hour.min.forEach((item, j) => {
        let time=hour.v+":"+item.v;
        if(!(TimeIsBetween(time,this.state.worktimefrom,this.state.worktimeto)))
        {
        console.log("remove"+time);
        hour.min=hour.min.splice(j,1);
        }
        else
        {
            if (item.c == 1) selectedmin.push(item.v);
        }
          });
        });
        if (selectedmin.length == 0) {
          error = "Please select time";
        }
        // console.log(this.state.hours);
      }
    } */

    //    let selectedmin = [];

    let newHours = hours.map((hour) => {
      let newMin = hour.min.filter((item) => {
        let time = hour.v + ":" + item.v;
        if (!(TimeIsBetween(time, this.state.worktimefrom, this.state.worktimeto)) || equalTime(time, this.state.worktimeto)) {
          //  console.log("remove" + time);
          return false;
        } else {
          // return item
          //item.c=1;
          return item;
          // return item.c === 1;
        }
      });
      return { ...hour, min: newMin };
    });
    //newHours = newHours.filter((hour) => hour.min.length > 0);

    if (newHours.length === 0) {
      error = "Please select time";
    }
    if (error != "") {
      MyToastShow(this, 2, error, 200, 120, "faise", "addemployee");
      return;
    }

    const postdata = {
      uid: myusername,
      username: this.state.username,
      periodno: periodno,
      fulltime: this.state.fulltime,
      hours: newHours,
      //hours:hours
    };
  

//return;
    let that = this;
    $.ajax({
      type: "POST",
      dataType: "json",
      url:
        AJAROOT +
        "/postaja/savexx.aspx?action=saveemployeetime&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "",
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
        var r = res.r;
        var msg = res.s;

        if (r === 1) {
          //  this.postresult = "登陆成功！";

          that.setState({
            postresult: msg,
            submited: false
          });
          MyToastShow(that, 2, res.s, 200, 100, "sucess", "addemployee");

          //  this.getWorktime(myusername, encodeURIComponent(ukey));
          //this.getData(myusername, encodeURIComponent(ukey));
          that.setMonthRange();
          that.getWorkTimeData(
            myusername,
            encodeURIComponent(ukey),
            that.state.username
          );

          that.props.history.push(
            "/user/employee/worktime?username=" + that.state.username + ""
          );
        } else {
          MyToastShow(that, 2, msg, 200, 100, "faise", "addemployee");
          //document.getElementById("result2").style.color = "red";
          that.setState({
            postresult: msg,
            submited: false
          });

          //  document.getElementById("result2").innerHTML = msg;
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {
        console.log("error000");
        that.setState({

          submited: false
        });
      });
  }
  selectALL(index) {
    let months = this.state.months;
    let check = (months[index].check + 1) % 2;

    months[index].check = check;
    let days = months[index].days;

    days.forEach((element, j) => {
      element.clicked = true;
      element.check = check;
    });

    months[index].weeks.forEach((element, j) => {

      element.check = check;
    });
    //console.log(days);
    this.setState({
      months: months,
    });
  }
  showDayLeave(fulltime, index) {
    fulltime = fulltime * 1;
    if (fulltime === -2 || fulltime == -1) return;
    document.getElementById("day" + index).innerHTML = "";
    document.getElementById("day" + index).style.display = "none";
    // }
  }
  showDay(fulltime, _item, index, index2) {
    fulltime = fulltime * 1;

    if (fulltime === -2 || fulltime == -1) return;
    else {
      let hours = _item.hours;
      hours = JSON.parse(hours);
      // console.log(hours.length);
      var html = "<table>";
      html += "<tr><td colspan=6>Location: " + _item.dianusername + "</td></tr>";
      hours.forEach((hour, j) => {

       
        var row = "<tr>";
        // console.log(_item.hours[j]);

        // min = JSON.parse(min);
        //   console.log(hour.min.length);
        var minutes = hour.min;
        var selmiu = 0;

        minutes.forEach((min, j2) => {
          var h = hour.v * 1;
          if (h <= 9) h = "0" + h;
          var m = min.v * 1;
          if (m == 0) m = "00";
          var time = h + ":" + m;
          var bgcolor = "#fff";
          if (min.c == 1) {
            selmiu++;
            bgcolor = bookButtonColor;
          }
          row +=
            "<td style='background:" +
            bgcolor +
            ";width:35px;height:22px;padding:3px;border:1px solid #777;'>" +
            time +
            "</td>";
        });

        row += "</tr>";
        if (j < hours.length && selmiu > 0) html += row;
      });
      /*    for (var j = 0; j < _item.hours.length; j++) {
        var row = "<tr>";
        // console.log(_item.hours[j]);

        // min = JSON.parse(min);
        //  console.log(hour);
        for (var j2 = 0; j2 < min.length; j2++) {
          var time = _item.hours[j].v + ":" + min[j2];
          row += "<td>" + time + "</td>";
        }

        row += "</tr>";
        html += row;
      } */
      html += "<tr><td>Max:" + _item.maxavamins + "</td></tr>";
      html += "</table>";
      document.getElementById("day" + index).innerHTML = html;
      document.getElementById("day" + index).style.display = "block";
    }
  }
  selectDay(index, index2, canclick, checked) {
    if (!canclick) {

      return;
    }
    let months = this.state.months;
    months[index].days[index2].clicked = true;

    //let check = (months[index].days[index2].check + 1) % 2;
    if (checked)
      months[index].days[index2].check = 0;
    else months[index].days[index2].check = 1;

    // let days = months[index].days;

    //console.log(days);
    this.setState({
      months: months,
    });
  }
  selectWeek(index, index2) {
    let months = this.state.months;
    let check = (months[index].weeks[index2].check + 1) % 2;
    months[index].weeks[index2].check = check;
    // let days = months[index].days;

    index2 = index2 + 1;

    let days = months[index].days;
    // console.log(days);
    days.forEach((element, j) => {

      const period = months[index].v + "-" + element.v + " 00:00:00";

      const date = new Date(period);
      let _index = date.getDay();

      //  console.log(_index);
      if (_index == 0) _index = 7;
      if (index2 == _index) {
        element.clicked = true;
        element.check = check;
      }
      //element.check = check;
    });

    //console.log(days);
    this.setState({
      months: months,
    });
  }
  setUsername(val) {
    this.setState({
      username: val,
      months: []
    });
  }
  setStartDate(val) {
    let date = "";
    if (val == null) date = "";
    else {
      let _date = new Date(
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      );
      date = _date;
    }
    this.setState({
      schfromdate: date,
      months: []
    });
  }
  setToDate(val) {
    let date = "";
    if (val == null) date = "";
    else {
      let _date = new Date(
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      );
      date = _date;
    }
    this.setState({
      schtodate: date,
      months: []
    });
  }
  doSearch() {
    const { myusername, ukey } = this.props;
    // const { t } = this.props.match.params;
    if (this.state.schfromdate == "" || this.state.schtodate == "") {
      MyToastShow(this, 2, "please select from date and end date", 300, 200, "faise", "addemployee");
      return;
    }
	

    this.setMonthRange();
    this.getWorkTimeData(
      myusername,
      encodeURIComponent(ukey),
      this.state.username,
   
    );
    this.setState({
      selectedDate:true
    });
    
  }
  setMonthRange() {
    if (this.state.schfromdate == "" || this.state.schtodate == "") return;
    const date1 = new Date(this.state.schfromdate);
    const date2 = new Date(this.state.schtodate);
    let monthdiff =
      date2.getFullYear() * 12 +
      date2.getMonth() -
      (date1.getFullYear() * 12 + date1.getMonth());
    let months = [];

    for (let j = 0; j <= monthdiff; j++) {
      let _days = this.getDays();
      let _date = new Date(
        new Date(this.state.schfromdate).setMonth(date1.getMonth() + j)
      );

      let M = _date.getMonth() + 1;
      let year = _date.getFullYear();
      let month = year;
      if (M <= 9) month += "-0" + M;
      else month += "-" + M;
      //   let schtodate = _date.getFullYear() + "-" + (_date.getMonth() + 1);

      // let arr = month.split("-");

      // let yearno = arr[0] * 1;
      // let monthno = arr[1] * 1;
      // let maxday = 30;
      if (
        M == 1 ||
        M == 3 ||
        M == 5 ||
        M == 7 ||
        M == 8 ||
        M == 10 ||
        M == 12
      ) {
        _days.push({ v: 29, check: 0 });
        _days.push({ v: 30, check: 0 });
        _days.push({ v: 31, check: 0 });
      } else if (M == 2) {
        if (year % 4 == 0) {
          _days.push({ v: 29, check: 0 });
        }
      } else {
        _days.push({ v: 29, check: 0 });
        _days.push({ v: 30, check: 0 });
        //  days.push({ v: 31, check: 0 });
        //  maxday = 31;
      }

      months.push({ v: month, check: 0, days: _days, weeks: this.getWeeks() });
    }
    this.setState({
      months: months,
    });

    this.setSelectWeekAndMonth();
  }
  changeField(e, field) {
    let val = e.target.value;

    if (field == "schusername") {
      this.setState({
        schusername: val,
      });
    } else if (field == "schzsxm") {
      this.setState({
        schzsxm: val,
      });
    } else if (field == "schtelephone") {
      this.setState({
        schtelephone: val,
      });
    } else if (field == "schbirth") {
      this.setState({
        schbirth: val,
      });
    }
  }
  getWorktime(myusername, ukey) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?action=worktime&dianusername=" +
      myusername +
      "&u=" +
      myusername +
      "&ukey=" +
      ukey;

    axios.get(geturl).then((res) => {
      const result = res.data;
 
      let worktimefrom = result.worktimefrom.split(":");
      let worktimeto = result.worktimeto.split(":");

      let hour1 = worktimefrom[0];
      let minute1 = worktimefrom[1];
      let hour2 = worktimeto[0];
      let minute2 = worktimeto[1];

      let hours = [];
      let from = hour1 * 1;
      let to = hour2 * 1;
      for (var j = from; j <= to; j++) {
        let mins = getMinutes();
        if (j == from) {
          if (minute1 * 1 > 0) {
            mins.forEach((element, index) => {
              if (element.v < minute1 * 1) element.c = 0;
            });
          }
        }

        if (j == to) {
          if (minute2 * 1 < 60 && minute2 > 0) {
            mins.forEach((element, index) => {
              if (element.v >= minute2 * 1) element.c = 0;
            });
          }
        }
        //let time=j+":"+
        // if(diffTime(result.worktimefrom,))
        hours.push({ v: j, check: 1, min: mins });
        // hours.push({ v: j, check: 0 });
      }

      this.setState(
        {
          worktimefrom: result.worktimefrom,
          worktimeto: result.worktimeto,
          hours: hours,
        },
        () => {
          this.setMonthRange();
        }
      );
      // this.setState({}); /**/
    });
  }
  getEmployeeData(myusername, ukey) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=employeelist";

    axios.get(geturl).then((res) => {
      const result = res.data;

      this.setState({
        list: result.list,
      });
      // this.setState({});
    });
  }
  getWorkTimeData(myusername, ukey, username) {

    let schfromdate =
      this.state.schfromdate.getFullYear() +
      "-" +
      (this.state.schfromdate.getMonth() + 1) +
      "-01";

    let schtodate =
      this.state.schtodate.getFullYear() +
      "-" +
      (this.state.schtodate.getMonth() + 1) +
      "-01";
    
let _fromperiodno=getPeriodnoFromDate(this.state.schfromdate);
let _toeriodno=getPeriodnoFromDate(this.state.schtodate);

	if(this.state.schfromdate.getMonth()==this.state.schtodate.getMonth())
	  {
		let year=this.state.schtodate.getFullYear();
		let month=this.state.schtodate.getMonth()+2;

		if (month > 12) {
		  month = 1;
		  year += 1;
		}

		schtodate=year+"-" +month+"-01";
	  }
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&username=" +
      username +
      "&fromdate=" +
      schfromdate +
      "&todate=" +
      schtodate +
      "&action=employeetimelist";
 //console.log(geturl);
    axios.get(geturl).then((res) => {
      const result = res.data;
   
      let months = this.state.months;
     // console.log(result);
      ///////////
      months.map((item, index) => {
        item.days.map((item2, index2) => {


          let day = item2.v;
          if (day <= 9) day = "0" + day;

          var periodno = item.v.replace("-", "") + "" + day;
     
          
          //  const date2 = new Date(arr[0], arr[1], item2);
          let _item = null;
          _item = result.list.find(
            (item) => item.periodno === periodno && item.fulltime >= 0
          );
          if (_item != null) {

            item2.check = 1;
            if(item.dianusername==username)item2.canclick=false;
            else
            {
            let result = JSON.parse(_item.hours).filter(item => item.min.some(minItem => minItem.hasOwnProperty('b')&&minItem.b!==''));
            if (result != null && result.length > 0) {
              item2.canclick = false;			
            }
          }
           
          }
          if(periodno<_fromperiodno||periodno>_toeriodno)
            {
              item2.canclick = false;			
            }
        });
      });
      //console.log(months);
      //////////////
      this.setState({
        worktimelist: result.list,
        datausername: username,
        months: months
      });
      // this.setState({});
    });
  }

  setSelectWeekAndMonth() {

    let selectedMonthday = this.getDays();
    let item = { v: 29, check: 0 };
    selectedMonthday.push(item);
    item = { v: 30, check: 0 };
    selectedMonthday.push(item);
    item = { v: 31, check: 0 };
    selectedMonthday.push(item);

    this.setState(
      {

        selectedWeekday: this.getWeeks(),
        selectedMonthday: selectedMonthday
      }
    );
    //if (IsPC()) getUserIndexData(this, myusername, ukey);
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;
    // const { t } = this.props.match.params;
    var { username } = queryString.parse(this.props.location.search);
    var title = "Employee Worktime set";
    //TimeIsBetween("21:30","9:30","21:30");
    document.title = title;
    // let firstday =getMonth();
    let today = new Date();
    let schfromdate = new Date(
      today.getFullYear() + "-" + (today.getMonth() + 1) + "-01"
    );


    let schtodate = new Date(
      today.getFullYear() + "-" + (today.getMonth() + 1) + "-01"
    );
    // 将月份加2
    schtodate.setMonth(schtodate.getMonth() + 2);

    // 设置日期为1号
    schtodate.setDate(1);

    // 格式化日期为字符串
    //schtodate = schtodate.getFullYear() + "-" + (schtodate.getMonth() + 1).toString().padStart(2, '0') + "-01";


  
    /*  let schtodate = new Date(
       today.getFullYear() + "-" + (today.getMonth() + 3) + "-01"
     ); */
    //schtodate = schfromdate;
    // const end = new Date();
    // const start = new Date();
    //   start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    //start.setDate(1);
    // hours;
    this.getWorktime(myusername, encodeURIComponent(ukey));
    this.getEmployeeData(myusername, encodeURIComponent(ukey));


    this.setState(
      {
        title: title,
        username: username,
        schfromdate: schfromdate,
        schtodate: schtodate,

      },
      () => {
     //   this.getWorkTimeData(myusername, encodeURIComponent(ukey), username);
      }
    );
    this.setSelectWeekAndMonth();
    //if (IsPC()) getUserIndexData(this, myusername, ukey);
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() { }
  componentDidUpdate() { }
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
  };
};

export default connect(mapStateToProps, null)(Index);
