import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import queryString from "query-string";
import { AJAROOT, FormKey } from "@/common/gdata/global.js";
import { Link, withRouter } from "react-router-dom";
import { checkUserLogin, Fenye,  MyToast,
  MyToastShow, } from "@/jscript/common.js";
import { Pages } from "@/pages/type/style.js";
import "@/css/user.css";
import "@/css/react-datepicker.css";
//import rjt from "../../../statics/rjt.png";
import DatePicker from "react-datepicker";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      title: "",
      list: [],
      schusername: "",

      schaddress: "",
      schtelephone: "",

      schzsxm: "",
      schbirthday: "",
      birthday: "",
      error: "",

      postresult: "",
      //  page: 1,
      pagesize: 30,
      totalpage: 0,
       mytoast: {
        show: false,
      },
      //   page: 1,
    };
  }
  render() {
    const { myusername } = this.props;

    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;

    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px", width: "90%" }}
      >
        <div className="pagetitle">{this.state.title}</div>
        <div className="row textright">
          <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10  textleft schform">
            <input
              style={{
                float: "left",width: "200px",
              }}
              name="schusername"
              value={this.state.schusername}
              className="schfield"
              placeholder="Input account"
              onChange={(ref) => this.changeField(ref, "schusername")}
            ></input>
            <input
              style={{
                float: "left",
                width: "200px",
              }}
              className="schfield"
              name="schzsxm"
              value={this.state.schzsxm}
              placeholder="Input name"
              onChange={(ref) => this.changeField(ref, "schzsxm")}
            ></input>
            <input
              style={{
                float: "left",
              }}
              className="schfield"
              name="schtelephone"
              value={this.state.schtelephone}
              onChange={(ref) => this.changeField(ref, "schtelephone")}
              placeholder="Input tel"
            ></input>
            <div
              style={{
                width: "200px",
                float: "left",
              }}
            >
              <DatePicker
                className="schfield"
                selected={this.state.birthday}
                onChange={(ref) => this.setBirthday(ref)}
                showYearDropdown
                dateFormat="yyyy-MM-dd"
                placeholderText="Input birthday"
              />
            </div>
            <input
              onClick={() => this.search(1)}
              type="button"
              name="schbut"
              value=" Search "
              className="add"
            ></input>
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
            <input
              onClick={() => this.addNew()}
              type="button"
              name="button1"
              value="New Customer"
              className="add"
            ></input>
          </div>
        </div>
        <div style={{ padding: "15px" }}></div>

        <div className="row">
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold textleft">
            Account
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold textleft">
            Name
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold textleft">
            Telephone
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold textleft">
            Birthday
          </div>
          {/* <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold textleft">
            Remark
          </div> */}
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 textright"></div>
        </div>

        {this.state.list.map((item, index) => {
          const customerform = item.customerform.slice().reverse(); //JSON.parse(str);
          let patientsign = item.patientsign;
          let basicfontcolor = "#111";
          if (patientsign == "") basicfontcolor = "blue";
          return (
            <div
              className="row list"
              key={"group" + index}
              style={{ position: "relative" }}
            >
              <div
                className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
                onMouseOver={() => this.showDetail(item, index)}
                onMouseOut={() => this.hiddenDetail(index)}
              >
                {item.username}
              </div>
              <div
                className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
                onMouseOver={() => this.showDetail(item, index)}
                onMouseOut={() => this.hiddenDetail(index)}
              >
                {item.firstname} {item.lastname}
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                {item.telephone}
              </div>

              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                {item.birthday}
              </div>
              {/* <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                {item.remark}
              </div> */}
              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 textright">
                <div style={{ clear: "both", lineHeight: "25px" }}>
                  
                                    
 <Link
                    to={"/user/customer/notelist?username=" + item["username"] + ""}
                  >
                    Note-List
                  </Link>
                 
                  {"　"}
 <Link
                    to={"/user/customer/planlist?username=" + item["username"] + ""}
                  >
                    Plan-List
                  </Link>
                 
                  {"　"}
                   <Link
                    to={"/user/book/list?username=" + item["username"] + ""}
                  >
                    Books
                  </Link>
                 {item["dianusername"]==myusername&&
                 <>
                  {"　"}                 
                  <a
                    href="#"
                    onClick={() =>
                      this.Del(index,
                       item["username"]
                      )
                    }
                  >
                    Delete
                  </a></>}
                </div>
                <div>
                  {item.haveinsurance == 1 &&
                    customerform.map((item2, index2) => {
                      let path = item2["key"].toLowerCase();
                      let color = "#111";
                      if (path == "health") {
                        let clientsign = item.clientsign;
                        if (clientsign == null) clientsign = "";
                        if (clientsign == "") color = "blue";
                      }

                      return (
                        <div
                          key={"form" + index2}
                          style={{
                            float: "right",
                            paddingLeft: "10px",
                            lineHeight: "25px",
                          }}
                        >
                          <Link
                            to={
                              "/user/form/" +
                              path +
                              "?username=" +
                              item.username +
                              ""
                            }
                            style={{ fontSize: "15px", color: color }}
                          >
                            {FormKey[item2.key]}
                          </Link>
                        </div>
                      );
                    })}


                  <div
                    style={{
                      float: "right",
                      paddingLeft: "10px",
                      lineHeight: "25px",
                    }}
                  >
                    <Link
                      to={"/user/customer/add?id=" + item.id + ""}
                      style={{ fontSize: "15px", color: basicfontcolor }}
                    >
                      Basic-Info{" "}
                    </Link>
                  </div>
                </div>
              </div>
              <div
                id={"row" + index + ""}
                style={{
                  display: "none",
                  position: "absolute",
                  background: "#fff",
                  width: "360px",
                  minheight: "60px",
                  border: "1px solid #ddd",
                  top: 30,
                  left: 200,
                  zIndex: 9,
                  padding: "10px",
                }}
              ></div>
            </div>
          );
        })}

        <div className="row">
          <Pages>
            {Fenye(
              "/user/customer/list?username=" +
                this.props.username +
                "&name=" +
                encodeURIComponent(this.props.name)+
                "&telephone=" +
                this.props.telephone +
                "&birthday=" +
                this.props.birthday +
                "&order=" +
                this.props.order +
                "&oddesc=" +
                this.props.oddesc +
                "&pagesize=" +
                this.state.pagesize +
                "",
              this.props.page,
              this.state.totalpage
            )}
          </Pages>
        </div>
                {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
  Del(index,username) {
   const { myusername, ukey } = this.props;
    if (window.confirm("Are you sure to delete it?")) {
     this.DelData(myusername, encodeURIComponent(ukey), username,index);
    } else return;
  }

  
  DelData(myusername, ukey, username,index) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=delcustomer&username=" +
      username +
      "";

    axios.get(geturl).then((res) => {
      const result = res.data;

      if (result.r == 1) {
      let list=this.state.list;
      list.splice(index,1);
        this.setState({
      list: list,
    });
        MyToastShow(
          this,
          1000,
          "Successfully! ",
          150,
          80,
          "success",
          "delemployee"
        );
      } else {
        MyToastShow(this, 2, result.s, 200, 120, "fail", "delemployee");
      }
      // this.setState({}); /**/
    });
  }
  addNew() {
    let url = "/user/customer/add";
    if (this.state.list.length == 0) {
      url =
        "/user/customer/add?username=" +
        this.state.schusername +
        "&name=" +
        encodeURIComponent(this.state.schzsxm) +
        "&telephone=" +
        this.state.schtelephone +
        "&birthday=" +
        this.state.schbirthday +
        "";
    }
    this.props.history.push(url);
  }
  search() {
    const { myusername, ukey } = this.props;

    let name = this.state.schzsxm;
    let birthday = "";
    if (this.state.birthday != "") {
      birthday = this.state.birthday;
      birthday =
        birthday.getFullYear() +
        "-" +
        (birthday.getMonth() + 1) +
        "-" +
        birthday.getDate();
    }
    let username = this.state.schusername;
    let telephone = this.state.schtelephone;
    //let order = this.state.order;
    // let page = this.state.page;

    let url =
      "/user/customer/list?username=" +
      username +
      "&name=" +
      encodeURIComponent(name) +
      "&telephone=" +
      telephone +
      "&birthday=" +
      birthday +
      "&pagesize=" +
      this.state.pagesize +
      "&page=1&order=id";
    this.props.history.push(url);
  }
  setBirthday(val) {
    let birthday = "";
    let schbirthday = "";
    if (val == null) {
      schbirthday = "";
      birthday = "";
    } else {
      let date = new Date(
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      );
      birthday = date;
      schbirthday =
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate();
    }

    this.setState({
      birthday: birthday,
      schbirthday: schbirthday,
    });
  }
  hiddenDetail(index) {
    document.getElementById("row" + index).innerHTML = "";
    document.getElementById("row" + index).style.display = "none";
  }
  showDetail(_item, index) {
    var html = "<table>";

    var row = "<tr>";
    // console.log(_item.hours[j]);

    // min = JSON.parse(min);
    //   console.log(hour.min.length);
    //let bgcolor = "#fff";
    row +=
      "<tr><td style='height:25px;padding:3px;'>Email: " +
      _item.email +
      "</td></tr>";
    row +=
      "<tr><td style='height:25px;padding:3px;'>Address: " +
      _item.address +
      "</td></tr>";
    /*  row +=
      "<tr><td style='height:25px;padding:3px;'>Scope: " +
      _item.dianscope +
      "</td></tr>"; */
    row +=
      "<tr><td style='height:25px;padding:3px;'>CreateTime: " +
      _item.dt +
      "</td></tr>";
    row +=
      "<tr><td style='height:25px;padding:3px;'>Remark: " +
      _item.remark +
      "</td></tr>";
    html += row;

    html += "</table>";
    document.getElementById("row" + index).innerHTML = html;
    document.getElementById("row" + index).style.display = "block";
  }
  changeField(e, field) {
    let val = e.target.value;

    if (field == "schusername") {
      this.setState({
        schusername: val,
      });
    } else if (field == "schzsxm") {
      this.setState({
        schzsxm: val,
      });
    } else if (field == "schtelephone") {
      this.setState({
        schtelephone: val,
      });
    }
  }
  getData(
    myusername,
    ukey,
    page,
    username,
    name,
    telephone,
    birthday,
    order,
    oddesc
  ) {
    /*   let name = this.state.schzsxm;
    let birthday = "";
    if (this.state.birthday != "") {
      birthday = this.state.birthday;
      birthday =
        birthday.getFullYear() +
        "-" +
        (birthday.getMonth() + 1) +
        "-" +
        birthday.getDate();
    } */
    // let username = this.state.schusername;
    //let telephone = this.state.schtelephone;
    //let order = this.state.order;
    //let page = this.state.page;

    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&username=" +
      username +
      "&telephone=" +
      telephone +
      "&name=" +
       encodeURIComponent(name) +
      "&birthday=" +
      birthday +
      "&order=" +
      order +
      "&oddesc=" +
      oddesc +
      "&page=" +
      page +
      "&pagesize=" +
      this.state.pagesize +
      "&action=customerlist";

    axios.get(geturl).then((res) => {
      const result = res.data;

      this.setState({
        list: result.list,
        // totalpage: result.num,
        totalpage: Math.ceil(result["num"] / this.state.pagesize),
        //     num: result["num"],
        //  page: page,
      });
      /* */
      const action = {
        type: "change_sch_para",

        //  totalpage: Math.ceil(result["num"] / this.state.pagesize),
        // num: result.num,
        name: name,
        username: username,
        telephone: telephone,
        birthday: birthday,
        order: order,
        oddesc: oddesc,
        page: page,
      };

      this.props.changeSchPara(action);
      // this.setState({}); /**/
    });
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;
    var { page, username, telephone, birthday, name, order, oddesc } =
      queryString.parse(this.props.location.search);
    if (page == null) page = 1;
    if (username == null) username = "";
    if (telephone == null) telephone = "";
    if (birthday == null) birthday = "";
    if (name == null) name = "";
    if (order == null) order = "";
    if (oddesc == null) oddesc = "";

    var title = "Customer List";
    document.title = title;

    this.setState({
      title: title,
       schusername: username,
     schtelephone: username,
     schzsxm: name,
     schtelephone: telephone,
     schbirthday: birthday,
    });

    this.getData(
      myusername,
      encodeURIComponent(ukey),

      page,
      username,
      name,
      telephone,
      birthday,
      order,
      oddesc
    );

    //if (IsPC()) getUserIndexData(this, myusername, ukey);
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {
    const { myusername, ukey } = this.props;

    var { page, username, telephone, birthday, name, order, oddesc } =
      queryString.parse(this.props.location.search);
    if (page == null) page = 1;
    if (order == null) order = "";
    if (oddesc == null) oddesc = "";

    if (username == null) username = "";
    if (telephone == null) telephone = "";
    if (birthday == null) birthday = "";
    if (name == null) name = "";

    if (
      page !== this.props.page ||
      order !== this.props.order ||
      oddesc !== this.props.oddesc ||
      username !== this.props.username ||
      telephone !== this.props.telephone ||
      birthday !== this.props.birthday ||
      name !== this.props.name
    ) {
      this.getData(
        myusername,
        encodeURIComponent(ukey),
        page,
        username,
        name,
        telephone,
        birthday,
        order,
        oddesc
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    page: state.getIn(["customer", "page"]),
    order: state.getIn(["customer", "order"]),
    oddesc: state.getIn(["customer", "oddesc"]),
    name: state.getIn(["customer", "name"]),
    telephone: state.getIn(["customer", "telephone"]),
    birthday: state.getIn(["customer", "birthday"]),
    username: state.getIn(["customer", "username"]),
  };
};
const mapDispatch = (dispath) => ({
  changeSchPara(action) {
    dispath(action);
  },
});
export default connect(mapStateToProps, mapDispatch)(withRouter(Index));
