import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";

//import $ from "jquery";
//import { bookButtonColor } from "@/common/gdata/global.js";
//import { Link } from "react-router-dom";
//import MySignatureCanvas from "@/jscript/myCanve.js";
import {
  checkUserLogin,
  IsPC,
  MyToast,
 // MyToastShow,
} from "@/jscript/common.js";

import { SpaForm, getSpaData,SaveSpaForm } from "@/jscript/userform.js";

//import axios from "axios";
import "@/css/user.css";
import "@/css/react-datepicker.css";

//import DatePicker from "react-datepicker";
let emptysign =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

const LCss = {
  agree: {
    padding: "15px",
  },
  signdiv: {
    verticalAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  signcave: {
    height: "52px",
    lineHeight: "52px",
    width: "202px",

    border: "1px solid #ccc",
    position: "relative",
    float: "left",
    textAlign: "left",
  },
  canve: {
    background: "#fff",
    position: "absolute",
    top: 0,
    left: 0,
  },
};
class User extends Component {
  constructor(props) {
    super(props);
    this.canvasRef1 = React.createRef();
    this.canvasRef2 = React.createRef();
    this.canvasRef3 = React.createRef();
    this.state = {
      //  myu: "",

      title: "",
      firstname: "",
      lastname: "",

      telephone: "",

      address: "",
      city: "",
      province: "",
      postal: "",
      birthday: "",
      email: "",
      password: "",
      haveinsurance: 1,
      error: "",
      contactname: "",
      contacttel: "",
      familydoc: "",
      familydoctel: "",
      familydocaddress: "",
      insur_massage: "",
      insur_acupuncture: "",
      insur_osteopath: "",
      insur_physiotherapy: "",
      insur_chiropractic: "",
      insur_naturopath: "",
      insur_company: "",
      insur_policynum: "",
      insur_memberid: "",
      seriousdata: [],
      seriousothers: "",
      haveallergy: "",
      whatallergy: "",
      ongoinghealthy: "",
      longtertreat: "",
      patientsign: emptysign,
      patientsigndate: "",
      guardiansign: emptysign,
      guardiansigndate: "",
      practitionersign: emptysign,
      practitionersigndate: "",
      haveimg1: 0,
      haveimg2: 0,
      haveimg3: 0,
      postresult: "",
      mytoast: {
        show: false,
      },
      isprint: false,
    };
  }

  render() {
    const { myusername } = this.props;
    const IsMobile = !IsPC();
    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;
    //var zsxm = this.state.zsxm;
    // if (zsxm === "") zsxm = this.state.myusername;

    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px" }}
      >
        <div className="row " style={{ height: "55px", lineHeight: "55px" }}>
          <div
            className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
            onClick={() => {
              this.back();
            }}
          >
            {!this.state.isprint && (
              <span className="iconfont fanhui">&#xe625;</span>
            )}
          </div>
          <div
            className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textcenter "
            style={{ fontSize: "19px", fontWeight: "bolder" }}
          >
            {!this.state.isprint ? this.state.title : ""}
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold">
            {!this.state.isprint && (
              <a
                onClick={() =>
                  this.print(
                    "/user/form/spa?username=" +
                      this.state.username +
                      "&print=1"
                  )
                }
              >
                 {!IsMobile?"Print":""}
              </a>
            )}
            {this.state.isprint && (
              <input
                style={{ height: "35px", lineHeight: "35px" }}
                type="button"
                value="Print"
                onClick={() =>
                  this.printPage(
                    "/user/form/spa?username=" +
                      this.state.username +
                      "&print=1"
                  )
                }
              ></input>
            )}
          </div>
        </div>

        <div className="PageNext" id="content">
          {this.state.isprint && (
            <div className="printtitle">{this.state.title}</div>
          )}

          {/*  <div className="row">
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft"
              style={{ fontSize: "16px" }}
            >
              Client Information
            </div>
          </div> */}
          {SpaForm(this)}
        </div>
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
 back() {

  window.history.back();return;
 /*    if (window.confirm("Do you need to save the form?")) {
      SaveSpaForm(this);
      window.history.back();
    } else window.history.back(); */
  }
  
  printPage(url) {
    var orihtml = window.document.body.innerHTML; //从标记里获取需要打印的页面
    document.getElementById("content").style.padding = "10px";
    var printHtml = document.getElementById("content").innerHTML; //从标记里获取需要打印的页面

    window.document.body.innerHTML = printHtml; //需要打印的页面

    window.print();
    window.document.body.innerHTML = orihtml;
    window.open(url, "_self");
  }
  print(url) {
    window.open(url, "_blank");
  }

  setBirthday(val, field) {
    let _date = val;
    if (val == null) _date = "";
    if (_date != "") {
/*       let date = new Date(
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      );

      _date = date; */
    }
    if (field == "birthday") {
      this.setState({
        birthday: _date,
      });
    } else if (field == "patientsigndate") {
      this.setState({
        patientsigndate: _date,
      });
    } else if (field == "guardiansigndate") {
      this.setState({
        guardiansigndate: _date,
      });
    } else if (field == "practitionersign") {
      this.setState({
        practitionersign: _date,
      });
    }
  }

  changeField(e, field) {
    let val = e.target.value;
    if (field == "username")
      this.setState({
        username: val,
      });
    else if (field == "firstname")
      this.setState({
        firstname: val,
      });
    else if (field == "lastname")
      this.setState({
        lastname: val,
      });
    else if (field == "password")
      this.setState({
        password: val,
      });
    else if (field == "telephone")
      this.setState({
        telephone: val,
      });
    else if (field == "email")
      this.setState({
        email: val,
      });
    else if (field == "address") {
      this.setState({
        address: val,
      });
    } else if (field == "city")
      this.setState({
        city: val,
      });
    else if (field == "province")
      this.setState({
        province: val,
      });
    else if (field == "postal")
      this.setState({
        postal: val,
      });
    else if (field == "contactname")
      this.setState({
        contactname: val,
      });
    else if (field == "contacttel")
      this.setState({
        contacttel: val,
      });
    else if (field == "familydoc")
      this.setState({
        familydoc: val,
      });
    else if (field == "familydoctel")
      this.setState({
        familydoctel: val,
      });
    else if (field == "familydocaddress")
      this.setState({
        familydocaddress: val,
      });
    else if (field == "insur_massage")
      this.setState({
        insur_massage: val,
      });
    else if (field == "insur_acupuncture")
      this.setState({
        insur_acupuncture: val,
      });
    else if (field == "insur_osteopath")
      this.setState({
        insur_osteopath: val,
      });
    else if (field == "insur_physiotherapy")
      this.setState({
        insur_physiotherapy: val,
      });
    else if (field == "insur_chiropractic")
      this.setState({
        insur_chiropractic: val,
      });
    else if (field == "insur_naturopath")
      this.setState({
        insur_naturopath: val,
      });
    else if (field == "insur_company")
      this.setState({
        insur_company: val,
      });
    else if (field == "insur_policynum")
      this.setState({
        insur_policynum: val,
      });
    else if (field == "insur_memberid")
      this.setState({
        insur_memberid: val,
      });
    else if (field == "seriousothers")
      this.setState({
        seriousothers: val,
      });
    else if (field == "whatallergy")
      this.setState({
        whatallergy: val,
      });
    else if (field == "ongoinghealthy")
      this.setState({
        ongoinghealthy: val,
      });
    else if (field == "longtertreat")
      this.setState({
        longtertreat: val,
      });
    else if (field == "patientsign")
      this.setState({
        patientsign: val,
      });
    else if (field == "guardiansign")
      this.setState({
        guardiansign: val,
      });
    else if (field == "practitionersign")
      this.setState({
        practitionersign: val,
      });
    else if (field == "password")
      this.setState({
        password: val,
      });
  }

  componentDidMount() {
    const { myusername, ukey } = this.props;

    //const { t } = this.props.match.params;
    var { username, print } = queryString.parse(this.props.location.search);
    var title = "Edit Required Infomation";
    document.title = title;
    if (print == null) print = "";
    if (print == "1") {
      title = "Required Infomation";
      this.setState({
        isprint: true,
      });
    }
    if (username == null) username = "";
    if (username == "") {
      username = myusername;
    }
    this.setState({
      title: title,
      username: username,
    });

    //  getUserIndexData(this, myusername, ukey);
    getSpaData(this, username);
    // this.getData(myusername, ukey, username);
  }

  componentWillUnmount() {
 // SaveSpaForm(this);
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {}
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
  };
};

export default connect(mapStateToProps, null)(User);
