import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import queryString from "query-string";
import { AJAROOT } from "@/common/gdata/global.js";
import { Link } from "react-router-dom";
import { MyToast, MyToastShow, Fenye } from "@/jscript/common.js";
import { Pages } from "@/pages/type/style.js";
import "@/css/user.css";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      title: "",
      list: [],

      schusername: "",
      schtelephone: "",
      schname: "",

      p_page: 1,
      p_username: "",
      p_name: "",
      p_telephone: "",

      pagesize: 30,
      totalpage: 0,
      page: 1,

      error: "",

      postresult: "",
      mytoast: {
        show: false,
      },
    };
  }
  render() {
    const { myusername } = this.props;

    // let checkstatus = checkUserLogin(this.props, myusername);

    // if (checkstatus != "") return checkstatus;

    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px", width: "90%" }}
      >
        <div className="pagetitle">{this.state.title}</div>
        <div className="row textright">
          {
            <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10  textleft schform">
              <input
                style={{
                  float: "left",
                  width: "150px",
                }}
                name="schusername"
                value={this.state.schusername}
                className="schfield"
                placeholder="Input Account"
                onChange={(ref) => this.changeField(ref, "schusername")}
              ></input>
              <input
                className="schfield"
                name="schname"
                style={{
                  float: "left",
                  width: "150px",
                }}
                value={this.state.schname}
                placeholder="Input Nickname"
                onChange={(ref) => this.changeField(ref, "schname")}
              ></input>
              <input
                style={{
                  float: "left",
                  width: "150px",
                }}
                className="schfield"
                name="schtelephone"
                value={this.state.schtelephone}
                onChange={(ref) => this.changeField(ref, "schtelephone")}
                placeholder="Input Tel"
              ></input>

              <input
                onClick={() => this.search()}
                type="button"
                name="schbut"
                value=" Search "
                className="add"
              ></input>
            </div> /**/
          }
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
            <Link to="/user/employee/add">
              <input
                type="button"
                name="button1"
                value="New Employee"
                className="add"
              ></input>
            </Link>
          </div>
        </div>
        <div style={{ padding: "15px" }}></div>

        <div className="row">
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 bold textleft">
            Account
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold textleft">
            Nickname
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold textleft">
            Telephone
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold textleft">
            Work days
          </div>
          {/* <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold textleft">
            Email
          </div> */}
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 bold textleft">
            Tips(last month)
          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 bold textleft">
            Status
          </div>

          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright"></div>
        </div>

        {this.state.list.map((item, index) => {
          /*   var routineitem = this.state.routinelist.find(function (item2) {
            return item.routineid * 1 == item2.id;
          }); */

          // let routinename = "";
          //  if (routineitem != null) routinename = routineitem.name;
          let needset = false;
          if (item.maxperiodno != "") {
            const days = 30;
            const maxdate = new Date(item.maxperiodno);
            const currentDate = new Date(); // 获取当前日期
            currentDate.setDate(currentDate.getDate() + days);
            if (maxdate < currentDate) needset = true;
          }
          return (
            <div
              className="row list"
              key={"group" + index}
              style={{ background: needset ? "#eeee" : "#fff" }}
            >
              <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
              <div>{item.username}</div>
              <div className="bold">[{item.dianusername}]</div>
       
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold">
                {item.lastname}
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                {item.telephone}
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                {item.minperiodno} - {item.maxperiodno}
              </div>
              {/*    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                {item.email}
              </div> */}
              <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                $ {item.lastmonthtip}
              </div>
              <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                {item.opened}
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                <Link
                  to={
                    "/user/employee/worktime?username=" + item["username"] + ""
                  }
                >
                  Worktime
                </Link>

                {"　"}

                <Link to={"/user/book/list?employee=" + item["username"] + ""}>
                  Books
                </Link>
                {"　"}
                {item["dianusername"] == myusername && (
                  <>
                    <Link to={"/user/employee/add?id=" + item["id"] + ""}>
                      Update
                    </Link>
                    {"　"}
                     <a href="#" onClick={() => this.Del(index, item["username"])}>
                  Delete
                </a>
                  </>
                )}
               
              </div>
            </div>
          );
        })}
        <div className="row">
          <Pages>
            {Fenye(
              "/user/employee/list?username=" +
                this.state.schusername +
                "&name=" +
                this.state.schname +
                "&telephone=" +
                this.state.schtelephone +
                "&pagesize=" +
                this.state.pagesize +
                "",
              this.state.page,
              this.state.totalpage
            )}
          </Pages>
        </div>
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
  Del(index, username) {
    const { myusername, ukey } = this.props;
    if (window.confirm("Are you sure to delete it?")) {
      this.DelData(myusername, encodeURIComponent(ukey), username, index);
    } else return;
  }

  DelData(myusername, ukey, username, index) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=delemployee&username=" +
      username +
      "";

    axios.get(geturl).then((res) => {
      const result = res.data;

      if (result.r == 1) {
        let list = this.state.list;
        list.splice(index, 1);
        this.setState({
          list: list,
        });
        MyToastShow(
          this,
          1000,
          "Successfully! ",
          150,
          80,
          "success",
          "delemployee"
        );
      } else {
        MyToastShow(this, 2, result.s, 200, 120, "fail", "delemployee");
      }
      // this.setState({}); /**/
    });
  }

  changeField(e, field) {
    let val = e.target.value;

    if (field == "schusername") {
      this.setState({
        schusername: val,
      });
    } else if (field == "schname") {
      this.setState({
        schname: val,
      });
    } else if (field == "schtelephone") {
      this.setState({
        schtelephone: val,
      });
    } else if (field == "schbirth") {
      this.setState({
        schbirth: val,
      });
    }
  }

  search() {
    const { myusername, ukey } = this.props;

    // let schusername = this.state.schusername;
    //let order = this.state.order;
    // let page = this.state.page;

    let url =
      "/user/employee/list?username=" +
      this.state.schusername +
      "&name=" +
      this.state.schname +
      "&telephone=" +
      this.state.schtelephone +
      "&pagesize=" +
      this.state.pagesize +
      "&page=1";

    this.props.history.push(url);
  }

  getData(page, username, name, telephone) {
    const { myusername, ukey } = this.props;
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&pagesize=" +
      this.state.pagesize +
      "&page=" +
      page +
      "&action=employeelist&username=" +
      username +
      "&name=" +
      encodeURIComponent(name) +
      "&telephone=" +
      telephone +
      "";
    //console.log(geturl);
    axios.get(geturl).then((res) => {
      const result = res.data;

      this.setState({
        list: result.list,
        totalpage: Math.ceil(result["num"] / this.state.pagesize),
      });
      // this.setState({}); /**/
    });
  }
  /* getRoutineData(myusername, ukey) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&onlyname=1&action=routinelist";

    axios.get(geturl).then((res) => {
      const result = res.data;

      this.setState({
        routinelist: result.list,
      });
      // this.setState({}); 
    });
  } */

  loadData(mounted) {
    var { page, username, name, telephone } = queryString.parse(
      this.props.location.search
    );
    if (page == null) page = 1;
    if (username == null) username = "";

    if (name == null) name = "";
    if (telephone == null) telephone = "";

    let needload = false;
    if (mounted == 0) {
      this.setState({
        page: page,
        schusername: username,
        schname: name,
        schtelephone: telephone,
      });
      needload = true;
    } else {
      if (
        page !== this.state.p_page ||
        username !== this.state.p_username ||
        name !== this.state.p_name ||
        telephone !== this.state.p_telephone
      ) {
        needload = true;
      }
    }
    if (needload) {
      this.setState({
        p_page: page,
        p_username: username,
        p_name: name,
        p_telephone: telephone,
      });
      this.getData(page, username, name, telephone);
    }
  }

  componentDidMount() {
    const { myusername, ukey } = this.props;
    // const { t } = this.props.match.params;

    var title = "Employee List";
    document.title = title;

    this.loadData(0);

    this.setState({
      title: title,
      mounted: 1,
    });

    //if (IsPC()) getUserIndexData(this, myusername, ukey);
    // this.getRoutineData(myusername, encodeURIComponent(ukey));
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {
    if (this.state.mounted == 1) {
      this.loadData(1);
    }
  }
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
  };
};

export default connect(mapStateToProps, null)(Index);
