import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import axios from "axios";
import $ from "jquery";
import { AJAROOT, FormKey } from "@/common/gdata/global.js";
import queryString from "query-string";
import {
  checkUserLogin,
  getUserIndexData,
  MyToast,
  MyToastShow,
} from "@/jscript/common.js";

import "@/css/user.css";
import rjt from "../../../statics/rjt.png";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      title: "",
      editid: "",
      cateid: "",
      servicetitle: "",
      price: "",
      minutes: "",
      remark: "",
      opened: "1",
      branchlist: [],
      serviceform: [],
      selectedforms: [],
      postresult: "",

      mytoast: {
        show: false,
      },
    };
  }
  render() {
    const { myusername } = this.props;
    //  let menujson = Menu();
    // console.log(menujson);

    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;

    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px", width: "90%" }}
      >
        <div className="pagetitle">
          <div style={{ float: "left" }}>
            <Link to={"/user/service/list"}>Service List</Link>{" "}
            <img src={rjt} style={{ width: "18px", height: "18px" }} />{" "}
            {this.state.title}
          </div>
          <div
            style={{
              float: "right",
            }}
          >
            <div
              style={{}}
              onClick={() => {
                window.history.back(-1);
              }}
            >
              <span className="iconfont fanhui">&#xe625;</span>
            </div>
          </div>
        </div>
        <div className="row textright">
          <div>
            <Link to="/user/service/list">
              <input
                type="button"
                name="button1"
                value="Service List"
                className="add"
              ></input>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
            {/* <div className={this.state.uptype === "1" ? "row" : "nodis"}>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 ">
                姓名:
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 ">
                <input
                  value={this.state.upgzhnickname}
                  onChange={this.changeFirstName.bind(this)}
                />
              </div>
            </div> */}

            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * Category:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                {this.state.branchlist.map((item, index) => {
                  return (
                    <div key={"branch" + index} style={{border:"1px solid #fff"}}>
                      <input
                        className="checkbox clear"
                        type="radio"
                        value={item.name}
                        checked={this.state.cateid == item.id ? true : false}
                        onChange={() => this.selBranch(item.id)}
                      />{" "}
                      {"　" + item.name}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * Title:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  style={{ width: "360px" }}
                  value={this.state.servicetitle}
                  onChange={this.changeName.bind(this)}
                />
              </div>
            </div>

            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * Price:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.price}
                  onChange={this.changePrice.bind(this)}
                />
              </div>
            </div>
            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * Minutes:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.minutes}
                  onChange={this.changeMinutes.bind(this)}
                />
              </div>
            </div>
            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Status:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  type="radio"
                  name="opened"
                  value="1"
                  onChange={() => this.setOpened("1")}
                  checked={this.state.opened == "1" ? true : false}
                  style={{
                    width: "15px",
                    height: "15px",
                    padding: "5px",
                  }}
                ></input>
                {" Normal"}
                <input
                  type="radio"
                  name="opened"
                  value="0"
                  onChange={() => this.setOpened("0")}
                  checked={this.state.opened == "0" ? true : false}
                  style={{
                    width: "15px",
                    height: "15px",
                    padding: "5px",
                    marginLeft: "10px",
                  }}
                ></input>
                {" Closed "}
              </div>
            </div>
            {this.state.serviceform.length>0&&<div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Forms:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                {this.state.serviceform &&
                  this.state.serviceform.map((item, index) => {
                    //  let included=true;
                    let _item = this.state.selectedforms.filter(
                      (element) => element.key == item.key
                    );

                    let include = false;
                    if (_item.length > 0) include = true;
                    return (
                      <div key={"branch" + index} style={{border:"1px solid #fff"}}>
                        <input
                          className="checkbox clear"
                          type="checkbox"
                          value={item.username}
                          checked={include ? true : false}
                          onChange={(ref) => this.selForms(item, ref)}
                        />
                        {"　" + FormKey[item.key]}
                      </div>
                    );
                  })}
              </div>
            </div>}
            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Remark:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.remark}
                  onChange={this.changeRemark.bind(this)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 textcenter">
                <button className="submit" onClick={() => this.submit()}>
                  Submit
                </button>
              </div>
            </div>
            <div className="row">
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter postresult"
                style={{ width: "70%", marginTop: "10px" }}
              >
                {this.state.postresult}
              </div>
            </div>
          </div>
        </div>
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
  changeName(e) {
    let val = e.target.value;
    this.setState({
      servicetitle: val,
    });
  }
  changePrice(e) {
    let val = e.target.value;
    var j = new Number(val);
    if (!isNaN(j)) {
      this.setState({
        price: val,
      });
    }
  }

  changeMinutes(e) {
    let val = e.target.value;
    var j = new Number(val);
    if (!isNaN(j)) {
      this.setState({
        minutes: val,
      });
    }
  }
  changeRemark(e) {
    let val = e.target.value;
    this.setState({
      remark: val,
    });
  }
  selForms(item, ref) {
    let selectedforms = this.state.selectedforms;
    /*     const checkIfExists = (element) => {
    return element.key === itme.key; // 假设要判断的对象具有 id 属性，并且希望找到 id 为 2 的元素
  }; */

    let _item = this.state.selectedforms.filter(
      (element) => element.key == item.key
    );
   // console.log(_item);
    let hasElement = false;
    if (_item.length > 0) hasElement = true;

    // const hasElement = selectedforms.includes(item);
    if (!hasElement) selectedforms.push(item);
    else {
      selectedforms = selectedforms.filter((element) => element.key !== item.key);
      /*  setMyArray(newArray);
    selectedforms.shift(item); */
    }
    this.setState({
      selectedforms: selectedforms,
    });
  }
  selBranch(cateid) {
    this.setState({
      cateid: cateid,
    });
  }
  setOpened(value) {
    this.setState({
      opened: value,
    });
  }
  submit() {
    const { myusername, ukey } = this.props;

    //console.log(this.state.pages);
    // document.getElementById("result2").style.color = "#000000";
    //

    this.setState({
      postresult: "Waiting....",
    });
    // document.getElementById("result2").innerHTML = "正在提交....";
    let price = this.state.price;
    let minutes = this.state.minutes;
    if (price == "") price = 0;
    else price = price * 1;
    if (minutes == "") minutes = 0;
    else minutes = minutes * 1;
    const postdata = {
      uid: myusername,
      editid: this.state.editid,
      cateid: this.state.cateid,
      title: this.state.servicetitle,
      opened: this.state.opened,
      price: price,
      minutes: minutes,
      remark: this.state.remark,
      serviceform: this.state.selectedforms,
    };

    const this2 = this;
    var error = "";

    if (this.state.cateid === "") error = "Please select category";
    else if (this.state.servicetitle === "") error = "Please fill in title";
    else if (price == 0) error = "Please fill in price";
    else if (minutes == 0) error = "Please fill in minutes";

    //document.getElementById("result2").style.color = "red";
    if (error !== "") {
      this.setState({
        postresult: error,
      });
    } else {
      $.ajax({
        type: "POST",
        dataType: "json",
        url:
          AJAROOT +
          "/postaja/savexx.aspx?action=saveservice&u=" +
          myusername +
          "&ukey=" +
          encodeURIComponent(ukey) +
          "",
        data: JSON.stringify(postdata),
      })
        .done(function (res) {
          var r = res.r;

          if (r === 1) {
            //  this.postresult = "登陆成功！";
            MyToastShow(
              this2,
              2,
              "Successfully! ",
              150,
              80,
              "success",
              "addbranch"
            );
            this2.setState({
              postresult: "Successfully! ",
            });

            this2.props.history.push("/user/service/list");
          } else {
            MyToastShow(this2, 2, res.s, 200, 120, "fail", "addservice");
            //document.getElementById("result2").style.color = "red";
            this2.setState({
              postresult: res.s,
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    }
  }

  getBranchData(myusername, ukey) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=srvcatelist&readonly=1";

    axios.get(geturl).then((res) => {
      const result = res.data;

      //  let groupright = eval(result.groupright);

      this.setState({
        branchlist: result.list,
      });
      // this.setState({}); /**/
    });
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;
    var { id } = queryString.parse(this.props.location.search);
    if (id == undefined) id = "";
    var title = "Add Service";
    if (id != "") {
      title = "Edit Service";
    }
    document.title = title;
    this.setState({
      title: title,
      editid: id,
    });

    getUserIndexData(this, myusername, ukey);
    if (id != "") this.getData(myusername, encodeURIComponent(ukey), id);

    this.getBranchData(myusername, encodeURIComponent(ukey));
    //  this.getData(myusername, ukey);
    //  MyToastShow(this, 200, "Successfully! ", 150, 80, "success", "addgroup");
  }

  getData(myusername, ukey, id) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=serviceinfo&id=" +
      id +
      "";
    axios.get(geturl).then((res) => {
      const result = res.data;
      let title = result.title;
  
      //  let groupright = eval(result.groupright);
      this.setState({
        editid: result.id,
        title: title,
        price: result.price,
        minutes: result.minutes,
        servicetitle: result.title,
        cateid: result.cateid,
        opened: result.opened,
        remark: result.remark,
        selectedforms: result.serviceform,
      });
      // this.setState({}); /**/
    });
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  componentWillUpdate() {}
  componentDidUpdate() {}
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    zsxm: state.getIn(["gdata", "zsxm"]),
  };
};

export default connect(mapStateToProps, null)(Index);
