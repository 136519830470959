import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import queryString from "query-string";
import { AJAROOT } from "@/common/gdata/global.js";
import { Link } from "react-router-dom";
import {
  checkUserLogin,
  IsPC,
  Fenye,
  MyToast,
  MyToastShow,
} from "@/jscript/common.js";
import { Pages } from "@/pages/type/style.js";
import $ from "jquery";
import "@/css/user.css";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      title: "",
      giftcode: "",
      list: [],

      lastname: "",
      regtime: "",
      hba: 0,
      hbb: 0,
      hbc: 0,
      xfze: 0,
      totaljj: 0,
      error: "",

      postresult: "",
      pagesize: 30,
      totalpage: 0,
      mytoast: {
        show: false,
      },
    };
  }
  render() {
    const {
      myusername,
      myusertype,
      myusergrade,
      style,
      usertype,
      usergrade,
      hb,
    } = this.props;
    const IsMobile = !IsPC();
    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;
    //var zsxm = this.state.zsxm;
    /// if (zsxm === "") zsxm = this.state.myusername;
    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px", width: "90%" }}
      >
        <div className="pagetitle">{this.state.title}</div>
        <div className="row textright">
          <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10  textleft schform">
            <input
              style={{
                float: "left",
              }}
              name="giftcode"
              value={this.state.giftcode}
              className="schfield"
              placeholder="Input gift code"
              onChange={(ref) => this.changeField(ref, "giftcode")}
            ></input>

            <input
              onClick={() => this.search(1)}
              type="button"
              name="schbut"
              value=" Search "
              className="add"
            ></input>
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
            <Link to="/user/gift/add">
              <input
                type="button"
                name="button1"
                value="New Gift"
                className="add"
              ></input>
            </Link>
          </div>
        </div>
        {/* <div className="row textright">
          <div>
            <Link to="/user/gift/add">
              <input
                type="button"
                name="button1"
                value="New Gift"
                className="add"
              ></input>
            </Link>
          </div>
        </div> */}
        <div style={{ padding: "15px" }}></div>
        <div className="row">
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 bold textleft">
            Code
          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 bold textleft">
            Type
          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 bold textleft">
            Price
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 bold textleft">
            Service
          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 bold textleft">
            Status
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold textleft">
            Create time
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold textleft"></div>
        </div>
        {this.state.list.map((item, index) => {
          return (
            <div className="row list" key={"group" + index}>
              <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 ">
                {item.giftcode}
              </div>
              <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 ">
                {item.gifttype}
              </div>
              <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 ">
                $ {item.price}
              </div>
              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                {item.service.map((item2, index) => {
                  return (
                    <div className="row list " key={"group_" + index}>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                        {item2.title}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 ">
                <div>
                  {item.used == 0 ? "Unused" : "used"}
                  <input
                    name="submit1"
                    type="button"
                    value={item.used == 0 ? "Used" : "Unused"}
                    onClick={() =>
                      this.done(index, item.id, (item.used + 1) % 2)
                    }
                    style={{
                      height: "25px",
                      lineHeight: "25px",
                    }}
                  ></input>
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 ">
                {item.dt}
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
                <Link to={"/user/gift/add?id=" + item["id"] + ""}>Update</Link>{" "}
                {item["username"] != myusername && (
                  <a href="#" onClick={() => this.Del(index, item["giftcode"])}>
                    Delete
                  </a>
                )}
              </div>
            </div>
          );
        })}

        <div className="row">
          <Pages>
            {Fenye(
              "/user/gift/list?giftcode=" +
                this.props.giftcode +
                "&order=" +
                this.props.order +
                "&oddesc=" +
                this.props.oddesc +
                "&pagesize=" +
                this.state.pagesize +
                "",
              this.props.page,
              this.state.totalpage
            )}
          </Pages>
        </div>
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
  Del(index, giftcode) {
    const { myusername, ukey } = this.props;
    if (window.confirm("Are you sure to delete it?")) {
      this.DelData(myusername, encodeURIComponent(ukey), giftcode, index);
    } else return;
  }

  DelData(myusername, ukey, giftcode, index) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=delgift&giftcode=" +
      giftcode +
      "";

    axios.get(geturl).then((res) => {
      const result = res.data;

      if (result.r == 1) {
        let list = this.state.list;
        list.splice(index, 1);
        this.setState({
          list: list,
        });
        MyToastShow(
          this,
          1000,
          "Successfully! ",
          150,
          80,
          "success",
          "addgroup"
        );
      } else {
        MyToastShow(this, 2, result.s, 200, 120, "fail", "addbranch");
      }
      // this.setState({}); /**/
    });
  }

  changeField(e, field) {
    let val = e.target.value;

    if (field == "giftcode") {
      this.setState({
        giftcode: val,
      });
    }
  }
  done(index, id, value) {
    const that = this;
    const { myusername, ukey, myusertype } = this.props;
    const postdata = {
      id: id,
      value: value,
    };

    $.ajax({
      type: "POST",
      dataType: "json",
      url:
        AJAROOT +
        "/postaja/savexx.aspx?action=giftdone&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "",
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
        var r = res.r;
        var msg = res.msg;

        if (r === "1") {
          let list = that.state.list;
          list[index].used = value;
          //  this.postresult = "登陆成功！";
          MyToastShow(
            that,
            2,
            "Successfully! ",
            150,
            80,
            "success",
            "addbranch"
          );
          that.setState({
            list: list,
            postresult: "Successfully",
          });
          // if (myusername == this2.state.username)
          //   this2.props.history.push("/user");
          //  this2.props.history.push("/user");
        } else {
          //document.getElementById("result2").style.color = "red";
          that.setState({
            postresult: msg,
          });

          //  document.getElementById("result2").innerHTML = msg;
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {});
  }
  search() {
    const { myusername, ukey } = this.props;

    //let order = this.state.order;
    // let page = this.state.page;

    let url =
      "/user/gift/list?giftcode=" +
      this.state.giftcode +
      "&pagesize=" +
      this.state.pagesize +
      "&page=1&order=id&oddesc=desc";
    this.props.history.push(url);
  }
  getData(
    myusername,
    ukey,
    page,

    order,
    oddesc,
    giftcode
  ) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&giftcode=" +
      giftcode +
      "&order=" +
      order +
      "&oddesc=" +
      oddesc +
      "&page=" +
      page +
      "&pagesize=" +
      this.state.pagesize +
      "&action=giftlist";

    axios.get(geturl).then((res) => {
      const result = res.data;

      this.setState({
        list: result.list,
        totalpage: Math.ceil(result["num"] / this.state.pagesize),
        //totalpage: result.num,
      });

      const action = {
        type: "change_sch_para",

        //  totalpage: Math.ceil(result["num"] / this.state.pagesize),
        // num: result.num,
        giftcode: giftcode,

        order: order,
        oddesc: oddesc,
        page: page,
      };

      this.props.changeSchPara(action);
      // this.setState({}); /**/
    });
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;
    //  const { t } = this.props.match.params;

    var title = "Gift List";
    document.title = title;
    this.setState({
      title: title,
    });

    var { page, giftcode, order, oddesc } = queryString.parse(
      this.props.location.search
    );
    if (page == null) page = 1;
    if (giftcode == null) giftcode = "";

    if (order == null) order = "";
    if (oddesc == null) oddesc = "";

    //if (IsPC()) getUserIndexData(this, myusername, ukey);

    this.getData(
      myusername,
      encodeURIComponent(ukey),
      page,

      order,
      oddesc,
      giftcode
    );
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {
    const { myusername, ukey } = this.props;

    var { page, giftcode, order, oddesc } = queryString.parse(
      this.props.location.search
    );
    if (page == null) page = 1;
    if (order == null) order = "";
    if (oddesc == null) oddesc = "";

    if (giftcode == null) giftcode = "";

    if (
      page !== this.props.page ||
      order !== this.props.order ||
      oddesc !== this.props.oddesc ||
      giftcode !== this.props.giftcode
    ) {
      this.getData(
        myusername,
        encodeURIComponent(ukey),
        page,

        order,
        oddesc,
        giftcode
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
    page: state.getIn(["gift", "page"]),
    order: state.getIn(["gift", "order"]),
    oddesc: state.getIn(["gift", "oddesc"]),
    giftcode: state.getIn(["gift", "giftcode"]),
  };
};
const mapDispatch = (dispath) => ({
  changeSchPara(action) {
    dispath(action);
  },
});
export default connect(mapStateToProps, mapDispatch)(Index);
