import React, { Component } from "react";

import $ from "jquery";
import queryString from "query-string";

//import { LoginWrapper, LoginBox, Input, Button, PostResult } from "./style";

import { showSvg, MyToast, MyToastShow } from "@/jscript/common.js";
import { AJAROOT } from "@/common/gdata/global.js";

class Confirmemail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Feedback our service",
      servicetitle: "",
      periodno: "",
      fromtime: "",
      employee: "",
      minutes: 0,
      name: "",
      telephone: "",
      token: "",
      postresult: "",
      posterror: "",
      exist: false,
      stararray: [1, 2, 3, 4, 5],
      starnum: 3,

      mytoast: {
        show: false,
      },
      showpanel: true,
      afterfeedlink:""
    };
  }
  render() {
    // const { postresult } = this.props;
    //  console.log("render");
    return (
      <div className="container wrap">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter">
            <span style={{ fontSize: "25px", fontWeight: "bold" }}>
              {this.state.title}
            </span>
          </div>
        </div>

        {this.state.exist && this.state.showpanel && (
          <div className="row" style={{ marginTop: "15px" }}>
            <div
              id={"star_"}
              style={{
                position: "absolute",
                background: "#fff",
                width: "360px",
                height: "200px",
                border: "1px solid #ddd",
                top: 30,
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 9,
                padding: "10px",
              }}
            >
              <div
                style={{
                  width: "100%",

                  justifyContent: "center",
                  display: "flex",
                  paddingTop: "25px",
                }}
              >
                {this.state.stararray.map((item2, index2) => {
                  let color = "#cccccc";
                  if (item2 <= this.state.starnum) color = "red";
                  return (
                    <div
                      onClick={() => this.ClickStar(item2)}
                      style={{ float: "left", paddingRight: "5px" }}
                      key={"star2_" + index2}
                    >
                      {showSvg("star",50, 50, color)}
                    </div>
                  );
                })}{" "}
              </div>
              <div
                style={{
                  width: "100%",

                  justifyContent: "center",
                  display: "flex",
                  paddingTop: "19px",
                }}
              >
                <input
                  onClick={() => this.submitstar()}
                  type="button"
                  name="schbut"
                  value=" Submit "
                  className="add"
                ></input>
              </div>
            </div>
          </div>
        )}

        {!this.state.exist && (
          <div className="row" style={{ marginTop: "15px" }}>
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              style={{
                border: "1px solid #ddd",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter bold">
                  A review has already been sumitted.
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter">
            <span
              className={this.state.posterror === "" ? "textcenter" : "nodis"}
              style={{ fontSize: "25px" }}
            >
              {this.state.postresult}
            </span>
            <span
              className="loginerror textcenter"
              style={{ fontSize: "25px" }}
            >
              {this.state.posterror}
            </span>
          </div>
        </div>
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
  componentDidMount() {
    var { token } = queryString.parse(this.props.location.search);

    if (token == null) token = "";

    this.setState({
      token: token,
    });
    this.getData(token);
    // this.login2(token);
  }

  ClickStar(star) {
    if (this.state.starnum == star) {
      star--;
    }
    this.setState({
      starnum: star,
    });
  }

   componentWillUnmount() {
    clearTimeout(this.timer);
    this.setState = (state, callback) => {
      return;
    };
  }

  submitstar() {
    const that = this;

    const postdata = {
      token: this.state.token,
      star: this.state.starnum,
    };

    $.ajax({
      type: "POST",
      dataType: "json",
      url: AJAROOT + "/postaja/savexx.aspx?action=customersavestar",
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
        var r = res.r;

        if (r === "1") {
          //  this.postresult = "登陆成功！";

          MyToastShow(
            that,
            2,
            "We appreciate your feedback.",
            150,
            120,
            "success",
            "addbranch"
          );
          

          that.setState({
            showpanel: false,
            postresult: "Successfully, we appreciate your feedback.",
          });
          if (that.state.starnum >=4&&that.state.afterfeedlink!="") {
          //console.log(that.state.afterfeedlink);
          const delay = 2000; // 延迟时间，以毫秒为单位
            this.timer = setTimeout(() => {
              const afterfeedlink =
                that.state.afterfeedlink;

              // that.props.history.push(googleurl);
              const w = window.open("_blank"); //这里是打开新窗口

              w.location.href = afterfeedlink; //这样就可以跳转了
            }, delay);
          }
        } else {
          MyToastShow(
            that,
            2,
            "Unsuccessfully! Please try later. ",
            150,
            120,
            "faise",
            "addbranch"
          ); //document.getElementById("result2").style.color = "red";
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {});
  }
  getData(token) {
    // document.getElementById("result2").style.color = "#000000";

    //document.getElementById("result2").style.color = "red";
    let that = this;
    let url = AJAROOT + "/getaja/xx.aspx?action=starinfo&token=" + encodeURIComponent(token) + "";
    // console.log(url);

    $.ajax({
      type: "GET",
      dataType: "json",
      url: url,
    })
      .done(function (data) {
        var r = data.r;

        console.log(data);

        if (r === 1) {
          that.setState({
          afterfeedlink:data.afterfeedlink,
            exist: true,
          });
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {});
  }
}

export default Confirmemail;
