import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getDomainConfig,
  toUrl,
  MyToast,
  MyToastShow,
  isMainDomain
} from "@/jscript/common.js";
import { SiteName } from "@/common/gdata/global.js";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import "@/css/global.css";
//import { defaultStore } from "../gdata/global";
class Bottom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mytoast: {
        show: false,
      },
    };
  }

  render() {
   
    let isuserdomain = !isMainDomain();
    let showtop =!isuserdomain;
const {sitename,logo,address,telephone,postal,email}=this.props;
    /* */
    /*  */
    // console.log("000");

    const path = this.props.location.pathname;
    // console.log(path);
    var showmheader = true;
    if (path == "/chat" || path == "/chat/") {
      showmheader = false;
    }
    return (
      <>
        {showmheader && (
          <div
            id="bottomer"
            className="container"
            style={{
              width: "100%",
              backgroundColor: "#333333",
              color: "white",
            }}
          >
            <div className="row">
              <div
                className="col-xs-12 col-sm-12 col-md-3 col-lg-3"
                style={{ paddingTop: "20px" }}
              >
                {showtop == 1 && (
                  <>
                    <p>
                      <Link to="/?showhome=1" style={{ paddingRight: "20px" }}>
                        Feature List
                      </Link>
                    </p>
                    <p>
                      <Link to="/start" style={{ paddingRight: "20px" }}>
                        How To Start
                      </Link>
                    </p>
                    <p>
                      <Link to="/price" style={{ paddingRight: "20px" }}>
                        About Price
                      </Link>
                    </p>
                    <p>
                      <span
                        onClick={() => toUrl(this, "/chat", 1)}
                        style={{ paddingRight: "20px", cursor: "pointer" }}
                      >
                        Living Chat
                      </span>
                    </p>
                  </>
                )}
              </div>

              <div
                className="col-xs-12 col-sm-12 col-md-3 col-lg-3"
                style={{ paddingTop: "20px" }}
              >

<p>VAUGHAN LOCATION</p>
            
                    <p>1490 Major Mackenzie Drive West</p>
                    <p>Vaughan, ON L6A 4H6</p>
                    <p>(905)832-0999</p>
               
{/* 
<p>{sitename}</p>
            
                    <p>{address}</p>
                    <p>{postal}</p>
                    <p>{telephone}</p>
                    <p>{email}</p> */}
              </div>

              <div
                className="col-xs-12 col-sm-12 col-md-3 col-lg-3"
                style={{ paddingTop: "20px" }}
              >
              
   
              {/*       <p>{sitename}</p>
             
                    <p>{address}</p>
                    <p>{postal}</p>
                    <p>{telephone}</p>
                    <p>{email}</p> */}
               <p>SCARBOROUGH LOCATION</p>
            
            <p>119 Ellesmere Rd</p>
            <p>Scarborough, ON M1R 4C3</p>
            <p>(647)349-3303</p>
              
              </div>
              <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3"></div>
            </div>
            <div className="row" style={{ backgroundColor: "#111111" }}>
              <div style={{ fontSize: "14px", color: "#999" }}>
                ©2023 {sitename} All Rights
                Reserved.
                <span style={{paddingLeft:'29px'}}>Development && Technical Support: gtasoft2022@gmail.com</span>
              </div>
            </div>
          </div>
        )}
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </>
    );
  }
}
const mapState = (state) => ({
  bnewlist: state.getIn(["home", "bnewlist"]),
  storeinfo: state.getIn(["gdata", "storeinfo"]),
  myusername: state.getIn(["gdata", "username"]),
  logo: state.getIn(["header", "logo"]),
  sitename: state.getIn(["header", "sitename"]),
  address: state.getIn(["header", "address"]),
  telephone: state.getIn(["header", "telephone"]),
  email: state.getIn(["header", "email"]),

   postal: state.getIn(["header", "postal"]),
});
export default connect(mapState, null)(withRouter(Bottom));
