import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
//import SignatureCanvas from "react-signature-canvas";
//import $ from "jquery";
import { AJAROOT } from "@/common/gdata/global.js";
import { EclaimForm, getEclaimData ,saveEclaimForm} from "@/jscript/eclaim.js";
//import MySignatureCanvas from "@/jscript/myCanve.js";
import {
  checkUserLogin,
  IsPC,
  MyToast,
  MyToastShow,
} from "@/jscript/common.js";
//import axios from "axios";
import "@/css/user.css";
import "@/css/react-datepicker.css";

//import DatePicker from "react-datepicker";
let emptysign =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

class User extends Component {
  constructor(props) {
    super(props);
    this.canvasRef1 = React.createRef();
    this.canvasRef2 = React.createRef();
    this.canvasRef3 = React.createRef();
    this.state = {
      //  myu: "",

      title: "",
      error: "",
      comname: "",

      address: "",

      city: "",
      province: "",
      postal: "",

      firstname: "",
      lastname: "",
      ec_insurancepayer: "",
      ec_membername: "",
      ec_policynumber: "",
      ec_certificated: "",

      ec_secondmembername: "",

      ec_sign: emptysign,
      ec_signdate: "",

      haveimg1: 0,
      agree1: "",
      agree2: "",
      postresult: "",
      mytoast: {
        show: false,
      },
      isprint: false,
    };
  }

  render() {
    const { myusername } = this.props;
    const IsMobile = !IsPC();
    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;
    //var zsxm = this.state.zsxm;
    // if (zsxm === "") zsxm = this.state.myusername;

    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px" }}
      >
        <div className="row " style={{ height: "55px", lineHeight: "55px" }}>
          <div
            className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
            onClick={() => {
            this.back();
            }}
          >
            {!this.state.isprint && (
              <span className="iconfont fanhui">&#xe625;</span>
            )}
          </div>
          <div
            className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textcenter "
            style={{ fontSize: "19px", fontWeight: "bolder" }}
          >
            {!this.state.isprint ? this.state.title : ""}
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            {!this.state.isprint ? (
              <a
                onClick={() =>
                  this.print(
                    "/user/form/eclaim?username=" +
                      this.state.username +
                      "&print=1"
                  )
                }
              >
                 {!IsMobile?"Print":""}
              </a>
            ) : (
              <input
                className="printField"
                style={{ height: "35px", lineHeight: "35px" }}
                type="button"
                value="Print"
                onClick={() =>
                  this.printPage(
                    "/user/form/eclaim?username=" +
                      this.state.username +
                      "&print=1"
                  )
                }
              ></input>
            )}
          </div>
        </div>
        {EclaimForm(this)}
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
   back() {
    if (window.confirm("Do you need to save the form?")) {
      saveEclaimForm(this);
      window.history.back();
    } else window.history.back();
  }
  clearCanv(index) {
    if (index == 1) {
      if (this.state.haveimg1 == 0) this.canvasRef1.current.clear();
      this.setState({
        haveimg1: 0,
      });
    }
  }

  print(url) {
    window.open(url, "_blank");
  }
  printPage(url) {
    var orihtml = window.document.body.innerHTML; //从标记里获取需要打印的页面

    // document.getElementById("content").style.padding = "10px";
    var printHtml = document.getElementById("content").innerHTML; //从标记里获取需要打印的页面

    window.document.body.innerHTML = printHtml; //需要打印的页面

    window.print();

    window.document.body.innerHTML = orihtml;

    window.open(url, "_self");
    // window.open(window.location.href, "_self");
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;
    //const { t } = this.props.match.params;
    var { username, print } = queryString.parse(this.props.location.search);
    var title = "Electronic transmission authorizationand consent form";
    document.title = title;
    if (username == null) username = "";
    if (username == "") {
      username = myusername;
    }
    if (print == null) print = "";
    if (print == "1") {
      this.setState({
        isprint: true,
      });
    }
    this.setState({
      title: title,
      username: username,
    });

    //  getUserIndexData(this, myusername, ukey);
    getEclaimData(this, username);
  }

  componentWillUnmount() {
   saveEclaimForm(this);
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {}
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
  };
};

export default connect(mapStateToProps, null)(User);
