import styled from "styled-components";

export const HomeLeft = styled.div`
  width: 198px;
  float: left;
  border: 1px solid #bbb;
`;

export const HomeRight = styled.div`
  width: 1000px;
  margin-left: 0px;
  float: left;
`;

export const List = styled.div``;

export const ListItem = styled.div`
  padding: 10px 0;
  overflow: hidden;
  border-bottom: 1px solid #dcdcdc;
  .img {
    width: 125px;
    height: 100px;
    display: block;
    float: right;
    border-radius: 10px;
  }
`;

export const LbHeader = styled.div`
  width: 100%;
  height: 40px;
  background: #383a3e;
  margin: 0 auto;
  line-height: 40px;
`;

export const LbBody = styled.div`
  width: 100%;
  height: 400px;
  margin: 0 auto;
  line-height: 40px;
`;
export const LbItem = styled.div`
  width: 100%;
  float: left;
  margin: 0 auto;
  a:link {
    color: #666666;

    width: 100%;
    height: 100%;
    float: left;
  }
  a:active {
    color: #666666;
  }
  a:visited {
    color: #666666;
  }
  a:hover {
    background: #337b70;
    color: #000;
  }
  .lbname {
    float: left;
    font-weight: bold;
    font-size: 17px;
  }
  .img {
    float: left;
    width: 40px;
    height: 40px;
    margin: 4px;
    margin-right: 10px;
  }
`;

export const LbColumnm = styled.div`
  width: 100%;

  float: left;

  text-align: left;
  padding-left: 0px;
  margin: 0px;
`;

export const LbTitle = styled.div`
  width: 100%;
  height: 30px;
  float: left;
  line-height: 30px;
  text-align: left;
  padding-left: 10px;
  /*   background: #111; */
  color: black;
  span {
    padding-right: 10px;
    font-size: 15px;
  }
`;
export const Pages = styled.div`
  width: 100%;
  font-size: 17px;
  float: left;
  line-height: 40px;
  height: 40px;

  text-align: center;

  margin: 0 0;
  padding: 0px;

  span {
    padding-right: 10px;
    font-size: 17px;
    color: #111111;
    line-height: 40px;
    height: 40px;
  }
  .cur {
    color: #bbbbbb;
    font-size: 17px;
    background: #000;
    margin: 6px;
    padding: 6px;
    line-height: 40px;
    height: 40px;
  }

  .page {
    line-height: 40px;
    height: 40px;
    color: #fff;
    cursor: pointer;
    font-size: 17px;
    background: #000;
    margin: 6px;
    padding: 6px;
    a {
      color: white;
    }
  }
`;
