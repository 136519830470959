import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import axios from "axios";
import queryString from "query-string";
import { AJAROOT } from "@/common/gdata/global.js";
import { LbTitle, Pages } from "@/pages/type/style.js";
import { IsPC, getTitle, Fenye } from "@/jscript/common.js";
import "@/css/newslist.css";

class NewsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pagesize: 30,
      newslist: [],
      totalpage: 0,

      catename: "",
      cateod: 0,
      cateid: "",

      kwd: "",

      page: "",
    };
  }
  render() {
    // console.log(GetTypeId());
    const IsMobile = !IsPC();
    var nextpage = this.state.page * 1 + 1;

    if (nextpage > this.state.totalpage * 1) nextpage = this.state.totalpage;
    var lastpage = this.state.page * 1 - 1;
    if (lastpage < 1) lastpage = 1;
    //JSON.parse(JSON.stringify(cpLb)).le

    // const url = "/type/" + typeid + "/{page}";
    //const yestr = Fenye2(typeid, lastpage, nextpage, totalpage);
    const styles = {
      lbtitle: {
        fontSize: "16px",

        padding: "10px",
        color: "#111",
        fontWeight: "800",
      },
    };

    return (
      <div className="container wrap">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            {!IsMobile && (
              <div className="row">
                <LbTitle>
                  <span>
                    <a href="/">Home</a>
                  </span>

                  <span>
                    /{" "}
                    <a href={"/newslist?cateid=" + this.state.cateid + ""}>
                      {this.state.catename}
                    </a>
                  </span>
                </LbTitle>
              </div>
            )}
            {!IsMobile && (
              <div className="row big textcenter" style={{ padding: "30px" }}>
                {this.state.catename}
              </div>
            )}

            <div
              gutter={24}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap ",
              }}
            >
              {this.state.newslist.map((item2, index) => {
                return (
                  <div
                    className={
                      this.state.cateod >= 100 ? "newswindow" : "newslist"
                    }
                    key={item2["id"]}
                  >
                    <div className="cptp">
                      <Link
                        to={"/newsview?id=" + item2["id"] + ""}
                        key={item2["id"]}
                      >
                        <img
                          style={{ width: "100%" }}
                          src={AJAROOT + item2["newsimg"]}
                          key={index}
                        />
                      </Link>
                    </div>
                    <div className="cptext">
                      <div className="cptitle">
                        <Link
                          to={"/newsview?id=" + item2["id"] + ""}
                          key={item2["id"]}
                        >
                          {item2["title"]}
                          <span
                            style={{ paddingLeft: "20px", fontSize: "15px" }}
                          >
                            {item2["systime"]}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row">
              <Pages>
                {Fenye(
                  "newslist?cateid=" + this.state.cateid + "",

                  this.state.page,

                  this.state.totalpage
                )}
              </Pages>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getHomeData(cateid, kwd, page) {
    var geturl =
      AJAROOT +
      "/getaja/news_list.aspx?cid=" +
      cateid +
      "&key=" +
      kwd +
      "&page=" +
      page +
      "&u=";

    axios.get(geturl).then((res) => {
      const result = res.data;

      // this.setState({}); /**/
      this.setState({
        newslist: result["news"]["newslist"],
        catename: result["news"]["catename"],
        cateod: result["news"]["cateod"],
        totalpage: result["news"]["totalpage"],
        totalitem: result["news"]["totalitem"],

        cateid: cateid,
        kwd: kwd,
        page: page,
      });
      let title = result["news"]["catename"];

      if (kwd !== "") title = kwd + "";

      if (title.substring(title.length - 1) === ",")
        title = title.substring(0, title.length - 1);

      document.title = title;

      const action = {
        type: "change_pagetitle",

        pagetitle: title,
      };

      this.props.changeData(action);

      //   this.props.changeHomeData(action);
    });
  }

  componentDidMount() {
    // console.log(this.props);
    // const { typeid } = this.props.match.params;
    var { cateid, kwd } = queryString.parse(this.props.location.search);

    if (cateid == null) cateid = "";
    if (kwd == null) kwd = "";

    //this.setState({
    //  kwd: kwd,
    // });
    this.getHomeData(cateid, kwd, 1);
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {
    // const { typeid } = this.props.match.params;
    var { cateid, kwd } = queryString.parse(this.props.location.search);

    if (cateid == null) cateid = "";
    if (kwd == null) kwd = "";
    if (cateid !== this.state.cateid || kwd != this.state.kwd) {
      //  this.setState({
      //   kwd: kwd,
      //  });
      this.getHomeData(cateid, kwd, 1);
    }
  }
}

const mapDispatch = (dispath) => ({
  changeData(action) {
    dispath(action);
  },
});
export default connect(null, mapDispatch)(withRouter(NewsList));
