import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import { Link } from "react-router-dom";
import "@/css/cart.css";

class Alert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      color: "#111",

      message: "",
      tourl: "",
      urltitle: "",
    };
  }
  render() {
    const { myusername } = this.props;

    return (
      <div
        style={{
          color: this.state.color,
          fontSize: "19px",
          padding: "100px 10px",
        }}
      >
        <span
          style={{
            color: this.state.color,
            fontSize: "19px",
            paddingRight: "20px",
          }}
        >
          {this.state.message}
        </span>

        <Link to={"/" + this.state.tourl} style={{ fontSize: "19px" }}>
          {this.state.urltitle}
        </Link>
      </div>
    );
  }

  componentDidMount() {
    // $("#bottomer").css("display", "none");
    const { myusername, ukey } = this.props;
    var { message, success, tourl, urltitle } = queryString.parse(
      this.props.location.search
    );
    let fontcolor = "#ff0000";
    if (success === "1") {
      fontcolor = "#111";
    }
    this.setState({
      message: message,
      tourl: tourl,
      urltitle: urltitle,
    });
    document.title = "Result";
  }

  componentWillUnmount() {
    //   $("#bottomer").css("display", "block");
  }

  componentWillUpdate() {}
  componentDidUpdate() {}
}

export default connect(null, null)(Alert);
