import React, { Component,PureComponent } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  IsPC,
  MyToast, 
  toUrl,
  isMainDomain,
  getDomain,
} from "@/jscript/common.js";
import { AJAROOT, defaultStore,getDomainInfo} from "@/common/gdata/global.js";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Logo, Nav2Item } from "./style";
import cookie from "react-cookies";
import * as actionCreators from "./store/actionCreator";
import { Link } from "react-router-dom";
import "@/css/global.css";
//import publiclogo from "../../statics/plogo.png";

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      kwd: "",
      type2: [],
      shownav: false,
      company: "",
      showtop: 0,
      logo: "",
      mytoast: {
        show: false,
      },
      domainAllowReg:false

      //   isuserdomain: false,
      //  telephone:""
    };
  }

  render() {
    const { myusername, usertype } = this.props;

    const IsMobile = !IsPC();
    //const arrusertype = UserType();
    let myusertype = usertype;
    /*     let width = $(window).width();
    let minwidth = 680;
    let smalldevice = 1;
    if (width > minwidth) smalldevice = 0; */
    // document.getElementById("searchword").value = this.props.kwd;
    // this.getHomeData(myusername);
    // console.log(this.state.type2);
    //console.log(this.props.location);
    const path = this.props.location.pathname;
    // console.log(path);
    var showmheader = true;
    if (path == "/" || IsMobile) showmheader = false;
    let pagetitle = this.props.pagetitle;
    if (path == "/type") pagetitle = this.props.typepagetitle;
    else if (path == "/module") pagetitle = document.title;
    // else if (path == "/newslist") pagetitle = document.title;
    else if (path.indexOf("/detail") == 0) pagetitle = "商品详细";
    else if (path == "/newsview") pagetitle = "详细";
    else if (path == "/login") pagetitle = "登陆";
    else if (path == "/register") pagetitle = "注册";
    else if (path == "/findpass") pagetitle = "重置密码";
    else if (path == "/user") pagetitle = "会员中心";
    else if (path == "/user/hblist") pagetitle = "帐目往来";
    else if (path == "/user/group") pagetitle = "我的团队";
    else if (path == "/user/mylink") pagetitle = "推广链接";
    else if (path.indexOf("/user/myorder") == 0) pagetitle = "我的订单";
    else if (path == "/userup/1") pagetitle = "修改资料";
    else if (path == "/userup/2") pagetitle = "修改密码";
    else if (path == "/cart") pagetitle = "购物车";
    else if (path == "/cart2") pagetitle = "结算购物车";
    //console.log("parentusername" + this.props.parentusername);
    return (
      <>
        {showmheader && 1 == 2 ? (
          <div className="container wrap">
            <div
              className="row "
              style={{ height: "50px", lineHeight: "50px" }}
            >
              <div
                onClick={() => {
                  window.history.back(-1);
                }}
              >
                <span className="iconfont fanhui">&#xe625;</span>
              </div>
              <div
                className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textcenter "
                style={{ fontSize: "22px", fontWeight: "bolder" }}
              >
                {pagetitle}
              </div>
              {/*   <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 cartimg">
                <Link to="/cart" key="mycart">
                  <span className="icon iconfont" style={{ fontSize: "40px" }}>
                    &#xe70b;
                  </span>
                  <span className="cartnum">{cartnum}</span>
                </Link>
              </div> */}
            </div>
          </div>
        ) : (
          <div>
            <div
              className="container top "
              style={{ width: "96%", height: "65px" }}
            >
              <div className="row ">
                <div
                  className="col-xs-2 col-sm-2 col-md-2 col-lg-2 "
                  style={{ paddingTop: "3px" }}
                >
                  <Link to="/">
                    {this.props.logo && (
                      <Logo
                        style={{
                          backgroundImage: `url(${this.props.logo})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                        }}
                        /*   style={{
                        backgroundImage:
                          'url("http://localhost:8200/keditor/attached/image/20230118/20230118222927_5719.png")',
                        backgroundRepeat: "no-repeat center center",
                      }} */
                      ></Logo>
                    )}
                  </Link>
                </div>
                <div
                  className="col-xs-7 col-sm-7 col-md-7 col-lg-7 "
                  style={{ height: "60px", lineHeight: "60px" }}
                >
                  {(myusername == "" && this.state.showtop == 1 &&!IsMobile)&& (
                    <div style={{}}>
                      <div
                        style={{
                          float: "left",
                          width: "80%",
                          fontSize: "22px",
                        }}
                      >
                        Easy-to-use, powerful, and fast booking system
                      </div>
                      <div
                        style={{
                          float: "right",
                          width: "20%",
                          textAlign: "right",
                        }}
                      >
                        <Link to="">
                          <span
                            onClick={() => toUrl(this, "/chat", 1)}
                            className="iconfont fanhui"
                            style={{ color: "#111", cursor: "pointer" }}
                          >
                            &#xe604;
                          </span>
                        </Link>
                      </div>
                    </div>
                  )}

                  {myusertype == 0 && myusername != "" && (
                    <Link to="/user/book/add" style={{ paddingRight: "20px" }}>
                      <input
                        type="button"
                        name="button1"
                        value=" New Book "
                        className="add"
                      ></input>
                    </Link>
                  )}
                  {myusertype >= 100 && (
                    <>
                      <Link
                        to="/user/book/panel"
                        style={{ paddingRight: "20px" }}
                      >
                        <input
                          type="button"
                          name="button1"
                          value={myusertype==2?" Book ":" Panel "}
                          className="add"
                        ></input>
                      </Link>
                    </>
                  )}

{myusertype >= 2 && (
                    <>

<Link
                        to="/user/book/panel"
                        style={{ paddingRight: "20px" }}
                      >
                        <input
                          type="button"
                          name="button1"
                          value={myusertype==2?" Book ":" Panel "}
                          className="add"
                        ></input>
                      </Link>
                      <Link
                        to="/user/customer/add"
                        style={{ paddingRight: "20px" }}
                      >
                        <input
                          type="button"
                          name="button1"
                          value="New Customer "
                          className="add"
                        ></input>
                      </Link>
                    </>
                  )}
                </div>
                <div
                  className="col-xs-3 col-sm-3 col-md-3 col-lg-3"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60px",
                    lineHeight: "60px",
                  }}
                >
                  <div className="row  textright ">
                    {myusername === "" && (
                      <Nav2Item className="right ">
                        <Link to="/login" style={{ paddingRight: "20px" }}>
                          Login
                        </Link>
                        {this.state.domainAllowReg&&(<Link to="/register">Register</Link>)}
                      </Nav2Item>
                    )}
                    {myusername !== "" && (
                      <Nav2Item className="right">
                        {/*   <Link to="/cart">
                          <span className="iconfont tb">&#xe70b;</span>
                          <span className="cartnum">{cartnum}</span>
                        </Link>{" "} */}
                        {/*  {arrusertype[this.props.usertype]} */}
                        {!IsMobile && (
                          <>
                            <Link to="/">
                              Welcome, {this.props.firstname}{" "}
                              <span className="iconfont tb user">&#xe61a;</span>
                            </Link>{" "}
                          </>
                        )}
                        <Link to="/logout">
                          <span className="iconfont tb">&#xe623;</span>
                        </Link>{" "}
                      </Nav2Item>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#111111",
                width: "100%",
                marginTop: "8px",
                paddingLeft: "30px",
                paddingRight: "30px",
                fontColor: "white",
              }}
              /*   className={!IsMobile ? "" : "nodis"} */
            >
              <div className={"nav"}>
                <ul>
                  <li
                    className="li"
                    onMouseOver={() => this.ShowMenu("sub1", "nav1")}
                    onMouseOut={() => this.HidMenu("sub1", "nav1")}
                  >
                    <Link to="/" id="nav1">
                      Home
                    </Link>
                    <div className="sub" id="sub1"></div>
                  </li>

                  {this.props.usertype >= 2 && (
                    <li
                      className="li"
                      onMouseOver={() => this.ShowMenu("sub80", "nav80")}
                      onMouseOut={() => this.HidMenu("sub80", "nav80")}
                    >
                      <Link to="/user/customer/list" id="nav80">
                        Customer List
                      </Link>
                      <div className="sub" id="sub80"></div>
                    </li>
                  )}
                  {myusername == "" && this.state.showtop == 1 && (
                    <>
                      <li
                        className="li"
                        onMouseOver={() => this.ShowMenu("sub81", "nav81")}
                        onMouseOut={() => this.HidMenu("sub81", "nav81")}
                      >
                        <Link to="/start" id="nav81">
                          How To Start
                        </Link>
                        <div className="sub" id="sub81"></div>
                      </li>
                      <li
                        className="li"
                        onMouseOver={() => this.ShowMenu("sub82", "nav82")}
                        onMouseOut={() => this.HidMenu("sub82", "nav82")}
                      >
                        <Link to="/price" id="nav82">
                          Price
                        </Link>
                        <div className="sub" id="sub82"></div>
                      </li>
                    </>
                  )}

                  {myusername !== "" && (
                    <li
                      className="li"
                      onMouseOver={() => this.ShowMenu("sub83", "nav83")}
                      onMouseOut={() => this.HidMenu("sub83", "nav83")}
                    >
                      <Link to="/user/book/list" id="nav83">
                        Book List
                      </Link>
                      <div className="sub" id="sub83"></div>
                    </li>
                  )}

                  {this.props.usertype >= 2 && (
                    <>
                      <li
                        className="li"
                        onMouseOver={() => this.ShowMenu("sub60", "nav60")}
                        onMouseOut={() => this.HidMenu("sub60", "nav60")}
                      >
                        <Link to="/user/employee/list" id="nav60">
                          Employee
                        </Link>
                        <div className="sub" id="sub60"></div>
                      </li>
                      <li
                        className="li"
                        onMouseOver={() => this.ShowMenu("sub62", "nav62")}
                        onMouseOut={() => this.HidMenu("sub62", "nav62")}
                      >
                        <Link to="/user/service/list" id="nav62">
                          Service
                        </Link>
                        <div className="sub" id="sub62">
                          {this.props.parentusername == "" && (
                            <>
                              <p>
                                <Link to={"/user/service/list"}>
                                  Service List
                                </Link>
                              </p>
                              <p>
                                <Link to={"/user/srvcate/list"}>
                                  Service Category
                                </Link>
                              </p>
                            </>
                          )}
                        </div>
                      </li>
                      <li
                        className="li"
                        onMouseOver={() => this.ShowMenu("sub61", "nav61")}
                        onMouseOut={() => this.HidMenu("sub61", "nav61")}
                      >
                        <Link to="/user/gift/list" id="nav61">
                          Gift Card
                        </Link>
                        <div className="sub" id="sub61"></div>
                      </li>
                      {this.props.parentusername == "" && (
                        <li
                          className="li"
                          onMouseOver={() => this.ShowMenu("sub65", "nav65")}
                          onMouseOut={() => this.HidMenu("sub65", "nav65")}
                        >
                          <Link to="/user/branch/list" id="nav65">
                            Branch
                          </Link>
                          <div className="sub" id="sub65"></div>
                        </li>
                      )}
                    </>
                  )}

                  {this.props.usertype >= 2 && (
                    <li
                      className="li"
                      onMouseOver={() => this.ShowMenu("sub88", "nav88")}
                      onMouseOut={() => this.HidMenu("sub88", "nav88")}
                    >
                      <Link to="#" id="nav88">
                        System
                      </Link>
                      <div className="sub" id="sub88">
                        <p>
                          <Link to={"/user/system/setup?action=link"}>
                            Signup&Book Link
                          </Link>
                        </p>
                       
                        {this.props.parentusername == "" && (
                         
                            <p>
                              <Link to={"/user/system/setup?action=backupdb"}>
                                Database Backup
                              </Link>
                            </p> 
                     
                        )}

<p>
                          <Link to={"/user/system/setup?action=worktime"}>
                            Setup
                          </Link>
                        </p>
                        {/* <p>
                          <Link to={"/user/routine/list"}>Routine Set</Link>
                        </p> */}
                        {/*  <p>
                          <a href="/user/system/setup?action=worktime">
                            Set Work Time
                          </a>
                        </p> */}

                        {/* <p>
                          <Link to={"/user/system/setup?action=backupdb"}>
                            Backup && Restore
                          </Link>
                        </p>
                        <p>
                          <Link to={"/user/system/setup?action=ini"}>
                            Initialize System
                          </Link>
                        </p> */}
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </>
    );
  }
  onKeyPressed(e) {
    if (e.keyCode == 13) this.handleSearch();
  }

  reLogin(isuserdomain) {
    if (!isuserdomain) {
      console.log("aaa");
    }
    const { myusername, ukey } = this.props;

    if (myusername === null) myusername = "";

    // const theconfig = getDomainConfig();

    // let logo = publiclogo;

    let rememberusername = "";
    let rememberopenid = "";
    if (myusername === "") {
      rememberusername = cookie.load("username");
      rememberopenid = cookie.load("openid");
      if (rememberusername == null) rememberusername = "";
      if (rememberopenid == null) rememberopenid = "";
    }

    /*  //去掉
    if (myusername == "" && rememberusername == "") {
      //未登陆情况下 使用默认域名的logo及company

      this.setState({
        logo: theconfig.logo,
        showtop: theconfig.showtop,
        //  telephone:JSON.parse(theconfig.storeinfo).tel
      });
      document.title = theconfig.company;
      //  return;
    } //登陆情况下，使用载入的会员资料相关的logo及公司名称
    else {
      if (this.props.storeinfo) {
        let storeinfo = JSON.parse(this.props.storeinfo);

        if (storeinfo.logo) {
          this.setState({
            logo: AJAROOT + storeinfo.logo,
            //    telephone:storeinfo.tel
          });
          document.title = storeinfo.company;
        }
      }
    } */

    var geturl =
      AJAROOT +
      "/getaja/index.aspx?id=1&u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&rememberusername=" +
      rememberusername +
      "&rememberopenid=" +
      rememberopenid +
      "";

    axios.get(geturl).then((res) => {
      const result = res.data;
      console.log(result);
      const lgstatus = result["lgstatus"];
      /*      
      const action = {
        type: "change_home_data",
        cpLb: result["lblist"],
        adList: result["ads"],
        ggList: result["ggList"],
        homeregad: result["homeregad"],
        bnewlist: result["bnewlist"],
        storeinfo: result["storeinfo"],
        //  dflblist: result["dflblist"],
      };
      this.props.changeHomeData(action); */
      if (lgstatus === "logout") {
        //clear cookie
        const action = {
          type: "change_login_data",
          username: "",
          ukey: "",
          usertype: 0,
          usergrade: 1,
          cartnum: 0,
          dianusername: "",
          lastname: "",
          firstname: "",
          parentusername: "",
          //  storeinfo: theconfig.storeinfo,
        };
        this.props.changeLoginData(action);

        if (!isuserdomain) {
          const action = {
            type: "CHANGE_DOMAIN",
            logo: defaultStore.logo,
            sitename: defaultStore.sitename,
            address: defaultStore.address,
            postal: defaultStore.postal,
            telephone: defaultStore.telephone,
            email: defaultStore.email,
            loaded: 1,
          };
          this.props.CHANGE_DOMAIN(action);

          if (defaultStore.logo) {
            this.setState({
              logo: defaultStore.logo,
              sitename: defaultStore.sitename,
              //  telephone:storeinfo.tel
            });
            document.title = defaultStore.sitename;
          }
        }
      } else if (lgstatus === "reload") {
        let inFifteenMinutes = new Date(
          new Date().getTime() + 24 * 3600 * 1000 * 90
        );

        cookie.save("username", result["uname"], {
          expires: inFifteenMinutes,
          path: "/",
        });

        cookie.save("openid", rememberopenid, {
          expires: inFifteenMinutes,
          path: "/",
        });

        const action = {
          type: "change_login_data",
          username: result["uname"],
          lastname: result["lastname"],
          firstname: result["firstname"],
          ukey: result["ukey"],
          usertype: result["usertype"],
          usergrade: result["usergrade"],
          cartnum: result["cartnum"],
          parentusername: result["parentusername"],
          dianusername: result["dianusername"],
          //  storeinfo: result["storeinfo"],
        };

        //如果是主域名，则更新changedomain

        this.props.changeLoginData(action);

        if (!isuserdomain) {
          let storeinfo = result["storeinfo"];

          const action = {
            type: "CHANGE_DOMAIN",
            logo: AJAROOT + storeinfo.logo,
            sitename: storeinfo.sitename,
            address: storeinfo.address,
            postal: storeinfo.postal,
            telephone: storeinfo.telephone,
            email: storeinfo.email,
            loaded: 1,
            customersite: isuserdomain ? 1 : 0,
          };
          this.props.CHANGE_DOMAIN(action);
          console.log("reload");

          if (storeinfo.logo) {
            this.setState({
              logo: AJAROOT + storeinfo.logo,
              sitename: defaultStore.sitename,
              //  telephone:storeinfo.tel
            });
            document.title = storeinfo.sitename;
          }
        }
        //   let storeinfo = JSON.parse(result["storeinfo"]);

        if (
          this.props.location.pathname != "/user/book/panel" &&
          this.props.location.pathname != "/user" &&
          this.props.location.pathname != "/confirmbook"
        ) {
          this.props.history.push("/user");
        }
      }
    });
  }
  ShowNav() {
    let shownav = this.state.shownav;
    if (shownav === 1) shownav = 0;
    else shownav = 1;
    this.setState({
      shownav: shownav,
    });
  }
  ShowMenu(id, id2) {
    document.getElementById(id).style.display = "block";

    document.getElementById(id2).style.backgroundColor = "#aaaaaa";
  }
  HidMenu(id, id2) {
    document.getElementById(id).style.display = "none";
    document.getElementById(id2).style.backgroundColor = "#111111";
  }
  componentDidMount() {
    let isuserdomain = !isMainDomain();
    this.loaddomain(isuserdomain);
    this.reLogin(isuserdomain);
  }
  loaddomain(isuserdomain) {

    //if(this.props.loaded==1)return;

    let logo = "";
    let sitename = "";
    let address = "";
    let postal = "";
    let telephone = "";
    let email = "";
    this.setState({
      //   isuserdomain: isuserdomain,
      showtop: !isuserdomain,
    });
    //如果不是主域名 isuserdomain=true，则获取域名信息，重置CHANGE_DOMAIN//
    if (isuserdomain) {
      let domain = getDomain();
      var geturl =
        AJAROOT + "/getaja/xx.aspx?action=loaddomain&domain=" + domain + "";
      console.log(geturl);
      axios.get(geturl).then((res) => {
        let result = res.data;
        console.log(result);

        if (result.r == 0) {
          logo = defaultStore.logo;

          sitename = defaultStore.sitename;
          address = defaultStore.address;
          postal = defaultStore.postal;
          telephone = defaultStore.telephone;
          email = defaultStore.email;
        } else {
          logo = AJAROOT + result.logo;
          sitename = result.sitename;
          address = result.address;
          postal = result.postal;
          telephone = result.telephone;
          email = result.email;
        }
        const action = {
          type: "CHANGE_DOMAIN",
          logo: logo,
          sitename: sitename,
          address: address,
          postal: postal,
          telephone: telephone,
          email: email,
          loaded: 1,
          customersite: isuserdomain ? 1 : 0,
        };

        this.props.CHANGE_DOMAIN(action);
        if (logo) {
          this.setState({
            logo: logo,
            sitename: sitename,
            //  telephone:storeinfo.tel
          });
          document.title = sitename;
        }
      });
    } else {
      logo = defaultStore.logo;
      sitename = defaultStore.sitename;
      address = defaultStore.address;
      postal = defaultStore.postal;
      telephone = defaultStore.telephone;
      email = defaultStore.email;

      const action = {
        type: "CHANGE_DOMAIN",
        logo: logo,
        sitename: sitename,
        address: address,
        postal: postal,
        telephone: telephone,
        email: email,
        loaded: 1,
        customersite: isuserdomain ? 1 : 0,
      };
      console.log(action);
      this.props.CHANGE_DOMAIN(action);
      if (logo) {
        this.setState({
          logo: logo,
          sitename: sitename,
          //  telephone:storeinfo.tel
        });
        document.title = sitename;
      }

      // this.reLogin(isuserdomain);
    }

    //如果是主域名，则重置CHANGE_DOMAIN固定信息
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {
    //  document.getElementById("searchword").value = "";
    //  document.getElementById("searchword").value = "";
  }
  componentDidUpdate() {
    // console.log("componentDidUpdate");
    //  console.log("componentWillUnmount" + this.state.kwd);
    //document.getElementById("searchword").value = this.props.kwd;
    var { kwd } = queryString.parse(this.props.location.search);
    if (kwd == null) kwd = "";

    if (this.props.kwd !== kwd) {
      // console.log("ffff" + kwd);
      document.getElementById("searchword").value = kwd;
    }

    if (this.state.logo != this.props.logo && this.props.logo) {
      //退出状态
      this.setState({
        logo: this.props.logo,
        //  telephone:JSON.parse(theconfig.storeinfo).tel
      });
      document.title = this.props.sitename;
    }


    let domainAllowReg=true;
    if(!getDomainInfo().allowReg)
      {
        domainAllowReg=false;
      }
    this.setState({

      domainAllowReg: domainAllowReg,

    });

  }

  handleSearch() {
    var kwd = this.searchword.value;

    this.setState({
      kwd: kwd,
    });
    //  document.location = "/type?kwd=" + kwd;
    if (kwd === null) kwd = "";
    if (kwd !== "") this.props.history.push("/type?kwd=" + kwd);
  }
}

/*
const Header = (props) => {
    
}*/
const mapStateToProps = (state) => {
  return {
    //focused: state.header.focused,
    //   hotlist: state.header.get('hotlist'),
    //totalpage: state.header.totalpage,
    // page: state.header.page,
    // mouseIn: state.header.mouseIn

    loaded: state.getIn(["header", "loaded"]),
    logo: state.getIn(["header", "logo"]),
    sitename: state.getIn(["header", "sitename"]),

    myusername: state.getIn(["gdata", "username"]),
    dianusername: state.getIn(["gdata", "dianusername"]),

    parentusername: state.getIn(["gdata", "parentusername"]),

    lastname: state.getIn(["gdata", "lastname"]),
    firstname: state.getIn(["gdata", "firstname"]),
    usertype: state.getIn(["gdata", "usertype"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
    pagetitle: state.getIn(["gdata", "pagetitle"]),

    // storeinfo: state.getIn(["gdata", "storeinfo"]),

    typepagetitle: state.getIn(["type", "pagetitle"]),
    kwd: state.getIn(["type", "kwd"]),

    // adList: state.getIn(["home", "adList"]),
  };
};
const mapDispathToProps = (dispatch) => {
  return {
    CHANGE_DOMAIN(action) {
      dispatch(action);
    },
    changeHomeData(action) {
      dispatch(action);
    },
    changeLoginData(action) {
      dispatch(action);
    },
    handleChangePage(page, totalpage, spin) {
      var originAngle = spin.style.transform.replace(/[^0-9]/gi, "");

      if (!originAngle) originAngle = 0;
      var newangle = originAngle * 1 + 360;
      spin.style.transform = "rotate(" + newangle + "deg)";
      var tmppage = 1;
      if (page < totalpage) tmppage = page + 1;

      const action = actionCreators.changePage(tmppage);
      dispatch(action);
    },
  };
};
export default connect(mapStateToProps, mapDispathToProps)(withRouter(Header));
