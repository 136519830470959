import {
  AJAROOT,
  MinDawai,
  FormKey,
  checkColor,
  bookButtonColor,
  PayMethods,
  littleBlueColor,
  domainConfig,
  MainDomain,
  checkoutBgcolor,
  fixedColor
} from "@/common/gdata/global.js";
import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import errorPic from "../statics/error.png";
import gouPic from "../statics/gou.png";

import msgpic from "../statics/msg.png";
import msgokpic from "../statics/msgok.png";
import { Fragment } from "react";

import "@/css/react-datepicker.css";
//import rjt from "../../../statics/rjt.png";
import DatePicker from "react-datepicker";

import { BasicForm, SpaForm, getSpaData } from "@/jscript/userform.js";
import { EclaimForm, getEclaimData } from "@/jscript/eclaim.js";
import { HealthForm, getHealthData } from "@/jscript/health.js";

export function isEmpty(value) {
  if(value==""||value==null||value=="")return true;
  else return false;  
}
export function getDomainConfig() {
  let host = window.location.host;
  if (host.indexOf(":") > 0) {
    let arr = host.split(":");
    host = arr[0];
  }
  const theconfig = domainConfig.find((config) => config.domain === host);
  return theconfig;
}

export function convertToRgba(color, opacity) {
  let r, g, b;
  if (color.startsWith("#")) {
    r = parseInt(color.slice(1, 3), 16);
    g = parseInt(color.slice(3, 5), 16);
    b = parseInt(color.slice(5, 7), 16);
  } else if (color.startsWith("rgb")) {
    const rgbValues = color.match(/\d+/g);
    r = parseInt(rgbValues[0]);
    g = parseInt(rgbValues[1]);
    b = parseInt(rgbValues[2]);
  }
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function getDomain() {
  let host = window.location.host;
  if (host.indexOf(":") > 0) {
    let arr = host.split(":");
    host = arr[0];
  }
  return host;
}
export function isMainDomain() {
  let host = getDomain();
  console.log(host);
  const indexof = MainDomain.indexOf(host);

  if (indexof >= 0) return true;
  else return false;
}
export function OpenUrl(url, self) {
  const w = window.open(self); //这里是打开新窗口

  w.location.href = url;
}

export function showSvg(code, width, height, color) {
  let svgCode = ``;

  if (code == "gou") {
    svgCode = `
  <svg t="1684593781749" fill=${color} class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1290" width=${width} height=${height}><path d="M892.064 261.888a31.936 31.936 0 0 0-45.216 1.472L421.664 717.248l-220.448-185.216a32 32 0 1 0-41.152 48.992l243.648 204.704a31.872 31.872 0 0 0 20.576 7.488 31.808 31.808 0 0 0 23.36-10.112L893.536 307.136a32 32 0 0 0-1.472-45.248z" p-id="1291"></path></svg> `;
  } else if (code == "cha") {
    svgCode = `
  <svg t="1684594301701" fill=${color} class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1129" width=${width} height=${height}><path d="M561.17013333 509.06026667L858.02666667 213.73973333c14.03733333-13.968 14.1088-36.60053333 0.1408-50.63786666-13.99893333-14.06826667-36.592-14.10773333-50.62933334-0.1408L510.6048 458.31466667 216.256 163.06986667c-13.9328-13.96693333-36.59733333-14.03733333-50.63466667-0.07146667-14.00426667 13.96586667-14.03733333 36.63146667-0.0704 50.6688l294.27733334 295.1744-296.71466667 295.14026667c-14.0384 13.968-14.1088 36.59733333-0.14293333 50.63786666a35.7216 35.7216 0 0 0 25.3856 10.56c9.13066667 0 18.26666667-3.4688 25.25013333-10.4192l296.78613333-295.2128L807.4304 857.48266667c6.9824 7.02186667 16.15253333 10.53013333 25.35253333 10.53013333a35.72906667 35.72906667 0 0 0 25.28213334-10.45973333c13.99893333-13.96586667 14.03733333-36.592 0.07146666-50.62933334L561.17013333 509.06026667z m0 0" p-id="1130"></path></svg>
  `;
  } else if (code == "star") {
    svgCode = `
   <svg t="1677874205695" fill=${color} class="icon" viewBox="0 0 1151 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2484" width=${width} height=${height}><path d="M518.535872 35.620955L387.952021 300.38821 95.788154 342.982943c-52.39352 7.59906-73.390924 72.191072-35.395623 109.186496l211.373859 205.974527-49.993817 290.964016c-8.998887 52.593496 46.394262 91.988624 92.788525 67.391666L575.928774 879.116638l261.367676 137.38301c46.394262 24.396983 101.787412-14.79817 92.788525-67.391666l-49.993818-290.964016 211.373859-205.974527c37.995301-36.995425 16.997898-101.587436-35.395622-109.186496L763.905526 300.38821 633.321676 35.620955c-23.397106-47.194163-91.188723-47.794089-114.785804 0z" p-id="2485"></path></svg>
 `;
  }
  return <div dangerouslySetInnerHTML={{ __html: svgCode }} />;
}

export function TurnNull(value) {
  let _value = value;
  if (
    _value == null ||
    _value == "null" ||
    _value == "undefined" ||
    _value == undefined
  )
    _value = "";
  return _value;
}

export function getPeriodnoFromDate(date) {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month <= 9) month = "0" + month * 1;
  if (day <= 9) day = "0" + day * 1;
  return year + "" + month + "" + day;
}

export function getPeriodnoFromDate_YYYYMMDD(date) {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month <= 9) month = "0" + month * 1;
  if (day <= 9) day = "0" + day * 1;
  return year + "/" + month + "/" + day;
}

export function getDateFromPeriod(periodno) {
  if (periodno == "") return "";
  if (periodno.indexOf("-") > 0) {
    const date = new Date(periodno);

    return date;
  } else {
    const year = periodno.slice(0, 4);
    const month = periodno.slice(4, 6);
    const day = periodno.slice(6, 8);
    const date = new Date(year, month - 1, day);
    return date;
  }
}
export function getDateFromPeriodno(periodno) {
  if (periodno == "") return "";
  let time =
    periodno.substring(0, 4) +
    "-" +
    periodno.substring(4, 6) +
    "-" +
    periodno.substring(6, 8);
  return time;
}
export function getTitle(title, lan, zhsize) {
  const ensize = zhsize * 2;
  let _title = title;
  if (lan == "en") {
    if (_title.length > ensize) {
      _title = _title.substring(0, ensize) + "...";
    }
    return _title;
  } else {
    if (_title.length > zhsize) {
      _title = _title.substring(0, zhsize) + "...";
    }
    return _title;
  }
}
export function DrawImage(eleid, width, height) {
  var vwidth = width;
  var vheight = height;
  var image = new Image();
  var ImgD = document.createElement(eleid);
  image.src = ImgD.src;

  if (image.width > 0 && image.height > 0) {
    if (image.width / image.height >= vwidth / vheight) {
      if (image.width > vwidth) {
        ImgD.width = vwidth;
        ImgD.height = (image.height * vwidth) / image.width;
      } else {
        ImgD.width = image.width;
        ImgD.height = image.height;
      }
      //ImgD.alt = image.width + "×" + image.height;
    } else {
      if (image.height > vheight) {
        ImgD.height = vheight;
        ImgD.width = (image.width * vheight) / image.height;
      } else {
        ImgD.width = image.width;
        ImgD.height = image.height;
      }
      // ImgD.alt = image.width + "×" + image.height;
    }
  }
}
export function TurnTime(time) {
  let arr = time.split(":");
  let hour = arr[0];
  let minute = arr[1];
  if (hour <= 9) hour = "0" + hour * 1;
  if (minute <= 9) minute = "0" + minute * 1;
  return hour + ":" + minute;
}
export function toUrl(ele, url, needlogin) {
  const { myusername, ukey } = ele.props;
  if (needlogin == 1) {
    let _myusername = TurnNull(myusername);
    if (_myusername == "") {
      MyToastShow(
        ele, 3,
        "Please login in! ",
        150,
        80,
        "success",
        "addgroup"
      );
      return;
    }
  }
  ele.props.history.push(url);
}
export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
}
export function getRndChr(e) {
  e = e || 32;
  var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz0123456789",
    a = t.length,
    n = "";
  for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n;
}

export function IsPC() {
  var userAgentInfo = navigator.userAgent;

  var Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
    "Mobile",
  ];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      //  console.log("ismobile");
      flag = false;
      break;
    }
  }
  return flag;
}

export function Fenye(preurl, page, totalpage) {
  // var last = fenfen + "" + pagesize + "/页";
  let firstpage = page - 2;
  if (firstpage <= 0) firstpage = 1;
  let lastpage = page * 1 + 2;

  if (lastpage > totalpage) lastpage = totalpage;
  // let rightstr = "";
  let firstarr = [];
  var o = 0;
  if (totalpage <= 5) {
    for (let a = 1; a <= totalpage; a++) {
      firstarr[o] = a;

      o++;
    }
  } else {
    for (let a = firstpage; a <= lastpage; a++) {
      firstarr[o] = a;

      o++;
    }
  }

  return (
    <div style={{ padding: "5px" }}>
      {firstpage > 1 && (
        <Link to={preurl + "&page=1"}>
          <span
            className={page * 1 === totalpage ? "cur" : "page"}
          /*  onClick={() => this.getHomeData(typeid, kwd, price, item)} /**/
          >
            &lt; &lt;
          </span>
        </Link>
      )}

      {firstarr.map((item, index) => {
        return (
          <Link to={preurl + "&page=" + item + ""} key={item}>
            <span
              key={index}
              className={page * 1 === item ? "cur" : "page"}
            /*  onClick={() => this.getHomeData(typeid, kwd, price, item)} /**/
            >
              {item}
            </span>
          </Link>
        );
      })}

      {lastpage < totalpage && (
        <Link to={preurl + "&page=" + totalpage + ""}>
          <span
            className={page * 1 === totalpage ? "cur" : "page"}
          /*  onClick={() => this.getHomeData(typeid, kwd, price, item)} /**/
          >
            &gt;&gt;
          </span>
        </Link>
      )}
    </div>
  );
}
export function getMinutes() {
  let days = [
    { v: 0, c: 1, u: 0 },
    { v: 10, c: 1, u: 0 },
    { v: 20, c: 1, u: 0 },
    { v: 30, c: 1, u: 0 },
    { v: 40, c: 1, u: 0 },
    { v: 50, c: 1, u: 0 },
  ];
  return days;
}

export function equalTime(time1, time2) {
  let arr1 = time1.split(":");

  let arr2 = time2.split(":");
  if (arr2[1] * 1 + arr2[0] * 60 == arr1[1] * 1 + arr1[0] * 60) return true;
  else return false;
}

export function diffTime(time1, time2) {
  let arr1 = time1.split(":");

  let arr2 = time2.split(":");
  if (arr2[1] * 1 + arr2[0] * 60 >= arr1[1] * 1 + arr1[0] * 60) return true;
  else return false;
}
export function TimeIsBetween(time, time1, time2) {
  let arr = time.split(":");

  let arr1 = time1.split(":");

  let arr2 = time2.split(":");
  let v = parseInt(arr[1] * 1 + arr[0] * 60);
  let v1 = parseInt(arr1[1] * 1 + arr1[0] * 60);
  let v2 = parseInt(arr2[1] * 1 + arr2[0] * 60);
  //v=1290;
  //v2=1290;
  if (v >= v1 && v <= v2) {
    return true;
  } else {
    return false;
  }
}
function selectALLMin(ele, index) {
  let hours = ele.state.hours;
  let check = (hours[index].check + 1) % 2;
  hours[index].check = check;
  let mins = hours[index].min;

  mins.forEach((element, j) => {
    let time = hours[index].v + ":" + element.v;

    if (
      diffTime(ele.state.worktimefrom, time) &&
      diffTime(time, ele.state.worktimeto)
    )
      element.c = check;
  });
  //console.log(days);
  ele.setState({
    hours: hours,
  });
}

function selectMin(ele, index, index2) {
  let hours = ele.state.hours;
  let c = (hours[index].min[index2].c + 1) % 2;
  hours[index].min[index2].c = c;

  //console.log(days);
  ele.setState({
    hours: hours,
  });
}
/* 
function selectStuffALLMin(ele, index, index1) {
  let bookdata = ele.state.bookdata;
  let error = "";

  if (
    bookdata.employee != "" &&
    bookdata.employee != ele.state.fixtime[index].username &&
    bookdata.mins.length > 0
  ) {
    error = "You should select only one employee.";
  } else if (
    bookdata.periodno != "" &&
    bookdata.periodno != ele.state.fixtime[index].periodno &&
    bookdata.mins.length > 0
  ) {
    error = "You should select only one day.";
  }
  if (error != "") {
    MyToastShow(ele, 2, error, 200, 120, "fail", "addemployee");
    return;
  }

  let fixtime = ele.state.fixtime;

  let hours = ele.state.fixtime[index].hours;
  let selected = hours[index1].s;
  if (selected == null) selected = 0;
  selected = (selected + 1) % 2;
  hours[index1].s = selected;
  let mins = hours[index1].min;

  bookdata.employee = ele.state.fixtime[index].username;
  bookdata.periodno = ele.state.fixtime[index].periodno;
  bookdata.nickname = ele.state.fixtime[index].zsxm;
  if (bookdata.nickname == "") bookdata.nickname = bookdata.employee;
  //let cansel = true;
  mins.forEach((element, j) => {
    if (element.u != 1) {
      element.s = selected;
      if (selected == 1) bookdata.mins.push(hours[index1].v + ":" + element.v);
      else bookdata.mins.splice(hours[index1].v + ":" + element.v);
    } else {
      //  cansel = false;
    }
  });

  ele.setState({
    fixtime: fixtime,
    bookdata: bookdata,
  });
} */


export function NoteForm(ele) {
  // hours = [];

  return (
    /*     
        {ele.state.showuserremark == 1 &&
          ele.state.editremarkbookid == bookid && ( */
    <div
      style={{


        /* */
        /*   position: "fixed",
          width: "300px",
          height: "300px",
          border: "1px solid #ddd",
          backgroundColor: "#f6f6f6",
          //borderTop: "2px solid #ddd",
          // borderBottom: "2px solid #ddd",
          top: 300,
          left: 500,
          zIndex: 999999,
          margin: "5px",
          borderRadius: "8px",
          padding: "5px",
          transform: "translate(-50%, -50%)" */

      }}
    >
      <div style={{ fontWeight: "bold" }}>
        <div
          style={{
            float: "left",
            width: "100%",
            textAlign: "center",
            padding: "15px 8px"
          }}
        >
          Note of{" "}
          {ele.state.books[ele.state.editremarkbookid].username}
        </div>
      </div>

      <div
        style={{
          padding: "10px",
          display: "flex",
          paddingTop: "5px",
        }}
      >
        <textarea
          defaultValue={
            ele.state.books[ele.state.editremarkbookid].userremark
          }
          onChange={(ref) =>
            ele.changeField(ref, "userremark")
          }
          style={{
            width: "300px",
            height: "160px",
            border: "1px solid #333"
          }}
        ></textarea>
      </div>
      <div
        style={{
          paddingTop: "5px",
          display: "flex",
          justifyItems: "center",
          justifyContent: "center",
        }}
      >


        <input
          type="button"
          name="butsaveremark"
          value=" Close "
          style={{
            width: "100px",
            marginRight: "15px",
            backgroundColor: "#bbb",
            color: "white",
          }}
          className="add"
          onClick={(ref) =>

            ele.setState({
              showuserremark: 0,
              editremarkbookid: "",
            })
            /* 
            ele.ShowRemark("0", 0) */
          }
        ></input>

        <input
          type="button"
          name="butsaveremark"
          value=" Save "
          style={{
            width: "100px",
            backgroundColor: bookButtonColor,
            color: "white",
          }}
          className="add"
          onClick={() => ele.saveRemark(ele.state.editremarkbookid)}
        ></input>
      </div>
      {/*  <div
            style={{
              width: "100%",
              textAlign: "right",
            }}
          >
            <span
              style={{
                float: "right",
                width: "100px",
                fontSize: "22px",
                paddingRight: "15px",
              }}
              onClick={(ref) =>
                ele.ShowRemark(bookid, 0)
              }
            >
              X
            </span>
          </div> */}
    </div>
    /*  )} */
  );
}

export function showDayHours(ele, hours) {
  // hours = [];

  return (
    <div
      style={{
        margin: "10px auto",
        textAlign: "center",

        borderRadius: "5px",

        width: "350px",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <table style={{ border: "1px solid #ddd" }}>
        <tbody>
          {hours.map((item, index) => {
            let v1 = item.v;
           
            //  let bgcolor1 = "#fff";
            //  if (item.check == 1) bgcolor1 = "#0066ff";
            if (v1 <= 9) v1 = "0" + v1 * 1;
            let haveminuts=true;
            if(index==hours.length-1)
              {
                haveminuts=false;
                let _time = v1+":00";           
                  let isbetween = TimeIsBetween(
                    _time,
                    ele.state.worktimefrom,
                    ele.state.worktimeto
                  );
                  if (isbetween & (_time != ele.state.worktimeto))haveminuts=true;

              }

            if(!haveminuts)return;
            else 
           

            return (
              <tr key={"hours" + index}>
                <td
                  style={{
                    width: "60px",
                    height: "30px",
                    border: "1px solid #ccc",
                  }}
                >
                  <input
                    type="checkbox"
                    name="allminute"
                    checked={item.check == 1}
                    value=""
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "0px",
                    }}
                    onChange={() => selectALLMin(ele, index)}
                  ></input>
                </td>

                {item.min.map((item2, index2) => {
                  let v = item2.v;
                  if (v == 0) v = "00";
                  let _time = v1 + ":" + v;
                  let bgcolor = "#fff";
                  if (item2.c == 1) bgcolor = bookButtonColor;
                  let isbetween = TimeIsBetween(
                    _time,
                    ele.state.worktimefrom,
                    ele.state.worktimeto
                  );
                  if (isbetween & (_time != ele.state.worktimeto)) {
                    return (
                      <td
                        key={"minute" + index2}
                        onClick={() => selectMin(ele, index, index2)}
                        style={{
                          width: "60px",
                          height: "30px",
                          border: "1px solid #ccc",
                          backgroundColor: bgcolor,
                        }}
                      >
                        {_time}
                      </td>
                    );
                  } /**/ else {
                    return (
                      <td
                        key={"minute" + index2}
                        style={{
                          width: "60px",
                          height: "30px",
                          border: "1px solid #ccc",
                        }}
                      ></td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export function showStuffDayHours(ele, username, hours, index,blankrow=0) {//工作台
  // hours = [];
  const { myusername,myusertype} = ele.props;
  /*  const [xPos, setXPos] = useState(0);
  const [yPos, setYPos] = useState(0);
  const [showMenu, setShowMenu] = useState(false);

  const handleContextMenu = (e) => {
    e.preventDefault();
    setXPos(e.pageX);
    setYPos(e.pageY);
    setShowMenu(true);
  }; */

  //let fulltime = ele.state.fixtime[index].fulltime;

  let periodno = ele.state.fixtime[index].periodno;
  let gridheight = ele.state.sys_mingrid_height;

  //let blankrow=3;
  return (
    <div
      style={{
        margin: "0px auto",
        textAlign: "center",

        borderRadius: "5px",

        width: "170px",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
     paddingLeft:"0",

      }}
    >
      <table style={{ border: "1px solid #ddd" }}>
        <tbody>

      {Array.from({ length: blankrow }, (_, index) => (
         <tr
         key={"blank_" + index}     
       >
         <td
           key={"minute_" + index}
        
           style={{
             width: "170px",
             height: gridheight + "px",
             padding: "0px",
             position: "relative",    
             boxSizing: "border-box",
             background: "#eee",

           }}
         >
           <div
             style={{
               width: "100%",     
               height:gridheight + "px",
               display: "flex",         
             }}        
           >         
         
           </div>
           </td>
           </tr>
      ))}        


          {hours.map((item, index1) => {
            let v1 = item.v;
            //  let bgcolor1 = "#ccc";
            //     if (fulltime == "1" || item.check == 1) bgcolor1 = "#fff";

            //  let s = 0;

            //if (item.s != null) s = item.s;
            if (v1 <= 9) v1 = "0" + v1;

            return (
              <Fragment key={"hours_" + index1}>
                {item.min.map((item2, index2) => {
                  let v = item2.v;
                 
                  if (v == 0) v = "00";

                  //  if (fulltime == "1" || item2.c == 1) bgcolor = "#fff";
                  let wktimebgcolor = ele.state.colors2[1].color;
                  if (item2.c == 1) wktimebgcolor = "#fff";
                  // let cust = "";
                  let srvtle = item2.srvtle;
                  if (srvtle == null) srvtle = "";
                  // else cust = item2.cust;
                  let bookid = item2.b;
                  if (bookid == null) bookid = "";

                  //  let u = 0; //已用
                  //  if (item2.u != null) u = item2.u;
                  let l = 90; //已用
                  if (item2.l != null) l = item2.l;
                  let rows = Math.ceil(l / MinDawai);
                  let _height = rows * gridheight + 0;

                  let time = "";
                  if (v * 1 == 0 || (v * 1 == 30 && item2.c == 1))
                    time = v1 + ":" + v;
                  //book颜色
                  let bgcolor = ""; //未确认的是蓝色
                  if (bookid != "") {
                   
                    //console.log(time+"bookid"+bookid);

                    // console.log(ele.state.books[bookid]);
                    bgcolor = littleBlueColor; //浅蓝
                    //   srvtle = ele.state.books[bookid].title;
                    //  if (ele.state.books[bookid].userchecked == 1) {//客户确认过的
                    //  if (ele.state.books[bookid].userchecked == 1) {
                    if (ele.state.books[bookid].checked == 1) {
                      bgcolor = checkColor; //前台确认过的紫色,接电话的

                     
                    } else if (ele.state.books[bookid].checked == 2) {
                      bgcolor = "#96f3c0"; //前台打电话未接的 是放鸽子 浅绿
                    }
                    
                    //已完成的 深灰
                    if (ele.state.books[bookid].status == 1)                 
                      bgcolor = checkoutBgcolor;
                    /*  else
                      {
                        bgcolor = checkColor;//前台确认过的紫色
                        if (ele.state.books[bookid].status == 1)//已完成的 深灰
                          bgcolor = "#aaa";
                      } */
                    //   }
                    //  }
                    // else bgcolor = "#bee8f8";//客户未确认的 浅蓝
                  }
                  /*   if (u == 1) {
                    bgcolor = "#ffcc33";
                    if (srvtle != "") bgcolor = "#ff9900";
                  } */

                  let showTitle = false;
                  let fromtime = "";
                  if (srvtle != "") 
                    {                  
                      if (!ele.state.books[bookid]) 
                        {                     
                          srvtle = "";
                        }
                    }
                  let titlebgcolor="";
                  let titleColor="#000";
                  //   let totime = "";
                  if (srvtle != "") {

                  
                     if(ele.state.books[bookid].onlinebook==1)titleColor="red";

                    if (ele.state.books[bookid].servicetitle)
                      srvtle = ele.state.books[bookid].servicetitle;

                    if (ele.state.books[bookid].booklist) {

                      let _booklist = JSON.parse(ele.state.books[bookid].booklist);

                      //多个服务项目的情况
                      titlebgcolor = _booklist[0].bgcolor;
                    
                      if (_booklist.length > 1) {
                      //  srvtle = "";
                     //   console.log("_booklist",_booklist);
                        for (let j = 0; j < _booklist.length; j++) {

                          /* if (j == 0) srvtle = _booklist[j]["servicetitle"];
                          else srvtle = srvtle + " || " + _booklist[j]["servicetitle"];
 */                            
//同一ticket的其它项目放在后面
                            if(_booklist[j]["id"]!=bookid)
                              srvtle = srvtle + " || " + _booklist[j]["servicetitle"];    
                        }                        
                      }
                    }

                    /* if (ele.state.books[bookid].booklist)
                      titlebgcolor =  JSON.parse(ele.state.books[bookid].booklist)[0].bgcolor; */
                    
                    if (ele.state.books[bookid].status == 0&&titlebgcolor!="")//如果有服务背景则应用于没有结束的
                      {
                        bgcolor=titlebgcolor;
                      }
                    showTitle = true;
                    fromtime = v1 + ":" + v;
                    let arr = fromtime.split(":");
                    // let num = Math.ceil(this.state.serviceminutes / MinDawai);
                    //  let firsttime = new Date(2022, 1, 1, arr[0], arr[1]);

                    let _totime = new Date(2022, 1, 1, arr[0], arr[1]);
                    _totime.setTime(_totime.getTime() + l * 60 * 1000);
                    //    totime = _totime.getHours() + ":" + _totime.getMinutes();
                    //  console.log(index2 + "bookid" + bookid);
                  }

                  let moveddiv =
                    "Move_" +
                    username +
                    "_" +
                    periodno +
                    "_" +
                    index +
                    "_" +
                    index1 +
                    "_" +
                    index2;

                  let divid =
                    "Min_" +
                    username +
                    "_" +
                    periodno +
                    "_" +
                    index +
                    "_" +
                    index1 +
                    "_" +
                    index2;

                  let blockpanel = 0;
                  if (
                    ele.state.fixtime[index].hours[index1].min[index2]
                      .blockpanel
                  ) {
                    blockpanel =
                      ele.state.fixtime[index].hours[index1].min[index2]
                        .blockpanel;
                  }
                  let timefontsize = parseInt(gridheight / 2);
                 
                  //if (item2.c == 0)
                  return (
                    <tr
                      key={"mina+" + index2}
                      onDoubleClick={(ref) =>
                        ele.ShowTicket(index, index1, index2, bookid, item2.c)
                      }
                      onPaste={(ref) =>
                        ele.ParseBook(ref, index, index1, index2)
                      }
                      onContextMenu={(ref) =>
                        ele.blockTime(ref, index, index1, index2, 1, bookid)
                      }
                    >
                      <td
                        key={"minute_" + index2}
                        /*  onClick={() =>
                          selectStuffMin(ele, index, index1, index2, u)
                        } */
                        style={{
                          width: "170px",
                          height: gridheight + "px",

                          padding: "0px",
                          position: "relative",
                          textAlign: "left",

                          boxSizing: "border-box",
                          background: wktimebgcolor,
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            borderBottom:
                              item2.c == 1 ? v * 1 == 50?"1px solid #000":"1px solid #ccc" : "0px",
                            color: v * 1 == 30 ? "#e5e5e5" : "#111",
                            paddingLeft: "3px",
                            height:
                              item2.c == 1
                                ? gridheight - 1 + "px"
                                : gridheight + "px",
                            fontSize: timefontsize + "px",

                            verticalAlign: "middle",

                            display: "flex",
                            alignItems: "center",
                            margin: 0,
                            padding: 0,
                          }}
                          id={divid}
                        >
                          {time}
                        </div>

                        {/*  设置block开始 */}
                        {blockpanel == 1 && (
                          <div
                            style={{
                              position: "absolute",
                              width: "150px",
                              height: "350px",
                              border: "1px solid #ddd",
                              backgroundColor: "#f6f6f6",
                              //borderTop: "2px solid #ddd",
                              // borderBottom: "2px solid #ddd",
                              top: 0,
                              left: 8,
                              zIndex: 100,
                              padding: "5px",
                            }}
                          >
                            <div
                              style={{
                                float: "left",
                                width: "90%",
                                textAlign: "center",
                              }}
                            >
                              Set Block Of {username}
                            </div>
                            <div
                              style={{
                                float: "left",
                                width: "10%",
                                fontSize: "22px",
                              }}
                              onClick={(ref) =>
                                ele.blockTime(
                                  ref,
                                  index,
                                  index1,
                                  index2,
                                  0,
                                  bookid
                                )
                              }
                            >
                              X
                            </div>

                            <div
                              style={{
                                paddingTop: "15px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                              }}
                            >
                              {getDateFromPeriodno(periodno)}{" "}
                            </div>

                            <div
                              style={{
                                paddingTop: "15px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <select
                                name="fromhour"
                                style={{ height: "30px" }}
                                onChange={(ref) =>
                                  ele.changeBlockfield(
                                    ref,
                                    index,
                                    index1,
                                    index2,
                                    "fromhour"
                                  )
                                }
                                value={
                                  ele.state.fixtime[index].hours[index1].min[
                                    index2
                                  ].fromhour
                                    ? ele.state.fixtime[index].hours[index1]
                                      .min[index2].fromhour
                                    : v1
                                }
                              /*    onChange={this.changeService} */
                              >
                                {ele.state.hours.map((item, index) => {
                                  return (
                                    <option
                                      key={"fromhour" + index}
                                      value={"" + item}
                                    >
                                      {item}
                                    </option>
                                  );
                                })}
                              </select>
                              <span
                                style={{ width: "25px", textAlign: "center" }}
                              >
                                :
                              </span>
                              <select
                                name="fromminute"
                                style={{ height: "30px" }}
                                onChange={(ref) =>
                                  ele.changeBlockfield(
                                    ref,
                                    index,
                                    index1,
                                    index2,
                                    "fromminute"
                                  )
                                }
                                value={
                                  ele.state.fixtime[index].hours[index1].min[
                                    index2
                                  ].fromminute
                                    ? ele.state.fixtime[index].hours[index1]
                                      .min[index2].fromminute
                                    : v
                                }
                              /*    onChange={this.changeService} */
                              >
                                {ele.state.minutes.map((item, index) => {
                                  return (
                                    <option
                                      key={"fromminute" + index}
                                      value={"" + item}
                                    >
                                      {item}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              -
                            </div>
                            <div
                              style={{
                                paddingTop: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <select
                                name="tohour"
                                style={{ height: "30px" }}
                                onChange={(ref) =>
                                  ele.changeBlockfield(
                                    ref,
                                    index,
                                    index1,
                                    index2,
                                    "tohour"
                                  )
                                }
                                value={ele.getWorkTimeToHour(
                                  index,
                                  index1,
                                  index2
                                )}
                              /*    onChange={this.changeService} */
                              >
                                {ele.state.hours.map((item, index) => {
                                  return (
                                    <option
                                      key={"tohours" + index}
                                      value={"" + item}
                                    >
                                      {item}
                                    </option>
                                  );
                                })}
                              </select>
                              <span
                                style={{ width: "25px", textAlign: "center" }}
                              >
                                :
                              </span>
                              <select
                                name="tominute"
                                style={{ height: "30px" }}
                                onChange={(ref) =>
                                  ele.changeBlockfield(
                                    ref,
                                    index,
                                    index1,
                                    index2,
                                    "tominute"
                                  )
                                }
                                value={
                                  ele.state.fixtime[index].hours[index1].min[
                                    index2
                                  ].tominute
                                    ? ele.state.fixtime[index].hours[index1]
                                      .min[index2].tominute
                                    : "00"
                                }
                              /*    onChange={this.changeService} */
                              >
                                {ele.state.minutes.map((item, index) => {
                                  return (
                                    <option
                                      key={"tominutes" + index}
                                      value={"" + item}
                                    >
                                      {item}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div
                              style={{
                                paddingTop: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <span
                                onClick={(ref) =>
                                  ele.blockAllday(index, index1, index2)
                                }
                                style={{ cursor: "pointer", padding: "5px" }}
                              >
                                All Day
                              </span>
                            </div>

                            <div
                              className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                              style={{
                                display: "flex",
                                alignContent: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",

                                height: "30px",
                                lineHeight: "30px",
                                verticalAlign: "middle",
                              }}
                            >
                              Work On
                              <input
                                type="radio"
                                name="rdworkon"
                                style={{
                                  width: "18px",
                                  justifyContent: "center",
                                  verticalAlign: "middle",
                                  height: "100%",
                                  margin: "0px",

                                  marginLeft: "3px",
                                  marginRight: "15px",
                                }}
                                checked={ele.getWorkOnValue(
                                  index,
                                  index1,
                                  index2,
                                  1
                                )}
                                onChange={(ref) =>
                                  ele.setWorkOnRadio(
                                    ref,
                                    index,
                                    index1,
                                    index2,
                                    1
                                  )
                                }
                              ></input>
                              Block
                              <input
                                checked={ele.getWorkOnValue(
                                  index,
                                  index1,
                                  index2,
                                  0
                                )}
                                onChange={(ref) =>
                                  ele.setWorkOnRadio(
                                    ref,
                                    index,
                                    index1,
                                    index2,
                                    0
                                  )
                                }
                                type="radio"
                                name="rdworkon"
                                style={{
                                  width: "18px",

                                  height: "100%",
                                  margin: "0px",

                                  marginLeft: "3px",
                                }}
                              ></input>
                            </div>

                            <div
                              style={{
                                paddingTop: "15px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <input
                                type="button"
                                name="butblock"
                                value=" Set "
                                style={{
                                  width: "150px",
                                  backgroundColor: bookButtonColor,
                                  color: "white",
                                }}
                                className="add"
                                onClick={() =>
                                  ele.saveBlock(
                                    username,
                                    periodno,
                                    index,
                                    index1,
                                    index2
                                  )
                                }
                              ></input>
                            </div>
                          </div>
                        )}

                        {/*  设置block结束  */}


                        {showTitle && (
                          <div
                            title={srvtle}
                            onMouseDown={(ref) =>
                              ele.dragBook(ref, moveddiv, bookid,index,index1,index2)
                            }
                            onMouseMove={(ref) =>
                              ele.mousemoveBook(ref, moveddiv, bookid)
                            }

                        /*     onMouseUp={(ref) =>
                              ele.mouseRelease(ref, moveddiv, bookid)
                            } */

            
                            
                            id={moveddiv}
                            style={{

                              position: "absolute",
                              width: "170px",

                              border: "1px solid #ddd",
                              //borderTop: "2px solid #ddd",
                              // borderBottom: "2px solid #ddd",
                              /*  top:
                                ele.state.dragingbookid == bookid * 1
                                  ? ele.state.top2
                                  : 0,
                              left:
                                ele.state.dragingbookid == bookid * 1
                                  ? ele.state.left2
                                  : 50, */
                              top: 0,
                              left: 0,
                              zIndex: 99,

                              /*  backgroundColor: bgcolor, */

                              backgroundColor: convertToRgba(bgcolor, 0.9),
                              height: _height,
                              padding: "0px",
                              paddingTop: "0px",
                              display: "flex",
                              flexDirection: "column",

                              /*  cursor:
                                ele.state.isDragging2 &&
                                bookid == ele.state.dragingbookid
                                  ? "grabbing"
                                  : "grab", */
                              /*  cursor: ele.state.isDragging2
                                ? "grabbing"
                                : "grab", */
                            }}
                          >


  {/* 按钮开始 */}
  {ele.state.dragingbookid == bookid * 1 ? (
                              <div
                                style={{
                                  textAlign: "left",
                                  marginTop: "auto",
                                  marginRight: "3px"
                                }}
                              >
                                <input
                                  type="button"
                                  value=" Put Here "
                                  onClick={(ref) => {
                                    ele.savepos(
                                      moveddiv,

                                      bookid,
                                      index,
                                      index1,
                                      index2
                                    );
                                  }}

                                  className="bookbutton"
                                  style={{
                                    backgroundColor: bookButtonColor,
                                  }}

                                ></input>
                                <input
                                  type="button"
                                  value=" Reset "
                                  onClick={(ref) => {
                                    ele.resetPos(moveddiv);
                                  }}

                                  className="bookbutton"
                                  style={{
                                    backgroundColor: bookButtonColor,
                                  }}

                                ></input>
                              </div>
                            ) : (
                              <div
                                style={{
                                  textAlign: "right",
                                  marginTop: "0px",

                                  marginRight: "3px",

                                  height: "17px",

                                }}
                              >
                                {ele.state.pubdowndraging &&
                                  ele.state.books[bookid].status * 1 == 0 &&
                                  (ele.state.books[bookid].checked == 0 ||
                                    ele.state.books[bookid].checked == 2) && (
                                    <input
                                    onClick={() =>
                                      ele.checkBook(bookid, "checked", 1)
                                    }
                                     /*  onClick={() => {
                                        ele.setState({
                                          checkpanel: 1,
                                          editremarkbookid: bookid,
                                        });
                                      
                                      }} */
                                      type="button"
                                      value="Check"
                                      className="bookbutton"
                                      style={{
                                        backgroundColor: bookButtonColor,
                                      }}
                                    ></input>
                                  )}

                                {myusertype*1>=2&&ele.state.showcustomercheck && ele.state.pubdowndraging &&
                                  ele.state.books[bookid].status * 1 == 0 && (
                                    <input
                                      onClick={() => {
                                        ele.checkBook(
                                          bookid,
                                          "userchecked",
                                          (ele.state.books[bookid].userchecked *
                                            1 +
                                            1) %
                                          2
                                        );
                                      }}
                                      type="button"
                                      value={
                                        ele.state.books[bookid].userchecked == 0
                                          ? "I Confirm"
                                          : "Un Confirm"
                                      }
                                      className="bookbutton"
                                      style={{
                                        backgroundColor: bookButtonColor,
                                      }}

                                    ></input>
                                  )}
                              </div>
                            )}

                            {/* 按钮结束  */}
                            
                          

                              
                           
                           



                            {/* 信息开始 */}

                            {myusertype*1>=2&&<div
                              className="bookcontent"
                              style={{
                                textAlign: "left",
                                height: "15px",
                                fontSize: "11px",
                                paddingLeft: "2px",
                                fontWeight: "bold"
                              }}
                            >
                              {ele.state.books[bookid].firstname +
                                " " +
                                ele.state.books[bookid].lastname}
                            </div>}

                            <div>
                              <div
                                className="bookcontent"
                                style={{
                                  textAlign: "left",
                                  fontWeight: "normal",
                                  fontSize: "11px",
                                  height: "16px",
                                  paddingLeft: "2px",
                                  float: "left", width: "50%",fontWeight:"bold"

                                }}
                              >
                                {myusertype*1>=2?ele.state.books[bookid].telephone:ele.state.books[bookid].firstname +
                                " " +
                                ele.state.books[bookid].lastname}
                              </div>
                              <div
                                className="bookcontent"
                                style={{
                                  textAlign: "left",
                                  fontSize: "9px",
                                  height: "15px",
                                  paddingLeft: "2px",
                                  float: "right", width: "50%"
                                }}
                              >
                                {ele.state.books[bookid].fromtime} -{" "}
                                {ele.state.books[bookid].totime}
                              </div>
                            </div>

                            <div style={{
                              paddingLeft: "2px",
                              border: "0px solid red", height: "15px", fontSize: "11px",
                              color:titleColor,fontWeight:ele.state.books[bookid].onlinebook==1?'bold':'normal'
                              /* backgroundColor:titlebgcolor!=''?titlebgcolor:"" */
                            }}>
{/*  <pre>{ JSON.stringify(ele.state.books[bookid], null, 2) }</pre> */}
                          {srvtle}
                            </div>
                            {/* 信息结束  */}

                            {/* 头部开始 */}
                            <div
                              style={{
                                float: "right",

                                width: "100%",
                                justifyContent: "right",
                                display: "flex",
                                height: "25px",
                                lineHeight: "25px",
                                position: "relative",
                                alignItems: "center",
                                cursor: "grab",
                                padding: "0px",

                                backgroundColor: ele.state.books[bookid].fixedemployee == 1 ? fixedColor : ""
                              }}
                            >

                              {/* 开始check 暂时删除*/}
                              {ele.state.checkpanel == 1 &&
                                ele.state.editremarkbookid == bookid && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      width: "155px",
                                      height: "230px",
                                      border: "1px solid #ddd",
                                      backgroundColor: "#f6f6f6",
                                      //borderTop: "2px solid #ddd",
                                      // borderBottom: "2px solid #ddd",
                                      top: "3px",
                                      left: "0px",
                                      zIndex: 100,
                                      padding: "5px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        padding: "15px",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                      }}
                                    >
                                      Confirmed:
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignContent: "center",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                        width: "100%",

                                        height: "30px",
                                        lineHeight: "30px",
                                        verticalAlign: "middle",
                                        paddingLeft: "25px",
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        name="rdworkon"
                                        style={{
                                          width: "18px",
                                          justifyContent: "center",
                                          verticalAlign: "middle",
                                          height: "100%",
                                          margin: "0px",

                                          marginLeft: "3px",
                                          marginRight: "5px",
                                        }}
                                        checked={
                                          ele.state.books[bookid].newchecked
                                            ? ele.state.books[bookid]
                                              .newchecked == 1
                                            : false
                                        }
                                        onChange={(ref) =>
                                          ele.setCheckStatus(bookid, 1)
                                        }
                                      ></input>{" "}
                                      By Telephone
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        alignContent: "center",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                        width: "100%",

                                        height: "30px",
                                        lineHeight: "30px",
                                        verticalAlign: "middle",
                                        paddingLeft: "25px",
                                      }}
                                    >
                                      <input
                                        checked={
                                          ele.state.books[bookid].newchecked
                                            ? ele.state.books[bookid]
                                              .newchecked == 2
                                            : false
                                        }
                                        onChange={(ref) =>
                                          ele.setCheckStatus(bookid, 2)
                                        }
                                        type="radio"
                                        name="rdworkon"
                                        style={{
                                          width: "18px",

                                          height: "100%",
                                          margin: "0px",

                                          marginLeft: "3px",
                                          marginRight: "5px",
                                        }}
                                      ></input>
                                      No answered
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        paddingTop: "15px",

                                        justifyItems: "center",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <input
                                        type="button"
                                        name="butsaveremark"
                                        value=" Submit "
                                        style={{
                                          width: "100px",
                                          backgroundColor: bookButtonColor,
                                          color: "white",
                                        }}
                                        className="add"
                                        onClick={() =>
                                          ele.checkBook(bookid, "checked", "")
                                        }
                                      ></input>
                                    </div>
                                    <div
                                      style={{
                                        width: "100%",
                                        textAlign: "right",
                                        marginTop: "8px"
                                      }}
                                    >
                                      <span
                                        style={{
                                          float: "right",
                                          width: "100px",
                                          fontSize: "22px",
                                          paddingRight: "15px",
                                        }}
                                        onClick={(ref) =>
                                          ele.setState({
                                            checkpanel: 0,
                                            editremarkbookid: "",
                                          })
                                        }
                                      >
                                        X
                                      </span>
                                    </div>
                                  </div>
                                )}
       {/*    结束check */}


{/* 剪刀 */}
                              {myusertype*1>=2&&ele.state.pubdowndraging && (
                                <span
                                  title="Cute And Paste"
                                  className="iconfont fanhui"
                                  onClick={() => {
                                    ele.MoveBook(
                                      bookid,
                                      ele.state.books[bookid].minutes
                                    );
                                  }}
                                  style={{
                                    marginRight: "15px",
                                    textAlign: "center",

                                    fontSize: "15px",
                                    verticalAlign: "center",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#555",
                                  }}
                                >
                                  &#xe603;
                                </span>
                              )}

                              {myusertype*1>=2&&ele.state.books[bookid].fixedemployee == 1 && (
                                <span
                                  title={
                                    "Fixed to " +
                                    ele.state.books[bookid].employee +
                                    ""
                                  }
                                  className="iconfont fanhui"
                                  style={{
                                    marginRight: "15px",
                                    textAlign: "center",

                                    fontSize: "15px",
                                    verticalAlign: "center",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#red",
                                  }}
                                >
                                  &#xe6f5;
                                </span>
                              )}
                              {myusertype*1>=2&&<span
                                title="ReBook"
                                className="iconfont fanhui"
                                onClick={() => {
                                  ele.ReBook(
                                    index,
                                    index1,
                                    index2,
                                    bookid,
                                    1,
                                    1,
                                    srvtle
                                  );
                                }}
                                style={{
                                  marginRight: "15px",
                                  textAlign: "center",

                                  fontSize: "15px",
                                  verticalAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#555",
                                }}
                              >
                                &#xec7a;
                              </span>}
                              {myusertype*1>=2&&ele.state.books[bookid].sentmsg == 1 && (
                                <img
                                  title={
                                    ele.state.books[bookid].userchecked == 1
                                      ? "Customer Confirmed"
                                      : "Message sent, Customer Not Confirm"
                                  }
                                  style={{
                                    width: "19px",
                                    height: "19px",
                                    marginRight: "15px",
                                    fontSize: "15px"
                                  }}
                                  src={
                                    ele.state.books[bookid].userchecked == 1
                                      ? msgokpic
                                      : msgpic
                                  }
                                ></img>
                              )}

                              {myusertype*1>=2&&<span
                                title={
                                  ele.state.books[bookid].userremark == ""
                                    ? ""
                                    : ele.state.books[bookid].userremark
                                }
                                className="iconfont fanhui"
                                onClick={() => {
                                  ele.ShowRemark(bookid, 1);
                                }}
                                style={{
                                  background: "#fff",
                                  width: "17px",
                                  height: "17px",
                                  borderRadius: "8px",
                                  marginRight: "15px",
                                  textAlign: "center",

                                  fontSize: "20px",
                                  verticalAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color:
                                    ele.state.books[bookid].userremark == ""
                                      ? "#555"
                                      : "red",
                                }}
                              >
                                &#xe76e;
                              </span>}

                              {myusertype*1>=2&&ele.state.pubdowndraging && (
                                <span
                                  title={"Delete book(" + bookid + ")"}
                                  onClick={() => {
                                    ele.delbook(
                                      bookid,
                                      -1,
                                      ele.state.books[bookid].periodno
                                    );
                                  }}
                                  style={{
                                    textAlign: "right",

                                    fontSize: "19px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  {" "}
                                  X{" "}
                                </span>
                              )}

                               </div>
 {/* 头部结束  */}
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
/* function showSrv(srvtle, eleid, show) {
  if (srvtle == "") return;

  if (show == 1) {
    document.getElementById(eleid).style.display = "block";
  } else document.getElementById(eleid).style.display = "none";
} */
export function LunBo(AJAROOT, arrimg) {
  return (
    <div id="myCarousel" className="carousel slide">
      <ol className="carousel-indicators">
        {arrimg.map((item, index) => {
          return (
            <li
              key={"lunbo" + index}
              data-target="#myCarousel"
              data-slide-to={"" + index + ""}
              className={index === 0 ? "active" : ""}
            ></li>
          );
        })}
      </ol>

      <div className="carousel-inner">
        {arrimg.map((item, index) => {
          return (
            <div className={index === 0 ? "item active" : "item"} key={item}>
              <img src={AJAROOT + item} alt="Second slide" />
            </div>
          );
        })}
      </div>

      <a
        className="left carousel-control"
        href="#myCarousel"
        role="button"
        data-slide="prev"
      >
        <span
          className="glyphicon glyphicon-chevron-left"
          aria-hidden="true"
        ></span>
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="right carousel-control"
        href="#myCarousel"
        role="button"
        data-slide="next"
      >
        <span
          className="glyphicon glyphicon-chevron-right"
          aria-hidden="true"
        ></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  );
}

export function getUserStatus(axios) {
  var userstatus = [];
  var geturl = AJAROOT + "/getaja/view.aspx?id=";

  axios.get(geturl).then((res) => {
    const result = res.data;
    // this.setState({}); /**/
  });
  return userstatus;
}

export function Aja(token, lan) {
  return axios.create({
    baseURL: AJAROOT,
    timeout: 5000,
    headers: {
      "X-Token": token,
      "X-Language": lan,
    },
  });
}

export function mheader(title, cartnum) {
  return (
    <div className="container wrap">
      <div className="row " style={{ height: "55px", lineHeight: "55px" }}>
        <div
          className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
          onClick={() => {
            window.history.back(-1);
          }}
        >
          <span className="iconfont fanhui">&#xe625;</span>
        </div>
        <div
          className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textcenter "
          style={{ fontSize: "22px", fontWeight: "bolder" }}
        >
          {title}
          {/* {this.state.title} {this.state.anlibm} */}
        </div>
        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 cartimg">
          <Link to="/cart" key="mycart">
            <span className="icon iconfont" style={{ fontSize: "40px" }}>
              &#xe70b;
            </span>
            <span className="cartnum">{cartnum}</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export function checkUserLogin(props, myusername) {
  if (myusername == null) myusername = "";
  if (typeof myusername == "undefined") myusername = "";
  if (myusername == "undefined") myusername = "";
  if (myusername == undefined) myusername = "";

  if (myusername == "null") myusername = "";

  if (myusername === "") {
    return (
      <div className="nologin">
        <Link to="/login">Please Login</Link>
      </div>
    );
  } else return "";
}

export function getUserIndexData(ele, myusername, ukey) {
  if (myusername == "") return;
  var that = ele;
  var geturl =
    AJAROOT +
    "/getaja/user.aspx?u=" +
    myusername +
    "&ukey=" +
    encodeURIComponent(ukey) +
    "";
console.log(geturl);
  axios.get(geturl).then((res) => {
    const result = res.data;

    // this.setState({}); /**/
    let fkfs = "1";
    if (result.hba * 1 > 0) fkfs = "A";
    var lastname = result.lastname;
    var username = result.username;

    if (lastname === "") lastname = username;
    that.setState({
      lastname: lastname,
      regtime: result.regtime,
      hba: result.hba,
      hbb: result.hbb,
      hbc: result.hbc,
      xfze: result.xfze,
      firstname: result.firstname,
      lastname: result.lastname,
      telephone: result.telephone,
      totaljj: result.totaljj,
      usergrade: result.usergrade,
      usertype: result.usertype,
      usertypename: result.usertypename,
      // basicform: result.basicform,
      serviceform: result.serviceform,
    });
  });
}

export function MyToast(MyToast) {
  let height = MyToast.height;
  let width = MyToast.width;
  if (height == undefined) height = 100;
  if (width == undefined) width = 80;

  let title = MyToast.title;
  //let txttitleheight = height;
  //MyToast.status = "success";
  //if (MyToast.status != "") txttitleheight = height / 2;
  let img;
  let showimg = false;
  if (MyToast.status == "success") {
    showimg = true;
    img = gouPic;
  } else {
    showimg = true;
    img = errorPic;
  }
  return (
    <div
      style={{
        zIndex: 99999,
        width: "100%",
        height: height,

        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        position: "fixed",
        top: 300,
        left: "50%",
        right: "50%",

        width: width,
      }}
    >
      <div
        style={{
          opacity: 0.8,
          backgroundColor: "#000",
          borderRadius: 5,
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          width: width,
          height: height,
        }}
      >
        {showimg && (
          <div
            style={{
              height: 50,
              padding: 10,
              textAlign: "center",
            }}
          >
            <img
              src={img}
              style={{
                height: 30,
                width: 30,
              }}
            />
          </div>
        )}
        <div
          style={{
            color: "#ffffff",

            textAlign: "center",

            verticalAlign: "center",
          }}
        >
          {title}
        </div>
      </div>
    </div>
  );
}
export function MyToastShow(
  ele,
  seconds,
  title,
  width,
  height,
  status = "",
  key = ""
) {
  let mytoast = ele.state.mytoast;
  if (width == 0) width = 150;
  if (height == 0) height = 80;
  mytoast.width = width;
  mytoast.height = height;
  mytoast.title = title;
  mytoast.status = status;
  mytoast.key = key;
  mytoast.show = true;

  ele.setState({
    // bottomAdImg: result.pic,
    //bottomAdImgHeight: _height,

    //  bottomAdLink: result.link ? result.link : '/',
    mytoast: mytoast,
  });
  let num = 0;
  let timer = null;
  timer = setInterval(() => {
    num++;

    // if(num>=seconds)
    //   console.log('把一个定时器的引用挂在this上');
    if (num >= seconds) {
      clearInterval(timer);

      mytoast.show = false;
      // mytoast.title = '';
      ele.setState({
        mytoast: mytoast,
      });
    }
  }, 1000);

  return;
}
export function formatDate(date, fmt) {
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
}
export function showStuffTime(ele, usertimelist) {
  return (
    <div style={{ margin: "5px", padding: "5px", textAlign: "Left" }}>
      {usertimelist.map((item, index) => {
        //  const date2 = new Date(arr[0], arr[1], item2);

        let hours = item.hours;
        // if (hours == null) hours = [];
        //hours = JSON.parse(hours);
        if (hours != null) {
          // hours = JSON.parse(hours);
          var periodno = item.periodno;
          periodno =
            periodno.substring(0, 4) +
            "-" +
            periodno.substring(4, 6) +
            "-" +
            periodno.substring(6);
          return (
            <div
              key={"fixuer" + index}
              style={{
                padding: "0px",
                border: "1px solid #ddd",
                width: "275px",

                display: "inline-block",
                float: "left",
                marginRight: "5px",
              }}
            >
              <div className="textcenter">{item.zsxm} {item.blankrow}</div>
              <div>{showStuffDayHours(ele, item.username, hours, index,item.blankrow)}</div>
            </div>
          );
        } else {
          return (
            <div
              key={"fixuer" + index}
              style={{
                padding: "0px",
                border: "1px solid #ddd",
                width: "275px",

                display: "inline-block",
              }}
            >
              {" "}
              <div className="textcenter">
                {item.zsxm} {periodno}
              </div>
              <div>Off Work</div>
            </div>
          );
        }
      })}
    </div>
  );
}
export function userMenuForm(ele, username) {
  let myusertype=ele.props.myusertype*1;
  if(myusertype==1)
  {
 //   console.log(ele.state.defaultform.toLowerCase(),ele.state.historynote);
    return (
      <>
        <div className={"row"} style={{}}>
          <div
            className="col-xs-9 col-sm-9 col-md-9 col-lg-9 textcenter"
            style={{ fontWeight: "bold" }}
          >
            notehistory of {username}
          </div>
          <div
            className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright"
            onClick={() =>
              ele.setState({
                usermenu: 0,
              })
            }
            style={{ fontSize: "25px", paddingRight: "25px" }}
          >
            X
          </div>
        </div>
  
  {(ele.state.defaultform.toLowerCase() == "notehistory" ||
          ele.state.defaultform.toLowerCase() == "treatehistory") &&
          ele.state.historynote.length > 0 && (
            <div
              style={{
                overflowX: "hidden",
                paddingTop: "15px",
                overflowY: "scroll",
                height: "500px",
              }}
            >
              {ele.state.historynote.map((item2, index2) => {
                return (
                  <div
                    className="row"
                    key={"hbook_" + index2}
                    style={{ padding: "30px" }}
                  /*  onClick={() => ele.gotoBook(item2.periodno, item2.id)} */
                  >
                    {item2.dt} - {item2.servicetitle}
                 
                  </div>
                );
              })}
            </div>
          )}
      </>
    );
  }

  if (ele.state.customerform == null) return <></>;
  const customerform = ele.state.customerform.slice().reverse();
  return (
    <>
      <div className={"row"} style={{}}>
        <div
          className="col-xs-9 col-sm-9 col-md-9 col-lg-9 textcenter"
          style={{ fontWeight: "bold" }}
        >
          Information of {username}
        </div>
        <div
          className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright"
          onClick={() =>
            ele.setState({
              usermenu: 0,
            })
          }
          style={{ fontSize: "25px", paddingRight: "25px" }}
        >
          X
        </div>
      </div>

      <div className="row" style={{}}>
        <div
          className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter"
          style={{
            height: "30px",
          }}
        >
          <div
            className={
              ele.state.defaultform == "basicform"
                ? "currentuserMenu"
                : "userMenu"
            }
            onClick={() => {
              ele.setState({ defaultform: "basicform" });
            }}
          >
            Basic Info
          </div>
          {ele.state.haveinsurance == 1 && (
            <div
              className={
                ele.state.defaultform == ele.state.basicform
                  ? "currentuserMenu"
                  : "userMenu"
              }
              onClick={() => {
                ele.setState({ defaultform: ele.state.basicform }, () => {
                  getSpaData(ele, username);
                });
              }}
            >
             {/*  Required Info */}
             Acupuncture
            </div>
          )}
          {ele.state.haveinsurance == 1 &&
            customerform.map((item2, index2) => {
              let path = item2["key"].toLowerCase();
              let color = "#111";
              if (path == "health") {
                let clientsign = ele.state.clientsign;
                if (clientsign == null) clientsign = "";
                if (clientsign == "") color = bookButtonColor;
              }
              if(path=='spa')return;

              return (
                <div
                  className={
                    ele.state.defaultform == item2.key
                      ? "currentuserMenu"
                      : "userMenu"
                  }
                  key={"form" + index2}
                  style={{
                    color: color,
                  }}
                  onClick={() => {
                    ele.setState({ defaultform: item2.key }, () => {
                      getData(ele, item2.key, username);
                    });
                  }}
                >
                  {FormKey[item2.key]}
                </div>
              );
            })}

          <div
            className={
              ele.state.defaultform == "historybook"
                ? "currentuserMenu"
                : "userMenu"
            }
            onClick={() => {
              ele.setState({ defaultform: "historybook" }, () => {
                ele.getHistoryBooks(username, 1);
              });
            }}
          >
            History Books
          </div>

          <div
            className={
              ele.state.defaultform == "upcomingbook"
                ? "currentuserMenu"
                : "userMenu"
            }
            onClick={() => {
              ele.setState({ defaultform: "upcomingbook" }, () => {
                ele.getHistoryBooks(username, 0);
              });
            }}
          >
            Upcoming Books
          </div>
        </div>
      </div>
      {ele.state.defaultform == "basicform" && (
        <div
          style={{ overflowX: "hidden", overflowY: "scroll", height: "500px" }}
        >
          {BasicForm(ele)}
        </div>
      )}
      {ele.state.defaultform.toLowerCase() == "spa" && (
        <div
          style={{ overflowX: "hidden", overflowY: "scroll", height: "500px" }}
        >
          {SpaForm(ele)}
        </div>
      )}
      {ele.state.defaultform.toLowerCase() == "eclaim" && (
        <div
          style={{ overflowX: "hidden", overflowY: "scroll", height: "500px" }}
        >
          {EclaimForm(ele)}
        </div>
      )}
      {ele.state.defaultform.toLowerCase() == "health" && (
        <div
          style={{ overflowX: "hidden", overflowY: "scroll", height: "500px" }}
        >
          {HealthForm(ele)}
        </div>
      )}
      {(ele.state.defaultform.toLowerCase() == "historybook" ||
        ele.state.defaultform.toLowerCase() == "upcomingbook") &&
        ele.state.historybooks.length > 0 && (
          <div
            style={{
              overflowX: "hidden",
              paddingTop: "15px",
              overflowY: "scroll",
              height: "500px",
            }}
          >
            {ele.state.historybooks.map((item2, index2) => {
              return (
                <div
                  className="row"
                  key={"hbook_" + index2}
                  style={{ padding: "10px" }}
                /*  onClick={() => ele.gotoBook(item2.periodno, item2.id)} */
                >
                  <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                    {item2.servicetitle}
                  </div>

                  <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                    {item2.periodno} {item2.fromtime} -{item2.totime}
                  </div>
                  <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    {/*  Payed: ${item2.realpay + " "} */}
                    Tips: ${item2.tip}
                  </div>
                  <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span
                      style={{
                        display: "inline-block",
                        float: "left",
                      }}
                    >
                      {item2.employee}
                    </span>

                    <span
                      onClick={() => ele.delbook(item2.id, index2, "")}
                      className="icon iconfont"
                      style={{
                        display: "inline-block",
                        float: "left",
                        marginLeft: "15px",
                      }}
                    >
                      &#xe658;
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        )}

{(ele.state.defaultform.toLowerCase() == "notehistory" ||
        ele.state.defaultform.toLowerCase() == "treatehistory") &&
        ele.state.historynote.length > 0 && (
          <div
            style={{
              overflowX: "hidden",
              paddingTop: "15px",
              overflowY: "scroll",
              height: "500px",
            }}
          >
            {ele.state.historynote.map((item2, index2) => {
              return (
                <div
                  className="row"
                  key={"hbook_" + index2}
                  style={{ padding: "10px" }}
                /*  onClick={() => ele.gotoBook(item2.periodno, item2.id)} */
                >
                  
               
                </div>
              );
            })}
          </div>
        )}
    </>
  );
}
function getData(ele, form, username) {

  if (form.toLowerCase() == "eclaim") {
    getEclaimData(ele, username);
  } else if (form.toLowerCase() == "health") {

    getHealthData(ele, username);
  }
}
export function turnBirth(_birthday) {
  let birthday = _birthday;
  if (birthday == null) birthday = "";
  if (birthday != "") {
    birthday = new Date(birthday);
  }
}
export function getCustomerData(ele, username) {
  const { myusername, ukey } = ele.props;

  var geturl =
    AJAROOT +
    "/getaja/xx.aspx?u=" +
    myusername +
    "&ukey=" +
    encodeURIComponent(ukey) +
    "&action=customerinfo&username=" +
    username +
    "";
  //console.log(geturl);
  axios.get(geturl).then((res) => {
    const result = res.data;
console.log(result);
    // this.setState({}); /**/

    var firstname = result.firstname;
    var lastname = result.lastname;
    let birthday = result.birthday;
    if (birthday == null) birthday = "";
   if (birthday != "") {
      let _birthday = new Date(birthday);
      let targetDate = new Date(1990, 0, 1, 0, 0, 0); 
      if (_birthday.getTime() === targetDate.getTime()) birthday="";
      else
      {
        birthday=getPeriodnoFromDate_YYYYMMDD(_birthday);
      }
    } 
    // if (zsxm === "") zsxm = username;
    ele.setState({
      customerform: result.customerform,
      basicform: result.basicform,
      firstname: firstname,
      lastname: lastname,
      telephone: result.telephone,
      address: result.address,
      city: result.city,
      province: result.province,
      postal: result.postal,
      haveinsurance: result.haveinsurance,
      clientsign: result.clientsign,
      birthday: birthday,
      email: result.email,
    });
  });
}

export function ticketForm(ele) {//book表单
  let myusertype= ele.props.myusertype*1

  let title = "Add Book";
  if (ele.state.editid != "") {
    if (ele.state.rebook == 1) {
      title = "ReBook";
    } else
    {
      title = "Edit Ticket";
      if(myusertype==1)title="View Ticket";
    } 
  }
  //console.log(ele);
  //ele.setJine(ele.state.books[ele.state.editid].aftertaxprice);
  return (
    <>
      <div
        className={"row"}
        style={{ marginBottom: "15px", margin: 0, padding: 0 }}
        onMouseDown={(ref) => ele.handleMouseDown(ref)}
        onMouseMove={(ref) => ele.handleMouseMove(ref)}
        onMouseUp={(ref) => ele.handleMouseUp(ref)}
        onMouseLeave={(ref) => ele.handleMouseLeave(ref)}
      >
        <div
          className="col-xs-9 col-sm-9 col-md-9 col-lg-9 textcenter"
          style={{ fontWeight: "bold", fontSize: "17px" }}
        >
          {title} {ele.state.editid != "" ? "[" + ele.state.editid + "]" : ""}
        </div>
        <div
          className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright"
          style={{ fontWeight: "bold", fontSize: "23px", paddingRight: "15px" }}

          onClick={() =>
            ele.CloseTickeForm()
          }
        >
          X
        </div>
      </div>
      <div
        style={{
          border: "1px solid #ccc",
          borderRadius: "6px",
          padding: "5px",
        }}
      >
        <div className="row" style={{ display: "flex", alignItems: "center" }}>
          <div
            className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright"
            style={{ height: "30px", lineHeight: "30px", verticalAlign: "top" }}
          >
            * Customer:
          </div>
          <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 ">
            {ele.state.username != "" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "30px",
                    lineHeight: "30px",
                    overflow: "hidden",
                  }}
                >
                 {myusertype>=2&& <span
                    style={{
                      paddingRight: "10px",
                      height: "30px",
                      lineHeight: "30px",
                    }}
                  >
                    Account: {ele.state.username}
                  </span>}
                  {ele.state.name != "" && (
                    <span
                      style={{
                        paddingRight: "10px",
                        height: "30px",
                        lineHeight: "30px",
                      }}
                    >
                      Name: {ele.state.name}
                    </span>
                  )}
                  {myusertype>=2&&ele.state.usertel != "" && (
                    <span
                      style={{
                        paddingRight: "10px",
                        height: "30px",
                        lineHeight: "30px",
                      }}
                    >
                      Tel: {ele.state.usertel}
                    </span>
                  )}

                  {myusertype>=2&&
                    <span
                      className="icon iconfont"
                      style={{
                        paddingRight: "10px",
                        paddingLeft: "15px",
                        fontSize: "17px",
                        textAlign: "right",
                        backgroundColor: "#f6f6f6",
                        color: "#333"
                      }}
                      onClick={() => ele.selCust("", "", "")}
                    >
                      {/*   &#xe658; */}【Change Customer】
                    </span>
                  }
                  {myusertype>=2&&ele.state.editid != "" &&
                    ele.state.username !=
                    ele.state.books[ele.state.editid].username && (
                      <input
                        type="button"
                        name="upcustomer"
                        value="Update Customer"
                        className="add"
                        onClick={(ref) => ele.savetricketcol("customer")}
                        style={{
                          height: "30px",
                          lineHeight: "30px",
                          width: "150px",
                          float: "left",
                          background: bookButtonColor,
                          marginLeft: "15px",
                          color: "white",
                        }}
                      ></input>
                    )}
                </div>
              </>
            ) : (
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    autoComplete="off"
                    name="schusername"
                    value={ele.state.schusername}
                    onChange={(ref) => ele.searchCust(ref)}
                    placeholder="Search for telephone or name"
                    style={{
                      borderTop: "0px",
                      borderLeft: "0px",
                      borderRight: "0px",

                      borderBottomColor: "#aaa",
                      borderBottomWidth: "1px",
                      height: "30px",
                      lineHeight: "30px",
                      width: "200px",
                    }}
                  ></input>

                  <span
                    className="icon iconfont"
                    style={{
                      paddingRight: "10px",
                      paddingLeft: "15px",
                      fontSize: "26px",
                      textAlign: "right",
                    }}
                    onClick={() => ele.ShowNewCustomer(1)}
                  >
                    &#xe656;
                  </span>
                </div>

                {ele.state.newcustomerpanel == 1 && (
                  <div
                    style={{
                      position: "absolute",
                      width: "250px",
                      height: "300px",
                      border: "1px solid #ddd",
                      backgroundColor: "#e5e5e5",
                      //borderTop: "2px solid #ddd",
                      // borderBottom: "2px solid #ddd",
                      top: -3,
                      left: -8,
                      zIndex: 100,
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",

                        height: "30px",
                        lineHeight: "30px",
                      }}
                    >
                      <div
                        style={{
                          float: "left",
                          width: "90%",
                          textAlign: "center",
                        }}
                      >
                        New Customer
                      </div>
                      <div
                        style={{
                          float: "left",
                          width: "10%",
                          fontSize: "22px",
                        }}
                        onClick={() => ele.ShowNewCustomer(0)}
                      >
                        X
                      </div>
                    </div>
                    {/*  <div style={{ fontWeight: "bold", textAlign: "center" }}>
                      New Customer{" "}
                    </div> */}

                    <div style={{ padding: "5px" }}>
                      * Telephone:
                      <input
                        value={ele.state.newtelephone}
                        onChange={(ref) => ele.changeField(ref, "newtelephone")}
                        style={{
                          width: "200px",
                          height: "30px",
                        }}
                      ></input>
                    </div>

                    <div style={{ padding: "5px" }}>
                      * First Name:
                      <input
                        value={ele.state.newfirstname}
                        onChange={(ref) => ele.changeField(ref, "newfirstname")}
                        style={{
                          width: "200px",
                          height: "30px",
                        }}
                      ></input>
                    </div>

                    <div style={{ padding: "5px" }}>
                      Last Name:
                      <input
                        value={ele.state.newlastname}
                        onChange={(ref) => ele.changeField(ref, "newlastname")}
                        style={{
                          width: "200px",
                          height: "30px",
                        }}
                      ></input>
                    </div>
                    <div
                      style={{
                        paddingTop: "15px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        type="button"
                        name="butsavenewcustomer"
                        value=" Save "
                        style={{
                          width: "100px",
                          backgroundColor: bookButtonColor,
                          color: "white",
                        }}
                        className="add"
                        onClick={() => ele.SaveNewCustomer()}
                      ></input>

                      {/*    <input
                        type="button"
                        name="butsaveremark"
                        value=" Close "
                        style={{
                          width: "70px",
                          marginLeft: "15px",
                          backgroundColor: "#bbb",
                          color: "white",
                        }}
                        className="add"
                        onClick={() => this.ShowNewCustomer(0)}
                      ></input> */}
                    </div>
                    <div className="row">
                      <div
                        className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter postresult"
                        style={{ width: "70%", marginTop: "10px" }}
                      >
                        {ele.state.postresult}
                      </div>
                    </div>
                  </div>
                )}

                {ele.state.customerlist.length > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "35px",
                      background: "#f6f6f6",
                      zIndex: 9999,
                      maxHeight: "400px",
                      minHeight: "100px",
                      overflowY: "auto",
                      width: "450px",
                      border: "1px solid #ddd",
                    }}
                  >
                    {ele.state.customerlist.map((item, index) => {
                      return (
                        <div
                          key={"schcustomer" + index}
                          style={{
                            padding: "7px",
                            borderBottom: "1px solid #ddd",
                          }}
                          onClick={() =>
                            ele.selCust(
                              item.username,
                              item.zsxm,
                              item.telephone
                            )
                          }
                        >
                          <span>
                            {"" + item.telephone + ", " + item.zsxm + ""}
                          </span>
                          {item.remark != "" && (
                            <span style={{ color: "red", paddingLeft: "15px" }}>
                              【{"" + item.remark + "】"}
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {ele.state.username != "" && (
          <div
            className="row"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright"
              style={{
                height: "30px",
                lineHeight: "30px",
                verticalAlign: "top",
              }}
            ></div>
            <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 ">
              {myusertype>=2&&<div style={{ height: "30px", lineHeight: "30px" }}>
                <span
                  style={{
                    padding: "5px",
                    margin: "5px",
                    color: bookButtonColor,
                    background: "#f6f6f6",
                  }}
                  onClick={() => ele.ShowUser(ele.state.username, 1)}
                >
                  Customer Info
                </span>
                <span
                  style={{
                    background: "#f6f6f6",
                    padding: "5px",
                    margin: "5px",
                    color: bookButtonColor,
                  }}
                  onClick={() => ele.ShowHistory(ele.state.username, 1)}
                >
                  History
                </span>
                <span
                  style={{
                    background: "#f6f6f6",
                    padding: "5px",
                    margin: "5px",
                    color: bookButtonColor,
                  }}
                  onClick={() => ele.ShowHistory(ele.state.username, 0)}
                >
                  Upcoming
                </span>
              </div>}

              {myusertype==1&&<div style={{ height: "30px", lineHeight: "30px" }}>
              
                <span
                  style={{
                    background: "#f6f6f6",
                    padding: "5px",
                    margin: "5px",
                    color: bookButtonColor,
                  }}
                  onClick={() => ele.ShowHistory(ele.state.username, 3)}
                >
                  Note Form
                </span>

                <span
                  style={{
                    background: "#f6f6f6",
                    padding: "5px",
                    margin: "5px",
                    color: bookButtonColor,
                  }}
                  onClick={() => ele.ShowHistory(ele.state.username, 3)}
                >
                  Note History
                </span>

                <span
                  style={{
                    background: "#f6f6f6",
                    padding: "5px",
                    margin: "5px",
                    color: bookButtonColor,
                  }}
                  onClick={() => ele.ShowHistory(ele.state.username, 5)}
                >
                  Treat Form
                </span>
                <span
                  style={{
                    background: "#f6f6f6",
                    padding: "5px",
                    margin: "5px",
                    color: bookButtonColor,
                  }}
                  onClick={() => ele.ShowHistory(ele.state.username, 4)}
                >
                  Treat History
                </span>
              </div>}

            </div>
          </div>
        )}
        <div style={{ height: "15px" }}></div>
        {ele.state.editid != "" && ele.state.rebook == 0 && (
          <div
            className="row"
            style={{ display: "flex", alignItems: "flex-start" }}
          >
            <div
              className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright"
              style={{
                height: "60px",
                lineHeight: "30px",
                verticalAlign: "top",
              }}
            >
              <div>Service:</div>
              {myusertype>=2&&<div
                style={{ fontSize: "22px", paddingRight: "25px" }}
                onClick={() => {
                  ele.addBook(ele.state.editid);
                }}
              >
                +
              </div>}
            </div>
            <div
              className="col-xs-10 col-sm-10 col-md-10 col-lg-10 "
              style={{}}
            >
              {JSON.parse(ele.state.books[ele.state.editid].booklist).map(
                (item, index) => {
                  return (
                    <div
                      className={"row"}
                      key={"ticketbooks_" + index}
                      style={{
                        padding: "8px",
                        background: "#eeeeee",
                        marginRight: "15px",
                      }}
                    >
                      <div style={{ lineHeight: "30px" }}>
                        <span>{item.servicetitle}</span>
                      </div>
                      <div
                        style={{
                          lineHeight: "30px",
                          borderBottom: "1px solid #ccc",
                        }}
                      >
                        <span>{"【" + item.employee + "】"}</span>
                        <span>
                          {"At " +
                            getDateFromPeriodno(item.periodno) +
                            " " +
                            item.fromtime}
                        </span>

                        <span>{" " + item.minutes + "Minutes"}</span>
                        {myusertype>=2&&<span
                          className="icon iconfont"
                          style={{
                            paddingRight: "10px",
                            paddingLeft: "15px",
                            fontSize: "26px",
                            textAlign: "center",
                          }}
                          onClick={() =>
                            ele.editTicketBook(ele.state.editid, item.id, index)
                          }
                        >
                          &#xe621;
                        </span>}

                        {myusertype>=2&&<span
                          className="icon iconfont"
                          style={{
                            paddingRight: "10px",
                            paddingLeft: "15px",
                            fontSize: "22px",
                            textAlign: "center",
                          }}
                          onClick={() =>
                            ele.delTicketBook(ele.state.editid, item.id)
                          }
                        >
                          &#xe74b;
                        </span>}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}

        {ele.state.showbookPanel == 1 && (
          <div
            style={{
              padding: "10px",
              background: ele.state.editid != "" ? "#eeeeee" : "#f6f6f6",
              marginTop: "15px",
              border: "1px solid #bbb",
              borderRadius: "6px",
            }}
          >
            <div className={"row"} style={{}}>
              <div
                className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright"
                style={{ height: "30px", lineHeight: "30px" }}
              >
                * Employee:
              </div>
              <div
                className="col-xs-10 col-sm-10 col-md-10 col-lg-10"
                style={{
                  height: "30px",
                  lineHeight: "30px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <select
                  name="employee"
                  style={{ height: "30px", marginRight: "6px" }}
                  onChange={(ref) => ele.changeField(ref, "employee")}
                  value={ele.state.bookdata.employee}
                /*    onChange={this.changeService} */
                >
                  {ele.state.employeelist.map((item, index) => {
                    return (
                      <option
                        key={"employee" + index}
                        value={"" + item.username}
                      >
                        {item.firstname + "　" + item.lastname + ""}
                      </option>
                    );
                  })}
                </select>
                Fixed
                <input
                  type="checkbox"
                  name="fixedemployee"
                  value="1"
                  onChange={(ref) => ele.changeField(ref, "fixedemployee")}
                  checked={ele.state.fixedemployee == 1 ? true : false}
                  style={{
                    width: "18px",
                    height: "18px",
                    marginLeft: "5px",
                  }}
                ></input>
                {/*   {this.state.editid == ""
              ? this.state.bookdata.nickname
              : this.state.books[this.state.editid].employee} */}
              </div>
            </div>

            <div
              className="row"
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
                * Service: 
              </div>
              <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                {ele.state.serviceid != "" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        paddingRight: "10px",
                        height: "30px",
                        lineHeight: "30px",
                      }}
                    >
                      {ele.state.servicetext}
                    </span>

                    <span
                      className="icon iconfont"
                      style={{
                        paddingRight: "10px",
                        paddingLeft: "15px",
                        fontSize: "19px",
                        textAlign: "right",
                      }}
                      onClick={() => ele.selService("", "", 0)}
                    >
                      &#xe658;
                    </span>
                  </div>
                ) : (
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                  
                      <input
                        autoComplete="off"
                        name="schservice"
                        value={ele.state.schservicetitle}
                        onChange={(ref) => ele.searchService(ref)}
                        placeholder="Search for service"
                        style={{
                          borderTop: "0px",
                          borderLeft: "0px",
                          borderRight: "0px",

                          borderBottomColor: "#aaa",
                          borderBottomWidth: "1px",
                          height: "30px",
                          lineHeight: "30px",
                          width: "200px",
                        }}
                      ></input>

                      <span
                        className="icon iconfont"
                        style={{
                          paddingRight: "10px",
                          paddingLeft: "15px",
                          fontSize: "33px",
                          textAlign: "right",
                          height: "30px",
                          lineHeight: "30px",
                          marginBottom: "15px",
                        }}
                        onClick={() => ele.ShowServiceList(1)}
                      >
                        ...
                      </span>
                    </div>

                    {ele.state.servicelistshow == 1 ? (
                      <div
                        style={{
                          position: "absolute",
                          width: "450px",
                          height: "500px",
                          border: "1px solid #ddd",
                          backgroundColor: "#f6f6f6",
                          //borderTop: "2px solid #ddd",
                          // borderBottom: "2px solid #ddd",
                          top: -3,
                          left: -8,
                          zIndex: 100,
                          padding: "5px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",

                            height: "30px",
                            lineHeight: "30px",
                          }}
                        >
                          <div
                            style={{
                              float: "left",
                              width: "90%",
                              textAlign: "center",
                            }}
                          >
                            Select Service
                          </div>
                          <div
                            style={{
                              float: "left",
                              width: "10%",
                              fontSize: "22px",
                            }}
                            onClick={() => ele.ShowServiceList(0)}
                          >
                            X
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            borderBottom: "1px solid #aaa",
                            paddingTop: "10px",
                          }}
                        >
                          {ele.state.servicelist.map((item, index) => {
                            return (
                              <div
                                key={"schcate" + index}
                                style={{
                                  margin: "5px",
                                  padding: "5px",
                                  float: "left",
                                  fontWeight:
                                    ele.state.selectedservicecateindex == index
                                      ? "bold"
                                      : "normal",
                                  background:
                                    ele.state.selectedservicecateindex == index
                                      ? "#fff"
                                      : "#f6f6f6",
                                }}
                                onClick={() =>
                                  ele.setState({
                                    selectedservicecateindex: index,
                                  })
                                }
                              >
                                {"" + item.catename + ""}
                              </div>
                            );
                          })}
                        </div>

                        {ele.state.servicelist.length > 0 && (
                          <div
                            style={{
                              maxHeight: "400px",
                              minHeight: "100px",
                              overflowY: "auto",
                              clear: "both",
                            }}
                          >
                            {ele.state.servicelist[
                              ele.state.selectedservicecateindex
                            ].child.map((item, index) => {
                              return (
                                <div
                                  key={"schservice" + index}
                                  style={{ padding: "3px", width: "100%" }}
                                  onClick={() =>
                                    ele.selService(
                                      item.id,
                                      item.title,
                                      item.minutes
                                    )
                                  }
                                >
                                  {"" + item.title + "　"}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    ) : (
                      <>
                        {ele.state.filteredservicelist.length > 0 && (
                          <div
                            style={{
                              position: "absolute",
                              top: "35px",
                              background: "#f6f6f6",
                              zIndex: 9999,
                              maxHeight: "400px",
                              minHeight: "100px",
                              overflowY: "auto",
                              border: "1px solid #555",
                            }}
                          >
                            {ele.state.filteredservicelist.map(
                              (item, index) => {
                                return (
                                  <div
                                    key={"schcustomer" + index}
                                    style={{
                                      padding: "3px",
                                      height: "30px",
                                      lineHeight: "30px",
                                      overflow: "hidden",
                                    }}
                                    onClick={() =>
                                      ele.selService(
                                        item.id,
                                        item.title,
                                        item.minutes
                                      )
                                    }
                                  >
                                    {"" + item.title + "　"}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div
              className={"row"}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <div
                className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright"
                style={{ height: "30px", lineHeight: "30px" }}
              >
                * Date && Time:
              </div>
              <div
                className="col-xs-10 col-sm-10 col-md-10 col-lg-10"
                style={{ height: "32px", lineHeight: "32px" }}
              >
                <div
                  style={{
                    float: "left",
                    width: "100px",

                    overflow: "hidden",
                    height: "30px",
                    border: "1px solid #ddd",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <DatePicker
                    selected={getDateFromPeriod(ele.state.bookdata.periodno)}
                    onChange={(ref) => ele.setDate(ref, "periodno")}
                    showYearDropdown
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Select date"
                    style={{
                      float: "left",
                      width: "100px",
                      height: "28px",
                      border: "0px",
                    }}
                  />
                </div>
                <div style={{ float: "left", paddingLeft: "10px" }}>
                  <select
                    name="hour"
                    style={{ height: "30px" }}
                    onChange={(ref) => ele.changeField(ref, "hour")}
                    value={
                      TurnTime(
                        ele.state.bookdata.fromtime
                          ? ele.state.bookdata.fromtime
                          : ele.state.defaultfromtime
                      ).split(":")[0]
                    }
                  /*    onChange={this.changeService} */
                  >
                    {ele.state.hours.map((item, index) => {
                      return (
                        <option key={"hours" + index} value={"" + item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                  <span
                    style={{
                      marginLeft: "3px",
                      marginRight: "3px",
                      fontWeight: "bold",
                    }}
                  >
                    :
                  </span>
                  <select
                    name="minute"
                    style={{ height: "30px" }}
                    onChange={(ref) => ele.changeField(ref, "minute")}
                    value={
                      TurnTime(
                        ele.state.bookdata.fromtime
                          ? ele.state.bookdata.fromtime
                          : ele.state.defaultfromtime
                      ).split(":")[1]
                    }
                  /*    onChange={this.changeService} */
                  >
                    {ele.state.minutes.map((item, index) => {
                      return (
                        <option key={"minutes" + index} value={"" + item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                  {ele.state.newbook == 1 && ele.state.rebook == 0
                    ? "-"
                    : "-" + ele.state.bookdata.totime
                      ? ele.state.bookdata.totime
                      : ""}
                  <span style={{ marginLeft: "15px" }}>
                    {" "}
                    Minutes:
                    <input
                      name="serviceminute"
                      value={ele.state.serviceminute}
                      onChange={(ref) => ele.changeField(ref, "serviceminute")}
                      onBlur={(ref) => ele.BlurField(ref, "serviceminute")}
                      style={{
                        height: "30px",
                        lineHeight: "30px",
                        width: "50px",

                        marginLeft: "3px",
                      }}
                    ></input>
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 textcenter">
                <button
                  className="submit"
                  onClick={() => ele.saveBook()}
                  style={{
                    background: bookButtonColor,

                    color: "white",
                    fontSize: "19px",
                  }}
                >
                  
                  {ele.state.posting
                    ? "Posting"
                    : ele.state.rebook == 1
                      ? "Save Rebook"
                      : ele.state.newbook == 1
                        ? "Add"
                        : "Edit"}
                </button>
              </div>
      
              {ele.state.editid != "" && ele.state.rebook == 0 && (
                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textcenter">
                  <button
                    className="submit"
                    onClick={() =>
                      ele.setState({
                        showbookPanel: 0,
                      })
                    }
                    style={{
                      width: "80px",

                      color: "#000",
                      background: "#eee",
                      border: "1px solid #bbb",
                    }}
                  >
                    
                    Hidden
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div style={{ height: "15px" }}></div>
      {ele.state.editid != "" && ele.state.rebook == 0 && (
        <div
          style={{
            border: "1px solid #ccc",
            borderRadius: "6px",
            padding: "5px",
          }}
        >
          <div
            className={"row"}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
              Total:
            </div>
            <div
              className="col-xs-10 col-sm-10 col-md-10 col-lg-10"
              onClick={() => {
                ele.setJine(ele.state.books[ele.state.editid].price);
              }}
            >
              <span
                style={{
                  float: "left",
                  verticalAlign: "middle",
                }}
              >
                ${ele.state.books[ele.state.editid].price}
              </span>
            </div>
          </div>
          <div
            className={"row"}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
              Total(Tax):
            </div>
            <div
              className="col-xs-10 col-sm-10 col-md-10 col-lg-10"
              onClick={() => {
                ele.setJine(ele.state.books[ele.state.editid].aftertaxprice);
              }}
            >
              <span
                style={{
                  float: "left",
                  verticalAlign: "middle",
                }}
              >
                ${ele.state.books[ele.state.editid].aftertaxprice}
              </span>

              <span
                style={{
                  float: "left",
                  paddingLeft: "6px",
                }}
              >
                (Click it auto fill in amount)
              </span>
            </div>
          </div>
          {myusertype>=2&&<div
            className={"row"}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
              * Received:
            </div>
            <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">
              <span
                style={{
                  float: "left",
                  verticalAlign: "middle",
                  height: "30px",
                  lineHeight: "30px",
                  paddingRight: "8px",
                }}
              >
                ${ele.state.books[ele.state.editid].orirealpay}
              </span>

              <span
                style={{
                  float: "left",
                  verticalAlign: "middle",
                  height: "30px",
                  lineHeight: "30px",
                  paddingRight: "8px",
                }}
              >
                Amount:
                <input
                  name="realpay"
                  value={ele.state.books[ele.state.editid].realpay}
               /*    value={ele.state.books[ele.state.editid].realpay}
                */   onFocus={(ref) => ele.FocusField(ref, "realpay")}
                  onChange={(ref) => ele.changeField(ref, "realpay")}
                  style={{
                    height: "30px",
                    lineHeight: "30px",
                    width: "50px",
                  }}
                ></input>
              </span>
              <select
                name="paymethod"
                style={{
                  marginLeft: "5px",
                  marginRight: "5px",
                  height: "30px",
                  float: "left",
                  borderRadius: "5px",
                }}
                onChange={(ref) => ele.changePaymethod(ref)}
                value={ele.state.books[ele.state.editid].paymethod}
              >
                {Object.keys(PayMethods).map((item, index) => {
                  return (
                    <option key={"paymethod" + index} value={"" + item}>
                      {PayMethods[item]}
                    </option>
                  );
                })}
              </select>
              <span
                style={{
                  float: "left",
                }}
              >
                Eclaim:
                <input
                  name="eclaim_jine"
                  value={ele.state.books[ele.state.editid].eclaim_jine}
                  onFocus={(ref) => ele.FocusField(ref, "eclaim_jine")}
                  onChange={(ref) => ele.changeField(ref, "eclaim_jine")}
                  style={{
                    height: "30px",
                    lineHeight: "30px",
                    width: "50px",
                  }}
                ></input>
              </span>

					 <span
                style={{
                  float: "left",
                }}
              >
                Tips:
                <input
                  name="tickettip"
                  value={ele.state.books[ele.state.editid].tickettip}
                  onFocus={(ref) => ele.FocusField(ref, "tickettip")}
                  onChange={(ref) => ele.changeField(ref, "tickettip")}
                  style={{
                    height: "30px",
                    lineHeight: "30px",
                    width: "50px",
                  }}
                ></input>
              </span>
              <input
                type="button"
                name="settip"
                value={
                  ele.state.books[ele.state.editid].orirealpay > 0 &&
                    ele.state.books[ele.state.editid].realpay !=
                    ele.state.books[ele.state.editid].orirealpay
                    ? " ReSet "
                    : " Set "
                }
                className="add"
                onClick={(ref) => ele.savetricketcol("realpay")}
                style={{
                  height: "30px",
                  lineHeight: "30px",
                  width: "100px",
                  float: "left",
                  background: bookButtonColor,
                  marginLeft: "15px",
                  color: "white",
                }}
              ></input>
            </div>
          </div>}
          {/*   <div
            className={"row"}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
              * Tips:
            </div>
            <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">
              <span
                style={{
                  float: "left",
                  verticalAlign: "middle",
                  height: "30px",
                  lineHeight: "30px",
                }}
              >
                $
              </span>
             

              <input
                type="button"
                name="settip"
                value="Set"
                className="add"
                onClick={(ref) => ele.savetricketcol("tickettip")}
                style={{
                  height: "30px",
                  lineHeight: "30px",
                  width: "100px",
                  float: "left",
                  background: bookButtonColor,
                  marginLeft: "15px",
                  color: "white",
                }}
              ></input>
            </div>
          </div> */}
          <div
            className={"row"}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
              Note:
            </div>
            <div
              className="col-xs-10 col-sm-10 col-md-10 col-lg-10"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span
                style={{
                  float: "left",
                  verticalAlign: "middle",
                  height: "30px",
                  lineHeight: "30px",
                }}
              ></span>

              <textarea
                placeholder="note"
                name="ticketremark"
                value={ele.state.books[ele.state.editid].ticketremark}
                onChange={(ref) => ele.changeField(ref, "ticketremark")}
                style={{
                  height: "60px",
                  lineHeight: "30px",
                  width: "255px",
                  float: "left",
                }}
              ></textarea>

              {myusertype>=2&&<input
                type="button"
                name="setremark"
                value="Set"
                className="add"
                onClick={(ref) => ele.savetricketcol("ticketremark")}
                style={{
                  height: "30px",
                  lineHeight: "30px",
                  width: "100px",
                  float: "left",
                  background: bookButtonColor,
                  marginLeft: "15px",
                  color: "white",
                }}
              ></input>}
            </div>
          </div>
          {
            ele.state.books[ele.state.editid].userchecked == 1 && (
              <div className="row" style={{ marginTop: "15px" }}>
                <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 textcenter">
                  <button
                    className="submit"
                    onClick={(ref) => ele.savebookcol("checkout")}
                    style={{
                      background: bookButtonColor,
                      color: "white",
                      fontSize: "19px",
                    }}
                  >
                    {ele.state.books[ele.state.editid].status == 0
                      ? "CheckOut"
                      : "UnCheckOut"}
                  </button>
                </div>
              </div>
            )}
          {/* {ele.state.books[ele.state.editid].checked == 1 &&
            ele.state.books[ele.state.editid].userchecked == 1 && (
              <div
                className={"row"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "15px",
                }}
              >
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <input
                    type="button"
                    name="settip"
                    value=" CheckOut "
                    className="add"
                    onClick={(ref) => ele.savebookcol("status")}
                    style={{
                      height: "30px",
                      lineHeight: "30px",
                      width: "250px",
                      float: "left",
                      background: "#bbb",
                      marginLeft: "15px",
                      color: "white",
                    }}
                  ></input>
                </div>
              </div>
            )} */}
          <div className="row">
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter postresult"
              style={{ width: "70%", marginTop: "10px" }}
            >
              {ele.state.postresult}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
