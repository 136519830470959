import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import $ from "jquery";
import { AJAROOT } from "@/common/gdata/global.js";
import {
  checkUserLogin,
  IsPC,
  MyToast,
  MyToastShow,
} from "@/jscript/common.js";
import axios from "axios";
import "@/css/user.css";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      uptype: "1",
      title: "",
      zsxm: "",

      error: "",

      telephone: "",

      address: "",
      password: "",
      password2: "",
      postresult: "",
      mytoast: {
        show: false,
      },
    };
  }
  render() {
    const { myusername } = this.props;
    const IsMobile = !IsPC();
    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;
    var zsxm = this.state.zsxm;
    if (zsxm === "") zsxm = this.state.myusername;
    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px" }}
      >
        {!IsMobile && (
          <div className="row " style={{ height: "55px", lineHeight: "55px" }}>
            <div
              className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
              onClick={() => {
                window.history.back(-1);
              }}
            >
              <span className="iconfont fanhui">&#xe625;</span>
            </div>
            <div
              className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textcenter "
              style={{ fontSize: "19px", fontWeight: "bolder" }}
            >
              {this.state.title}
            </div>
            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>
          </div>
        )}
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
            {/* <div className={this.state.uptype === "1" ? "row" : "nodis"}>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 ">
                姓名:
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 ">
                <input
                  value={this.state.upgzhnickname}
                  onChange={this.changeFirstName.bind(this)}
                />
              </div>
            </div> */}

            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Password:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  type="password"
                  value={this.state.uppassword}
                  onChange={this.changePassword.bind(this)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Retype Password:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  type="password"
                  value={this.state.uppassword2}
                  onChange={this.changePassword2.bind(this)}
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter postresult"
                style={{ width: "70%", marginTop: "5px", height: "25px" }}
              >
                {this.state.postresult}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 textcenter">
                <button className="submit" onClick={() => this.submit()}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }

  changePassword(e) {
    let val = e.target.value;
    this.setState(() => {
      return {
        password: val,
      };
    });
  }
  changePassword2(e) {
    let val = e.target.value;
    this.setState(() => {
      return {
        password2: val,
      };
    });
  }
  submit() {
    const { myusername, ukey } = this.props;

    // document.getElementById("result2").style.color = "#000000";

    this.setState({
      postresult: "Waiting....",
    });
    // document.getElementById("result2").innerHTML = "正在提交....";

    var password = this.state.password;
    var password2 = this.state.password2; //#region ;
    const postdata = {
      username: this.state.username,

      password: password,
    };
    const this2 = this;
    var error = "";

    if (password !== password2) {
      error = "The twice input assword doesn't math";
    }
    if (password === "") error = "Please fill in password";

    //document.getElementById("result2").style.color = "red";
    if (error !== "") {
      this.setState({
        postresult: error,
      });
    } else {
      $.ajax({
        type: "POST",
        dataType: "json",
        url:
          AJAROOT +
          "/postaja/savexx.aspx?action=uppwd&u=" +
          myusername +
          "&ukey=" +
          encodeURIComponent(ukey) +
          "",
        data: JSON.stringify(postdata),
      })
        .done(function (data) {
          var r = data.r;
          var msg = data.s;

          if (r === "1") {
            //  this.postresult = "登陆成功！";
            MyToastShow(
              this2,
              2,
              "Successfully!",
              200,
              120,
              "success",
              "addemployee"
            );
            this2.setState({
              postresult: "",
            });
            // if (myusername == this2.state.username)
            //   this2.props.history.push("/user");
            //  this2.props.history.push("/user");
          } else {
            MyToastShow(this2, 2, msg, 200, 120, "success", "addemployee");
            //document.getElementById("result2").style.color = "red";
            this2.setState({
              postresult: "",
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    }
  }

  getData(myusername, ukey) {
    var geturl =
      AJAROOT +
      "/getaja/user.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "";

    axios.get(geturl).then((res) => {
      const result = res.data;
      //  console.log(result);
      // this.setState({}); /**/

      var zsxm = result.zsxm;
      //  var username = result.username;

      // if (zsxm === "") zsxm = username;
      this.setState({
        zsxm: zsxm,
        telephone: result.mobile,
        address: result.address,
      });
    });
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;
    var { username } = queryString.parse(this.props.location.search);
    var title = "Edit Password";

    if (username == null) username = "";
    if (username == "") {
      username = myusername;
    }
    this.setState({
      title: title,
      username: username,
    });

    //  getUserIndexData(this, myusername, ukey);
    // this.getData(myusername, ukey);
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {}
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
  };
};

export default connect(mapStateToProps, null)(User);
