import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import $ from "jquery";
import { AJAROOT } from "@/common/gdata/global.js";
//import { Link } from "react-router-dom";
//import MySignatureCanvas from "@/jscript/myCanve.js";
import {
  checkUserLogin,
  IsPC,
  MyToast,
  MyToastShow,
} from "@/jscript/common.js";

import { BasicForm } from "@/jscript/userform.js";

import axios from "axios";
import "@/css/user.css";
import "@/css/react-datepicker.css";

import DatePicker from "react-datepicker";
let emptysign =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

const LCss = {
  agree: {
    padding: "15px",
  },
  signdiv: {
    verticalAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  signcave: {
    height: "52px",
    lineHeight: "52px",
    width: "202px",

    border: "1px solid #ccc",
    position: "relative",
    float: "left",
    textAlign: "left",
  },
  canve: {
    background: "#fff",
    position: "absolute",
    top: 0,
    left: 0,
  },
};
class User extends Component {
  constructor(props) {
    super(props);
    this.canvasRef1 = React.createRef();
    this.canvasRef2 = React.createRef();
    this.canvasRef3 = React.createRef();
    this.state = {
      //  myu: "",

      title: "",
      firstname: "",
      lastname: "",

      telephone: "",

      address: "",
      city: "",
      province: "",
      postal: "",
      birthday: "",
      email: "",
      password: "",
      haveinsurance: 1,
      error: "",
      contactname: "",
      contacttel: "",
      familydoc: "",
      familydoctel: "",
      familydocaddress: "",
      insur_massage: "",
      insur_acupuncture: "",
      insur_osteopath: "",
      insur_physiotherapy: "",
      insur_chiropractic: "",
      insur_naturopath: "",
      insur_company: "",
      insur_policynum: "",
      insur_memberid: "",
      seriousdata: [],
      seriousothers: "",
      haveallergy: "",
      whatallergy: "",
      ongoinghealthy: "",
      longtertreat: "",
      patientsign: emptysign,
      patientsigndate: "",
      guardiansign: emptysign,
      guardiansigndate: "",
      practitionersign: emptysign,
      practitionersigndate: "",
      haveimg1: 0,
      haveimg2: 0,
      haveimg3: 0,
      postresult: "",
      mytoast: {
        show: false,
      },
      isprint: false,
    };
  }

  render() {
    const { myusername } = this.props;
    const IsMobile = !IsPC();
    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;
    //var zsxm = this.state.zsxm;
    // if (zsxm === "") zsxm = this.state.myusername;

    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px" }}
      >
        <div className="row " style={{ height: "55px", lineHeight: "55px" }}>
          <div
            className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
            onClick={() => {
              window.history.back(-1);
            }}
          >
            {!this.state.isprint && (
              <span className="iconfont fanhui">&#xe625;</span>
            )}
          </div>
          <div
            className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textcenter "
            style={{ fontSize: "19px", fontWeight: "bolder" }}
          >
            {!this.state.isprint ? this.state.title : ""}
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold">
            {!this.state.isprint && (
              <a
                onClick={() =>
                  this.print(
                    "/user/form/basic?username=" +
                      this.state.username +
                      "&print=1"
                  )
                }
              >
                 {!IsMobile?"Print":""}
              </a>
            )}
            {this.state.isprint && (
              <input
                style={{ height: "35px", lineHeight: "35px" }}
                type="button"
                value="Print"
                onClick={() =>
                  this.printPage(
                    "/user/form/basic?username=" +
                      this.state.username +
                      "&print=1"
                  )
                }
              ></input>
            )}
          </div>
        </div>

        <div className="PageNext" id="content">
          {this.state.isprint && (
            <div className="printtitle">{this.state.title}</div>
          )}

          <div className="row">
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft"
              style={{ fontSize: "16px" }}
            >
              Client Information
            </div>
          </div>
          {BasicForm(this)}

          {/* {!this.state.isprint && (
            <div className="row" style={{ paddingTop: "25px" }}>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 textcenter">
                <button className="submit" onClick={() => this.submit()}>
                  Submit All
                </button>
              </div>
            </div>
          )} */}
        </div>
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
  printPage(url) {
    var orihtml = window.document.body.innerHTML; //从标记里获取需要打印的页面
    document.getElementById("content").style.padding = "10px";
    var printHtml = document.getElementById("content").innerHTML; //从标记里获取需要打印的页面

    window.document.body.innerHTML = printHtml; //需要打印的页面

    window.print();
    window.document.body.innerHTML = orihtml;
    window.open(url, "_self");
  }
  print(url) {
    window.open(url, "_blank");
  }

  setBirthday(val, field) {
    let _date = val;
    if (val == null) _date = "";
    if (_date != "") {
  /*     let date = new Date(
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      );

      _date = date; */
    }
    if (field == "birthday") {
      this.setState({
        birthday: _date,
      });
    } else if (field == "patientsigndate") {
      this.setState({
        patientsigndate: _date,
      });
    } else if (field == "guardiansigndate") {
      this.setState({
        guardiansigndate: _date,
      });
    } else if (field == "practitionersign") {
      this.setState({
        practitionersign: _date,
      });
    }
  }

  changeField(e, field) {
    let val = e.target.value;
    if (field == "username")
      this.setState({
        username: val,
      });
    else if (field == "firstname")
      this.setState({
        firstname: val,
      });
    else if (field == "lastname")
      this.setState({
        lastname: val,
      });
    else if (field == "password")
      this.setState({
        password: val,
      });
    else if (field == "telephone")
      this.setState({
        telephone: val,
      });
    else if (field == "email")
      this.setState({
        email: val,
      });
    else if (field == "address") {
      this.setState({
        address: val,
      });
    } else if (field == "city")
      this.setState({
        city: val,
      });
    else if (field == "province")
      this.setState({
        province: val,
      });
    else if (field == "postal")
      this.setState({
        postal: val,
      });
    else if (field == "contactname")
      this.setState({
        contactname: val,
      });
    else if (field == "contacttel")
      this.setState({
        contacttel: val,
      });
    else if (field == "familydoc")
      this.setState({
        familydoc: val,
      });
    else if (field == "familydoctel")
      this.setState({
        familydoctel: val,
      });
    else if (field == "familydocaddress")
      this.setState({
        familydocaddress: val,
      });
    else if (field == "insur_massage")
      this.setState({
        insur_massage: val,
      });
    else if (field == "insur_acupuncture")
      this.setState({
        insur_acupuncture: val,
      });
    else if (field == "insur_osteopath")
      this.setState({
        insur_osteopath: val,
      });
    else if (field == "insur_physiotherapy")
      this.setState({
        insur_physiotherapy: val,
      });
    else if (field == "insur_chiropractic")
      this.setState({
        insur_chiropractic: val,
      });
    else if (field == "insur_naturopath")
      this.setState({
        insur_naturopath: val,
      });
    else if (field == "insur_company")
      this.setState({
        insur_company: val,
      });
    else if (field == "insur_policynum")
      this.setState({
        insur_policynum: val,
      });
    else if (field == "insur_memberid")
      this.setState({
        insur_memberid: val,
      });
    else if (field == "seriousothers")
      this.setState({
        seriousothers: val,
      });
    else if (field == "whatallergy")
      this.setState({
        whatallergy: val,
      });
    else if (field == "ongoinghealthy")
      this.setState({
        ongoinghealthy: val,
      });
    else if (field == "longtertreat")
      this.setState({
        longtertreat: val,
      });
    else if (field == "patientsign")
      this.setState({
        patientsign: val,
      });
    else if (field == "guardiansign")
      this.setState({
        guardiansign: val,
      });
    else if (field == "practitionersign")
      this.setState({
        practitionersign: val,
      });
    else if (field == "password")
      this.setState({
        password: val,
      });
  }

  submit() {
    const { myusername, ukey } = this.props;
    // document.getElementById("result2").style.color = "#000000";

    this.setState({
      postresult: "Waiting....",
    });
    // document.getElementById("result2").innerHTML = "正在提交....";
    var telephone = this.state.telephone;
    var firstname = this.state.firstname;
    var lastname = this.state.lastname;
    //var gzhnickname = this.state.upgzhnickname;
    //var comname = this.state.upcomname;

    let birthday = this.state.birthday;
    if (birthday != "") {
      birthday =
        birthday.getFullYear() +
        "-" +
        (birthday.getMonth() + 1) +
        "-" +
        birthday.getDate();
    }
    var contactname = this.state.contactname;
    var contacttel = this.state.contacttel;
    var familydoc = this.state.familydoc;
    var familydoctel = this.state.familydoctel;
    var familydocaddress = this.state.familydocaddress;

    let guardiansigndate = this.state.guardiansigndate;
    if (guardiansigndate != "") {
      guardiansigndate =
        guardiansigndate.getFullYear() +
        "-" +
        (guardiansigndate.getMonth() + 1) +
        "-" +
        guardiansigndate.getDate();
    }

    let patientsigndate = this.state.patientsigndate;
    if (patientsigndate != "") {
      patientsigndate =
        patientsigndate.getFullYear() +
        "-" +
        (patientsigndate.getMonth() + 1) +
        "-" +
        patientsigndate.getDate();
    }

    let practitionersigndate = this.state.practitionersigndate;
    if (practitionersigndate != "") {
      practitionersigndate =
        practitionersigndate.getFullYear() +
        "-" +
        (practitionersigndate.getMonth() + 1) +
        "-" +
        practitionersigndate.getDate();
    }
    let patientsign = this.state.patientsign;
    if (this.state.haveimg1 == 0) {
      patientsign = this.canvasRef1.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      if (patientsign == emptysign) patientsign = "";
    }

    let guardiansign = this.state.guardiansign;
    if (this.state.haveimg2 == 0) {
      guardiansign = this.canvasRef2.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      if (guardiansign == emptysign) guardiansign = "";
    }

    let practitionersign = this.state.practitionersign;
    if (this.state.haveimg3 == 0) {
      practitionersign = this.canvasRef3.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      if (practitionersign == emptysign) practitionersign = "";
    }
    this.setState({
      patientsign: patientsign,
      guardiansign: guardiansign,
      practitionersign: practitionersign,
    });
    const postdata = {
      username: this.state.username,
      telephone: telephone,
      firstname: firstname,
      lastname: lastname,
      address: this.state.address,
      city: this.state.city,
      province: this.state.province,
      postal: this.state.postal,
      email: this.state.email,
 password: this.state.password,

      birthday: birthday,
      contactname: contactname,
      contacttel: contacttel,
      familydoc: familydoc,
      familydoctel: familydoctel,
      familydocaddress: familydocaddress,
      insur_massage: this.state.insur_massage,
      insur_acupuncture: this.state.insur_acupuncture,
      insur_osteopath: this.state.insur_osteopath,
      insur_physiotherapy: this.state.insur_physiotherapy,
      insur_chiropractic: this.state.insur_chiropractic,
      insur_naturopath: this.state.insur_naturopath,
      insur_company: this.state.insur_company,
      insur_policynum: this.state.insur_policynum,
      insur_memberid: this.state.insur_memberid,
      seriousdata: this.state.seriousdata,
      seriousothers: this.state.seriousothers,
      haveallergy: this.state.haveallergy,
      whatallergy: this.state.whatallergy,
      ongoinghealthy: this.state.ongoinghealthy,
      longtertreat: this.state.longtertreat,
      patientsign: patientsign,
      patientsigndate: patientsigndate,
      guardiansign: guardiansign,
      guardiansigndate: guardiansigndate,
      practitionersign: practitionersign,
      practitionersigndate: practitionersigndate,
    };

    const this2 = this;
    var error = "";
    if (this.state.telephone === "") error = "Please fill in telephone";
    else if (this.state.firstname == "") error = "Please fill in firstname";
    else if (this.state.lastname == "") error = "Please fill in lastname";
    else if (this.state.address == "") error = "Please fill in address";
    else if (this.state.contactname == "")
      error = "Please fill in emergency contact name";
    else if (this.state.contacttel == "")
      error = "Please fill in emergency contact telephone";
    else if (this.state.familydoc == "") error = "Please fill in family doctor";
    else if (this.state.familydoctel == "")
      error = "Please fill in family doctor telephone";
    else if (this.state.familydocaddress == "")
      error = "Please fill in family doctor address";
    else if (patientsign == "") error = "Please fill in patient signature";
    else if (practitionersign == "")
      error = "Please fill in practitioner signature";

    //document.getElementById("result2").style.color = "red";
    if (error !== "") {
      this.setState({
        postresult: error,
      });
    } else {
      $.ajax({
        type: "POST",
        dataType: "json",
        url:
          AJAROOT +
          "/postaja/savexx.aspx?action=savespa&u=" +
          myusername +
          "&ukey=" +
          encodeURIComponent(ukey) +
          "",
        data: JSON.stringify(postdata),
      })
        .done(function (res) {
          var r = res.r;
          var msg = res.msg;

          if (r === "1") {
            //  this.postresult = "登陆成功！";

            this2.setState({
              postresult: "Successfully",
            });
            //if (myusername == this2.state.username)
            //this2.props.history.push("/user");
          } else {
            //document.getElementById("result2").style.color = "red";
            this2.setState({
              postresult: msg,
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    }
  }

  getData(myusername, ukey, username) {
    if (username == "") username = myusername;
    var geturl =
      AJAROOT +
      "/getaja/user.aspx?u=" +
      myusername +
      "&username=" +
      username +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "";

    axios.get(geturl).then((res) => {
      const result = res.data;
      console.log(result);
      // this.setState({}); /**/
      let haveimg1 = 0;
      let haveimg2 = 0;
      let haveimg3 = 0;

      var firstname = result.firstname;
      var lastname = result.lastname;
      let birthday = result.birthday;
      if (birthday == null) birthday = "";
      if (birthday != "") {
        birthday = new Date(birthday);
      }

      let patientsigndate = result.patientsigndate;
      if (patientsigndate == null) patientsigndate = "";
      if (patientsigndate != "") {
        patientsigndate = new Date(patientsigndate);
      }

      let guardiansigndate = result.guardiansigndate;
      if (guardiansigndate == null) guardiansigndate = "";
      if (guardiansigndate != "") {
        guardiansigndate = new Date(guardiansigndate);
      }

      let practitionersigndate = result.practitionersigndate;
      if (practitionersigndate == null) practitionersigndate = "";
      if (practitionersigndate != "") {
        practitionersigndate = new Date(practitionersigndate);
      }

      let patientsign = result.patientsign;
      if (patientsign == null) patientsign = "";
      if (patientsign != "") {
        haveimg1 = 1;
      }

      let guardiansign = result.guardiansign;
      if (guardiansign == null) guardiansign = "";
      if (guardiansign != "") {
        haveimg2 = 1;
      }

      let practitionersign = result.practitionersign;
      if (practitionersign == null) practitionersign = "";
      if (practitionersign != "") {
        haveimg3 = 1;
      }

      //this.canvasRef1.current.fromDataURL(patientsign);
      // this.canvasRef2.current.fromDataURL(guardiansign);
      // this.canvasRef3.current.fromDataURL(practitionersign);
      // if (zsxm === "") zsxm = username;
      this.setState({
        firstname: firstname,
        lastname: lastname,
        telephone: result.telephone,
        address: result.address,
        city: result.city,
        province: result.province,
        postal: result.postal,
        birthday: birthday,
        email: result.email,
        haveinsurance: result.haveinsurance,
        contactname: result.contactname,
        contacttel: result.contacttel,
        familydoc: result.familydoc,
        familydoctel: result.familydoctel,
        familydocaddress: result.familydocaddress,
        insur_massage: result.insur_massage,
        insur_acupuncture: result.insur_acupuncture,
        insur_osteopath: result.insur_osteopath,
        insur_physiotherapy: result.insur_physiotherapy,
        insur_chiropractic: result.insur_chiropractic,
        insur_naturopath: result.insur_naturopath,
        insur_company: result.insur_company,
        insur_policynum: result.insur_policynum,
        insur_memberid: result.insur_memberid,
        seriousdata: result.seriousdata,
        seriousothers: result.seriousothers,
        haveallergy: result.haveallergy,
        whatallergy: result.whatallergy,
        ongoinghealthy: result.ongoinghealthy,
        longtertreat: result.longtertreat,

        patientsign: patientsign,
        patientsigndate: patientsigndate,
        guardiansign: guardiansign,
        guardiansigndate: guardiansigndate,
        practitionersign: practitionersign,
        practitionersigndate: practitionersigndate,
        haveimg1: haveimg1,
        haveimg2: haveimg2,
        haveimg3: haveimg3,
      });
    });
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;

    //const { t } = this.props.match.params;
    var { username, print } = queryString.parse(this.props.location.search);
    var title = "Edit Client Info";
    document.title = title;
    if (print == null) print = "";
    if (print == "1") {
      title = "Client Info";
      this.setState({
        isprint: true,
      });
    }
    if (username == null) username = "";
    if (username == "") {
      username = myusername;
    }
    this.setState({
      title: title,
      username: username,
    });

    //  getUserIndexData(this, myusername, ukey);
    this.getData(myusername, ukey, username);
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {}
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
  };
};

export default connect(mapStateToProps, null)(User);
