import styled from "styled-components";

export const LoginWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 45px;
  z-index: 999999;
  height: 300px;
`;

export const LoginBox = styled.div`
  width: 550px;
  height: 260px;
  margin: 100px auto;
  padding-top: 20px;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
`;

export const Input = styled.input`
  width: 200px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  color: #777;
  margin: 10px auto;
`;

export const Button = styled.div`
  width: 220px;
  height: 30px;
  line-height: 30px;

  color: #fff;
  background: #337b70;
  border-radius: 15px;
  margin: 10px auto;
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
`;

export const PostResult = styled.div`
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;

  margin: 2px auto;
  text-align: center;
  .loginerror {
    color: rgb(228, 63, 63);
  }
`;
