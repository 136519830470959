import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { AJAROOT } from "@/common/gdata/global.js";
import { Link } from "react-router-dom";
import {
  checkUserLogin, MyToast,
  MyToastShow,
} from "@/jscript/common.js";
import "@/css/user.css";

const LCss = {
  category: {
    display: "flex", // Apply flexbox to the inner div
    alignItems: "center", // Vertically center the content
    justifyContent:"center",
    width:"150px",
    margin:"5px",
    padding: "5px", border: "1px solid #ddd",
    borderRadius: "5px", 
    textAlign: "center", cursor: "pointer",

  },
 
};

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      title: "",
      catelist: [],
      list: [],
      cateid: "",
      zsxm: "",
      regtime: "",
      hba: 0,
      hbb: 0,
      hbc: 0,
      xfze: 0,
      totaljj: 0,
      error: "",

      postresult: "",
      mytoast: {
        show: false,
      },
    };
  }
  render() {
    const { myusername } = this.props;

    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;

    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px", width: "90%" }}
      >
        <div className="pagetitle ">{this.state.title}</div>
        <div className="row textright">

          <div>
            <Link to="/user/service/add">
              <input
                type="button"
                name="button1"
                value="New Service"
                className="add"
              ></input>
            </Link>
          </div>
        </div>

        <div className="block">
        <div class="r border" style={{  flexWrap: "wrap", height: "auto" }}>
            {this.state.catelist.map((item, index) => {
              return (
                <div key={"category" + index} 
                style={{ ...LCss.category, background: item.id == this.state.cateid ? "#f6f6f6" : "#fff" }}
             
                  onClick={() => this.search(item.id)}>

                  {"" + item.name}
                </div>
              );
            })}
          </div>
        </div>
        <div style={{ padding: "15px" }}></div>

        <div className="row">
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold textleft">
            Category
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 bold textleft">
            Title
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold textleft">
            Price
          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 bold textleft">
            Minutes
          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 bold textleft">
            Status
          </div>
          <div className="col-xs-3 col-sm-2 col-md-2 col-lg-2 textright"></div>
        </div>

        {this.state.list.map((item, index) => {
          return (
            <div className="row list " key={"group" + index}>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                {item.catename}
              </div>
              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 ">
                {item.title}
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                <div style={{ height: "22px", lineHeight: "22px" }}>
                  ${item.price}
                </div>
                <div style={{ height: "22px", lineHeight: "22px" }}>
                  ${item.aftertaxprice} (After Tax)
                </div>
              </div>
              <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 ">
                {item.minutes}
              </div>
              <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                {item.opened}
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
                <Link to={"/user/service/add?id=" + item["id"] + ""}>
                  Update
                </Link>{" "}
                <a
                  href="#"
                  onClick={() =>
                    this.Del(index, item["id"])
                  }
                >
                  Delete
                </a>
              </div>
            </div>
          );
        })}

        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
  Del(index, id) {
    const { myusername, ukey } = this.props;
    if (window.confirm("Are you sure to delete it?")) {
      this.DelData(myusername, encodeURIComponent(ukey), id, index);
    } else return;

  }
  DelData(myusername, ukey, id, index) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=delservice&id=" +
      id +
      "";

    axios.get(geturl).then((res) => {
      const result = res.data;

      if (result.r == 1) {
        let list = this.state.list;
        list.splice(index, 1);
        this.setState({
          list: list,
        });
        MyToastShow(
          this,
          1000,
          "Successfully! ",
          150,
          80,
          "success",
          "delservice"
        );
      } else {
        MyToastShow(this, 2, result.s, 200, 120, "fail", "delservice");
      }
      // this.setState({}); /**/
    });
  }
  search(cateid) {
    this.setState({
      cateid: cateid,
    });
    const { myusername, ukey } = this.props;
    this.getData(myusername, encodeURIComponent(ukey), cateid);
  }
  getData(myusername, ukey, cateid) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=servicelist&cateid=" + cateid + "";

    axios.get(geturl).then((res) => {
      const result = res.data;

      this.setState({
        list: result.list,
      });
      // this.setState({}); /**/
    });
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;
    // const { t } = this.props.match.params;

    var title = "Service List";
    document.title = title;
    this.setState({
      title: title,
    });
    //if (IsPC()) getUserIndexData(this, myusername, ukey);
    this.getData(myusername, encodeURIComponent(ukey), "");
    this.getCategory(myusername, encodeURIComponent(ukey));

  }
  getCategory(myusername, ukey) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=srvcatelist&readonly=1";

    axios.get(geturl).then((res) => {
      const result = res.data;

      //  let groupright = eval(result.groupright);

      this.setState({
        catelist: result.list,
      });
      // this.setState({}); /**/
    });
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() { }
  componentDidUpdate() { }
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
  };
};

export default connect(mapStateToProps, null)(Index);
