import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { AJAROOT } from "@/common/gdata/global.js";
import { Link } from "react-router-dom";
import { checkUserLogin, IsPC , MyToast,
  MyToastShow,} from "@/jscript/common.js";
import "@/css/user.css";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      title: "",
      list: [],

      lastname: "",
      regtime: "",
      hba: 0,
      hbb: 0,
      hbc: 0,
      xfze: 0,
      totaljj: 0,
      error: "",

      postresult: "",
        mytoast: {
        show: false,
      },
    };
  }
  render() {
    const {
      myusername,
      myusertype,
      myusergrade,
      style,
      usertype,
      usergrade,
      hb,
    } = this.props;
    const IsMobile = !IsPC();
    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;
    //var zsxm = this.state.zsxm;
    /// if (zsxm === "") zsxm = this.state.myusername;
    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px", width: "90%" }}
      >
        <div className="pagetitle">{this.state.title}</div>
        <div className="row textright">
          <div>
            <Link to="/user/branch/add">
              <input
                type="button"
                name="button1"
                value="New Branch"
                className="add"
              ></input>
            </Link>
          </div>
        </div>
        <div style={{ padding: "15px" }}></div>
        <div className="row">
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 bold textleft">
            Branch name
          </div>
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 bold textleft">
            Manager account
          </div>
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 bold textleft">
            Create time
          </div>
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright"></div>
        </div>
        {this.state.list.map((item, index) => {
          return (
            <div className="row list" key={"group" + index}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 ">
                {item.lastname}
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 ">
                {item.username}
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 ">
                {item.dt}
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                <Link to={"/user/branch/add?id=" + item["id"] + ""}>
                  Update
                </Link>{" "}
                {item["username"] != myusername && (
                   <a
                      href="#"
                      onClick={() =>
                        this.Del(index,item["username"])
                      }
                    >
                      Delete
                    </a>
                )}
              </div>
            </div>
          );
        })}
                     {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
 Del(index,username) {
   const { myusername, ukey } = this.props;
    if (window.confirm("Are you sure to delete the branch?")) {
       this.DelData(myusername, encodeURIComponent(ukey), username,index);
    } else return;
  }

  
  DelData(myusername, ukey, username,index) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=delbranch&username=" +
      username +
      "";
console.log(geturl);
    axios.get(geturl).then((res) => {
      const result = res.data;
     console.log(result);
      if (result.r == 1) {
        let list=this.state.list;
      list.splice(index,1);
        this.setState({
      list: list,
    });
        MyToastShow(
          this,
          1000,
          "Successfully! ",
          150,
          80,
          "success",
          "addgroup"
        );
      } else {
        MyToastShow(this, 2, result.s, 200, 120, "fail", "addbranch");
      }
      // this.setState({}); /**/
    });
  }
  getData(myusername, ukey) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=branchlist";

    axios.get(geturl).then((res) => {
      const result = res.data;

      this.setState({
        list: result.list,
      });
      // this.setState({}); /**/
    });
  }

  componentDidMount() {
    const { myusername, ukey } = this.props;
    //  const { t } = this.props.match.params;

    var title = "Branch List";
    document.title = title;
    this.setState({
      title: title,
    });
    //if (IsPC()) getUserIndexData(this, myusername, ukey);
    this.getData(myusername, encodeURIComponent(ukey));
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {}
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
  };
};

export default connect(mapStateToProps, null)(Index);
