import * as actionTypes from "./actionTypes";
import { fromJS } from "immutable";
import { defaultStore } from "../../gdata/global";

let header = sessionStorage.getItem("header")
  ? JSON.parse(sessionStorage.getItem("header"))
  : {
     logo: defaultStore.logo,
  sitename:defaultStore.sitename,
  address: defaultStore.address,
  postal:defaultStore.postal,
  telephone: defaultStore.telephone,
email:defaultStore.email,
loaded:0,
customersite:0
    };
    
const defaultState = fromJS({
   logo: header.logo,
  sitename:header.sitename,
  address: header.address,
  postal:header.postal,
  telephone: header.telephone,
email:header.email,
loaded:header.loaded,
customersite:header.customersite,
});
/*
const defaultState =
{
    focused: false,
    mouseIn: false,
    hotlist: [],
    page: 1,
    totalpage: 1
};
*/
const todo = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_FOCUS: {
      // const newState = JSON.parse(JSON.stringify(state));
      //newState.focused = true;
      // return newState;
      return state.set("focused", true);
    }
    case actionTypes.SEARCH_BLUR: {
      return state.set("focused", false);
    }
    case "CHANGE_DOMAIN": {
    
 sessionStorage.setItem("header", JSON.stringify(action));
      return state.merge({
        logo: action.logo,
        sitename: action.sitename,
        address: action.address,
        postal: action.postal,
        telephone: action.telephone,
        email:action.email,
        loaded:action.loaded,
      });
    } /**/
    case actionTypes.MOUSE_ENTER: {
      return state.set("mouseIn", true);
    }
    case actionTypes.MOUSE_LEAVE: {
      return state.set("mouseIn", false);
    }
    case actionTypes.CHANGE_PAGE: {
      return state.set("page", action.page);
      //  return state.set('hotlist',action.page);
    }
    default: {
      return state;
    }
  }
};
export default todo;
