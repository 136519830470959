import { AJAROOT, bookButtonColor } from "@/common/gdata/global.js";
import React from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
//import errorPic from "../statics/error.png";
//import gouPic from "../statics/gou.png";
//import { Fragment } from "react";
import { getPeriodnoFromDate_YYYYMMDD, MyToastShow } from "@/jscript/common.js";
import SignatureCanvas from "react-signature-canvas";
import $ from "jquery";
import "@/css/react-datepicker.css";
import "@/css/user.css";
import DatePicker from "react-datepicker";

const LCss = {
  agree: {
    padding: "15px",
  },
  conditioncheck: {
    display: "flex",

    alignContent: "center",
    paddingLeft: "5px",
    Height: "25px",
    lineHeight: "25px",
  },
  signdiv: {
    verticalAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  signcave: {
    height: "52px",
    lineHeight: "52px",
    width: "202px",

    border: "1px solid #ccc",
    position: "relative",
    float: "left",
    textAlign: "left",
  },
  canve: {
    background: "#fff",
    position: "absolute",
    top: 0,
    left: 0,
  },
};
export function getEclaimData(ele, username) {
  const { myusername, ukey } = ele.props;
  if (username == "") username = myusername;
  var geturl =
    AJAROOT +
    "/getaja/eclaim.aspx?u=" +
    myusername +
    "&username=" +
    username +
    "&ukey=" +
    encodeURIComponent(ukey) +
    "";
console.log(geturl);
  axios.get(geturl).then((res) => {
    const result = res.data;

    // this.setState({}); /**/
    let haveimg1 = 0;

    var firstname = result.firstname;
    var lastname = result.lastname;
    var ec_firstname = result.ec_firstname;
    var ec_lastname = result.ec_lastname;
    

    let ec_signdate = result.ec_signdate;
    if (ec_signdate == null) ec_signdate = "";
    if (ec_signdate != "") {
      ec_signdate = new Date(ec_signdate);
    }

    let ec_birthday = result.ec_birthday;
    if (ec_birthday == null) ec_birthday = "";
    if (ec_birthday != "") {
      ec_birthday = getPeriodnoFromDate_YYYYMMDD(new Date(ec_birthday));

    }

    let ec_sign = result.ec_sign;
    if (ec_sign == null) ec_sign = "";
    if (ec_sign != "") {
      haveimg1 = 1;
    }

    //this.canvasRef1.current.fromDataURL(patientsign);
    // this.canvasRef2.current.fromDataURL(guardiansign);
    // this.canvasRef3.current.fromDataURL(practitionersign);
    // if (zsxm === "") zsxm = username;
    ele.setState({
      ec_birthday:ec_birthday,
      firstname: firstname,
      lastname: lastname,

      ec_firstname: ec_firstname,
      ec_lastname: ec_lastname,
      comname: result.comname,
      
      ec_address: result.address,
      ec_province: result.province,
      ec_city: result.city,
      ec_postal: result.postal,

      ec_insurancepayer: result.ec_insurancepayer,
      ec_membername: result.ec_membername,
      ec_policynumber: result.ec_policynumber,
      ec_certificated: result.ec_certificated,

      ec_secondmembername: result.ec_secondmembername,

      ec_sign: ec_sign,
      ec_signdate: ec_signdate,

      haveimg1: haveimg1,
    });
  });
}
export function EclaimForm(ele) {
  if (ele.state.ec_sign == null) return <></>;
  return (
    <div className="PageNext" id="content">
      {ele.state.isprint && <div className="printtitle">{ele.state.title}</div>}
      <div
        className="row"
        style={{ paddingTop: "15px", paddingBottom: "15px" }}
      >
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
          lnstructions: This fonm must be filled out when claims are submitted
          electronically by the provider on the patient's behalPlease retain
          this form in the patient's file for veritfication purposes for two
          years following closure of the patient file.
        </div>
      </div>

      <div className="row" style={{ paddingLeft: "5px" }}>
        <div
          className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft"
          style={{
            fontSize: "16px",
            marginTop: "10px",
            backgroundColor: "#111",
            color: "white",
          }}
        >
          Provider
        </div>
      </div>

      <div
        className="row"
        style={{ border: "1px solid #ddd", paddingTop: "10px" }}
      >
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
          <div className="row">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
              First and last name or clinic name:
            </div>
            <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 textleft">
              {ele.state.comname}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
              Address:
            </div>
            <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              {ele.state.ec_address}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
              City:
            </div>
            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textleft">
              {ele.state.ec_city}
            </div>
            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
              Province:
            </div>
            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textleft">
              {ele.state.ec_province}
            </div>
            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
              Postal Code:
            </div>
            <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 textleft">
              {ele.state.ec_postal}
            </div>

            <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 textright"></div>
          </div>
        </div>
      </div>
      <div className="row" style={{ paddingLeft: "5px" }}>
        <div
          className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft"
          style={{
            fontSize: "16px",
            marginTop: "10px",
            backgroundColor: "#111",
            color: "white",
          }}
        >
          Patient
        </div>
      </div>
      <div
        className="row"
        style={{ border: "1px solid #ddd", paddingTop: "10px" }}
      >
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
          <div className="row">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            Patient First name:
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          {ele.state.firstname}
              
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            Patient Last name:
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              {ele.state.lastname} 
            
            </div>
          </div>

          

          <div className="row">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
              Primany coverage insurer/payer:
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <input
                className="printField"
                style={{ width: "150px" }}
                value={ele.state.ec_insurancepayer}
                onChange={(ref) => changeField(ele, ref, "ec_insurancepayer")}
              />
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
              Primary coverage plan member name:
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <input
                className="printField"
                style={{ width: "150px" }}
                value={ele.state.ec_membername}
                onChange={(ref) => changeField(ele, ref, "ec_membername")}
              />
            </div>
          </div>


          <div className="row">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            Primary coverage First name:
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
       
              <input
                className="printField"
                style={{ width: "150px" }}
                value={ele.state.ec_firstname}
                onChange={(ref) => changeField(ele, ref, "ec_firstname")}
              />

            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            Primary coverage Last name:
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
 
              <input
                className="printField"
                style={{ width: "150px" }}
                value={ele.state.ec_lastname}
                onChange={(ref) => changeField(ele, ref, "ec_lastname")}
              />

            </div>
          </div>


          <div className="row">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
            Primary coverage Birthday:
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <input
                className="printField"
                value={ele.state.ec_birthday}
                placeholder="YYYY/MM/DD"
                onChange={(ref) => changeField(ele, ref, "ec_birthday")}
              />
         {/*    <DatePicker
                  className="printField"
                  selected={ele.state.ec_birthday}
                  onChange={(ref) => setBirthday(ele, ref, "ec_birthday")}
                  showYearDropdown
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Input date"
                />
 */}
            </div>
          {/*   <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
              Last name:
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
    
              <input
                className="printField"
                style={{ width: "150px" }}
                value={ele.state.lastname}
                onChange={(ref) => changeField(ele, ref, "lastname")}
              />

            </div> */}
          </div>


          <div className="row">
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textright">
            Primary coverage policy number(also referred to as group or
              contract number):
            </div>
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
              <input
                className="printField"
                value={ele.state.ec_policynumber}
                onChange={(ref) => changeField(ele, ref, "ec_policynumber")}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textright">
            Primary coverage certificted(also referred to as
              member/identification number):
            </div>
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
              <input
                className="printField"
                value={ele.state.ec_certificated}
                onChange={(ref) => changeField(ele, ref, "ec_certificated")}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textright">
              (Canada Life only) secondary coverage plan member name:
            </div>
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft">
              <input
                className="printField"
                value={ele.state.ec_secondmembername}
                onChange={(ref) => changeField(ele, ref, "ec_secondmembername")}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{ paddingTop: "10px", border: "0px solid #ccc" }}
      >
        <div
          className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form"
          style={{ backgroundColor: "#f6f6f6", padding: "10px" }}
        >
          <div>
            <div
              style={{
                fontSize: "19px",
                fontWeight: "bold",
                textIndent: "15px",
              }}
            >
              Consent to collect and exchange personal information
            </div>

            <div style={LCss.agree}>
              <div style={{ fontWeight: "bold" }}>Purpose</div>
              Personal information that we collect and disclose about you, and
              if applicable, is used by the insurer, and/or plan administrator
              of your group benefits plan, its affiliates and their service
              provioer(s) for the purposes of assessing eligibility for your
              claims, underwiting, investigating, auditing and otherwise
              administering the croup benefits plan, including the investigation
              of fraud and / or plan abuse and for internal data management and
              data analytical purposes.
            </div>
            <div style={LCss.agree}>
              <div style={{ fontWeight: "bold" }}>
                Authorization and consent
              </div>
              I authorize my healthcare provider to collect, use and disclose
              personal information concerning any claims submitted on my behalf
              with the insurer and/or plan administrator and their service
              provider(s) for the above purposes.
            </div>
            <div style={LCss.agree}>
              <div>
                I authorize such insurer and / or plan administrator and their
                service provideri(s) to:
              </div>
              <div>
                <span className="dot"></span>use my perscnal information for the
                above purposes
              </div>
              <div>
                <span className="dot"></span>exchange personal information with
                any individual or organization, inclucing heathcare
                professionals, investigative agencies. insurers and reinsurers,
                and adinistrators of government benefits, or other benefits
                programs, other organizatons, or service providers working with
                such insurer and/or plan administrator or any of the toregoing,
                when relevant for the above purposes.
              </div>

              <div>
                <span className="dot"></span>where applicable exchange personal
                intormation concerning any claims with any assignee of benefits
                payable and exchange persona! informetion for the above purposes
                electronically or in any other manner.
              </div>
            </div>
            <div style={LCss.agree}>
              I understand that personal information may be subject to
              disclosure to those authorized under applicable law.
            </div>

            <div style={LCss.agree}>
              I agree that a photocopy or electronic version of this
              authorization shall be as valid as the original, and may remain in
              effect for the continued administration of the group benefits
              plan.
            </div>

            <div style={LCss.agree}>
              In the event there is suspicion and/or evidence of fraud and/or
              plan abuse concerning any claims submitted, I acknowledge and
              agree that the insurer and/or plan administrator and their service
              provider(s) may use and disclose relevant personal information to
              any relevant organization including law enforcement bodies,
              regulatory bodies, government organizations, medical suppliers and
              other insurers, and where applicable my employer or benefit plan
              sponsor, for the purposes of investigation and prevention of fraud
              and/or benefit plan abuse. I understand that the submission of
              fraudulent claims is a criminal offence.
            </div>

            <div style={LCss.agree}>
              If there is an overpayment, I authorize the recovery of the full
              amount of the overpayment from any amount payable under the group
              benefits plan, and the exchange of personal information with other
              persons or organizations, including credit agencies and, where
              applicable, my benefit plan sponsor, for that purpose.
            </div>

            <div style={LCss.agree}>
              If the patient is a person other than myself, I confirm that the
              patient has given their consent to provide their personal
              information for the healthcare provider and the insurer and/or
              plan administrator and their service provider(s) to use and
              disclose their personal information as set out above.
            </div>
            <div
              style={Object.assign({}, LCss.agree, LCss.conditioncheck, {
                fontWeight: "bold",
                marginLeft: "5px",
              })}
            >
              <input
                type="checkbox"
                name="agree1"
                value="Y"
                onChange={(ref) => setCheck(ele, ref, "agree1")}
                checked={ele.state.agree1 == "Y" ? true : false}
                style={{
                  width: "18px",
                  height: "18px",
                  marginRight: "5px",
                }}
              ></input>
              I accept the terms and conditions
            </div>
            <div style={LCss.agree}>
              <div style={{ fontWeight: "bold" }}>Benefit assignment form</div>I
              hereby assign benefits payable for the eligible claims to the
              healthcare provider responsible for submitting my claims
              electronically to the group benefits plan and I authorize the
              insurer/plan administrator to issue payment directly to such
              provider. In the event my claim(s) are declined by the
              insurer/plan administrator, I
            </div>
            <div style={LCss.agree}>
              I acknowledge and agree that the insurer/plan administrator is
              under no obligation to accept this benefit assignment form, that
              any benefit payment made in accordance with this benefit
              assignment form will discharge the insurer/plan administrator of
              its obligations with respect to that benefit payment, and that in
              the event the benefit payment is made to me, the insurer/plan
              administrator will also be discharged of its obligation with
              respect to that benefit payment.
            </div>
            <div style={LCss.agree}>
              I understand that this assignment will apply to all eligible
              claims submitted electronically by my healthcare provider and that
              I may revoke it at any time by providing written notice to the
              insurer/plan administrator.
            </div>
            <div style={LCss.agree}>
              If I am a spouse or dependent, I confirm that I am authorized by
              the plan member to execute an assignment of benefit payments to
              the healthcare provider.
            </div>

            <div
              style={Object.assign({}, LCss.agree, LCss.conditioncheck, {
                fontWeight: "bold",
                marginLeft: "5px",
              })}
            >
              <input
                type="checkbox"
                name="agree2"
                value="Y"
                onChange={(ref) => setCheck(ele, ref, "agree2")}
                checked={ele.state.agree2 == "Y" ? true : false}
                style={{
                  width: "18px",
                  height: "18px",
                  marginRight: "5px",
                }}
              ></input>
              I accept the terms and conditions
            </div>
          </div>

          <div className="row" style={{ paddingTop: "15px", height: "60px" }}>
            <div
              className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright bold"
              style={{ height: "50px", lineHeight: "50px" }}
            >
              Signature of plan member
            </div>
            <div
              className="col-xs-3 col-sm-3 col-md-3 col-lg-3"
              style={LCss.signdiv}
            >
              <div style={LCss.signcave}>
                {ele.state.haveimg1 == 0 ? (
                  <SignatureCanvas
                    penColor="black"
                    ref={ele.canvasRef1} //用于调用插件的API
                    canvasProps={{
                      width: 200,
                      height: 50,
                      className: "sigCanvas",
                      style: LCss.canve,
                    }}
                  />
                ) : (
                  <div
                    style={{
                      float: "center",

                      textAlign: "center",
                      height: "50px",
                      lineHeight: "50px",
                    }}
                  >
                    <img src={ele.state.ec_sign} style={{}}></img>
                  </div>
                )}
              </div>
              <button
                style={{
                  float: "left",
                  marginLeft: "10px",
                  height: "30px",
                  lineHeight: "30px",
                }}
                onClick={() => clearCanv(ele, 1)}
              >
                {ele.state.haveimg1 == 1 ? "Reset" : "Clear"}
              </button>
            </div>

            <div
              className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textleft bold"
              style={{
                height: "50px",
                verticalAlign: "center",
                display: "flex",
              }}
            >
              <div
                style={{
                  float: "left",
                  paddingRight: "15px",
                  height: "50px",
                  lineHeight: "50px",
                }}
              >
                Date:
              </div>
              <div
                style={{
                  float: "left",
                  height: "50px",
                  lineHeight: "50px",
                }}
              >
                <DatePicker
                  className="printField"
                  selected={ele.state.ec_signdate}
                  onChange={(ref) => setBirthday(ele, ref, "ec_signdate")}
                  showYearDropdown
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Input date"
                />
              </div>
            </div>
          </div>

          <div style={LCss.agree}>
            All information contained herein is protected by privacy laws
            including the Personal Information Protection and Electronic
            Documents Act (PIPEDA) and all the corresponding provincial
            legislation. All users agree to protect the personal health
            information contained herein from unauthorized use, disclosure,
            loss, theft, or compromise in accordance with the above noted laws
            and with at least the same care employed to protect their own
            confidential information. Any unauthorized access, disclosure or use
            of this information is illegal.
          </div>
        </div>
      </div>
      {!ele.state.isprint && (
        <div className="row">
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter postresult"
            style={{ width: "70%", marginTop: "10px", height: "25px" }}
          >
            {ele.state.postresult}
          </div>
        </div>
      )}
      {!ele.state.isprint && (
        <div className="row" style={{ paddingTop: "5px" }}>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter">
            <button
              className="submit"
              onClick={() => saveEclaimForm(ele,1)}
             style={{ background: bookButtonColor,color:"white" }}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

function setSerData(ele, index) {
  let serviousdata = ele.state.seriousdata;
  let val = serviousdata[index].a;
  val = (val + 1) % 2;
  serviousdata[index].a = val;
  ele.setState({
    seriousdata: serviousdata,
  });
}

function setCheck(ele, ref, field) {
  let val = "";
  if (ref.target.checked) {
    val = "Y";
  } else {
    val = "N";
  }
  /*     if (val == "") val = "N";
    if (val == "N") val = "Y";
    else val = "N";
 */
  if (field == "agree1") {
    ele.setState({
      agree1: val,
    });
  } else if (field == "agree2") {
    ele.setState({
      agree2: val,
    });
  }
}
function clearCanv(ele, index) {
  if (index == 1) {
    if (ele.state.haveimg1 == 0) ele.canvasRef1.current.clear();
    ele.setState({
      haveimg1: 0,
    });
  } else if (index == 2) {
    if (ele.state.haveimg2 == 0) ele.canvasRef2.current.clear();
    ele.setState({
      haveimg2: 0,
    });
  } else if (index == 3) {
    if (ele.state.haveimg3 == 0) ele.canvasRef3.current.clear();
    ele.setState({
      haveimg3: 0,
    });
  }
}
function setBirthday(ele, val, field) {
  let _date = val;
  if (val == null) _date = "";
  if (_date != "") {
/*     let date = new Date(
      val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
    );

    _date = val; */
  }
  if (field == "ec_birthday") {
    ele.setState({
      ec_birthday: _date,
    });
  } else if (field == "patientsigndate") {
    ele.setState({
      patientsigndate: _date,
    });
  } else if (field == "guardiansigndate") {
    ele.setState({
      guardiansigndate: _date,
    });
  } else if (field == "practitionersigndate") {
    ele.setState({
      practitionersigndate: _date,
    });
  } else if (field == "ec_signdate") {
    ele.setState({
      ec_signdate: _date,
    });
  }
}
function changeField(ele, e, field) {
  let val = e.target.value;
  if (field == "username")
    ele.setState({
      username: val,
    });
  else if (field == "firstname")
    ele.setState({
      firstname: val,
    });
    
  else if (field == "lastname")
    ele.setState({
      lastname: val,
    });
  else if (field == "password")
    ele.setState({
      password: val,
    });
  else if (field == "telephone")
    ele.setState({
      telephone: val,
    });
  else if (field == "email")
    ele.setState({
      email: val,
    });
  else if (field == "address") {
    ele.setState({
      address: val,
    });
  } else if (field == "city")
    ele.setState({
      city: val,
    });
  else if (field == "province")
    ele.setState({
      province: val,
    });
  else if (field == "postal")
    ele.setState({
      postal: val,
    });
  else if (field == "contactname")
    ele.setState({
      contactname: val,
    });
  else if (field == "contacttel")
    ele.setState({
      contacttel: val,
    });
  else if (field == "familydoc")
    ele.setState({
      familydoc: val,
    });
  else if (field == "familydoctel")
    ele.setState({
      familydoctel: val,
    });
  else if (field == "familydocaddress")
    ele.setState({
      familydocaddress: val,
    });
  else if (field == "insur_massage")
    ele.setState({
      insur_massage: val,
    });
  else if (field == "insur_acupuncture")
    ele.setState({
      insur_acupuncture: val,
    });
  else if (field == "insur_osteopath")
    ele.setState({
      insur_osteopath: val,
    });
  else if (field == "insur_physiotherapy")
    ele.setState({
      insur_physiotherapy: val,
    });
  else if (field == "insur_chiropractic")
    ele.setState({
      insur_chiropractic: val,
    });
  else if (field == "insur_naturopath")
    ele.setState({
      insur_naturopath: val,
    });
  else if (field == "insur_company")
    ele.setState({
      insur_company: val,
    });
  else if (field == "insur_policynum")
    ele.setState({
      insur_policynum: val,
    });
  else if (field == "insur_memberid")
    ele.setState({
      insur_memberid: val,
    });
  else if (field == "seriousothers")
    ele.setState({
      seriousothers: val,
    });
  else if (field == "whatallergy")
    ele.setState({
      whatallergy: val,
    });
  else if (field == "ongoinghealthy")
    ele.setState({
      ongoinghealthy: val,
    });
  else if (field == "longtertreat")
    ele.setState({
      longtertreat: val,
    });
  else if (field == "patientsign")
    ele.setState({
      patientsign: val,
    });
  else if (field == "guardiansign")
    ele.setState({
      guardiansign: val,
    });
  else if (field == "practitionersign")
    ele.setState({
      practitionersign: val,
    });
  else if (field == "password")
    ele.setState({
      password: val,
    });
  else if (field == "haveinsurance") {
    val = "";
    if (e.target.checked) {
      val = 1;
    } else {
      val = 0;
    }
    ele.setState({
      haveinsurance: val,
    });
  }
  else if (field == "ec_firstname")
    ele.setState({
      ec_firstname: val,
    });
    else if (field == "ec_lastname")
      ele.setState({
        ec_lastname: val,
      });
      else if (field == "ec_birthday")
        ele.setState({
          ec_birthday: val,
        });
  if (field == "ec_insurancepayer")
    ele.setState({
      ec_insurancepayer: val,
    });
  else if (field == "ec_membername")
    ele.setState({
      ec_membername: val,
    });
  else if (field == "ec_policynumber") {
    ele.setState({
      ec_policynumber: val,
    });
  } else if (field == "ec_certificated")
    ele.setState({
      ec_certificated: val,
    });
  else if (field == "ec_secondmembername")
    ele.setState({
      ec_secondmembername: val,
    });
}

export function saveEclaimForm(ele,needyz=0) {
  let emptysign =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

  const { myusername, ukey } = ele.props;
  // document.getElementById("result2").style.color = "#000000";

  ele.setState({
    postresult: "Waiting....",
  });
  // document.getElementById("result2").innerHTML = "正在提交....";

  //var gzhnickname = this.state.upgzhnickname;
  //var comname = this.state.upcomname;

  let ec_signdate = ele.state.ec_signdate;
  if (ec_signdate != "") {
    ec_signdate =
      ec_signdate.getFullYear() +
      "-" +
      (ec_signdate.getMonth() + 1) +
      "-" +
      ec_signdate.getDate();
  }

  let ec_birthday = ele.state.ec_birthday;
 /*  if (ec_birthday != "") {
    ec_birthday =
    ec_birthday.getFullYear() +
      "-" +
      (ec_birthday.getMonth() + 1) +
      "-" +
      ec_birthday.getDate();
  }
 */
  let ec_sign = ele.state.ec_sign;
  if (ele.state.haveimg1 == 0) {
    ec_sign = ele.canvasRef1.current.getTrimmedCanvas().toDataURL("image/png");
    if (ec_sign == emptysign) ec_sign = "";
  }

  ele.setState({
    ec_sign: ec_sign,
  });
  const postdata = {
    username: ele.state.username,
    // telephone: telephone,
    firstname: ele.state.firstname,
    lastname: ele.state.lastname,
    ec_firstname: ele.state.ec_firstname,
    ec_lastname: ele.state.ec_lastname,
    //  address: this.state.address,

    ec_insurancepayer: ele.state.ec_insurancepayer,
    ec_membername: ele.state.ec_membername,
    ec_policynumber: ele.state.ec_policynumber,
    ec_certificated: ele.state.ec_certificated,
    
    ec_secondmembername: ele.state.ec_secondmembername,
    ec_birthday:ec_birthday,
    ec_sign: ec_sign,
    ec_signdate: ec_signdate,
  };
 
  // return;
  const this2 = ele;
  var error = "";

  if (ele.state.ec_firstname == "") error = "Please fill in firstname";
  else if (ele.state.ec_lastname == "") error = "Please fill in lastname";
  else if (ele.state.agree1 != "Y")
    error = "You should agree with first terms and conditions";
  else if (ele.state.agree2 != "Y")
    error = "You should agree with second terms and conditions";
  else if (ec_sign == "") error = "Please signature";
  // else if (clientsigndate == "") error = "Please fill in signature date";

  //document.getElementById("result2").style.color = "red";
  if (error !== ""&&needyz==1) {
    ele.setState({
      postresult: error,
    });
    return;
  }
    $.ajax({
      type: "POST",
      dataType: "json",
      url:
        AJAROOT +
        "/postaja/savexx.aspx?action=saveeclaim&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "",
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
   
        var r = res.r;
        var msg = res.s;

        if (r === "1") {
          //  this.postresult = "登陆成功！";
          MyToastShow(
            this2,
            2,
            "Successfully!",
            200,
            120,
            "success",
            "addemployee"
          );
          this2.setState({
            postresult: "",
          });
          //if (myusername == this2.state.username)
          //this2.props.history.push("/user");
        } else {
          //document.getElementById("result2").style.color = "red";
          MyToastShow(this2, 2, msg, 200, 120, "fail", "addemployee");
          this2.setState({
            postresult: "",
          });

          //  document.getElementById("result2").innerHTML = msg;
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {});
  }

