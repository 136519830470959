import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import axios from "axios";
import $ from "jquery";
import { AJAROOT } from "@/common/gdata/global.js";
import queryString from "query-string";
import { checkUserLogin, MyToast, MyToastShow,getPeriodnoFromDate_YYYYMMDD } from "@/jscript/common.js";

import "@/css/user.css";
import "@/css/react-datepicker.css";
import rjt from "../../../statics/rjt.png";
import DatePicker from "react-datepicker";
class Index extends Component {
  constructor(props) {
    super(props);
    this.posted = false;
    this.state = {
      //  myu: "",
      title: "",
      editid: "",
      username: "",
      firstname: "",
      lastname: "",
      telephone: "",
      email: "",
      address: "",
      birthday: "",//new Date("1990-1-1"),
      gender: "F",
      haveinsurance: 1,
      password: "",
      remark: "",
      selectedbranch: ",",
      branchlist: [],

      postresult: "",

      mytoast: {
        show: false,
      },
    };
  }
  render() {
    const { myusername } = this.props;
    //  let menujson = Menu();
    // console.log(menujson);

    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;

    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px", width: "90%" }}
      >
        <div className="pagetitle">
          <div style={{ float: "left" }}>
            <Link to={"/user/customer/list"}>Customer List</Link>{" "}
            <img src={rjt} style={{ width: "18px", height: "18px" }} />{" "}
            {this.state.title}
          </div>
          <div
            style={{
              float: "right",
            }}
          >
            <div
              style={{}}
              onClick={() => {
                this.back();
              }}
            >
              <span className="iconfont fanhui">&#xe625;</span>
            </div>
          </div>
        </div>
        {/*  <div className="row " style={{ height: "55px", lineHeight: "55px" }}>
          <div
            className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
            onClick={() => {
              window.history.back(-1);
            }}
          >
            <span className="iconfont fanhui">&#xe625;</span>
          </div>
          <div
            className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textcenter "
            style={{ fontSize: "19px", fontWeight: "bolder" }}
          >
            {this.state.title}
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>
        </div> */}
        <div className="row textright clear">
          <div>
            <Link to="/user/customer/list">
              <input
                type="button"
                name="button1"
                value="Customer List"
                className="add"
              ></input>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * Telephone:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.telephone}
                  onChange={(ref) => this.changeField(ref, "telephone")}
                />
              </div>
            </div>
            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Email:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.email}
                  onChange={(ref) => this.changeField(ref, "email")}
                />
              </div>
            </div>

            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Birthday:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <div
                  style={{
                    width: "200px",
                    float: "left",
                  }}
                >

                  
                     <input
         
                value={this.state.birthday}
                placeholder="YYYY/MM/DD"
                onChange={(ref) => this.changeField(ref, "birthday")}
       
              />{/* 
                  <DatePicker
                    selected={this.state.birthday}
                    onChange={(ref) => this.setBirthday(ref)}
                    showYearDropdown
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Input birthday"
                  /> */}
                </div>
              </div>
            </div>

            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * First Name:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  style={{ width: "200px" }}
                  placeholder="Your first name"
                  value={this.state.firstname}
                  onChange={(ref) => this.changeField(ref, "firstname")}
                />
              </div>
            </div>
            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * Last Name:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  style={{ width: "200px" }}
                  placeholder="Your last name"
                  value={this.state.lastname}
                  onChange={(ref) => this.changeField(ref, "lastname")}
                />
              </div>
            </div>
            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * Address:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  style={{ width: "300px" }}
                  value={this.state.address}
                  onChange={(ref) => this.changeField(ref, "address")}
                />
              </div>
            </div>

            {/* <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Gender:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  type="radio"
                  name="gender"
                  value="F"
                  onChange={() => this.setGender("F")}
                  checked={this.state.gender == "F" ? true : false}
                  style={{
                    width: "15px",
                    height: "15px",
                    padding: "5px",
                  }}
                ></input>
                {" Female"}
                <input
                  type="radio"
                  name="gender"
                  value="M"
                  onChange={() => this.setGender("M")}
                  checked={this.state.gender == "M" ? true : false}
                  style={{
                    width: "15px",
                    height: "15px",
                    padding: "5px",
                    marginLeft: "10px",
                  }}
                ></input>
                {" Male "}
                <input
                  type="radio"
                  name="gender"
                  value="X"
                  onChange={() => this.setGender("X")}
                  checked={this.state.gender == "X" ? true : false}
                  style={{
                    width: "15px",
                    height: "15px",
                    padding: "5px",
                    marginLeft: "10px",
                  }}
                ></input>
                {" Unknow "}
              </div>
            </div> */}

            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Have Insurance:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  type="checkbox"
                  name="haveinsurance"
                  value="Y"
                  onChange={(ref) => this.changeField(ref, "haveinsurance")}
                  checked={this.state.haveinsurance == 1 ? true : false}
                  style={{
                    width: "18px",
                    height: "18px",
                  }}
                ></input>
              </div>
            </div>
            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Password:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.password}
                  onChange={(ref) => this.changeField(ref, "password")}
                />
              </div>
            </div>
            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Remark:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.remark}
                  onChange={(ref) => this.changeField(ref, "remark")}
                />
              </div>
            </div>

            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * Branch:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                {this.state.branchlist.map((item, index) => {
                  return (
                    <div key={"branch" + index} style={{clear:"both"}}>
                      <input
                        className="checkbox"
                        type="checkbox"
                        value={item.username}
                        checked={
                          this.state.selectedbranch.indexOf(
                            "," + item.username + ","
                          ) >= 0
                            ? true
                            : false
                        }
                        onChange={() =>
                          this.selBranch(item.username, item.dianusername)
                        }
                      />
                      {"　" + item.lastname}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 textcenter">
                <button className="submit" onClick={() => this.submit(1)}>
                  Submit
                </button>
              </div>
            </div>
            <div className="row">
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter postresult"
                style={{ width: "70%", marginTop: "10px" }}
              >
                {this.state.postresult}
              </div>
            </div>
          </div>
        </div>
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
  back() {
    window.history.back();
    /*    if (window.confirm("Do you need to save the form?")) {
      this.submit();
      window.history.back();
    } else window.history.back(); */
  }
  setBirthday(val) {
   // console.log("1",val);
    let birthday = "";
    if (val == null) birthday = "";
    else {
   /*    let date = new Date(
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      ); */
      birthday = val;
     /*  console.log("val.getFullYear()",val.getFullYear());
      console.log("(val.getMonth() + 1) ",(val.getMonth() + 1) );
      console.log("val.getDate()",val.getDate());
      console.log("2",date); */
    }

    this.setState({
      birthday: birthday,
    });
  }
  selBranch(username, dianusername) {
      const { parentusername, ukey } = this.props;
   // if (this.props.myusername != dianusername) return;
    let selectedbranch = this.state.selectedbranch;
    if (selectedbranch.indexOf("," + username + ",") >= 0)
      selectedbranch = selectedbranch.replace("," + username + ",", ",");
    else {
      selectedbranch = selectedbranch + username + ",";
    }


    if (parentusername == "") {
      this.setState({
        selectedbranch: selectedbranch,
      });
    } else {
      MyToastShow(
        this,
        2,
        "This item can only be modified by the headquarter",
        200,
        120,
        "fail",
        "addemployee"
      );
    }
  }
  setGender(value) {
    this.setState({
      gender: value,
    });
  }
  changeField(e, field) {
    let val = e.target.value;
    if (field == "username")
      this.setState({
        username: val,
      });
    else if (field == "birthday")
      this.setState({
        birthday: val,
      });
      else if (field == "firstname")
        this.setState({
          firstname: val,
        });
    else if (field == "lastname")
      this.setState({
        lastname: val,
      });
    else if (field == "password")
      this.setState({
        password: val,
      });
    else if (field == "telephone")
      this.setState({
        telephone: val,
      });
    else if (field == "email")
      this.setState({
        email: val,
      });
    else if (field == "address") {
      this.setState({
        address: val,
      });
    } else if (field == "remark")
      this.setState({
        remark: val,
      });
    else if (field == "haveinsurance")
      this.setState({
        haveinsurance: val,
      });
  }

  submit(needyz = 0) {
    if (this.posted) return;

    const { myusername, ukey } = this.props;

    //console.log(this.state.pages);
    // document.getElementById("result2").style.color = "#000000";
    //

    this.setState({
      postresult: "Waiting....",
    });
    // document.getElementById("result2").innerHTML = "正在提交....";
    let dianscope = this.state.selectedbranch;
    let birthday = this.state.birthday;
   /*  if (birthday != "") {
      birthday =
        birthday.getFullYear() +
        "-" +
        (birthday.getMonth() + 1) +
        "-" +
        birthday.getDate();
    } */
    const postdata = {
      uid: myusername,
      editid: this.state.editid,
      lastname: this.state.lastname,
      firstname: this.state.firstname,
      telephone: this.state.telephone,
      email: this.state.email,
      password: this.state.password,
      gender: this.state.gender,
      address: this.state.address,
      haveinsurance: this.state.haveinsurance,
      birthday: birthday,
      remark: this.state.remark,
      dianscope: dianscope,
    };

    const that = this;
    var error = "";

    if (this.state.telephone === "") error = "Please fill in telephone";
    else if (this.state.firstname == "") error = "Please fill in firstname";
    else if (this.state.lastname == "") error = "Please fill in lastname";
    else if (this.state.address == "") error = "Please fill in address";
    else if (dianscope == ",") error = "Please select branch";
    //document.getElementById("result2").style.color = "red";
    if (error !== "" && needyz == 1) {
      this.setState({
        postresult: error,
      });
      return;
    }

    this.posted = true;
    $.ajax({
      type: "POST",
      dataType: "json",
      url:
        AJAROOT +
        "/postaja/savexx.aspx?action=savecustomer&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "",
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
        var r = res.r;

        if (r === 1) {
          //  this.postresult = "登陆成功！";
          MyToastShow(
            that,
            2,
            "Successfully! ",
            150,
            80,
            "success",
            "addbranch"
          );
          that.setState({
            postresult: "Successfully! ",
          });
          //  window.history.back(-1);
          that.props.history.push("/user/customer/list");
        } else {
          MyToastShow(that, 2, res.s, 200, 120, "fail", "addemployee");
          //document.getElementById("result2").style.color = "red";
          that.setState({
            postresult: res.s,
          });

          //  document.getElementById("result2").innerHTML = msg;
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {});
  }
  getBranchData(myusername, ukey, editid) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=branchlist";

    axios.get(geturl).then((res) => {
      const result = res.data;
      let selectedbranch = ",";
      result.list.forEach((element, j) => {
        selectedbranch = selectedbranch + element.username + ",";
      });
      //  let groupright = eval(result.groupright);
      if (editid == "") {
        this.setState({
          selectedbranch: selectedbranch,
          branchlist: result.list,
        });
      } else {
        this.setState({
          //  selectedbranch: selectedbranch,
          branchlist: result.list,
        });
      }
      // this.setState({}); /**/
    });
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;
    var { id } = queryString.parse(this.props.location.search);
    if (id == undefined) id = "";
    var title = "Add Customer";
    if (id != "") {
      title = "Edit Customer";
    }
    document.title = title;
    var { username, telephone, birthday, lastname } = queryString.parse(
      this.props.location.search
    );

    if (username == null) username = "";
    if (telephone == null) telephone = "";
    if (birthday == null) birthday = "";
    if (lastname == null) lastname = "";

    let _birthday = "";
    if (birthday == null) _birthday = "";
    else {
      let date = new Date(birthday);
      _birthday = date;
    }
    //if (birthday == "") _birthday = new Date("1990-1-1");

    this.setState({
      title: title,
      editid: id,
      username: username,
      telephone: telephone,
      birthday: _birthday,
      lastname: lastname,
      //  firstname: firstname,
    });
    this.getBranchData(myusername, encodeURIComponent(ukey), id);
    // getUserIndexData(this, myusername, ukey);
    if (id != "") this.getData(myusername, encodeURIComponent(ukey), id);

    //  this.getData(myusername, ukey);
    //  MyToastShow(this, 200, "Successfully! ", 150, 80, "success", "addgroup");
  }

  getData(myusername, ukey, id) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=customerinfo&id=" +
      id +
      "";

    axios.get(geturl).then((res) => {
      const result = res.data;
      let birthday = result.birthday;
      if (birthday == null) birthday = "";
      if (birthday != "") {
        birthday = getPeriodnoFromDate_YYYYMMDD(new Date(birthday));
      //  birthday = new Date(birthday);
      }

      //  let groupright = eval(result.groupright);
      this.setState({
        editid: result.id,
        username: result.username,
        address: result.address,
        firstname: result.firstname,
        lastname: result.lastname,
        telephone: result.telephone,
        gender: result.gender,
        birthday: birthday,
        email: result.email,
        remark: result.remark,
        selectedbranch: result.dianscope,
      });
      // this.setState({}); /**/
    });
  }
  componentWillUnmount() {
    this.submit(0);
    this.setState = (state, callback) => {
      return;
    };
  }
  componentWillUpdate() {}
  componentDidUpdate() {}
}

const mapStateToProps = (state) => {
  return {
    parentusername: state.getIn(["gdata", "parentusername"]),
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    lastname: state.getIn(["gdata", "lastname"]),
  };
};

export default connect(mapStateToProps, null)(Index);
