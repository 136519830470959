import React, { Component } from "react";
import { connect } from "react-redux";

import $ from "jquery";
import {
  checkUserLogin,
  getUserIndexData,
  getDateFromPeriodno,
  MyToast,
  MyToastShow,
  showSvg,
  IsPC
} from "@/jscript/common.js";

import { AJAROOT, FormKey } from "@/common/gdata/global.js";

import axios from "axios";
import { Link } from "react-router-dom";
import "@/css/user.css";

import UserTop from "@/pages/user/usertop.js";
const IsMobile = !IsPC();
const LCss = {
  header: {
    backgroundColor: "#eee",
    height: "50px",
    lineHeight: "50px",

  },
};

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      lastname: "",
      regtime: "",
      tj: [],
      hba: 0,
      hbb: 0,
      hbc: 0,
      xfze: 0,
      totaljj: 0,
      usertype: 0,
      usertypename: "",
      list: [],
      employeebooklist: [],

      commentpanel: 0,
      currentbookindex: -1,
      stararray: [1, 2, 3, 4, 5],
      starnum: 3,

      error: "",
      mytoast: {
        show: false,
      },
      haveinsurance: 0,
      requiredform: "",
      customerform: [],
      gradelist: [],
      gradenum: 6,
      periodsmsbalance: 0,
      periodbooks: 0,
      totalsms: 0,
      parentusername: "",
      usergrade: 1,
      current: "today"
    };
  }
  render() {
    const { myusername, myusertype, myusergrade, style } = this.props;

    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;
    let title = "Edit Basic Info";
    var lastname = this.state.lastname;
    if (lastname === "") lastname = this.state.myusername;
    let basicform = "";
    let requiredForm = "";
    if (this.state.usertype == 0) {
      basicform = "/user/form/basic";

      if(this.state.requiredform)requiredForm = "/user/form/" + this.state.requiredform + "";
    } else if (this.state.usertype >= 2) {
      basicform = "/userup/upinfo";
      title = "Edit Store Info";
      requiredForm = "";
    } else if (this.state.usertype == 1) {
      basicform = "/userup/upinfo";
      title = "Edit Account Info";
      requiredForm = "";
    }
    //   if (this.state.haveinsurance == 0) requiredForm = "";
    const Now = new Date();
    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px" }}
      >
        <UserTop

          periodsmsbalance={this.state.periodsmsbalance}
          periodbooks={this.state.periodbooks}
          totalsms={this.state.totalsms}
          hba={this.state.hba}
          tj={this.state.tj}
          hbc={this.state.hbc}
          xfze={this.state.xfze}
          expiredate={this.state.expiredate}
          totaljj={this.state.totaljj}
          myusername={myusername}
          myusergrade={myusergrade}
          gradenum={this.state.gradenum}
          gradelist={this.state.gradelist}
          lastname={this.state.lastname}
          regtime={this.state.regtime}
          usertypename={this.state.usertypename}
          usergrade={this.state.usergrade}
          parentusername={this.state.parentusername}
          myusertype={myusertype}
        ></UserTop>

        <div style={{ padding: "10px" }}></div>
        <div className="row " style={LCss.header}>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
            {/*  Recent Books{" "}  */}
            <div class="block childcenter">
              <div class="r childcenter">
                <div onClick={() => this.setCurrent('yesterday')} className={'button marginLeftRight childcenter ' + (this.state.current === 'yesterday' ? 'current' : '')}>Before</div>
                <div onClick={() => this.setCurrent('today')} className={'button marginLeftRight childcenter ' + (this.state.current == 'today' ? 'current' : '')}>Today</div>
                <div onClick={() => this.setCurrent('tomorrow')} className={'button marginLeftRight childcenter ' + (this.state.current == 'tomorrow' ? 'current' : '')}>Future</div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{ border: "1px solid #ddd", borderBottom: "0px" }}
        >
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            {this.props.myusertype == 3 && <div className="row list bold">
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                Customer
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">Service</div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 ">
                Employee
              </div>
              <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 ">Tips</div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 ">Status</div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 "></div>
            </div>}
            {this.state.list.map((item, index) => {
              let fromtime = item.fromtime;
              let totime = item.totime;
              let time = fromtime + "-" + totime;
              // let timestr = getDateFromPeriodno(item.periodno) + " " + time;
              let timestr = item.periodno + " " + time;
              let fromtimestr =
                item.periodno + " " + fromtime;
              let employeedone = item.employeedone;
              // console.log(item)
              /*  
                            let statusstr = "Active";
                            if (status == 1) statusstr = "Inactive"; */

            //  let statusstr = "Active";
            /*   if (employeedone == 1&&item.status==1) statusstr = "Finished";
              else statusstr = "Inactive"; */

              let statusstr = "Active";
              if (employeedone == 1) statusstr = "Inactive";

              const dateTime = new Date(fromtimestr);
              let serviceform = item.serviceform;

              let star = item.star;

              let arr = [];
              for (let j = 0; j < star; j++) {
                arr.push(j);
              }
              //const className = `button marginLeftRight childcenter ${isActive ? 'active' : ''} ${isHighlighted ? 'highlight' : ''} current`;

              return (
                <div key={"treat" + index} className="row list" style={{ paddingBottom: "15px", background: index % 2 == 0 ? "#e5e5e5" : "#fff" }}>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2">
                    <div>{IsMobile ? "Customer: " : ""}{item.customerzsxm}</div>

                    {/*  {item.telephone!=""&&<div>Tel: {item.telephone}</div>} */}
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                    <span className="blockspan" style={{display:'block'}}>{timestr}</span>
                    <span className="blockspan" style={{display:'block'}}>{item.servicetitle}</span>
                    {/*   <span className="blockspan" style={{display:"block"}}>${item.price}</span> */}

                    <span className="blockspan" style={{ display: 'block' }}>${item.price} <span style={{ fontWeight: "bold", paddingLeft: "19px" }}>{item.minutes} Minutes    </span> </span>


                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 ">
                    <div>{IsMobile ? "Employee: " : ""}{item.employeezsxm}</div>
                    <div>
                      {star == -1 && myusertype == 0 ? (
                        <div>
                          <input
                            name="submit1"
                            type="button"
                            value={"Comment"}
                            onClick={() => this.Comment(index, 1)}
                            style={{
                              height: "25px",
                              lineHeight: "25px",
                            }}
                          ></input>
                        </div>
                      ) : (
                        <div>
                          {arr.map((item2, index2) => {
                            return (
                              <div
                                style={{ float: "left" }}
                                key={"star" + index2}
                              >
                                {showSvg("star", 15, 15, "red")}
                              </div>
                            );
                          })}
                        </div>
                      )}{" "}
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-1 ">
                    {IsMobile ? "Tips: " : ""}$ {item.tip}
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-1" style={{ display: "flex", flexWrap: IsMobile ? "nowrap" : "wrap", alignItems: "center" }}>
                    <div style={{ paddingRight: "10px", }}>{statusstr}</div>

                    {myusertype >= 1 &&
                      employeedone == 0 &&
                      (
                        <div style={{ flexBasis: "100%" }}>
                          <input
                            name="submit1"
                            type="button"
                            value={employeedone == 0 ? "I Done" : "I Undone"}
                            onClick={() =>
                              this.done(index, item.id, (employeedone + 1) % 2)
                            }
                            style={{
                              height: "25px",
                              lineHeight: "25px",
                              float: "left"
                            }}
                          ></input>
                        </div>
                      )}
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 "  style={{paddingRight:"10px",textAlign:"right"}}>
                  {myusertype >= 1 &&<div style={{border:"0px solid red"}}>
                                  
 <a
                    href={"/user/customer/notelist?username=" + item["username"] + ""}
                  >
                    Note-List
                  </a>
                 
                  {"　"}
 <a
                    href={"/user/customer/planlist?username=" + item["username"] + ""}
                  >
                    Plan-List
                  </a>
                
                  {"　"}
 <a
                    href={" /user/form/health?username=" + item["username"] + ""}
                  >
                    Health
                  </a>

                 
</div>}
                    {serviceform.map((item2, index2) => {
                      let cust = item2["cust"];
                      let path = item2["key"].toLowerCase();
                      let color = "#111";
                      if (item2.u != "1") color = "blue";
                      if (
                        myusertype >= 2 &&employeedone==0 &&
                        (path == "treatnote" || path == "acup")
                      )
                        return;
                      if (myusertype == 0 && cust == 0) return; //是客人不需要的 ，客人看不到
                      // if (myusertype == 1 && cust == 1) return; //是客人的，师傅不显示
                      let url =
                        "/user/form/" + path + "?bookid=" + item.id + "";
                      // if(path=="sensitiveconsent")url="/user/form/" + path + "/" + item.id + "";
                      return (
                        <div
                          key={"form" + index2}
                          style={{
                            float: "right",
                            paddingLeft: "10px",
                            height: "22px",
                            color: color,
                          }}
                        /*  onClick={()=>{this.gotoForm(url)}} */
                        >
                          {/*  <a href={url}>{FormKey[item2.key]}</a> */}
                          <a
                            href={url}
                            style={{ fontSize: "15px", color: color }}
                          >
                            {FormKey[item2.key]}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                  {this.state.commentpanel == 1 && (
                    <div
                      id={"star_" + index + ""}
                      style={{
                        position: "absolute",
                        background: "#fff",
                        width: "360px",
                        height: "200px",
                        border: "1px solid #ddd",
                        top: 30,
                        left: "50%",
                        transform: "translateX(-50%)",
                        zIndex: 9,
                        padding: "10px",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "right",
                          fontSize: "22px",
                          paddingRight: "10px",
                        }}
                        onClick={() => this.Comment(-1, 0)}
                      >
                        X
                      </div>
                      <div
                        style={{
                          width: "100%",

                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        {this.state.stararray.map((item2, index2) => {
                          let color = "#cccccc";
                          if (item2 <= this.state.starnum) color = "red";
                          return (
                            <div
                              onClick={() => this.ClickStar(item2)}
                              style={{ float: "left", paddingRight: "5px" }}
                              key={"star2_" + index2}
                            >
                              {showSvg("star", 50, 50, color)}
                            </div>
                          );
                        })}{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",

                          justifyContent: "center",
                          display: "flex",
                          paddingTop: "19px",
                        }}
                      >
                        <input
                          onClick={() => this.submitstar()}
                          type="button"
                          name="schbut"
                          value=" Submit "
                          className="add"
                        ></input>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        {basicform != "" && (
          <div className="row menu">
            <Link to={basicform}>
              <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 ">
                <span className="iconfont">&#xe68a;</span>
                {title}
              </div>
              <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 textright">
                <span className="iconfont">&#xe60b;</span>
              </div>
            </Link>
          </div>
        )}
        {myusertype == 0 && this.state.haveinsurance == 1 &&requiredForm!=''&& (
          <div className="row menu">
            <Link to={requiredForm}>
              <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 ">
                <span className="iconfont">&#xe652;</span>
                Required Information
              </div>
              <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 textright">
                <span className="iconfont">&#xe60b;</span>
              </div>
            </Link>
          </div>
        )}

        {myusertype == 0 &&
          this.state.haveinsurance == 1 &&
          this.state.customerform.map((item, index) => {
            let url = "/user/form/" + item.key.toLowerCase();
let name=FormKey[item.key];

            return (
              <div className="row menu" key={"frm" + index}>
                <Link to={url}>
                  <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 ">
                    <span className="iconfont">&#xe652;</span>
                    {name}
                  </div>
                  <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 textright">
                    <span className="iconfont">&#xe60b;</span>
                  </div>
                </Link>
              </div>
            );
          })}

        <div className="row menu">
          <Link to="/userup/uppwd">
            <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 ">
              <span className="iconfont">&#xe608;</span>Edit Password
            </div>
            <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 textright">
              <span className="iconfont">&#xe60b;</span>
            </div>
          </Link>
        </div>
        <div className="row menu">
          <Link to="/logout">
            <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 ">
              <span className="iconfont">&#xe623;</span>Logout
            </div>
            <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 textright">
              <span className="iconfont">&#xe60b;</span>
            </div>{" "}
          </Link>
        </div>
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
  gotoForm(router) {
    this.props.history.push(router);
  }
  setCurrent(val) {
    const { myusername, ukey, myusertype } = this.props;



    this.setState({
      current: val,


    },
      () => {
        this.getList(myusername, ukey);

      }
    );
  }
  submitstar() {
    if (this.state.currentbookindex == -1 || this.state.starnum == -1) return;
    const that = this;
    const { myusername, ukey, myusertype } = this.props;
    const postdata = {
      bookid: this.state.list[this.state.currentbookindex].id,
      star: this.state.starnum,
    };

    $.ajax({
      type: "POST",
      dataType: "json",
      url:
        AJAROOT +
        "/postaja/savexx.aspx?action=savestar&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "",
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
        var r = res.r;
        var msg = res.msg;

        if (r === "1") {
          let list = that.state.list;
          list[that.state.currentbookindex].star = that.state.starnum;
          //  this.postresult = "登陆成功！";
          MyToastShow(
            that,
            2,
            "We appreciate your feedback.",
            150,
            120,
            "success",
            "addbranch"
          );

          that.setState({
            list: list,
            postresult: "Successfully, we appreciate your feedback.",
            commentpanel: 0,
            currentbookindex: -1,
          });
          if (that.state.starnum == 5) {
            const googleurl =
              "https://www.google.com/search?hl=zh-CN&gl=ca&q=Alfa+Spa+%26+Wellness+(Massage+Therapy,facial+%26+Laser),+1490+Major+MacKenzie+Dr+W+d11,+Maple,+ON+L6A+4H6&ludocid=12039839314874764622&lsig=AB86z5W1jTrMa8nmBvbdbVQlU95p#lrd=0x882b296ea5a87b31:0xa7161a112be48d4e,3";

            // that.props.history.push(googleurl);
            const w = window.open("_blank"); //这里是打开新窗口

            w.location.href = googleurl; //这样就可以跳转了
          }
        } else {
          MyToastShow(
            that,
            2,
            "Unsuccessfully! Please try later. ",
            150,
            120,
            "faise",
            "addbranch"
          ); //document.getElementById("result2").style.color = "red";
          that.setState({
            postresult: msg,
            commentpanel: 0,
            currentbookindex: -1,
          });
        }
      })
      .fail(function (xhr, textStatus, errorThrown) { });
  }
  ClickStar(star) {
    if (this.state.starnum == star) {
      star--;
    }
    this.setState({
      starnum: star,
    });
  }
  Comment(index, show) {
    if (show == 0) index = -1;
    this.setState({
      commentpanel: show,
      currentbookindex: index,
    });
  }
  done(index, id, value) {
    const that = this;
    const { myusername, ukey, myusertype } = this.props;
    const postdata = {
      id: id,
      val: value*1,
      col: "employeedone",
    };

    $.ajax({
      type: "POST",
      dataType: "json",
      url:
        AJAROOT +
        "/postaja/savexx.aspx?action=employeedone&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "",
      data: JSON.stringify(postdata),
    })
      .done(function (data) {
  
        var r = data.r;
        var msg = data.s;

        if (r === 1) {
          let list = that.state.list;
          list[index].employeedone = value*1;
          //  this.postresult = "登陆成功！";
          MyToastShow(
            that,
            2,
            "Successfully! ",
            150,
            80,
            "success",
            "addbranch"
          );
          that.setState({
            list: list,
            postresult: "Successfully",
          });
          // if (myusername == this2.state.username)
          //   this2.props.history.push("/user");
          //  this2.props.history.push("/user");
        } else {
          MyToastShow(that, 2, msg, 150, 80, "faise", "addbranch");
          //document.getElementById("result2").style.color = "red";
          /*   that.setState({
            postresult: msg,
          }); */

          //  document.getElementById("result2").innerHTML = msg;
        }
      })
      .fail(function (xhr, textStatus, errorThrown) { });
  }
  showNote() { }
  componentDidMount() {
    const { myusername, ukey, myusertype } = this.props;
    getUserIndexData(this, myusername, ukey);
    this.getData(myusername, ukey);
    this.getList(myusername, ukey);
    /* console.log(this.props.storeinfo);
        if (this.props.storeinfo) {
          let storeinfo = JSON.parse(this.props.storeinfo);
         
        } */
  }
  getList(myusername, ukey) {
    const { myusertype } = this.props;

    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&current=" +
      this.state.current +
      "&action=booklist&pagesize=200";

    axios.get(geturl).then((res) => {
      const result = res.data;

      if (result.r == 1) {
        this.setState({

          list: result.list,

        });
      }
      // this.setState({}); /**/
    });
  }
  getData(myusername, ukey) {
    const { myusertype } = this.props;

    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +

      "&action=tj";

    axios.get(geturl).then((res) => {
      const result = res.data;

      if (result.r == 1) {
        this.setState({
          tj: result.tj,
          requiredform: result.requiredform,
          customerform: result.customerform,
          //  employeebooklist: result.employeebooklist,
          //   list: result.needtreatlist,
          haveinsurance: result.haveinsurance,
          gradelist: result.gradelist,
          gradenum: result.gradenum,
          periodsmsbalance: result.periodsmsbalance,
          periodbooks: result.periodbooks,
          totalsms: result.totalsms,
          expiredate: result.expiredate,
          parentusername: result.parentusername,
          usergrade: result.usergrade
        });
      }
      // this.setState({}); /**/
    });
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() { }
  componentDidUpdate() { }

}

const mapState = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
    storeinfo: state.getIn(["gdata", "storeinfo"]),

    //usertype: state.getIn(["header", "usertype"]),
  };
};

export default connect(mapState, null)(User);
