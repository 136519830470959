import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import queryString from "query-string";
import { AJAROOT, FormKey } from "@/common/gdata/global.js";
import { Link, withRouter } from "react-router-dom";
import {
  checkUserLogin,
  Fenye,
  MyToast,
  MyToastShow,
  TurnNull,
  showSvg,
IsPC
} from "@/jscript/common.js";
import { Pages } from "@/pages/type/style.js";
import $ from "jquery";

import "@/css/user.css";
import "@/css/react-datepicker.css";
//import rjt from "../../../statics/rjt.png";
import DatePicker from "react-datepicker";
    const IsMobile = !IsPC();
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      title: "",
      list: [],
      schusername: "",

      schaddress: "",
      schtelephone: "",

      schzsxm: "",
      schfromdate: "",
      fromdate: "",
      schtodate: "",
      todate: "",
      error: "",

      postresult: "",
      //  page: 1,
      pagesize: 30,
      totalpage: 0,
      employeelist: [],

      selectedemployee: "",
      schservice: "",

      commentpanel: 0,
      currentbookindex: -1,
      stararray: [1, 2, 3, 4, 5],
      starnum: 3,
      mytoast: {
        show: false,
      },
      //   page: 1,
    };
  }

  render() {
    const { myusername, myusertype } = this.props;

    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;
    let _username = TurnNull(this.props.username);
    let _name = TurnNull(this.props.name);
    let _telephone = TurnNull(this.props.telephone);
    const Now = new Date();

    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px", width: "90%" }}
      >
        <div className="pagetitle">{this.state.title}</div>
        <div className="row textright">
          <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10  textleft schform">
            {/*  <input
              style={{
                float: "left",
              }}
              name="schusername"
              value={this.state.schusername}
              className="schfield"
              placeholder="Input account"
              onChange={(ref) => this.changeField(ref, "schusername")}
            ></input> */}
            {/*      <input
              style={{
                float: "left",
                width: "200px",
              }}
              className="schfield"
              name="schzsxm"
              value={this.state.schzsxm}
              placeholder="Input first name or last name"
              onChange={(ref) => this.changeField(ref, "schzsxm")}
            ></input> */}
            {myusertype >= 1 && (
              <input
                style={{
                  float: "left",
                  width: "150px",
                }}
                className="schfield"
                name="schtelephone"
                value={this.state.schtelephone}
                onChange={(ref) => this.changeField(ref, "schtelephone")}
                placeholder="Account or tel"
              ></input>
            )}
            <input
              style={{
                float: "left",
                width: "150px",
              }}
              className="schfield"
              name="schservice"
              value={this.state.schservice}
              onChange={(ref) => this.changeField(ref, "schservice")}
              placeholder="Service"
            ></input>
            {myusertype >= 2 && (
              <div
                style={{
                  float: "left",
                }}
              >
                <select
                  style={{ width: "90px" }}
                  name="employee"
                  className="schfield"
                  value={this.state.selectedemployee}
                  onChange={(e) => this.setEmployeeUsername(e)}
                  /*  onChange={(ref) => this.setUsername(ref)} */
                >
                  <option value="">All Staff</option>
                  {this.state.employeelist.map((item, index) => {
                    //  const date2 = new Date(arr[0], arr[1], item2);

                    return (
                      <option key={"emply" + index} value={item.username}>
                        {item.firstname}{" "+item.lastname}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            <div
              style={{
                width: "130px",
                float: "left",
              }}
            >
              <DatePicker
                className="schfield schdate"
                selected={this.state.fromdate}
                onChange={(ref) => this.setFromdate(ref)}
                showYearDropdown
                dateFormat="yyyy-MM-dd"
                placeholderText="Input from date"
              />
            </div>
            <div
              style={{
                width: "22px",
                float: "left",
              }}
            >
              -
            </div>
            <div
              style={{
                width: "130px",
                float: "left",
              }}
            >
              <DatePicker
                className="schfield schdate"
                selected={this.state.todate}
                onChange={(ref) => this.setTodate(ref)}
                showYearDropdown
                dateFormat="yyyy-MM-dd"
                placeholderText="Input end date"
              />
            </div>
            <input
              onClick={() => this.search(1)}
              type="button"
              name="schbut"
              value=" Search "
              className="add"
            ></input>
          </div>
          {myusertype >= 2 && (
            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 textright">
              <input
                onClick={() => this.addNew()}
                type="button"
                name="button1"
                value="New Book"
                className="add"
              ></input>
            </div>
          )}
        </div>
        <div style={{ padding: "15px" }}></div>

       {this.props.myusertype==3&& <div className="row">
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold textleft">
            Customer
          </div>
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 bold textleft">
            Service && DateTime
          </div>

          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 bold bold textleft">
            Employee
          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 bold textleft">
            Status
          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 bold textleft">
            Tips
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold textleft">
            Remark
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 bold textleft">Action</div>
        </div>}

        {this.state.list.map((item, index) => {
          let fromtime = item.fromtime.replace(":0", ":00");
          //  let totime = item.totime.replace(":0", ":00");
          //   let time = fromtime + "-" + totime;

          let fromtimestr = item.periodno + " " + fromtime;
          let employeedone = item.employeedone * 1;
          let statusstr = "Active";
          if (employeedone == 1) statusstr = "Inactive";
          //  else if (status == 2) statusstr = "Finished";
          const dateTime = new Date(fromtimestr);

          let zsxm = item.zsxm;
          if (zsxm == null) zsxm = "";
          if (zsxm != "") zsxm = " ," + zsxm;
          let tip = item.tip;
          if (tip == "0") tip = "";
          let star = item.star;

          let arr = [];
          for (let j = 0; j < star; j++) {
            arr.push(j);
          }
         
          // if (_status == "2") _status = "Commented ";
          return (
            <div
              className="row list"
              key={"group" + index}
              style={{ border:"border:1px solid #ccc;",position: "relative", paddingBottom: "10px",background:index%2==0?"#e5e5e5":"#fff" }}
            >
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2">
                <div>{IsMobile?"Customer: ":""}{item.customerzsxm}</div>

               {/*   {item.telephone!=""&&<div>Tel: {item.telephone}</div>} */}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3" 
         >
                <span className="blockspan">
                  {item.periodno} {item.fromtime} - {item.totime}
                </span>
                <span className="blockspan" style={{display:'block'}}>{item.servicetitle}     </span>
               <span className="blockspan" style={{display:'block'}}>${item.price} <span style={{fontWeight:"bold",paddingLeft:"19px"}}>{item.minutes} Minutes    </span> </span>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-1">
                {IsMobile?"Employee: ":""}{item.employeezsxm}
                {star == -1 && myusertype == 0 ? (
                  <div>
                    <input
                      name="submit1"
                      type="button"
                      value={"Comment"}
                      onClick={() => this.Comment(index, 1)}
                      style={{
                        height: "25px",
                        lineHeight: "25px",
                      }}
                    ></input>
                  </div>
                ) : (
                  <div>
                    {arr.map((item2, index2) => {
                      return (
                        <div style={{ float: "left" }} key={"star" + index2}>
                          {showSvg("star",15, 15, "red")}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-1"
                style={{ position: "relative",display:"flex",alignItems:"center" , flexWrap: IsMobile?"nowrap":"wrap"}}
                /*   onDoubleClick={() => this.hiddenDetail("status" + index, 1)} */
              >
                <div style={{paddingRight:"10px",flexBasis: "100%"}}>{statusstr}</div>
                {item.fromtime!=item.fromtime&&item.worktime_check==0&&<div style={{cursor:"pointer",paddingRight:"10px",flexBasis: "100%",color:"#ff9900"}}
                  onClick={() =>
                    this.flushWorktime(item.id,index)
                  }>Warning</div>}
           
                {item.checked==1&&item.userchecked==1&&myusertype >= 1 && Now > dateTime && (
                  <div style={{flexBasis: "100%"}}>
                    <input
                      name="submit1"
                      type="button"
                      value={employeedone == 0 ? "I Done" : "I Undone"}
                      onClick={() =>
                        this.done(index, item.id, (employeedone + 1) % 2)
                      }
                      style={{
                        height: "25px",
                        lineHeight: "25px", 
                      }}
                    ></input>
                   
                  </div>
                )}
                {/*  {statusstr}
                <div id={"status" + index} style={{ display: "none" }}>
                  <span className="blockspan">
                    <input
                      style={{ width: "19px", heigth: "19px" }}
                      type="radio"
                      value="1"
                      checked={item.status == "1" ? true : false}
                      onChange={() => this.selStatus(index, "1")}
                    />
                    Done
                  </span>
                  <span className="blockspan">
                    <input
                      style={{ width: "19px", heigth: "19px" }}
                      type="radio"
                      value="1"
                      checked={item.status == "0" ? true : false}
                      onChange={() => this.selStatus(index, "0")}
                    />
                    Undone
                  </span>
                  <input
                    style={{
                      width: "50px",
                      height: "25px",
                      lineHeight: "25px",
                      borderRadius: "5px",
                      marginTop: "3px",
                    }}
                    onClick={() => this.savefield(index, "status")}
                    type="button"
                    name="schbut"
                    value=" Save "
                  ></input>
                </div> */}
              </div>
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-2"
                style={{ position: "relative" }}
                onDoubleClick={() => this.hiddenDetail("tip" + index, 1)}
              >
                 {IsMobile?"Tips: ":""}$ {item.tip}
                <div id={"tip" + index} style={{ display: "none" }}>
                  <input
                    style={{
                      float: "left",
                      width: "50px",
                      height: "25px",
                    }}
                    className="schfield"
                    name="tip"
                    value={tip}
                    onChange={(ref) => this.changeTip(ref, index)}
                    /*  onClick={(ref) => this.changeTip(ref, index)} */
                  ></input>
                  <input
                    style={{
                      width: "50px",
                      height: "25px",
                      lineHeight: "25px",
                      borderRadius: "5px",
                      marginTop: "3px",
                    }}
                    onClick={() => this.savefield(index, "tip")}
                    type="button"
                    name="schbut"
                    value=" Save "
                  ></input>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 ">
                {item.remark}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 " style={{paddingRight:"10px",textAlign:"right"}}>

              {myusertype >= 1 &&<div style={{width:"100%",clear: "both",border:"0px solid red",  float: "right",}}>
                                  
                                  <a
                                                     href={"/user/customer/notelist?username=" + item["username"] + ""}
                                                   >
                                                     Note-List
                                                   </a>
                                                  
                                                   {"　"}
                                  <a
                                                     href={"/user/customer/planlist?username=" + item["username"] + ""}
                                                   >
                                                     Plan-List
                                                   </a>
                                                 
                                                   {"　"}
                                  <a
                                                     href={" /user/form/health?username=" + item["username"] + ""}
                                                   >
                                                     Health
                                                   </a>
                                 
                                                  
                                 </div>}

                
{/* <pre>{ JSON.stringify(item, null, 2) }</pre> */}
{/* <pre>{ JSON.stringify(item.serviceform, null, 2) }</pre> */}
                {myusertype >= 2 && <div style={{ clear: "both" }}></div>}
                {item.serviceform.map((item2, index2) => {
                  let cust = item2["cust"];
                  let path = item2["key"].toLowerCase();
                
                  let color = "#111";
                  if (item2.u != "1") color = "blue";
                  if (
                    myusertype >= 2 &&employeedone==0&&
                    (path == "treatnote" || path == "acup")
                  )
                    return;
                    
                    //此两项做完才有
                //  if (myusertype == 0 && cust == 0) return; //是客人不需要的 ，客人看不到

                //  if (myusertype == 1 && cust == 1) return; //是客人的，师傅不显示
                  return (
                    <div
                      key={"form" + index2}
                      style={{clear: "both",
                        float: "right",
                        paddingLeft: "10px",
                        height: "20px",
                      }}
                    >
                      <a
                        href={"/user/form/" + path + "?bookid=" + item.id + ""}
                        style={{ fontSize: "15px", color: color }}
                      >
                        {FormKey[item2.key]}
                      </a>
                    </div>
                  );
                })}
              </div>

              {myusertype >= 2 && (
                  <div
                    style={{clear: "both",
                      float: "right",
                      paddingRight: "10px",
                      height: "20px",
                    }}
                  >
                    {"　"}
                    <Link to={"/user/book/panel?id=" + item["id"] + ""}>
                      Update
                    </Link>
                    {"　"}
                    <a
                      href="#"
                      onClick={() =>
                        this.Del(index,item["id"])
                      }
                    >
                      Delete
                    </a>
                  </div>
                )}

              {this.state.commentpanel == 1&&this.state.currentbookindex==index && (
                <div
                  id={"star_" + index + ""}
                  style={{
                    position: "absolute",
                    background: "#fff",
                    width: "360px",
                    height: "200px",
                    border: "1px solid #ddd",
                    top: 30,
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 9,
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      textAlign: "right",
                      fontSize: "22px",
                      paddingRight: "10px",
                    }}
                    onClick={() => this.Comment(-1, 0)}
                  >
                    X
                  </div>
                  <div
                    style={{
                      width: "100%",

                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    {this.state.stararray.map((item2, index2) => {
                      let color = "#cccccc";
                      if (item2 <= this.state.starnum) color = "red";
                      return (
                        <div
                          onClick={() => this.ClickStar(item2)}
                          style={{ float: "left", paddingRight: "5px" }}
                          key={"star2_" + index2}
                        >
                          {showSvg("star",50, 50, color)}
                        </div>
                      );
                    })}{" "}
                  </div>
                  <div
                    style={{
                      width: "100%",

                      justifyContent: "center",
                      display: "flex",
                      paddingTop: "19px",
                    }}
                  >
                    <input
                      onClick={() => this.submitstar()}
                      type="button"
                      name="schbut"
                      value=" Submit "
                      className="add"
                    ></input>
                  </div>
                </div>
              )}
            </div>
          );
        })}

        <div className="row">
          <Pages>
            {Fenye(
              "/user/book/list?username=" +
                _username +
                "&name=" +
                _name +
                "&telephone=" +
                _telephone +
                "&fromdate=" +
                this.props.fromdate +
                "&todate=" +
                this.props.todate +
                "&order=" +
                this.props.order +
                "&oddesc=" +
                this.props.oddesc +
                "&pagesize=" +
                this.state.pagesize +
                "",
              this.props.page,
              this.state.totalpage
            )}
          </Pages>
        </div>

        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
  Del(index,id) {
   const { myusername, ukey } = this.props;
    if (window.confirm("Are you sure to delete it?")) {
       this.DelData(myusername, encodeURIComponent(ukey), id,index);
    } else return;
  }
  DelData(myusername, ukey, id,index) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=delbook&id=" +
      id +
      "";

    axios.get(geturl).then((res) => {
      const result = res.data;

      if (result.r == 1) {
      let list=this.state.list;
      list.splice(index,1);
        this.setState({
      list: list});
        MyToastShow(
          this,
          1000,
          "Successfully! ",
          150,
          80,
          "success",
          "delemployee"
        );
      } else {
        MyToastShow(this, 2, result.s, 200, 120, "fail", "delemployee");
      }
      // this.setState({}); /**/
    });
  }
  submitstar() {
    if (this.state.currentbookindex == -1 || this.state.starnum == -1) return;
    const that = this;
    const { myusername, ukey, myusertype } = this.props;
    const postdata = {
      bookid: this.state.list[this.state.currentbookindex].id,
      star: this.state.starnum,
    };


    $.ajax({
      type: "POST",
      dataType: "json",
      url:
        AJAROOT +
        "/postaja/savexx.aspx?action=savestar&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "",
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
  
        var r = res.r;
        var msg = res.msg;

        if (r === "1") {
          let list = that.state.list;
          list[that.state.currentbookindex].star = that.state.starnum;
          //  this.postresult = "登陆成功！";
          MyToastShow(
            that,
            2,
            "We appreciate your feedback.",
            150,
            120,
            "success",
            "addbranch"
          );

          that.setState({
            list: list,
            postresult: "Successfully, we appreciate your feedback.",
            commentpanel: 0,
            currentbookindex: -1,
          });
          if (that.state.starnum == 5) {
            const googleurl =
              "https://www.google.com/search?hl=zh-CN&gl=ca&q=Alfa+Spa+%26+Wellness+(Massage+Therapy,facial+%26+Laser),+1490+Major+MacKenzie+Dr+W+d11,+Maple,+ON+L6A+4H6&ludocid=12039839314874764622&lsig=AB86z5W1jTrMa8nmBvbdbVQlU95p#lrd=0x882b296ea5a87b31:0xa7161a112be48d4e,3";

            // that.props.history.push(googleurl);
            const w = window.open("_blank"); //这里是打开新窗口

            w.location.href = googleurl; //这样就可以跳转了
          }
        } else {
          MyToastShow(
            that,
            2,
            "Unsuccessfully! Please try later. ",
            150,
            120,
            "faise",
            "addbranch"
          ); //document.getElementById("result2").style.color = "red";
          that.setState({
            postresult: msg,
            commentpanel: 0,
            currentbookindex: -1,
          });
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {});
  }
  ClickStar(star) {
    if (this.state.starnum == star) {
      star--;
    }
    this.setState({
      starnum: star,
    });
  }
  Comment(index, show) {
    if (show == 0) index = -1;
    this.setState({
      commentpanel: show,
      currentbookindex: index,
    });
  }
  done(index, id, value) {
    const that = this;
    const { myusername, ukey, myusertype } = this.props;
    const postdata = {
      id: id,
      val: value*1,
      col: "employeedone",
    };
   
    $.ajax({
      type: "POST",
      dataType: "json",
      url:
        AJAROOT +
        "/postaja/savexx.aspx?action=employeedone&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "",
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
  
        var r = res.r;
        var msg = res.s;

        if (r === 1) {
          let list = that.state.list;
          list[index].employeedone = value*1;
          //  this.postresult = "登陆成功！";
          MyToastShow(
            that,
            2,
            "Successfully! ",
            150,
            80,
            "success",
            "addbranch"
          );
          that.setState({
            list: list,
            postresult: "Successfully",
          });
          // if (myusername == this2.state.username)
          //   this2.props.history.push("/user");
          //  this2.props.history.push("/user");
        } else {
          //document.getElementById("result2").style.color = "red";
          that.setState({
            postresult: msg,
          });

          //  document.getElementById("result2").innerHTML = msg;
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {});
  }
  changeTip(e, index) {
    let val = e.target.value;
    if (isNaN(val)) {
      return;
    }
    let _list = this.state.list;
    _list[index].tip = val;

    this.setState({
      list: _list,
    });
  }
  selStatus(index, val) {
    let _list = this.state.list;
    _list[index].employeedone = val;
    this.setState({
      list: _list,
    });
  }
  savefield(index, field) {
    const { myusername, ukey } = this.props;
    let error = "";
    if (field == "tip") {
      if (this.state.list[index].tip == "") error = "Please input tip";
    }
    //console.log(this.state.pages);
    // document.getElementById("result2").style.color = "#000000";
    //
    if (error != "") {
      MyToastShow(this, 2, error, 200, 120, "fail", "addemployee");
      return;
    }
    this.setState({
      postresult: "Waiting....",
    });
    // document.getElementById("result2").innerHTML = "正在提交....";
    //   let dianscope = this.state.selectedbranch;
let value=this.state.list[index].tip;
if(field=="employeedone")value=this.state.list[index].employeedone;
    const postdata = {
      uid: myusername,
      col: field,
      val:value,
     
      //status: this.state.list[index].status,
      id: this.state.list[index].id+"",
    };

    const that = this;
  
    $.ajax({
      type: "POST",
      dataType: "json",
      url:
        AJAROOT +
        "/postaja/savexx.aspx?action=bookcoledit&u=" +
        myusername +
        "&ukey=" +
        encodeURIComponent(ukey) +
        "",
      data: JSON.stringify(postdata),
    })
      .done(function (res) {
        var r = res.r;

        if (r === 1) {
          //  this.postresult = "登陆成功！";
          MyToastShow(
            that,
            2,
            "Successfully! ",
            150,
            80,
            "success",
            "addbranch"
          );
          that.setState({
            postresult: "Successfully! ",
          });

          //  that.props.history.push("/user/book/list");
        } else {
          MyToastShow(that, 2, res.s, 200, 120, "fail", "addemployee");
          //document.getElementById("result2").style.color = "red";
          that.setState({
            postresult: res.s,
          });

          //  document.getElementById("result2").innerHTML = msg;
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {});
  }
  addNew() {
    let url = "/user/book/panel";

    this.props.history.push(url);
  }
  setEmployeeUsername(e) {
    const text = e.target.options[e.target.selectedIndex].text;
    let val = e.target.value;
    this.setState({
      selectedemployee: val,
    });
  }
  search() {
    const { myusername, ukey } = this.props;

    let fromdate = "";
    if (this.state.fromdate != "") {
      fromdate = this.state.fromdate;
      fromdate =
        fromdate.getFullYear() +
        "-" +
        (fromdate.getMonth() + 1) +
        "-" +
        fromdate.getDate();
    }

    let todate = "";
    if (this.state.todate != "") {
      todate = this.state.todate;
      todate =
        todate.getFullYear() +
        "-" +
        (todate.getMonth() + 1) +
        "-" +
        todate.getDate();
    }
    let service = this.state.schservice;
    let employee = this.state.selectedemployee;
    let telephone = this.state.schtelephone;
    //let order = this.state.order;
    // let page = this.state.page;

    let url =
      "/user/book/list?telephone=" +
      telephone +
      "&service=" +
      service +
      "&employee=" +
      employee +
      "&fromdate=" +
      fromdate +
      "&todate=" +
      todate +
      "&pagesize=" +
      this.state.pagesize +
      "&page=1&order=id";
      
    this.props.history.push(url);
  }
  setFromdate(val) {
    let fromdate = "";
    let schfromdate = "";
    if (val == null) {
      schfromdate = "";
      fromdate = "";
    } else {
      let date = new Date(
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      );
      fromdate = date;
      schfromdate =
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate();
    }

    this.setState({
      fromdate: fromdate,
      schfromdate: schfromdate,
    });
  }
  flushWorktime(bookid,index)
  {

    const { myusername, ukey } = this.props;
    var geturl =
    AJAROOT +
    "/getaja/xx.aspx?u=" +
    myusername +
    "&ukey=" +
    encodeURIComponent(ukey) +
    "&action=reflushbookworktime&bookid="+bookid+"";

  axios.get(geturl).then((res) => {
  
    const result = res.data;
    if(result.r==1)
      {
        let _list = this.state.list;
        _list[index]["worktime_check"] = 1;
        this.setState({
          list: _list,
        });
      }
   

  });
  }
  getEmployeeData(myusername, ukey) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=employeelist";

    axios.get(geturl).then((res) => {
      const result = res.data;

      this.setState({
        employeelist: result.list,
      });
      // this.setState({});
    });
  }
  setTodate(val) {
    let todate = "";
    let schtodate = "";
    if (val == null) {
      schtodate = "";
      todate = "";
    } else {
      let date = new Date(
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      );
      todate = date;
      schtodate =
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate();
    }

    this.setState({
      todate: todate,
      schtodate: schtodate,
    });
  }
  hiddenDetail(ele, show) {
    // document.getElementById(ele).innerHTML = "";
    if (this.props.myusertype <= 1) return;
    if (show == 0) document.getElementById(ele).style.display = "none";
    else document.getElementById(ele).style.display = "block";
  }

  changeField(e, field) {
    let val = e.target.value;

    if (field == "schusername") {
      this.setState({
        schusername: val,
      });
    } else if (field == "schzsxm") {
      this.setState({
        schzsxm: val,
      });
    } else if (field == "schtelephone") {
      this.setState({
        schtelephone: val,
      });
    } else if (field == "schservice") {
      this.setState({
        schservice: val,
      });
    }
  }
  getData(
    myusername,
    ukey,
    page,
    username,
    service,
    employee,
    telephone,
    fromdate,
    todate,
    order,
    oddesc
  ) {
    /*   let name = this.state.schzsxm;
    let birthday = "";
    if (this.state.birthday != "") {
      birthday = this.state.birthday;
      birthday =
        birthday.getFullYear() +
        "-" +
        (birthday.getMonth() + 1) +
        "-" +
        birthday.getDate();
    } */
    // let username = this.state.schusername;
    //let telephone = this.state.schtelephone;
    //let order = this.state.order;
    //let page = this.state.page;

    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&customer=" +
      username +
      "&service=" +
      service +
      "&employee=" +
      employee +
      "&telephone=" +
      telephone +
      "&todate=" +
      todate +
      "&fromdate=" +
      fromdate +
      "&order=" +
      order +
      "&oddesc=" +
      oddesc +
      "&page=" +
      page +
      "&pagesize=" +
      this.state.pagesize +
      "&action=booklist";

    axios.get(geturl).then((res) => {
      const result = res.data;

  if(result.r==1)
  {
      this.setState({
        list: result.list,
        // totalpage: result.num,
        totalpage: Math.ceil(result["num"] / this.state.pagesize),
        //     num: result["num"],
        //  page: page,
      });

      /* */
      const action = {
        type: "change_sch_para",

        //  totalpage: Math.ceil(result["num"] / this.state.pagesize),
        // num: result.num,
        username: username,
        service: service,
        employee: employee,
        telephone: telephone,
        fromdate: fromdate,
        todate: todate,
        order: order,
        oddesc: oddesc,
        page: page,
      };

      this.props.changeSchPara(action);
        }
      // this.setState({}); /**/
    });
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;
    var {
      page,
      username,
      telephone,
      service,
      employee,
      fromdate,
      todate,
      order,
      oddesc,
    } = queryString.parse(this.props.location.search);
    if (page == null) page = 1;
    if (username == null) username = "";
    if (telephone == null) telephone = "";
    if (fromdate == null) fromdate = "";
    if (todate == null) todate = "";
    if (service == null) service = "";
    if (employee == null) employee = "";
    //if (name == null) name = "";
    if (order == null) order = "";
    if (oddesc == null) oddesc = "";

    var title = "Book List";
    document.title = title;

    this.setState({
      title: title,
      selectedemployee:employee
    });

    this.getData(
      myusername,
      encodeURIComponent(ukey),

      page,
      username,
      service,
      employee,
      telephone,
      fromdate,
      todate,
      order,
      oddesc
    );
    this.getEmployeeData(myusername, encodeURIComponent(ukey));
    //if (IsPC()) getUserIndexData(this, myusername, ukey);
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {
    const { myusername, ukey } = this.props;

    var {
      page,
      username,
      telephone,
      service,
      employee,
      fromdate,
      todate,
      order,
      oddesc,
    } = queryString.parse(this.props.location.search);
    if (page == null) page = 1;
    if (order == null) order = "";
    if (oddesc == null) oddesc = "";

    if (username == null) username = "";
    if (telephone == null) telephone = "";
    if (fromdate == null) fromdate = "";
    if (todate == null) todate = "";
    if (service == null) service = "";
    if (employee == null) employee = "";

    if (
      page !== this.props.page ||
      order !== this.props.order ||
      oddesc !== this.props.oddesc ||
      todate !== this.props.todate ||
      username !== this.props.username ||
      telephone !== this.props.telephone ||
      fromdate !== this.props.fromdate ||
      service !== this.props.service ||
      employee !== this.props.employee
    ) {
      this.getData(
        myusername,
        encodeURIComponent(ukey),
        page,
        username,
        service,
        employee,

        telephone,
        fromdate,
        todate,
        order,
        oddesc
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),

    page: state.getIn(["book", "page"]),
    order: state.getIn(["book", "order"]),
    oddesc: state.getIn(["book", "oddesc"]),
    // name: state.getIn(["customer", "name"]),
    username: state.getIn(["book", "username"]),
    telephone: state.getIn(["book", "telephone"]),
    service: state.getIn(["book", "service"]),
    employee: state.getIn(["book", "employee"]),
    fromdate: state.getIn(["book", "fromdate"]),
    todate: state.getIn(["book", "todate"]),
  };
};
const mapDispatch = (dispath) => ({
  changeSchPara(action) {
    dispath(action);
  },
});
export default connect(mapStateToProps, mapDispatch)(withRouter(Index));
