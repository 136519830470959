import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
//import SignatureCanvas from "react-signature-canvas";
import $ from "jquery";
import { AJAROOT } from "@/common/gdata/global.js";
//import MySignatureCanvas from "@/jscript/myCanve.js";
import {
  checkUserLogin,
  IsPC,
  MyToast,
  // MyToastShow,
} from "@/jscript/common.js";
import axios from "axios";
import "@/css/user.css";
import "@/css/react-datepicker.css";

//import DatePicker from "react-datepicker";

const LCss = {
  agree: {
    padding: "15px",
  },
  signdiv: {
    verticalAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  signcave: {
    height: "52px",
    lineHeight: "52px",
    width: "202px",

    border: "1px solid #ccc",
    position: "relative",
    float: "left",
    textAlign: "left",
  },
  canve: {
    background: "#fff",
    position: "absolute",
    top: 0,
    left: 0,
  },
  radio: {
    width: "18px",
    height: "18px",
    marginLeft: "10px",
    marginRight: "3px",
  },
  conditioncheck: {
    display: "flex",

    alignContent: "center",
    paddingLeft: "5px",
    Height: "25px",
    lineHeight: "25px",
  },
  conditiondiv: {
    width: "100%",
    paddingLeft: "5px",
    fontWeight: "bold",
    height: "30px",
    lineHeight: "30px",
  },
  checkout: {
    width: "18px",
    height: "18px",
    marginRight: "5px",
  },
  field: {
    display: "inline-block",
    paddingLeft: "10px",
    paddingRight: "10px",

    borderBottom: "1px solid #000",
    marginRight: "18px",
  },
};
class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //  myu: "",

      title: "",
      error: "",
      editid: "",
      bookid: "",
      customer: "",
      periodno: "",
      fromtime: "",
      totime: "",
      minutes: 0,
      price: 0,
      employeename: "",

      progressillness: "",
      tongue: "",
      coating: "",
      news: "",
      pulse: "",
      herbs: "",
      acupuncture: "",
      othertreatment: "",

      postresult: "",
      mytoast: {
        show: false,
      },
      isprint: false,
    };
  }

  render() {
    const { myusername } = this.props;
    const IsMobile = !IsPC();
    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;
    //var zsxm = this.state.zsxm;
    // if (zsxm === "") zsxm = this.state.myusername;

    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px" }}
      >
        <div className="row " style={{ height: "55px", lineHeight: "55px" }}>
          <div
            className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
            onClick={() => {
              this.back();
            }}
          >
            {!this.state.isprint && (
              <span className="iconfont fanhui">&#xe625;</span>
            )}
          </div>

          <div
            className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textcenter "
            style={{ fontSize: "19px", fontWeight: "bolder" }}
          >
            {!this.state.isprint ? this.state.title : ""}
          </div>

          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            {!this.state.isprint ? (
              <a
                onClick={() =>
                  this.print(
                    "/user/form/acup?bookid=" + this.state.bookid + "&print=1"
                  )
                }
              >
                {!IsMobile?"Print":""}
              </a>
            ) : (
              <input
                style={{ height: "35px", lineHeight: "35px" }}
                type="button"
                value="Print"
                onClick={() => this.printPage()}
              ></input>
            )}
          </div>
        </div>
        <div className="PageNext" id="content">
          {this.state.isprint && (
            <div className="printtitle">{this.state.title}</div>
          )}
          <div className="row" style={{ paddingTop: "10px" }}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  Client Name:
                  <span style={LCss.field}>{this.state.customer}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  Date:
                  <span
                    style={Object.assign({}, LCss.field, {
                      width: "150px",
                    })}
                  >
                    {this.state.periodno}
                  </span>
                  Duration:
                  <span
                    style={Object.assign({}, LCss.field, {
                      width: "150px",
                    })}
                  >
                    {this.state.minutes} min
                  </span>
                  Time:
                  <span
                    style={Object.assign({}, LCss.field, {
                      width: "150px",
                    })}
                  >
                    {this.state.fromtime} - {this.state.totime}
                  </span>
                  Practitioner:
                  <span
                    style={Object.assign({}, LCss.field, {
                      width: "100px",
                      marginRight: "0px",
                    })}
                  >
                    {this.state.employeename}
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  Progress Illness:
                  <span
                    style={Object.assign({}, LCss.field, {
                      borderBottom: "0px",
                    })}
                  >
                    <input
                      className="printField"
                      style={{ width: "360px" }}
                      value={this.state.progressillness}
                      onChange={(ref) =>
                        this.changeField(ref, "progressillness")
                      }
                    />
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  Tongue:
                  <span
                    style={Object.assign({}, LCss.field, {
                      borderBottom: "0px",
                    })}
                  >
                    <input
                      className="printField"
                      style={{ width: "150px" }}
                      value={this.state.tongue}
                      onChange={(ref) => this.changeField(ref, "tongue")}
                    />
                  </span>
                  coating:
                  <span
                    style={Object.assign({}, LCss.field, {
                      borderBottom: "0px",
                    })}
                  >
                    <input
                      className="printField"
                      style={{ width: "150px" }}
                      value={this.state.coating}
                      onChange={(ref) => this.changeField(ref, "coating")}
                    />
                  </span>
                  Pulse:
                  <span
                    style={Object.assign({}, LCss.field, {
                      borderBottom: "0px",
                    })}
                  >
                    <input
                      className="printField"
                      style={{ width: "150px" }}
                      value={this.state.pulse}
                      onChange={(ref) => this.changeField(ref, "pulse")}
                    />
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  New Diagnosis and Principles:
                  <span
                    style={Object.assign({}, LCss.field, {
                      borderBottom: "0px",
                    })}
                  >
                    <input
                      className="printField"
                      style={{ width: "360px" }}
                      value={this.state.news}
                      onChange={(ref) => this.changeField(ref, "news")}
                    />
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  Herbs:
                  <span
                    style={Object.assign({}, LCss.field, {
                      borderBottom: "0px",
                    })}
                  >
                    <input
                      className="printField"
                      style={{ width: "360px" }}
                      value={this.state.herbs}
                      onChange={(ref) => this.changeField(ref, "herbs")}
                    />
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  Acupuncture:
                  <span
                    style={Object.assign({}, LCss.field, {
                      borderBottom: "0px",
                    })}
                  >
                    <input
                      className="printField"
                      style={{ width: "360px" }}
                      value={this.state.acupuncture}
                      onChange={(ref) => this.changeField(ref, "acupuncture")}
                    />
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  Other treatment(s):
                  <span
                    style={Object.assign({}, LCss.field, {
                      borderBottom: "0px",
                    })}
                  >
                    <input
                      className="printField"
                      style={{ width: "360px" }}
                      value={this.state.othertreatment}
                      onChange={(ref) =>
                        this.changeField(ref, "othertreatment")
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ paddingTop: "25px" }}>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter">
            <button className="submit" onClick={() => this.submit()}>
              Submit
            </button>
          </div>
        </div>
        <div className="row">
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter"
            style={{ width: "70%", marginTop: "10px" }}
          >
            {this.state.postresult}
          </div>
        </div>
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
  back() {
    if (window.confirm("Do you need to save the form?")) {
      this.submit();
      window.history.back();
    } else window.history.back();
  }
  changeField(e, field) {
    let val = e.target.value;
    if (field == "progressillness")
      this.setState({
        progressillness: val,
      });
    else if (field == "tongue") {
      this.setState({
        tongue: val,
      });
    } else if (field == "coating")
      this.setState({
        coating: val,
      });
    else if (field == "pulse")
      this.setState({
        pulse: val,
      });
    else if (field == "herbs")
      this.setState({
        herbs: val,
      });
    else if (field == "acupuncture")
      this.setState({
        acupuncture: val,
      });
    else if (field == "othertreatment")
      this.setState({
        othertreatment: val,
      });
    else if (field == "news")
      this.setState({
        news: val,
      });
  }

  submit() {
    const { myusername, ukey } = this.props;
    // document.getElementById("result2").style.color = "#000000";

    this.setState({
      postresult: "Waiting....",
    });
    // document.getElementById("result2").innerHTML = "正在提交....";

    //var gzhnickname = this.state.upgzhnickname;
    //var comname = this.state.upcomname;

    const postdata = {
      editid: this.state.editid,
      bookid: this.state.bookid,

      progressillness: this.state.progressillness,
      news: this.state.news,
      tongue: this.state.tongue,
      coating: this.state.coating,
      pulse: this.state.pulse,
      herbs: this.state.herbs,
      acupuncture: this.state.acupuncture,
      othertreatment: this.state.othertreatment,
    };
    console.log(postdata);
    // return;
    const this2 = this;
    var error = "";

    // else if (clientsigndate == "") error = "Please fill in signature date";

    //document.getElementById("result2").style.color = "red";
    if (error !== "") {
      this.setState({
        postresult: error,
      });
    } else {
      $.ajax({
        type: "POST",
        dataType: "json",
        url:
          AJAROOT +
          "/postaja/savexx.aspx?action=saveacup&u=" +
          myusername +
          "&ukey=" +
          encodeURIComponent(ukey) +
          "",
        data: JSON.stringify(postdata),
      })
        .done(function (res) {
          console.log(res);
          var r = res.r;
          var msg = res.msg;

          if (r === "1") {
            //  this.postresult = "登陆成功！";

            this2.setState({
              postresult: "Successfully",
            });
            //if (myusername == this2.state.username)
            //this2.props.history.push("/user");
          } else {
            //document.getElementById("result2").style.color = "red";
            this2.setState({
              postresult: msg,
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    }
  }

  getData(myusername, ukey, bookid) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&bookid=" +
      bookid +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&action=acup";
    console.log(geturl);
    axios.get(geturl).then((res) => {
      const result = res.data;
      console.log(result);
      this.setState({
        editid: result.editid,
        customer: result.customer,

        periodno: result.periodno,
        fromtime: result.fromtime,
        totime: result.totime,
        minutes: result.minutes,
        price: result.price,
        employeename: result.employeename,
        news: result.news,
        progressillness: result.progressillness,
        tongue: result.tongue,
        coating: result.coating,
        pulse: result.pulse,
        herbs: result.herbs,
        acupuncture: result.acupuncture,
        othertreatment: result.othertreatment,
      });
    });
  }
  print(url) {
    window.open(url, "_blank");
  }
  printPage() {
    var orihtml = window.document.body.innerHTML; //从标记里获取需要打印的页面
    // document.getElementById("content").style.padding = "10px";
    var printHtml = document.getElementById("content").innerHTML; //从标记里获取需要打印的页面

    window.document.body.innerHTML = printHtml; //需要打印的页面

    window.print();
    window.document.body.innerHTML = orihtml;
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;
    //const { t } = this.props.match.params;
    var { bookid, print } = queryString.parse(this.props.location.search);
    var title = "Acupuncture";
    document.title = title;
    if (bookid == null) bookid = "";
    if (print == null) print = "";
    if (print == "1") {
      this.setState({
        isprint: true,
      });
    }

    this.setState({
      title: title,
      bookid: bookid,
    });

    //  getUserIndexData(this, myusername, ukey);
    this.getData(myusername, ukey, bookid);
  }

  componentWillUnmount() {
    this.submit();
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {}
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
  };
};

export default connect(mapStateToProps, null)(User);
