import { fromJS } from "immutable";

const defaultState = fromJS({
  //totalpage: 0,
  username: "",
  service: "",
  employee: "",
  fromdate: "",
  todate: "",
  telephone: "",
  order: "",
  oddesc: "",
  page: 1,
});
const todo = (state = defaultState, action) => {
  switch (action.type) {
    case "change_sch_para": {
      // const newState = JSON.parse(JSON.stringify(state));
      //newState.topicList = action.topicList;
      // newState.articleList = action.articleList;
      // newState.recommendList = action.recommendList;

      // return newState;
      return state.merge({
        // totalpage: action.totalpage,
        //  num: action.num,

        username: action.username,
        service: action.service,
        employee: action.employee,
        todate: action.todate,
        fromdate: action.fromdate,
        telephone: action.telephone,
        order: action.order,
        oddesc: action.oddesc,

        page: action.page,
      });
      //    return state.set('topicList',fromJS(action.topicList)).set('articleList',fromJS(action.articleList)).set('recommendList',fromJS(action.recommendList));
    }

    default: {
      return state;
    }
  }
  //return state;
};
export default todo;
