import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { getDomainConfig,isMainDomain } from "@/jscript/common.js";
import { withRouter } from "react-router-dom";
import cookie from "react-cookies";
import { AJAROOT,defaultStore } from "@/common/gdata/global.js";
import "@/css/global.css";

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postresult: "",
    };
  }
  render() {
    // const { postresult } = this.props;
    //  console.log("render");
    return (
      <div className="textcenter" style={{ padding: "20px" }}>
        Logout...
      </div>
    );
  }
  componentDidMount() {
  let isuserdomain = !isMainDomain();
  
    this.logout(isuserdomain);
  }
  logout(isuserdomain) {
    const { myusername, ukey } = this.props;
    const encodeukey = encodeURIComponent(ukey);
    const this2 = this;
    $.ajax({
      type: "GET",
      url:
        AJAROOT +
        "/getaja/xx.aspx?action=logout&uid=" +
        myusername +
        "&ukey=" +
        encodeukey +
        "",
    })
      .done(function (data) {
        var r = data.r;
        var msg = data.msg;

        if (r === 1) {

         const theconfig = getDomainConfig();


          cookie.remove("username", { path: "/" });
          cookie.remove("openid", { path: "/" });
          const action = {
            type: "change_login_data",
            username: "",
            ukey: "",
            usertype: 0,
            usergrade: 1,
            cartnum: 0,

            lastname: "",
            firstname: "",

            parentusername: "",
            dianusername: "",
          //  storeinfo: theconfig.storeinfo,
          };
       
          this2.props.changeLoginData(action);

           if (!isuserdomain) {
            let storeinfo = defaultStore;
       
            const action = {
              type: "CHANGE_DOMAIN",
              logo: AJAROOT + storeinfo.logo,
              sitename: storeinfo.sitename,
              address: storeinfo.address,
              postal: storeinfo.postal,
              telephone: storeinfo.telephone,
              email: storeinfo.email,
              loaded:0
            };
            this2.props.CHANGE_DOMAIN(action);

            if (storeinfo.logo) {
              this2.setState({
                logo: AJAROOT + storeinfo.logo,
                //  telephone:storeinfo.tel
              });
              document.title = storeinfo.sitename;
            }
          }

          this2.props.history.push("/");
        } else {
          this2.setState({
            postresult: msg,
          });
          this2.props.history.push("/");
          //  document.getElementById("result2").innerHTML = msg;
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {
        this2.props.history.push("/");
      });
  }
}
const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    ukey: state.getIn(["gdata", "ukey"]),
  };
};
const mapDispatch = (dispatch) => ({
  changeLoginData(action) {
    dispatch(action);
  },
    CHANGE_DOMAIN(action) {
      dispatch(action);
    },
});

export default connect(mapStateToProps, mapDispatch)(withRouter(Logout));
