import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { AJAROOT } from "@/common/gdata/global.js";
import { IsPC, getDomainConfig } from "@/jscript/common.js";
import axios from "axios";
//import puppeteer from "puppeteer";
import "@/css/home.css";
import "@/css/global.css";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      youxuanlist: [],
      newest: [],
      saleest: [],
      screenWidth: 0,
    };
  }
  render() {
    const IsMobile = !IsPC();
    //JSON.parse(JSON.stringify(cpLb)).le

    const styles = {
      lbtitle: {
        fontSize: "17px",
        fontWeight: "bold",
        padding: "10px",
        color: "#fff",
      },
    };
   
    return (
      <div
        className="container wrap"
        style={{
          width: "100%",
          paddingTop: 0,
        }}
      >
        {IsMobile && <div style={{ paddingTop: "10px" }}></div>}

        <div style={{ padding: "10px", border: "0px solid red" }}>
          <div style={{ fontSize: "22px" }}>
            This is easy-to-use, faster and powerful book system. Register now
            and free try for a month.
          </div>
          <div style={{ fontSize: "22px", marginTop: "30px" }}>
            <span
              style={{
                textAlign: "center",
                background: "#f6f6f6",
                padding: "10px",
                width: "300px",
                display: "inline-block",
              }}
            >
              How To Start
            </span>
          </div>
          <div style={{ fontSize: "17px" }}>
            <div style={{ padding: "10px", marginTop: "10px" }}>
             
             1、Register and login.
            </div>
            <div style={{ padding: "10px" }}>
             
            2、Add branch if needed.
            </div>

            <div style={{ padding: "10px" }}>
             
             3、Setup worktime and upload logo(if you has).

            </div>

            <div style={{ padding: "10px" }}>
             4、Update your store basic infomation at account index, such as tax, company name and store name.

            </div>
             <div style={{ padding: "10px" }}>
             5、Add service catetory from menu.
            </div>
            <div style={{ padding: "10px" }}>
            6、Add your service.

            </div>
            <div style={{ padding: "10px" }}>
            7、Add your employee, and set employee worktime.

            </div>
            <div style={{ padding: "10px" }}>
            8、Click Book to book panel. Here you double-click the time grid and search/add customer, fill in service and submit. It's easy to do all in this panel.

            </div>
           
          </div>

            <button className="submit" style={{width:"300px"}} onClick={()=>{this.props.history.push("/register")}}>
                  Try Now
                  </button>
        </div>
      </div>
    );
  }
  componentDidUpdate() {
  
  }
  componentDidMount() {
   
   
  }
  
}
const mapState = (state) => ({
  myusername: state.getIn(["gdata", "username"]),
  ukey: state.getIn(["gdata", "ukey"]),

  //  dflblist: state.getIn(["home", "dflblist"]),
});

export default connect(mapState, null)(withRouter(Home));
