import React, { Component  } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import SignatureCanvas from "react-signature-canvas";
import $ from "jquery";
import { AJAROOT } from "@/common/gdata/global.js";
//import MySignatureCanvas from "@/jscript/myCanve.js";
import {
  checkUserLogin,
  IsPC,
  MyToast,
  MyToastShow,
} from "@/jscript/common.js";
import axios from "axios";
import "@/css/user.css";
import "@/css/react-datepicker.css";

import DatePicker from "react-datepicker";
let emptysign =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

//import DatePicker from "react-datepicker";

const LCss = {
  agree: {
    padding: "5px 15px",
  },
  signdiv: {
    verticalAlign: "center",
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    alignContent: "left",
    paddingLeft: "8px",
  },
  signcave: {
    height: "52px",
    lineHeight: "52px",
    width: "202px",

    border: "1px solid #ccc",
    position: "relative",
    float: "left",
    textAlign: "left",
  },
  canve: {
    background: "#fff",
    position: "absolute",
    top: 0,
    left: 0,
  },
  radio: {
    width: "18px",
    height: "18px",
    marginLeft: "10px",
    marginRight: "3px",
  },
  conditioncheck: {
    display: "flex",

    alignContent: "center",
    paddingLeft: "5px",
    Height: "25px",
    lineHeight: "25px",
  },
  conditiondiv: {
    width: "100%",
    paddingLeft: "5px",
    fontWeight: "bold",
    height: "30px",
    lineHeight: "30px",
  },
  checkout: {
    width: "18px",
    height: "18px",
    marginRight: "5px",
  },
  field: {
    display: "inline-block",
    paddingLeft: "10px",
    paddingRight: "10px",

    borderBottom: "1px solid #000",
    marginRight: "18px",
  },
};
class Index extends Component {
  constructor(props) {
    super(props);
    this.canvasRef1 = React.createRef();
    this.state = {
      //  myu: "",

      title: "",
      error: "",

      bookid: "",
      customer: "",

      employeename: "",
      sc_area: [],
      sc_indication: "",
      sc_clientsign: emptysign,
      sc_clientsigndate: "",
      haveimg1: 0,
      postresult: "",
      mytoast: {
        show: false,
      },
      isprint: false,
    };
  }

  render() {
    const { myusername } = this.props;
    const IsMobile = !IsPC();
    let checkstatus = checkUserLogin(this.props, myusername);

    if (checkstatus != "") return checkstatus;
    //var zsxm = this.state.zsxm;
    // if (zsxm === "") zsxm = this.state.myusername;

    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px" }}
      >
        <div className="row " style={{ height: "55px", lineHeight: "55px" }}>
          <div
            className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
            onClick={() => {
              this.back();
            }}
          >
            {!this.state.isprint && (
              <span className="iconfont fanhui">&#xe625;</span>
            )}
          </div>
          <div
            className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textcenter "
            style={{ fontSize: "19px", fontWeight: "bolder" }}
          >
            {!this.state.isprint ? this.state.title : ""}
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            {!this.state.isprint ? (
              <a
                onClick={() =>
                  this.print(
                    "/user/form/sensitiveconsent?bookid=" +
                      this.state.bookid +
                      "&print=1"
                  )
                }
              >
                 {!IsMobile?"Print":""}
              </a>
            ) : (
              <input
                style={{ height: "35px", lineHeight: "35px" }}
                type="button"
                value="Print"
                onClick={() =>
                  this.printPage(
                    "/user/form/sensitiveconsent?bookid=" +
                      this.state.bookid +
                      "&print=1"
                  )
                }
              ></input>
            )}
          </div>
        </div>
        <div className="PageNext" id="content">
          {this.state.isprint && (
            <div className="printtitle">{this.state.title}</div>
          )}
          <div className="row" style={{ paddingTop: "10px" }}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  I:
                  <span
                    style={Object.assign({}, LCss.field, {
                      width: "150px",
                    })}
                  >
                    {this.state.customer}
                  </span>
                  (name), have requested assessment and/or treatment by
                  this(name) for treatment of the Registered Massage
                  Therapist(RMT){" "}
                  <span
                    style={Object.assign({}, LCss.field, {
                      width: "150px",
                    })}
                  >
                    {this.state.employeename}
                  </span>
                  (name) for treatment of the clinically relevant areas
                  indicated below (please initial):
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  {this.state.sc_area.map((item, index) => {
                    return (
                      <div
                        key={"sc_area" + index}
                        style={{
                          display: "flex",
                          width: "100%",
                          float: "left",
                          padding: "5px",
                        }}
                      >
                        <input
                          type="checkbox"
                          name="ser"
                          value="Y"
                          onChange={(ref) =>
                            this.changeField2(ref, index, "sc_area")
                          }
                          checked={item.c == "Y" ? true : false}
                          style={{
                            width: "18px",
                            height: "18px",
                            marginRight: "3px",
                          }}
                        ></input>
                        {item.name}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  List Clinical Indication:
                  <span
                    style={Object.assign({}, LCss.field, {
                      borderBottom: "0px",
                    })}
                  >
                    <input
                      className="printField"
                      style={{ width: "700px" }}
                      value={this.state.sc_indication}
                      onChange={(ref) => this.changeField(ref, "sc_indication")}
                    />
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  The RMT has explained the following to me and Ifully
                  understand the proposed assessment and/ortreatment:
                </div>
              </div>

              <div style={LCss.agree}>
                <div>
                  <span className="dot"></span>The nature of assessment,
                  including the clinical reason(s) for assessment of the above
                  area(s)0and the draping methods to be used
                </div>
                <div>
                  <span className="dot"></span>The expected benefits of the
                  assessment
                </div>

                <div>
                  <span className="dot"></span>The potential risks of the
                  assessment
                </div>

                <div>
                  <span className="dot"></span>The potential side effects of the
                  assessment
                </div>
                <div>
                  <span className="dot"></span>The consent is voluntary
                </div>
                <div>
                  <span className="dot"></span>That I can withdraw or alter my
                  consent at anytime
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  I voluntarily give my informed consent for the assessment
                  and/or treatment as discussed and outlinedabove.
                </div>
              </div>

              <div className="row">
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright ">
                  Client Name(print):
                </div>
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textleft ">
                  <span
                    style={Object.assign({}, LCss.field, {
                      width: "150px",
                    })}
                  >
                    {this.state.customer}
                  </span>
                </div>
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright "></div>
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright "></div>
              </div>

              <div
                className="row"
                style={{ paddingTop: "15px", height: "60px" }}
              >
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-3" 
                  style={{ height: IsMobile?"25px":"60px",textAlign:IsMobile?"left":"right" }}
                >
                  Client Signature:
                </div>
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-3 textleft "
                  style={LCss.signdiv}
                >
                  <div style={LCss.signcave}>
                    {this.state.haveimg1 == 0 ? (
                      <SignatureCanvas
                        penColor="black"
                        ref={this.canvasRef1} //用于调用插件的API
                        canvasProps={{
                          width: 200,
                          height: 50,
                          className: "sigCanvas",
                          style: LCss.canve,
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          float: "left",

                          textAlign: "left",
                          height: "50px",
                          lineHeight: "50px",
                        }}
                      >
                        <img src={this.state.sc_clientsign} style={{}}></img>
                      </div>
                    )}
                  </div>
                  {this.props.myusertype == 0 && (
                    <button
                      style={{
                        float: "left",
                        marginLeft: "10px",
                        height: "30px",
                        lineHeight: "30px",
                      }}
                      onClick={() => this.clearCanv(1)}
                    >
                      {this.state.haveimg1 == 1 ? "Reset" : "Clear"}
                    </button>
                  )}
                </div>

                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-6 textleft "
                  style={{
                    height: "50px",
                    verticalAlign: "center",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      float: "left",
                      paddingRight: "15px",
                      height: "50px",
                      lineHeight: "50px",
                    }}
                  >
                    Date:
                  </div>
                  <div
                    style={{
                      float: "left",
                      height: "50px",
                      lineHeight: "50px",
                    }}
                  >
                    <DatePicker
                      className="printField"
                      selected={this.state.sc_clientsigndate}
                      onChange={(ref) =>
                        this.setBirthday(ref, "sc_clientsigndate")
                      }
                      showYearDropdown
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Input date"
                    />
                  </div>
                </div>
              </div>

              <div
                className="row"
                style={{ clear:"both",marginTop: "15px", borderTop: "2px solid #bbb" }}
              >
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  Ongoing Treatment:
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textleft">
                  am aware that the treatment ofthe above indicated area(s) is
                  part of a treatment plan which has beendiscussed with me by my
                  RMT. I confirm that, on the following date(s), the RMT has
                  reviewed thetreatment plan and I provide my informed consent.
                </div>
              </div>
            </div>
          </div>
        </div>
        {(this.props.myusertype == 0||this.props.myusertype == 3) && (
          <div className="row" style={{ paddingTop: "25px" }}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter">
              <button className="submit" onClick={() => this.submit()}>
                Submit
              </button>
            </div>
          </div>
        )}
        <div className="row">
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter"
            style={{ width: "70%", marginTop: "10px" }}
          >
            {this.state.postresult}
          </div>
        </div>
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
  back()
  {
  if(this.props.myusertype==1)
  {
  
  window.history.back();
  return;
  }
  
  if(window.confirm("Do you need to save the form?"))
  {
   this.submit();
   window.history.back();
  }
  else window.history.back();
 
  }
  clearCanv(index) {
    if (index == 1) {
      if (this.state.haveimg1 == 0) this.canvasRef1.current.clear();
      this.setState({
        haveimg1: 0,
      });
    }
  }
  setBirthday(val, field) {
    let _date = val;
    if (val == null) _date = "";
    if (_date != "") {
  /*     let date = new Date(
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      );

      _date = date; */
    }
    if (field == "sc_clientsigndate") {
      this.setState({
        sc_clientsigndate: _date,
      });
    }
  }
  setCheck(ref, field) {
    let val = ref.target.value;

    /*     if (val == "") val = "N";
    if (val == "N") val = "Y";
    else val = "N";
 */
    if (field == "discussed")
      this.setState({
        discussed: val,
      });
    else if (field == "received") {
      this.setState({
        received: val,
      });
    }
  }
  changeField2(ref, index, field) {
    let val = "";
    if (ref.target.checked) {
      val = "Y";
    } else {
      val = "N";
    }

    if (field == "sc_area") {
      let sc_area = this.state.sc_area;
      sc_area[index].c = val;

      this.setState({
        sc_area: sc_area,
      });
    }
  }

  changeField(e, field) {
    let val = e.target.value;
    if (field == "sc_indication")
      this.setState({
        sc_indication: val,
      });
  }

  submit() {
    const { myusername, ukey } = this.props;
    // document.getElementById("result2").style.color = "#000000";

    this.setState({
      postresult: "Waiting....",
    });
    // document.getElementById("result2").innerHTML = "正在提交....";

    //var gzhnickname = this.state.upgzhnickname;
    //var comname = this.state.upcomname;
    let sc_clientsigndate = this.state.sc_clientsigndate;
    if (sc_clientsigndate != "") {
      sc_clientsigndate =
        sc_clientsigndate.getFullYear() +
        "-" +
        (sc_clientsigndate.getMonth() + 1) +
        "-" +
        sc_clientsigndate.getDate();
    }

    let sc_clientsign = this.state.sc_clientsign;
    if (this.state.haveimg1 == 0) {
      sc_clientsign = this.canvasRef1.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      if (sc_clientsign == emptysign) sc_clientsign = "";
    }
    const postdata = {
      bookid: this.state.bookid,
      sc_area: this.state.sc_area,
      sc_indication: this.state.sc_indication,

      sc_clientsigndate: sc_clientsigndate,
      sc_clientsign: sc_clientsign,
    };
    console.log(postdata);
    // return;
    const this2 = this;
    var error = "";

    // else if (clientsigndate == "") error = "Please fill in signature date";

    //document.getElementById("result2").style.color = "red";
    if (error !== "") {
      this.setState({
        postresult: error,
      });
    } else {
      $.ajax({
        type: "POST",
        dataType: "json",
        url:
          AJAROOT +
          "/postaja/savexx.aspx?action=savesensitiveconsent&u=" +
          myusername +
          "&ukey=" +
          encodeURIComponent(ukey) +
          "",
        data: JSON.stringify(postdata),
      })
        .done(function (res) {
          console.log(res);
          var r = res.r;
          var msg = res.s;

          if (r === "1") {
            //  this.postresult = "登陆成功！";

            this2.setState({
              postresult: "Saved Successfully!",
            });
            //if (myusername == this2.state.username)
            //this2.props.history.push("/user");
          } else {
            //document.getElementById("result2").style.color = "red";
            this2.setState({
              postresult: msg,
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    }
  }

  getData(myusername, ukey, bookid) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&bookid=" +
      bookid +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&action=sensitiveconsent";
   // console.log(geturl);
    axios.get(geturl).then((res) => {
      const result = res.data;
     // console.log(result.sc_area);
      let haveimg1 = 0;
      let sc_clientsigndate = result.sc_clientsigndate;
      if (sc_clientsigndate == null) sc_clientsigndate = "";
      if (sc_clientsigndate != "") {
        sc_clientsigndate = new Date(sc_clientsigndate);
      }
      let sc_clientsign = result.sc_clientsign;
      if (sc_clientsign == null) sc_clientsign = "";
      if (sc_clientsign != "") {
        haveimg1 = 1;
      }

      this.setState({
        customer: result.customer,

        periodno: result.periodno,
        fromtime: result.fromtime,
        totime: result.totime,
        minutes: result.minutes,
        price: result.price,
        employeename: result.employeename,

        sc_area: result.sc_area,
        sc_indication: result.sc_indication,
        sc_clientsign: sc_clientsign,
        sc_clientsigndate: sc_clientsigndate,
        haveimg1: haveimg1,
      });
    });
  }
  printPage(url) {
    var orihtml = window.document.body.innerHTML; //从标记里获取需要打印的页面
    document.getElementById("content").style.padding = "10px";
    var printHtml = document.getElementById("content").innerHTML; //从标记里获取需要打印的页面

    window.document.body.innerHTML = printHtml; //需要打印的页面

    window.print();
    window.document.body.innerHTML = orihtml;
    window.open(url, "_self");
  }
  print(url) {
    window.open(url, "_blank");
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;

  //  const { action } = this.props.history;
 /*    console.log(action);
  if (action === "POP") {
    // 如果是返回操作，则不再加载数据
    return;
  } */

    //const { t } = this.props.match.params;
    //const { bookid } = this.props.match.params;

    var { print,bookid } = queryString.parse(this.props.location.search);

    var title = "Consent for Assessment and Treatment of Sensitive Areas";
    document.title = title;
    if (bookid == null) bookid = "";
    if (print == null) print = "";
    if (print == "1") {
      this.setState({
        isprint: true,
      });
    }
    this.setState({
      title: title,
      bookid: bookid,
    });

    //  getUserIndexData(this, myusername, ukey);
    this.getData(myusername, ukey, bookid);
  }

  componentWillUnmount() {
  console.log("saved");
  this.submit();
     this.setState = (state, callback) => {
      return;
    }; /**/
  }

  componentWillUpdate() {}
  componentDidUpdate() {}
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
  };
};

export default connect(mapStateToProps, null)(withRouter(Index));
