import React, { Component } from "react";
import { Provider } from "react-redux";
import { Route, BrowserRouter } from "react-router-dom";
import Header from "./common/header/index.js";
import Bottom from "./common/bottom/index.js";
import Home from "./pages/home/loadable.js";
import Start from "./pages/home/start.js";
import Price from "./pages/home/price.js";
//import Detail from "./pages/detail/index.js";
import Findpass from "./pages/login/findpass.js";
import Resetpass from "./pages/login/resetpass.js";
import Confirmemail from "./pages/login/confirmemail.js";
import Confirmbook from "./pages/user/confirmbook.js";
import Feedback from "./pages/user/feedback.js";
import Login from "./pages/login/loadable.js";
import Logout from "./pages/logout/index.js";
import User from "./pages/user/index.js";
import UpInfo from "./pages/user/userup/upinfo.js";
import UpPwd from "./pages/user/userup/uppwd.js";
/* import Mytj from "./pages/user/mytj.js";
import Hblist from "./pages/user/hblist.js";
import Mylink from "./pages/user/mylink.js";
//import PayOrder from "./pages/user/payorder.js";
import MyCard from "./pages/user/mycard.js";
import AddCard from "./pages/user/addcard.js";
import Group from "./pages/user/group.js";
import Cart from "./pages/cart/index.js";

import Cart2 from "./pages/cart2/index.js";
import Myorder from "./pages/user/myorder/index.js"; */

import Alert from "./pages/alert/index.js";
import Register from "./pages/register/loadable.js";
//import Type from "./pages/type/loadable.js";
import NewsType from "./pages/news/newslist.js";
import NewsView from "./pages/news/newsview.js";
import store from "./store/index.js";
//import Module from "./pages/module/loadable.js";
//import ScrollToTop from "./scrolltop";
//import Sign from "./pages/user/sign.js";
//import HbCz from "./pages/user/hb_cz.js";
//import HbTx from "./pages/user/hb_tx.js";
/* import GroupList from "./pages/user/group/list.js";
import GroupAdd from "./pages/user/group/add.js";
import GroupDel from "./pages/user/group/del.js"; */

import BranchList from "./pages/user/branch/list.js";
import BranchAdd from "./pages/user/branch/add.js";
//import BranchDel from "./pages/user/branch/del.js";

import SrvList from "./pages/user/srvcate/list.js";
import SrvAdd from "./pages/user/srvcate/add.js";
//import SrvDel from "./pages/user/srvcate/del.js";

import ServiceList from "./pages/user/service/list.js";
import ServiceAdd from "./pages/user/service/add.js";
//import ServiceDel from "./pages/user/service/del.js";

import EmployeeList from "./pages/user/employee/list.js";
import EmployeeAdd from "./pages/user/employee/add.js";
//import EmployeeDel from "./pages/user/employee/del.js";

import CustomerList from "./pages/user/customer/list.js";
import CustomerAdd from "./pages/user/customer/add.js";
//import CustomerDel from "./pages/user/customer/del.js";
import NoteList from "./pages/user/customer/notelist.js";
import PlanList from "./pages/user/customer/planlist.js";

import BookList from "./pages/user/book/list.js";
import BookAdd from "./pages/user/book/add.js";
//import BookDel from "./pages/user/book/del.js";
import BookPanel from "./pages/user/book/panel.js";
import WorkTime from "./pages/user/employee/worktime.js";

import Setup from "./pages/user/system/index.js";

//import RoutineList from "./pages/user/routine/list.js";
//import RoutineAdd from "./pages/user/routine/add.js";
//import RoutineDel from "./pages/user/routine/del.js";
import Health from "./pages/user/form/health.js";
import Eclaim from "./pages/user/form/eclaim.js";
import BasicForm from "./pages/user/form/basic.js";
import Spa from "./pages/user/form/spa.js";
import Treatnote from "./pages/user/form/treatnote.js";
import Acup from "./pages/user/form/acup.js";
import Treatplan from "./pages/user/form/treatplan.js";

import GiftList from "./pages/user/gift/list.js";
import GiftAdd from "./pages/user/gift/add.js";
//import GiftDel from "./pages/user/gift/del.js";
import SensitiveConsent from "./pages/user/form/sensitiveconsent.js";
//import edituser from "./pages/user/edituser.js";
//import notelist from './pages/user/customer/notelist.js';
import Chat from "./pages/chat/index.js";
class App extends Component {
  render() {
    //console.log(window.location);
    //window.location.href = AJAROOT;
    return (
      <Provider store={store}>
        <BrowserRouter>
       {/*    <ScrollToTop> */}
            <Header />
            
            <Route path="/user" exact component={User} />
            <Route path="/" exact component={Home} />
              <Route path="/start" exact component={Start} />
           <Route path="/price" exact component={Price} />
         
           {/*  <Route path="/detail/:cpid" exact component={Detail} /> */}
            <Route path="/register" exact component={Register} />
            <Route path="/login" exact component={Login} />
            <Route path="/findpass" exact component={Findpass} />
            <Route path="/resetpass" exact component={Resetpass} />
            <Route path="/confirmemail" exact component={Confirmemail} />
            <Route path="/confirmbook" exact component={Confirmbook} />
            <Route path="/feedback" exact component={Feedback} />
            <Route path="/logout" exact component={Logout} />
            <Route path="/health" exact component={Alert} />
           {/*  <Route path="/user/mytj" exact component={Mytj} />
            <Route path="/user/hblist" exact component={Hblist} />
            <Route path="/user/mylink" exact component={Mylink} /> */}
          {/*   <Route path="/user/payorder" exact component={PayOrder} /> */}
       {/*      <Route path="/user/group" exact component={Group} />
            <Route path="/user/mycard" exact component={MyCard} />
            <Route path="/user/addcard" exact component={AddCard} /> */}
             <Route path="/alert" exact component={Alert} />
      {/*       <Route path="/user/hb_cz" exact component={HbCz} />
            <Route path="/user/hb_tx" exact component={HbTx} />
            <Route path="/cart" exact component={Cart} />
            <Route path="/cart2" exact component={Cart2} /> */}
           
         {/*    <Route path="/user/myorder/:isjs" component={Myorder} /> */}

            {/*  <Route path="/type/:typeid" component={Type}></Route> */}
            {/* <Route path="/type" component={Type}></Route>
            <Route path="/module" component={Module}></Route> */}
            <Route path="/newslist" component={NewsType}></Route>
            <Route path="/newsview" component={NewsView}></Route>
            <Route path="/userup/upinfo" exact component={UpInfo}></Route>
            <Route path="/userup/uppwd" exact component={UpPwd}></Route>

            <Route path="/user/srvcate/list" component={SrvList} />
            <Route path="/user/srvcate/add" component={SrvAdd} />
   {/*          <Route path="/user/srvcate/del" component={SrvDel} /> */}
            <Route path="/user/branch/list" component={BranchList} />
            <Route path="/user/branch/add" component={BranchAdd} />
          {/*   <Route path="/user/branch/del" component={BranchDel} /> */}
            <Route path="/user/service/list" component={ServiceList} />
            <Route path="/user/service/add" component={ServiceAdd} />
         {/*    <Route path="/user/service/del" component={ServiceDel} /> */}

            <Route path="/user/employee/list" component={EmployeeList} />
            <Route path="/user/employee/add" component={EmployeeAdd} />
         {/*    <Route path="/user/employee/del" component={EmployeeDel} /> */}
            <Route path="/user/employee/worktime" component={WorkTime} />
            <Route path="/user/customer/list" component={CustomerList} />
            <Route path="/user/customer/add" component={CustomerAdd} />
           {/*  <Route path="/user/customer/del" component={CustomerDel} /> */}
  <Route path="/user/customer/notelist" component={NoteList} />
          
            <Route path="/user/customer/planlist" component={PlanList} />
          
            <Route path="/user/book/list" component={BookList} />
            <Route path="/user/book/add" component={BookAdd} />
     {/*        <Route path="/user/book/del" component={BookDel} /> */}
            <Route path="/user/book/panel" component={BookPanel} />

          {/*   <Route path="/user/routine/list" component={RoutineList} />
            <Route path="/user/routine/add" component={RoutineAdd} />
            <Route path="/user/routine/del" component={RoutineDel} /> */}

            <Route path="/user/system/setup" component={Setup} />
            <Route path="/user/form/basic" component={BasicForm} />
            <Route path="/user/form/spa" component={Spa} />
            <Route path="/user/form/health" component={Health} />
            <Route path="/user/form/eclaim" component={Eclaim} />
            <Route path="/user/form/treatnote" component={Treatnote} />
            <Route path="/user/form/acup" component={Acup} />
            <Route path="/user/form/treatplan" component={Treatplan} />
            <Route
              path="/user/form/sensitiveconsent"
              component={SensitiveConsent}
            />

            <Route path="/user/gift/list" component={GiftList} />
            <Route path="/user/gift/add" component={GiftAdd} />
            <Route path="/chat" component={Chat} />
    {/*         <Route path="/user/gift/del" component={GiftDel} /> */}
{/*    <Route path="/user/edituser" component={edituser} /> */}

            <Bottom />
        {/*   </ScrollToTop> */}
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
